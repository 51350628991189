<template>
  <div>
    <!-- DATOS DEL CLIENTE -->
    <b-container>
      <b-row class="bg-rosa text-burdeo">
        <b-col>
          <h2>Datos del Cliente</h2>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col md="12" lg="4">
          <vue-input-ui
            label=""
            size="lg"
            hint="Nombre del Cliente"
            v-model="venta.cliente"
          ></vue-input-ui>
        </b-col>
        <!-- <b-col md="12" lg="4">
          <vue-input-ui
            v-model="venta.direccion"
            label=""
            size="lg"
            hint="Dirección"
          ></vue-input-ui>
        </b-col> -->
        <b-col md="12" lg="4">
          <InputWhatsapp
            @ingresado="venta.telefono = $event"
            :value="venta.telefono"
            class="text-burdeo"
          ></InputWhatsapp>
        </b-col>
        <b-col md="12" lg="4">
          <b-form-input
            type="datetime-local"
            v-model="venta.fechaCompra"
          ></b-form-input>
        </b-col>
      </b-row>
      <!-- DATOS DE LA COMPRA -->
      <!-- <b-row class="my-1"> -->
        <!-- <b-col md="12" lg="6">
          <b-form-select
            v-model="venta.estadoId"
            :options="estados"
            value-field="id"
            text-field="nombre"
            disabled-field="notEnabled"
            class="w-100 h-100"
          ></b-form-select>
        </b-col> -->
      <!-- </b-row> -->
            <!-- DATOS DE LA ENTREGA -->
            <b-container>
            <b-row class="bg-rosa text-burdeo">
              <b-col cols="12">
                <h2>Datos de la Entrega</h2>
              </b-col>
                <b-col md="12" lg="4">
                  <vue-input-ui
                    v-model="venta.direccion"
                    label=""
                    size="lg"
                    hint="Dirección"
                  ></vue-input-ui>
                </b-col>
              <b-col md="12" lg="4">
                <b-form-input
                  type="date"
                  v-model="venta.fechaDelivery"
                  hint="Fecha del Delivery"
                ></b-form-input>
              </b-col>
              <b-col md="12" lg="4">
                <b-form-select
                  v-model="venta.estadoId"
                  :options="estados"
                  value-field="id"
                  text-field="nombre"
                  disabled-field="notEnabled"
                  class="w-100 h-100"
                ></b-form-select>
              </b-col>
              <b-col md="12" lg="12">
                <b-form-textarea
                  v-model="venta.notas"
                  debounce="500"
                  rows="3"
                  class="w-100"
                  placeholder="Notas"
                ></b-form-textarea>
              </b-col>
            </b-row>
          </b-container>

      <!-- DATOS DE LOS PRODUCTOS -->
      <b-row class="bg-rosa text-burdeo">
        <b-col>
          <h2>Datos de los Productos</h2>
        </b-col>
      </b-row>
      <!-- INGRESO DE PRODUCTOS MASIVOS -->
      <b-row class="my-1">
        <b-col header-tag="header" class="p-1" role="tab">
          <b-button class="bg-burdeo" block v-b-toggle.accordion-1
            >Ingreso Masivo</b-button
          >
        </b-col>
        <b-collapse
          class="col-12"
          id="accordion-1"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-row>
            <b-col>
              <b-card-body>
                <div class="col-12">
                  <textarea
                    v-model="inputMasivo"
                    rows="10"
                    placeholder="Ingrese aquí el mensaje de compra"
                    class="boxsizingBorder w-100"
                  >
                  </textarea>
                  <b-button
                    class="w-100 btn-sexduceme"
                    @click="procesarMasivo()"
                  >
                    Procesar
                  </b-button>
                </div>
              </b-card-body>
            </b-col>
          </b-row>
        </b-collapse>
      </b-row>
      <!-- INGRESO DE PRODUCTOS INDIVIDUALES -->
      <b-row align-v="center">
        <b-col cols="8" class="my-1">
          <b-overlay :show="cargando > 0" rounded="sm">
            <b-form-textarea
              id="busqueda"
              v-model="codigoBuscado"
              placeholder="Buscar Código"
              rows="1"
              max-rows="2"
              @focus="limpiarBusqueda()"
              @blur="searchProduct(codigoBuscado)"
            ></b-form-textarea>
          </b-overlay>
        </b-col>
        <b-col>
          <b-button @click="showSelectorModal()" class="btn-sexduceme">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-col>

        <!-- MODALIDADES -->
        <b-col>
          <b-form-checkbox
            @change="focalizar('busqueda')"
            v-model="modos.modoFeria"
            switch
            ><span class="px-1">Modo Feria</span></b-form-checkbox
          >
        </b-col>
        <b-col>
          <b-form-checkbox
            @change="focalizar('busqueda')"
            v-model="modos.modoMerma"
            switch
            ><span class="px-1">Mermar</span></b-form-checkbox
          >
        </b-col>
      </b-row>
      <!-- RESULTADO DE LOS PRODUCTOS -->
      <b-row>
        <b-col md="12" lg="4">
          {{ productoAgregado.producto }}
        </b-col>
        <b-col md="6" lg="2">
          <vue-input-ui
            label=""
            size="lg"
            min="0"
            :max="productoBuscado.stock"
            :hint="
              'Cantidad ' +
              (productoBuscado.stock ? '(' + productoBuscado.stock + ')' : '')
            "
            type="number"
            v-model="productoAgregado.cantidad"
            :disabled="productoBuscado.id ? false : true"
          ></vue-input-ui>
        </b-col>
        <b-col md="6" lg="2">
          <vue-input-ui
            v-model="productoAgregado.descuento"
            label=""
            size="lg"
            hint="Descuento"
            type="number"
            step="0.01"
            @change="actualizarAgregado()"
            :disabled="productoBuscado.id ? false : true"
          ></vue-input-ui>
        </b-col>
        <b-col md="12" lg="4">
          <vue-input-ui
            v-model="productoAgregado.precio"
            label=""
            size="lg"
            :hint="
              'Valor Final ' +
              (productoBuscado.precio
                ? formatPrice(aplicarDescuento(productoAgregado.precio,productoAgregado.descuento))
                : '')
            "
            type="number"
            step="10"
            :disabled="productoBuscado.id ? false : true"
          ></vue-input-ui>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col cols="6">
          <b-button
            @click="venta.vaciarProductos()"
            block
            variant="danger"
            :disabled="!venta.productos.length > 0"
            ><b-icon icon="trash"></b-icon
          ></b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            :disabled="!productoAgregado.produtoId || cargando > 0"
            block
            variant="success"
            @click="agregarAlCarro(productoAgregado)"
            ><b-icon icon="plus-circle"></b-icon
          ></b-button>
        </b-col>
      </b-row>

      <!-- TABLA DE PRODUCTOS -->
      <b-row>
        <b-col>
          <TableProducts
            :productos="venta.productos"
            @eliminar="eliminarDelCarro($event)"
          ></TableProducts>
        </b-col>
      </b-row>
      <!-- MEDIOS DE PAGO -->
      <b-row>
        <b-col>
          <PaySelector
            :totalCompra="resumen.total"
            @selected="venta.medioPagoId = $event"
          ></PaySelector>
        </b-col>
      </b-row>
      <!-- BOTONERA FINAL -->
      <b-row class="my-2">
        <b-col cols="4">
          <b-button @click="limpiarVenta()" block variant="danger">
            <b-icon flip-h icon="reply-all"></b-icon>
            Limpiar
          </b-button>
        </b-col>
        <!-- <b-col cols="4">
          <b-button
            @click="copiarDeliveryData()"
            block
            variant="info"
            class="h-100"
            :disabled="!venta.productos.length > 0 || cargando > 0"
          >
            <b-icon icon="truck"></b-icon>
            Copiar
          </b-button>
        </b-col> -->
        <b-col cols="4">
          <b-button
            @click="sendDeliemprendeData()"
            block
            variant="info"
            class="h-100"
            :disabled="!venta.productos.length > 0 || cargando > 0 || !this.readyDataDelivery()"
          >
            <b-icon icon="truck"></b-icon>
            Agendar Delivery {{this.venta.fechaDelivery?this.venta.fechaDelivery:""}}
          </b-button>
        </b-col>
        <b-col cols="4">
          <b-button
            @click="copiarDetalle()"
            block
            variant="info"
            class="h-100"
            :disabled="!venta.productos.length > 0 || cargando > 0"
          >
            <b-icon icon="clipboard"></b-icon>
            Copiar
          </b-button>
        </b-col>
        <b-col cols="12" class="mt-2">
          <b-button
            @click="saveVenta(venta)"
            block
            variant="success"
            :disabled="!venta.productos.length > 0 || cargando > 0"
          >
            <b-icon icon="cloud-upload"></b-icon>
            {{ venta.id ? "Editar" : "Guardar" }}
          </b-button>
        </b-col>
      </b-row>
      <b-button @click="showData()"> show Data </b-button>
    </b-container>

    <b-modal
      v-show="showSeleccion"
      id="modal-selector"
      size="xl"
      title="Búsqueda por Palabras Claves"
      ><ProductSelector
        @seleccion="seleccionarProducto($event)"
      ></ProductSelector
    ></b-modal>
  </div>
</template>
<script>
import InputWhatsapp from "@/components/inputs/InputWhatsapp.vue";
import TableProducts from "@/components/tables/TableProducts.vue";
import PaySelector from "@/components/inputs/PaySelector.vue";
import ProductSelector from "@/components/layouts/ProductSelectorLayout.vue";
const axios = require("@/assets/functions/axiosClient.js");
const {
  showError,
  showWarning,
  showSuccess,
} = require("@/assets/functions/mensajes.js");
const ProductoAgregar = require("@/models/ProductoAgregar.js");
const Venta = require("@/models/Venta.js");
const { formatPrice } = require("@/assets/functions/formaters");
const { resumenCarro } = require("@/assets/functions/calculosFinancieros");
const { aplicarDescuento } = require('@/assets/functions/calculosFinancieros.js')

const moment = require('moment')
// const _ = require("lodash");
const stockUmbral = 3;
export default {
  name: "POSVenta",
  props: ["ventaId"],
  components: {
    InputWhatsapp,
    TableProducts,
    PaySelector,
    ProductSelector,
  },
  watch: {
    ventaId: async function (newValue) {
      if (newValue) {
        await this.getVentaById(newValue);
      }
    },
    codigoBuscado: async function (newValue) {
      if (newValue && newValue.toString().includes("\n")) {
        this.searchProduct(newValue);
      }
    },
  },
  methods: {
    ...moment,
    resumenCarro,
    formatPrice,
    aplicarDescuento,
    async getVentaById(ventaId) {
      await axios
        .get("/inventarios/ventas/" + ventaId)
        .then((response) => {
          if (response.status === 200) {
            this.venta = new Venta(response.data.data);
            // this.venta.fechaCompra = moment(this.venta.fechaCompra).format(
            //   "yyyy-MM-DDTHH:mm"
            // );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    saveVenta(venta) {
      this.cargando++;
      if (!venta.id) {
        venta.setEstado(this.modos);
        venta.setSucursal(this.modos);
        venta.setCliente(this.modos);
      }
      console.log(venta);
      axios
        .post("/inventarios/ventas", this.venta)
        .then((response) => {
          console.log("Respondió: " + response);
          if (response.status === 201) {
            showSuccess(
              this,
              "Venta Guardada",
              "La venta se registrado correctamente: " + response.data.data.id
            );
            this.limpiarVenta();
          }
        })
        .catch((err) => {
          showError(this, "Error al Guardar", err.response.data);
        })
        .finally(() => {
          this.cargando--;
        });
    },
    limpiarBusqueda() {
      this.codigoBuscado = null;
      this.productoBuscado = {};
      this.productoAgregado = new ProductoAgregar();
    },
    limpiarVenta() {
      this.venta = new Venta();
      this.inputMasivo = "";
      this.resumen = resumenCarro(this.venta.productos);
    },
    focalizar(id) {
      document.getElementById(id).focus();
    },
    actualizarAgregado() {
      console.log("Vamo'h actualizar");
      this.productoAgregado.actualizar();
    },
    showData() {
      console.log(this.venta);
    },
    async agregarAlCarro(producto) {
      this.cargando++;
      await this.venta.agregarProducto(producto).finally(() => {
        this.cargando--;
      });
      this.resumen = resumenCarro(this.venta.productos);
      this.limpiarBusqueda();
    },
    eliminarDelCarro(codigo) {
      this.cargando++;
      this.venta.eliminarProducto(codigo).finally(() => {
        this.cargando--;
      });
    },
    cargarEstados() {
      this.cargando++;
      axios
        .get("/estados-ventas")
        .then((response) => {
          if (response.status === 200) {
            this.estados = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          showError(this, "", "Ocurrió un error al cargar los estados");
        })
        .finally(() => {
          this.cargando--;
        });
    },
    seleccionarProducto(producto) {
      console.log("En POS: ");
      console.log(producto)
      // this.productoBuscado = producto;
      this.searchProduct(producto.codigo)
      this.hideSelectorModal()
    },
    searchProduct(codigo) {
      if (!codigo) return;
      this.cargando++;
      return axios
        .get("/inventarios/productos/codigo/" + codigo)
        .then((response) => {
          if (response.status === 200) {
            this.productoBuscado = response.data.data;
            if (this.productoBuscado.stock > stockUmbral) {
              showSuccess(
                this,
                "Encontrado",
                "Producto Encontrado: " + this.productoBuscado.nombre
              );
            } else if (this.productoBuscado.stock > 0) {
              showWarning(
                this,
                "Encontrado [POCAS UNIDADES]",
                "Producto Encontrado: " +
                  this.productoBuscado.nombre +
                  " [" +
                  this.productoBuscado.stock +
                  "]"
              );
            } else {
              showError(
                this,
                "Encontrado [SIN UNIDADES]",
                "Producto Encontrado: " +
                  this.productoBuscado.nombre +
                  " [" +
                  this.productoBuscado.stock +
                  "]"
              );
            }
            if (this.modos.modoMerma === true) {
              this.productoBuscado.precio = 0;
            }
            if (this.modos.modoFeria === true) {
              this.agregarAlCarro(new ProductoAgregar(this.productoBuscado));
            } else {
              this.productoAgregado = new ProductoAgregar(this.productoBuscado);
            }
          }
        })
        .finally(() => {
          this.cargando--;
        });
    },
    async copiarDetalle() {
      this.cargando++;
      const aux = document.createElement("textarea");
      aux.innerHTML = await this.venta.getDetail();
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      showSuccess(this, "", "Copiado");
      this.cargando--;
    },
    showSelectorModal() {
      this.$bvModal.show("modal-selector");
    },
    hideSelectorModal() {
      this.$bvModal.hide("modal-selector");
    },
    async copiarDeliveryData() {
      this.cargando++;
      try {
        const aux = document.createElement("textarea");
        aux.innerHTML = await this.venta.getDeliveryData();
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
        showSuccess(this, "", "Copiado");
      } catch (err) {
        console.log(Object.keys(err));
        showError(this, "", err.message);
      }
      this.cargando--;
    },
    async procesarMasivo() {
      const lineas = this.inputMasivo.split("\n");
      const productosComprar = [];
      lineas.forEach((linea) => {
        if (linea.includes("(cod: ")) {
          const palabras = linea.split(" ");
          productosComprar.push({
            cantidad: palabras[0],
            codigo: linea
              .replaceAll("*", "")
              .split("(cod: ")[1]
              .replace(")", "\n"),
          });
        } else if (linea.includes("Medio de entrega: ")) {
          this.metodoEntrega = linea.split("Medio de entrega: ")[1];
          console.log(this.metodoEntrega);
        } else if (
          linea.includes("Dirección de Entrega: ") &&
          this.metodoEntrega === "Delivery"
        ) {
          this.venta.direccion = linea.split("Dirección de Entrega: ")[1];
        }
      });

      const aux = this.modos.modoFeria;
      this.modos.modoFeria = true;
      console.log("VAMOS A AGREGAR");
      for (const producto of productosComprar) {
        for (let i = 0; i < producto.cantidad; i++) {
          this.productoAgregado.cantidad = producto.cantidad;
          this.codigoBuscado = producto.codigo;
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
      this.modos.modoFeria = aux;
      showSuccess(
        this,
        "PROCESO MASIVO TERMINADO",
        "El proceso de agregada de productos masivos ha terminado exitosamente F"
      );
    },
    async sendDeliemprendeData() {
      const venta = this.venta;
      if (!this.venta.fechaDelivery) {
        alert("Selecciona una fecha de delivery")
      } else {
        const data = {
            contact_name: venta.cliente,
            contact_phone: "+" + venta.telefono,
            notes: venta.notas,
            address: venta.direccion,
            planned_date: venta.fechaDelivery,
            reference: venta.id
        }
        try {
          console.log(data)
          const auxResponse = await axios.post("/deliverys/visitas", data)
          if (auxResponse.status === 200) {
            alert("Creada correctamente")
          } else {
            alert("Error en el envío")
          }
        } catch (e) {
          alert("Error al enviar data", e)
        }
      }
    },
    readyDataDelivery() {
      console.log(this.venta);
      return (this.venta.cliente && this.venta.direccion && this.venta.id && this.venta.telefono)
    }
  },
  data() {
    return {
      showSeleccion: false,
      cargando: 0,
      inputMasivo: "",
      resumen: {
        total: 0,
        bultos: 0,
      },
      venta: new Venta(),
      codigoBuscado: "",
      productoBuscado: {},
      productoAgregado: new ProductoAgregar(),
      modos: {
        modoFeria: false,
        modoMerma: false,
      },
      estados: [],
    };
  },
  created() {
    this.cargarEstados();
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
    const value = params.venta_code; // "some_value"
    console.log(value);
    this.ventaId = value;
  },
  computed: {
    // this.productoAgregado.
  },
};
</script>