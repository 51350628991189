const moment = require('moment')
const _ = require('lodash')
const axios = require('@/assets/functions/axiosClient')
const { formatPrice } = require('@/assets/functions/formaters')
const estados = {
    Reservado: 2,
    Pagado: 3,
    Recibido: 4,
    Anulado: -1,
    Solicitado: 1
}

const sucursales = {
    casaMatriz: 1,
    feria: 2
}

module.exports = class Venta {
    constructor(venta) {
        if (venta) {
            this.id = venta.id;
            this.cliente = venta.cliente;
            this.direccion = venta.direccion;
            this.estadoId = venta.estadoId;
            this.medioPagoId = venta.medioPagoId;
            this.sucursalId = venta.sucursalId;
            this.fechaCompra = moment(venta.fechaCompra).format("yyyy-MM-DDTHH:mm");
            this.telefono = venta.telefono;
            this.productos = venta.productos;
            this.fechaDelivery = venta.fechaDelivery;
        } else {
            this.cliente = "";
            this.direccion = "";
            this.estadoId = 2;
            this.medioPagoId = 1;
            this.sucursalId = 1;
            this.fechaCompra = moment().format("yyyy-MM-DDTHH:mm");
            this.id = null;
            this.telefono = null;
            this.productos = [];
            this.fechaDelivery = null;
        }
    }

    async getPriceDelivery() {
        let costoDelivery = 0;
        if (this.direccion) {
            await axios.get("/public/integraciones/google/bussiness/sexduceme/deliverys-cost/" + this.direccion)
                .then(response => {
                    if (response.status === 200) {
                        costoDelivery = response.data.costo
                    }
                }).catch(() => {
                    costoDelivery = -1;
                })
        }
        return costoDelivery
    }

    async agregarProducto(producto) {
        const auxProductos = JSON.stringify(this.productos);
        const search = _.find(this.productos, {
            codigo: producto.codigo,
        });
        if (search) {
            search.cantidad = Number(producto.cantidad) + Number(search.cantidad);
        } else {
            this.productos.unshift(producto);
        }

        if (this.id) {
            try {
                producto.cantidad = search ? search.cantidad : producto.cantidad;
                await this.agregarProductoVenta(producto);
            } catch (err) {
                console.log(auxProductos)
                this.productos = JSON.parse(auxProductos);
            }
        }
        this.updateFecha();
    }

    async eliminarProducto(codigo) {
        const auxProductos = JSON.stringify(this.productos)
        const indexItem = _.findIndex(this.productos, {
            codigo: codigo
        });
        try {
            if (this.id) {
                await this.eliminarProductoVenta(this.productos[indexItem])
            }
            this.productos.splice(indexItem, 1);
        } catch (err) {
            console.log(err.message)
            this.productos = JSON.parse(auxProductos)
        }
        this.updateFecha();
    }

    async vaciarProductos() {
        try {
            if (this.id) {
                await this.vaciarProductosVenta();
            }
            this.productos = [];
            this.updateFecha();
        } catch (err) {
            console.log(err)
        }
    }

    updateFecha() {
        this.fechaCompra = moment().format("yyyy-MM-DDTHH:mm");
    }

    setEstadoId(estadoId) {
        this.estadoId = estadoId;
    }

    setEstado(modos) {
        if (this.estadoId !== estados.Recibido && this.estadoId !== estados.Pagado) {
            if (modos.modoFeria === true || modos.modoMerma === true) {
                this.setEstadoId(estados.Recibido);
            } else {
                this.setEstadoId(estados.Reservado);
            }
        }
    }

    setSucursalId(sucursalId) {
        this.sucursalId = sucursalId;
    }

    setSucursal(modos) {
        if (modos.modoFeria === true) {
            this.setSucursalId(sucursales.feria);
        } else {
            this.setSucursalId(sucursales.casaMatriz);
        }
    }

    setCliente(cliente) {
        if (!this.cliente) {
            if (typeof cliente === 'string') {
                this.cliente = cliente;
            } else if (typeof cliente === 'object') {
                const modos = cliente;
                if (modos.modoFeria === true) {
                    this.cliente = 'FERIA'
                } else if (modos.modoMerma === true) {
                    this.cliente = 'MERMA'
                }
            }
        }
    }

    async getDetail() {
        let total = 0;
        let detalle = "Detalle de su pedido:\n\n";
        const costoDelivery = await this.getPriceDelivery();
        const metodoEntrega = this.direccion ? "Delivery" : "Retiro"

        this.productos.forEach((producto) => {
            detalle +=
                producto.cantidad +
                " " +
                producto.producto +
                ": " +
                formatPrice(producto.precio * producto.cantidad) +
                "\n";
            total += producto.cantidad * producto.precio;
        });

        detalle += "\nSubtotal: " + formatPrice(total);

        detalle +=
            "\n\nEntrega (" +
            metodoEntrega +
            "): " +
            formatPrice(costoDelivery) +
            "\n\nTotal a Pagar: " +
            formatPrice(total + costoDelivery);
        return detalle;
    }

    async getDeliveryData() {
        if (!this.cliente) {
            throw new Error("No se ha identificado el [NOMBRE] del Cliente");
        } else if (!this.telefono) {
            throw new Error("No se ha identificado el [TELÉFONO] del Cliente");
        } else if (!this.direccion) {
            throw new Error("No se ha identificado la [DIRECCIÓN] del Cliente");
        } else {
            return "Toledo y Robledo Limitada\n" +
                "997322024\n" +
                "Parque Magallanes 1460, Coquimbo\n" +
                "Bosque San Carlos\n" +
                this.cliente + "\n" +
                this.telefono + "\n" +
                this.direccion + "\n" +
                "\n" +
                "pyme\n" +
                "transferencia\n" +
                "pendiente\n" +
                await this.getPriceDelivery() + "\n" +
                "1000"
        }
    }

    async agregarProductoVenta(search) {
        console.log(search)
        await axios
            .post("/inventarios/ventas/" + this.id + "/productos", {
                produtoId: search.produtoId,
                cantidad: search.cantidad,
                precio: search.precio,
            })
            .then((response) => {
                if (response.status === 201) {
                    console.log("Guardado" + response);
                } else {
                    console.table(response);
                }
            })
            .catch((error) => {
                console.log(error);
                alert("No se pudo Guardar: " + error);
                throw new Error(error.message);
            })
    }

    async eliminarProductoVenta(producto) {
        console.log(producto)
        await axios
            .delete("/inventarios/ventas/" + this.id + "/productos/" + producto.produtoId
                // , {
                //     data: { produtoId: producto.produtoId },
                // }
            )
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error);
                alert("No se Pudo eliminar: " + error);
                throw new Error(error)
            })
    }

    async vaciarProductosVenta() {
        console.log("VAMOS A VACIAR")
        await Promise.all(this.productos.map(async (producto) => {
            await this.eliminarProducto(producto);
        })).then(values => {
            console.log(values)
        })
    }
}