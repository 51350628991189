<template>
  <FomularioLogin></FomularioLogin>
</template>

<script>
import FomularioLogin from "@/components/Login.vue";
export default {
  components: {
    FomularioLogin,
  },
  created() {
    console.log("LOGIN VIEW");
  },
};
</script>