<template>
  <div class="container">
    <div id="filters" class="row d-flex">
      <div class="row col-8">
        <input
          v-model="fechaInicio"
          class="form-control col-lg-6 px-3"
          type="date"
          id="dateStart"
        />
        <input
          v-model="fechaFinal"
          class="form-control col-lg-6 px-3"
          type="date"
          id="dateEnd"
        />
      </div>
      <div class="row col-2 d-flex flex-row-reverse">
        <button
          @click="obtenerVentas()"
          class="btn btn-info"
          :disabled="cargando > 0"
        >
          <i v-if="!cargando > 0" class="fa fa-filter"></i>
          <i v-if="cargando > 0" class="fa fa-refresh loader"></i>
          <!-- <Loading v-if="cargando>0"></Loading> -->
        </button>
      </div>
      <div class="col-2 d-flex">
        <button
          @click="obtenerVentasPendientes()"
          class="btn btn-info"
          :disabled="cargando > 0"
        >
          Pendientes
          <!-- <Loading v-if="cargando>0"></Loading> -->
        </button>
      </div>
    </div>
    <div v-if="ventas.length > 0">
      <!-- <div class="alert alert-warning row">
                <div class="col-3 row">
                    <p class="col-12">ITEM</p>
                    <p class="col-12">Rango: </p>
                    <p class="col-12">Hoy: </p>
                </div>
                <div class="col-3 row">
                    <p class="col-12">Total de las ventas:</p>
                    <p class="col-12">{{formatPrice(ventasTotales())}}</p>
                    <p class="col-12">{{formatPrice(ventasTotales())}}</p>
                </div>
                <div class="col-3 row">
                    <p class="col-12">Total Venta Ayer:</p>
                    <p class="col-12">{{formatPrice(ventaDiaria(hoy.getDate()-1,hoy.getMonth(),hoy.getFullYear()))}}</p>
                    <p class="col-12">{{formatPrice(ventaDiaria(hoy.getDate()-1,hoy.getMonth(),hoy.getFullYear()))}}</p>
                </div>
                <div class="col-3 row">
                    <p class="col-12">Total Venta Hoy</p>
                    <p class="col-12">{{formatPrice(ventaDiaria(hoy.getDate(),hoy.getMonth(),hoy.getFullYear()))}}</p>
                    <p class="col-12">{{formatPrice(ventaDiaria(hoy.getDate(),hoy.getMonth(),hoy.getFullYear()))}}</p>
                </div>
            </div> -->
      <div class="alert alert-warning row text-left">
        <div class="col-12">
          <button @click="setShowData(!showData)" class="btn btn-info">
            <i v-if="!showData" class="fa fa-eye mx-1"></i>
            <i v-if="showData" class="fa fa-eye-slash mx-1"></i>
            <i class="fa fa-line-chart mx-1"></i>
          </button>
        </div>
        <div v-if="showData" id="estadisticas" class="row col mt-4">
          <div class="col-lg-2 d-none d-print-block row text-left">
            <p class="col-12">.</p>
            <p class="col-12">Ventas:</p>
            <p class="col-12">Total:</p>
            <p class="col-12">Promedio:</p>
          </div>
          <div class="col row">
            <p class="col-12" value="Example">
              <b>Selección: {{ getDiasSeleccion() }} días</b>
            </p>
            <p class="col-12">
              {{ getVentasConcretadas(this.ventas).length }} Ventas
            </p>
            <p class="col-12">{{ formatPrice(ventasTotales(this.ventas)) }}</p>
            <p class="col-12">{{ formatPrice(getPromedio(this.ventas)) }}</p>
          </div>
          <div class="col row">
            <p class="col-12">
              <b>Hoy: {{ hoy.format("DD-MM-yyyy") }}</b>
            </p>
            <p class="col-12">
              {{ getVentasConcretadas(this.ventasDia).length }} Ventas
            </p>
            <p class="col-12">
              {{ formatPrice(ventasTotales(this.ventasDia)) }}
            </p>
            <p class="col-12">{{ formatPrice(getPromedio(this.ventasDia)) }}</p>
          </div>
        </div>
      </div>
      <b-row>
        <b-col class="w-100">
          <input
          v-model="planned_dateGlobal"
          class="form-control px-3 w-100"
          type="date"
          id="planned_date"
          placeholder="Fecha Delivery"
        />
        </b-col>
        <b-col>
          <b-button @click="getAllDeliverysFromDay(planned_dateGlobal)" block variant="primary">
            <i class="fa fa-truck"></i>
            Buscar
          </b-button>
        </b-col>
        <b-col class="small">
          <p class="mb-0">{{ visitasAgendadas?("Visitas Agendadas para el día: "+visitasAgendadas.totalDeliverys):"Presione el Botón para buscar las visitas agendadas para el día" }}</p>
          <p class="my-0">{{ (visitasAgendadas&&visitasAgendadas.retiro)?("ID Retiro: "+visitasAgendadas.retiro.reference):"" }}</p>
        </b-col>
      </b-row>
      <b-row v-if="visitasAgendadas && visitasAgendadas.totalDeliverys > 0">
        <b-table striped hover :items="visitasAgendadas.deliverys" :fields="tableVisitasFields"></b-table>
      </b-row>
      <div
        class="btn-group btn-group-toggle row lex-column bd-highlight mb-3"
        data-toggle="buttons"
      >
        <label class="btn btn-secondary mx-1 my-1">
          <input
            @click="filtrarEstado(0)"
            type="radio"
            name="options"
            :id="0"
            autocomplete="off"
          />
          Todo ({{ ventas.length }})
        </label>
        <label
          v-for="estado in estados"
          :key="estado.id"
          class="btn btn-secondary mx-1 my-1"
        >
          <input
            @click="filtrarEstado(estado.id)"
            type="radio"
            name="options"
            :id="estado.id"
            autocomplete="off"
          />
          {{ estado.nombre + " (" + conteos[estado.id] + ")" }}
        </label>
      </div>

      <!-- <div class="text-light bg-dark row container">
                <p class="col-xl-4 col-md-12">Nombre de Cliente</p>
                <p class="col-xl-2 col-md-2">Fecha</p>
                <p class="col-xl-3 col-md-3">Total Venta</p>
                <p class="col-xl-3 col-md-12">Estado</p>
            </div> -->
      <div id="accordion">
        <div
          v-for="venta in ventas"
          :key="venta.id"
          :class="
            'card ' +
            (!filtroEstado || filtroEstado === venta.estadoId
              ? ''
              : 'desaparecer')
          "
        >
          <div :class="'card-header py-0 '" :id="'heading' + venta.id">
            <h5 class="mb-0 container w-100">
              <button
                class="btn btn-block w-100"
                data-toggle="collapse"
                :data-target="'#collapse' + venta.id"
                aria-expanded="false"
                :aria-controls="'collapse' + venta.id"
              >
                <div class="w-100 row">
                  <div class="col-xl-4 col-md-12 row">
                    <p :id="'p-' + venta.id" class="col-10">
                      {{
                        venta.cliente
                          ? venta.cliente
                          : "WSP: +" + venta.telefono
                      }}
                    </p>
                    <input
                      @change="cambiarNombre(venta.id)"
                      style="display: none"
                      type="text"
                      :id="'input-' + venta.id"
                      class="col-10"
                      :value="venta.cliente"
                    />
                    <!-- <button :id="'btn-'+venta.id" @click="editarCampo(venta.id)" class="btn btn-info col-2"><i :id="'fa-'+venta.id" class="fa fa-pencil"></i></button> -->
                  </div>
                  <!-- <p class="col-xl-4 col-md-12">{{venta.cliente}}</p> -->
                  <p class="col-xl-2 col-md-2">
                    {{ new Date(venta.fechaCompra).toLocaleDateString() }}
                  </p>
                  <p class="col-xl-3 col-md-3">
                    {{ formatPrice(totalVenta(venta.productos)) }}
                  </p>
                  <div class="col-xl-3 col-md-12 row">
                    <select
                      @change="cambiarEstado(venta, $event)"
                      v-model="venta.estadoId"
                      class="form-control col-8"
                    >
                      <option
                        v-for="estado in estados"
                        :key="estado.id"
                        :value="estado.id"
                      >
                        {{ estado.nombre }}
                      </option>
                    </select>
                    <div class="col-2">
                      <i
                        :class="
                          venta.medioPagoId === 3
                            ? 'py-1 fa fa-credit-card-alt'
                            : 'py-1 fa fa-money'
                        "
                      ></i>
                      <i
                        :class="
                          venta.sucursalId === 1
                            ? 'py-1 fa fa-home'
                            : 'py-1 fa fa-shopping-basket'
                        "
                      ></i>
                    </div>
                    <div class="col-2">
                      <a
                        :href="'/punto-venta?venta_code=' + venta.id"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button class="btn btn-info">
                          <i class="fa fa-pencil"></i>
                        </button>
                      </a>
                      <button :disabled="venta.estadoId!==3" v-show="readyDataDelivery(venta)" @click="sendDataDeliemprende(venta)" class="btn btn-warning">
                          <i class="fa fa-truck"></i>
                        </button>
                    </div>
                  </div>
                </div>
              </button>
            </h5>
          </div>

          <div
            :id="'collapse' + venta.id"
            class="collapse"
            :aria-labelledby="'heading' + venta.id"
            data-parent="#accordion"
          >
            <div class="row">
              <div class="col-xl-4 col-md-12 row">
                <p class="col-10">{{ venta.direccion }}</p>
                <!-- <button class="btn btn-info col-2"><i class="fa fa-pencil"></i></button> -->
              </div>
              <div v-if="venta.telefono" class="col-xl-4 col-md-12 row">
                <a
                  class="col-10"
                  target="blank"
                  :href="'http://wa.me/' + venta.telefono"
                  >+{{ venta.telefono }}</a
                >
                <!-- <button class="btn btn-info col-2"><i class="fa fa-pencil"></i></button> -->
              </div>
              <!-- <div class="col-xl-4 col-md-12 row mx-1">
                            <input class="col-xl-10 input-group-text">
                            <button class="btn btn-success col-xl-2"><i class="fa fa-plus-circle" aria-hidden="true" data-v-7b876761=""></i></button>
                        </div> -->
            </div>

            <div
              class="card-body row"
              v-for="producto in venta.productos"
              :key="producto.id"
            >
              <p class="col-2">{{ producto.cantidad }}</p>
              <p class="col-4">{{ producto.producto }}</p>
              <p class="col-3">{{ formatPrice(producto.precio) }}</p>
              <p class="col-3">{{ formatPrice(producto.subtotal) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- <Loading></Loading> -->
    </div>

    <div
      id="modalVenta"
      class="modal fade bd-example-modal-lg"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <MantenedorVenta
            :ventaId="ventaSeleccionada ? ventaSeleccionada : null"
          ></MantenedorVenta>
          <div class="modal-footer">
            <button
              @click="cerrarModal()"
              type="button"
              class="btn btn-success btn-block"
              data-dismiss="modal"
            >
              Listo!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MantenedorVenta from "@/views/MantenedorVentas";
// const axios = require('@/assets/functions/axiosClient.js');
const axios = require("@/assets/functions/axiosClient.js");
window.$ = window.jQuery = require("jquery");
const moment = require("moment");

// import Loading from '@/components/Loader.vue'

export default {
  name: "MantenedorVentas",
  components: {
    // Loading
    MantenedorVenta,
  },
  data() {
    return {
      visitasAgendadas: null,
      planned_dateGlobal: null,
      showData: false,
      ventaSeleccionada: null,
      ventas: [],
      ventasDia: [],
      estados: [],
      hoy: moment(),
      conteos: [],
      filtroEstado: null,
      cargando: 0,
      fechaInicio: moment().subtract(7, "d").format("yyyy-MM-DD"),
      fechaFinal: moment().format("yyyy-MM-DD"),
      tableVisitasFields: [
        {
          key: "reference",
          sortable: true,
          label: "ID"
        },
        {
          key: "contact_name",
          sortable: true,
          label: "Nombre"
        },
        {
          key: "address",
          sortable: true,
          label: "Dirección"
        },
        {
          key: "status",
          sortable: true,
          label: "Estado"
        },
        {
          key: "notes",
          sortable: true,
          label: "Notas"
        },
      ]
    };
  },
  methods: {
    async getAllDeliverysFromDay (fechaPlanificada) {
      if (fechaPlanificada) {
        await axios.get("/deliverys/visitas/" + moment(fechaPlanificada).format("yyyy-MM-DD"))
        .then(response => {
          console.log("OK", response)
          if (response.status === 200) {
            this.visitasAgendadas = response.data;
          }
        }).catch(error => {
          console.log("ERROR", error)
          alert("Tuvimos un error al consultar los deliveris: " + error)
        })
      } else {
        alert("Por Favor seleccione una fecha")
      }
    },
    readyDataDelivery(venta) {
      return (venta.cliente && venta.telefono && venta.direccion && venta.id);
    },
    async sendDataDeliemprende(venta) {
      if (!this.planned_dateGlobal) {
        alert("Selecciona una fecha de delivery")
      } else {
        const data = {
            contact_name: venta.cliente,
            contact_phone: "+" + venta.telefono,
            notes: "",
            address: venta.direccion,
            planned_date: this.planned_dateGlobal,
            reference: venta.id
        }
        try {
          const auxResponse = await axios.post("/deliverys/visitas", data)
          if (auxResponse.status === 200) {
            alert("Creada correctamente")
          } else {
            alert("Error en el envío")
          }
        } catch (e) {
          alert("Error al enviar data", e)
        }
      }
    },
    getDiasSeleccion() {
      return moment(this.fechaFinal).diff(this.fechaInicio, "days");
    },
    setShowData(mostrar) {
      this.showData = mostrar;
    },
    openEdit(venta) {
      this.ventaSeleccionada = venta.id;
      window.jQuery.noConflict();
      window.$("#modalVenta").modal();
    },
    cerrarModal() {
      this.ventaSeleccionada = null;
    },
    formatPrice(price) {
      const priceString = price
        .toFixed(1)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1.");
      return "$ " + priceString.substring(0, priceString.length - 2);
    },
    filtrarEstado(id) {
      console.log("Mostrar Solo:" + id);
      this.filtroEstado = id;
    },
    getVentasConcretadas(ventas) {
      const concretadas = [];
      ventas.forEach((venta) => {
        if (venta.estadoId === 3 || venta.estadoId === 4) {
          concretadas.push(venta.productos);
        }
      });
      return concretadas;
    },
    getPromedio(ventas) {
      if (ventas.length === 0) {
        return 0;
      }
      return this.ventasTotales(ventas) / ventas.length;
    },
    ventasTotales(ventas) {
      let total = 0;
      ventas.forEach((venta) => {
        if (venta.estadoId === 3 || venta.estadoId === 4) {
          total += this.totalVenta(venta.productos);
        }
      });
      return total;
    },
    ventaDiaria(ventas, dia, mes, anio) {
      let total = 0;
      let fecha;
      ventas.forEach((venta) => {
        fecha = new Date(venta.fechaCompra);
        if (
          fecha.getDate() === dia &&
          fecha.getMonth() === mes &&
          fecha.getFullYear() === anio &&
          (venta.estadoId === 3 || venta.estadoId === 4)
        ) {
          total += this.totalVenta(venta.productos);
        }
      });
      return total;
    },
    editarVenta(venta) {
      console.log(venta);
      console.log(Object.keys(venta));
    },
    cambiarNombre(ventaId) {
      const nombreNuevo = document.getElementById("input-" + ventaId).value;
      console.log("Cambiar nombre de " + ventaId + " a " + nombreNuevo);
      document.getElementById("p-" + ventaId).innerText = nombreNuevo;
      this.editarCampo(ventaId);
    },
    cambiarEstado(venta, estado) {
      estado.target.disabled = true;
      axios
        .patch("/inventarios/ventas/" + venta.id + "/estados", {
          id: estado.target.value,
        })
        .then((response) => {
          if (response.status === 201) {
            estado.target.disabled = false;
          }
        })
        .catch((error) => {
          console.log("[ERROR]");
          console.log(error);
        })
        .finally(() => {
          this.cargando--;
        });
    },
    obtenerVentas() {
      const endDate = moment(this.fechaFinal).format("yyyy-MM-DD 23:59:59");
      const startDate = moment(this.fechaInicio).format("yyyy-MM-DD 00:00:00");
      this.cargando++;
      this.conteosZero();
      axios
        .get(
          "/inventarios/ventas?dateStart=" + startDate + "&dateEnd=" + endDate
        )
        .then((response) => {
          this.ventas = response.data.data.rango;
          this.ventasDia = response.data.data.dia;
          this.ventas.forEach((venta) => {
            this.conteos[venta.estadoId] += 1;
          });
        })
        .catch((error) => {
          console.log("[ERROR]");
          console.log(error);
        })
        .finally(() => {
          this.cargando--;
        });
    },
    obtenerVentasPendientes() {
      this.cargando++;
      this.conteosZero();
      axios
        .get("/inventarios/ventas/pendientes")
        .then((response) => {
          this.ventas = response.data.data;
          this.ventasDia = response.data.data.dia;
          this.ventas.forEach((venta) => {
            this.conteos[venta.estadoId] += 1;
          });
        })
        .catch((error) => {
          console.log("[ERROR]");
          console.log(error);
        })
        .finally(() => {
          this.cargando--;
        });
    },
    obtenerEstados() {
      this.cargando++;
      axios
        .get("/estados-ventas")
        .then((response) => {
          if (response.status === 200) {
            this.estados = response.data.data;
            this.estados.forEach((estado) => {
              this.conteos[estado.id] = 0;
            });
          }
        })
        .catch((error) => {
          console.log("[ERROR]");
          console.log(error);
        })
        .finally(() => {
          this.cargando--;
        });
    },
    conteosZero() {
      this.estados.forEach((estado) => {
        this.conteos[estado.id] = 0;
      });
    },
    totalVenta(productos) {
      let total = 0;
      productos.forEach((producto) => {
        total += producto.subtotal;
      });
      return total;
    },
    ordenarPor() {
      this.ventas.sort(function (a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (a.id < b.id) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });
    },
    editarCampo(idComponente) {
      const parrafo = document.getElementById("p-" + idComponente);
      const input = document.getElementById("input-" + idComponente);
      const icon = document.getElementById("fa-" + idComponente);
      const btn = document.getElementById("btn-" + idComponente);

      console.log(icon.classList.value);
      if (icon.classList.value === "fa fa-pencil") {
        btn.classList = ["btn btn-danger col-2"];
        icon.classList = ["fa fa-ban"];
        parrafo.style.display = "none";
        input.style.display = "block";
      } else if (icon.classList.value === "fa fa-ban") {
        btn.classList = ["btn btn-info col-2"];
        icon.classList = ["fa fa-pencil"];
        parrafo.style.display = "block";
        input.style.display = "none";
      }
    },
  },
  created() {
    this.obtenerEstados();
    this.obtenerVentas();
  },
};
</script>

<style>
.desaparecer {
  display: none;
}

.loader {
  /* width: calc(100% - 0px);
  height: calc(100% - 0px);
  border: 8px solid #162534;
  border-top: 8px solid #bb1253;
  border-radius: 50%; */
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>