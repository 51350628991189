var axios = require('axios');
const { showError } = require("@/assets/functions/mensajes.js");

const credenciales = JSON.parse(localStorage.getItem('credentials'));
var axiosInstance = axios.create({
  baseURL: 'https://api.sexduceme.cl',
  // baseURL: 'http://localhost:8000',
  headers: {
    Authorization: credenciales ? credenciales.token : '',
    usuarioId: credenciales ? credenciales.usuarioId : ''
  }
  /* other custom settings */
});
// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  showError(this, "", "Ocurrió un error al cargar los estados");
  if (error.response.status === 401) {
    let url = "/login";
    if (window.location.pathname !== "/login") {
      url += "?redirect=" + window.location.pathname;
    }
    // axiosInstance.push(url)
    // window.location.href=url;
    console.log("redireccionar al " + url);
  }
  return Promise.reject(error);
});

module.exports = axiosInstance;