module.exports = {
    formatPrice(price) {
        const priceString = price.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1.');
        return '$ ' + priceString.substring(0, priceString.length - 2);
    },
    formatCellphone(number) {
        let
            // Quitamos los espacios
            formated = number.replace(/^(?!.*\d).*$/g, '');
        formated = number.replaceAll(' ', '');
        formated = formated.length > 8 ? formated.substring(formated.length - 8, formated.length) : formated
        return formated;
    },
    formatRut(rut) {
        if (rut.length < 1) {
            return ""
        }
        const newRut = rut.replace(/\./g, '').replace(/-/g, '').trim().toLowerCase();
        const lastDigit = newRut.substr(-1, 1);
        const rutDigit = newRut.substr(0, newRut.length - 1)
        let format = '';
        for (let i = rutDigit.length; i > 0; i--) {
            const e = rutDigit.charAt(i - 1);
            format = e.concat(format);
            if (i % 3 === 0) {
                format = '.'.concat(format);
            }
        }
        return format.concat('-').concat(lastDigit);
    },
    validarRut(rut) {
        console.log("Vamos a validar: " + rut)
        const regex = /^[0-9]{1,2}\.[0-9]{3}\.[0-9]{3}[-|‐]{1}[0-9kK]{1}$/
        return regex.test(rut);
    },
    spliteText(text, subSize) {
        let splited;
        if (text.length > subSize) {
            splited = text.substring(0, subSize) + " " + this.spliteText(text.substring(subSize, text.length), subSize)
        } else {
            return text
        }
        return splited;
    },
    trimFull(texto) {
        return texto.replaceAll(' ', '')
    },
    validateEmail(text) {
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

        return regex.test(text);
    }
}