<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="9">
        <b-form-input
          size="sm"
          placeholder="Buscar Producto"
          v-model="busqueda"
          @keyup.enter="searchProducts(busqueda)"
        ></b-form-input>
      </b-col>
      <b-col cols="2">
        <b-button
          @click="searchProducts(busqueda)"
          size="sm"
          class="btn-sexduceme"
          ><b-icon icon="search"></b-icon
        ></b-button>
      </b-col>
    </b-row>
    <b-row v-if="showProducts.length > 0">
      <b-col cols="12">
        <Selector
          @seleccion="seleccionar($event)"
          :id="idSelector"
          :productos="showProducts"
        ></Selector>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Selector from "@/components/inputs/ProductSelector";
const axios = require("@/assets/functions/axiosClient");
const { showError, showSuccess } = require("@/assets/functions/mensajes.js");
export default {
  props: ["productos", "busqueda", "all"],
  components: {
    Selector,
  },
  watch: {
    showProducts: () => {
      console.log("SHOW CAMBIÓ");
    },
    productos: (newValue) => {
      this.showProducts = newValue;
    },
    all: (newValue) => {
      if (newValue) {
        this.getAllProducts();
      }
    },
  },
  data() {
    return {
      cargando: 0,
      showProducts: [],
      idSelector: 0,
    };
  },
  methods: {
    seleccionar(producto) {
      console.log("En Layout: ");
      console.log(producto);
      this.$emit("seleccion", producto);
    },
    getAllProducts() {
      this.cargando++;
      axios
        .get("/inventarios/productos/")
        .then((response) => {
          if (response.status === 200) {
            this.showProducts = response.data.data;
          }
          this.idSelector++;
          showSuccess(
            this,
            "",
            "[" + this.showProducts.length + "] Productos Encontrados"
          );
        })
        .catch((err) => {
          showError(this, "", err.message);
        })
        .finally(() => {
          this.cargando--;
        });
    },
    searchProducts(busqueda) {
      this.cargando++;
      axios
        .get("/inventarios/productos/busqueda/" + busqueda.replaceAll(" ", "-"))
        .then((response) => {
          if (response.status === 200) {
            this.showProducts = response.data.data;
          }
          showSuccess(
            this,
            "",
            "[" + this.showProducts.length + "] Productos Encontrados"
          );
        })
        .catch((err) => {
          showError(this, "", err.message);
        })
        .finally(() => {
          this.cargando--;
        });
    },
  },
};
</script>

<style>
</style>