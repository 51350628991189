<template>
  <b-container>
    <b-row>
      <b-col>
        <b-button block @click="getAllProducts()"> Obtener Productos </b-button>
      </b-col>
      <b-col>
        <b-button block @click="evaluarImagenes()"> Evaluar </b-button>
      </b-col>
    </b-row>
    <b-row v-for="(producto,index) in imagenesEvaluadas" :key="index">
      <b-col cols="4">
        {{ producto.productoNombre }} ({{producto.totalImagenes}})
      </b-col>
      <b-col cols="8">
        <b-col
          cols="12"
          v-for="imagen in producto.imagenesFAIL"
          :key="imagen.id"
        >
          {{ imagen.url }}
        </b-col>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
const axios = require("@/assets/functions/axiosClient.js");

export default {
  data() {
    return {
      productos: [
        {
          id: 646,
          codigo: "6653201379624",
          nombre: "Disfraz Enfermera Infartante",
          descripcion:
            "Vestido blanco con detalles de aberturas y cintas rojas. Incluye accesorios",
          detalles:
            "El Disfraz Enfermera Infartante es de material eslasticado, el cual permite adaptarse a las distintas formas permitiendo así un calce perfecto. El Disfraz Enfermera Infartante consta de un sensual vestido blanco con detalles rojos, los cuales evocan al característico traje de enfermera. Este disfraz erótico femenino consta tanto de aberturas en sus constados, como en su espalda baja. Este disfraz erótico incluye accesorios de cintillo, colaless y estetoscopio.\n\nCARACTERÍSTICAS\nMaterial: Poliéster - Elastano\nColor: Blanco - Rojo\nPrendas: Vestido - Cintillo - Colaless - Estetoscopio.\n\nTALLA: Única",
          instagram: "",
          precio: 19990,
          descuento: 0.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1546,
              nombre: "",
              url: "https://sexshopmayorista.cl/13744-large_default/disfraz-enfermera-infartante-.jpg",
              descripcion: "",
            },
            {
              id: 1547,
              nombre: "",
              url: "https://sexshopmayorista.cl/13743-large_default/disfraz-enfermera-infartante-.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 634,
          codigo: "5600298351065",
          nombre: "Lubricante Orgie Efecto Calor",
          descripcion:
            "Lube Tube Hot, es un exquisito lubricante a base de agua, que no contiene aroma, otorga una duración prolongada de placer para utilizarlo en pareja o con tu juguete favorito ya que no deja residuos.",
          detalles:
            "Lubricante base de agua con Sensación Calor\n\nIngredientes: agua, propilen glicol, Glycerin\n\nContenido: 150 ml.\n\nEfecto: calor\n\nSabor: Sin Sabor, no comestible.\n\nObservaciones\n\n- Uso diario y ocasional\n\n- Mantener a temperatura ambiente\n\nModo de Aplicación\n\nAplicar la cantidad de forma generosa en el área deseada.\n\nADVERTENCIAS\n\n- Solo uso externo\n\n- Mantener fuera del alcance de los niños\n\n- Mantener alejado de los ojos y los oídos.\n\n- No usar si su pareja está embarazada\n\n-\n\nLos resultados dependerán del uso correcto del producto y del organismo de cada persona.\n\nAntes de usa un producto de cosmética erótica se recomienda revisar los componentes de este para evitar reacciones alérgicas.\n\nEn caso de irritación suspender y consultar con su dermatólogo.",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1533,
              nombre: "",
              url: "https://images.jumpseller.com/store/luxury/12010956/lubricante-lube-tube-hot.jpg?1634857703",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 243,
          codigo: "6914421000140",
          nombre: "Vibrapp 2.0 - Vibrador por Internet",
          descripcion:
            "Siente la pasión estés donde estés con la nueva versión del Vibrapp 2 controlable desde donde desees a través de la aplicación FOLOVE (Disponible en Android y iOS).\nSelecciona alguno de los patrones predefinidos o no limites tu imaginación y crea tus propios patrones de vibración, usa tu propia voz para crear impredecibles modalidades de vibración o goza al ritmo de tu canción favorita. Y como si todo lo anterior fuese poco, puedes aprovechar las funciones sociales descargando los mejores ritmos de vibración desde la nube de otros usuarios o entregarle el control a otra persona que tú decidas a través del chat desde cualquier parte del mundo, podrán hablarse y verse mientras juegan y se entregan al placer sin que las fronteras ni horarios importen.",
          detalles:
            "Detalles Técnicos:\nMaterial: Silicona Médica.\nLargo: 12cm.\nDiámetro: 3.4cm.\nPeso: 158g.\nBatería: Batería de Litio Recargable por USB\nColor: Rosado.\nRango Bluetooth: 10mtrs aproximadamente.\nRango Internet: Virtualmente Ilimitado.\n\nOtras Características:\n- A prueba de Agua.\n- Forma de Mariposa.\n- Adaptable a la Ropa Interior.\n- Aplicación Disponible para iOS y Android.\n\nCuidados:\n- Lavar con agua tibia y jabón neutro antes y después de su uso.\n- Dejar secar al aire.",
          instagram: "",
          precio: 49990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 820,
              nombre: "",
              url: "https://i.ibb.co/hVjyN6h/Pics-Art-02-08-03-55-22.jpg",
              descripcion: "",
            },
            {
              id: 1458,
              nombre: "",
              url: "https://i.ibb.co/Tw7n5VL/20211207-013047-0000.png",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 647,
          codigo: "6959532312287",
          nombre: "Vibrador Gene",
          descripcion:
            "Vibrador con 3 intensidades de vibración y 27 tipos de pulsaciones y con diseño dual.",
          detalles:
            'El Vibrador Gene posee un diseño dual el cual permite tanto penetración como estimulación del clítoris además de estimular el punto G, esto ayudado de sus 3 intensidades de vibración y 27 tipos de pulsaciones. Su silicona suave y flexible que faculta al estimulador y sus "orejas" llegar con mayor facilidad al clítoris. Uso de dos baterías AAA. Su material permite utilizar lubricantes en base a agua e híbridos.\n\nCaracterísticas\nMaterial Silicona\nColor Rosa-blanco\n3 intensidades de vibración\n27 tipos de pulsaciones\nUso dos baterías AAA\n\nMedidas\nLargo Total: 20,5cm\nLargo insertable: 9,5cm\nDiámetro: 3,4cm\n\nObservaciones\nDiseño ergonómico y dual\nEstimulador de Punto G / Clítoris\nResistente al agua / No sumergible\nApto para Lubricantes base a agua o híbridos',
          instagram: "",
          precio: 29990,
          descuento: 0.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1548,
              nombre: "",
              url: "https://sexshopmayorista.cl/12088-large_default/vibrador-gene-.jpg",
              descripcion: "",
            },
            {
              id: 1549,
              nombre: "",
              url: "https://sexshopmayorista.cl/12092-large_default/vibrador-gene-.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 648,
          codigo: "6653205469628",
          nombre: "Lubricante Caluga RAIN Sabores",
          descripcion:
            "Lubricante a base de agua, saborizado. Ideal para practicar sexo oral, ya sea en vulva o pene. ",
          detalles:
            "Características:\nContenido: 1 Caluga de 10 ml\nSabor: vainilla, fresa, chocolate, plátano, uva, cola.\n\nModo de uso:\nAplicar directo en la zona a lubricar, reaplicar de ser necesario.\n\nIngredientes : agua desionizada, hidroxietilcelulosa, carboximetilcelulosa de sodio, carbómero, glicerina, metilparabeno, germall, propileno, glicol, propilparabeno, polisorbato, extracto de aloe vera, acetato de vitamina E, trietanolamina.\n\n\nObservaciones:\nCompatible con preservativos de Látex y juguetería",
          instagram: "",
          precio: 1990,
          descuento: 0.0,
          stock: 10,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1550,
              nombre: "",
              url: "https://sexshopmayorista.cl/6395-large_default/lubricante-caluga-rain-sabores.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
          ],
        },
        {
          id: 635,
          codigo: "5600298351072",
          nombre: "LUBRICANTE EFECTO FRÍO ORGIE",
          descripcion:
            "El Lubricante Efecto Frío Orgie, un excelente lubricante a base de agua, perfecto para uso vaginal y/o anal que posee un estimulante efecto frío para que puedas disfrutar aún más de tus encuentros",
          detalles:
            "Características: \nContenido: 150 ml\nLubricante a base de agua\nCompatible con juguetes y preservativos\nEfecto frío\nUnisex\nInoloro\nInsaboro\nNo mancha\nUso anal y vaginal",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1534,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/orgie-cosmetica-lubricante-efecto-frio-orgie-29640665333949_2000x.jpg?v=1628880914",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 636,
          codigo: "7898626041964",
          nombre: "Estimulante Unisex- Meltesão",
          descripcion:
            "Meltesão es un dulce estimulante unisex de sabor y característico aroma natural a miel, genera múltiples efecto al contacto con la piel como calor, vibración y pulsación. Es ideal para estimular clítoris y/o glande. Su dulce sabor permitirá disfrutar del sexo oral",
          detalles:
            "Características:\nContenido: 12 ml\nSabor: Miel\nAroma: Miel\nEfecto: Vibración, pulsación y calor\n\n\nModo de uso:\nAplicar sobre la piel con la yema de los dedos, masajeando hasta que se absorba\n\nIngredientes:\nAqua (agua), aroma (aromatizante), alcohol (alcohol etílico), glicerina (glicerol), monoisopropanolamina (monoisopropanolamina), ciclamato y aspartamo de sodio y sacarina de sodio y dióxido de silicio y acesulfamo K (ciclamato de sodio, aspartamo, sacarina de sodio, Dióxido de silicio, Acesulfam potásico), Carboximetilcelulosa (Carboximetilcelulosa), Extracto de Acmella Oleracea (Extracto de Acmella Oleracea), Benzoato de sodio (Benzoato de sodio), EDTA (Etidronato disódico), BHT (Butilhidroxitolueno), CI98540\n",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 6,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1535,
              nombre: "",
              url: "https://sexshopmayorista.cl/15875-large_default/estimulante-unisex-meltesao-.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 102,
          codigo: "442000501116",
          nombre: "Rotador Conejo estimulador",
          descripcion:
            "Con múltiples funciones de vibración y rotación, su tamaño y potenci son ideales para principiantes. Contiene perlas giratorias y relieves que al moverse suavemente estimularán las paredes vaginales. Además, posee un travieso estimulador clitorial en forma de conejito que te dará aún mayor excitación. \nEl juguete perfecto para estimular tus zonas más erógenas ya seas experimentada o principiante en el uso de juguetes. se puede usar de forma vaginal o anal .",
          detalles:
            "Características:\n\nRotador:\nLargo total: 21.5 cm.\nLargo penetrable: 13 cm.\nDiámetro de su circunferencia : 11 cm\nCircunferencia: 10 cm.\nMaterial: Jelly\nPeso: 140 grs.\nBaterías: 3 pilas AAA\nResistente a salpicaduras, no sumergible\nObservaciones:\n\nLimpiar antes y después de utilizar\ncon agua tibia y jabón neutro.\nGuardar en un sitio limpio y seco, sin mezclar con otros juguetes.\nUsar con lubricante a base de agua o hibridos \nNo mojar ni sumergir las partes eléctricas.\nNo apretar cob fuerza cuando este sus perslas esten en movimiento ya que puede parar de girar",
          instagram: "",
          precio: 23990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 130,
              nombre: "",
              url: "https://i.ibb.co/H2bsp3w/IMG-20201108-151340.jpg",
              descripcion: "",
            },
            {
              id: 131,
              nombre: "",
              url: "https://i.ibb.co/Z2htCQk/IMG-20201108-151238.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 15,
              nombre: "Rotadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 649,
          codigo: "303090027721",
          nombre: "Conjunto Medea",
          descripcion: "Babydoll dos piezas, vestido y colales negro. TALLA L",
          detalles:
            "Sensual babydoll dos piezas incluye sensual vestido y colales. Este Bello babydoll tiene un su parte superior de encaje y cintas muy sutiles para darle un aspecto muy delicado y su falda con tela transparente hacen de este conjunto una prenda infalible para esos encuentros con nuestras parejas.\n\nCaracteristicas\n\nTalla L\nEncaje\n2 piezas\nPoliester\nColor Negro\n\nMedidas\nAncho bajo busto: 35 cm\nLargo Total: 60 cm (desde el busto)\n\nRecomendaciones\n\nLavar a Mano\n\nNo estrujar, no planchar, no lavar en seco",
          instagram: "",
          precio: 16990,
          descuento: 0.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1551,
              nombre: "",
              url: "https://sexshopmayorista.cl/14913-large_default/conjunto-medea.jpg",
              descripcion: "",
            },
            {
              id: 1552,
              nombre: "",
              url: "https://sexshopmayorista.cl/14914-large_default/conjunto-medea.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 637,
          codigo: "9711269379622",
          nombre: "Medias Ligas Rojo - Carmín",
          descripcion:
            " Las Medias Ligas Rojo Carmín son para las féminas que se identifican con el rojo, la pasión y no le temen al qué dirán. Si te identificas con esta personalidad atrevida, entonces esta prenda erótica calza perfecto contigo.\nLas medias son talla única debido a que son de material elasticado para adaptarse a distintas formas y tamaño. Se recomienda utilizarlas en conjunto con portaligas, ya que no poseen goma adherente para la piel.",
          detalles:
            "Características:\nMaterial: Nylon - Elastano\nColor: Rojo\nPrendas: Panty\n\nTalla: Única. ",
          instagram: "",
          precio: 3490,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1537,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/cristal-lenceria-lenceria-medias-ligas-rojo-carmin-18726757433494_2000x.jpg?v=1642527701",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
          ],
        },
        {
          id: 506,
          codigo: "7648795033204",
          nombre: "Potenciador Masculino OH",
          descripcion:
            "El potenciador masculino OH aumenta notablemente la sensibilidad y el flujo sanguíneo hacia los cuerpos cavernosos del pene, permitiendo una erección más firme y durable.\n\nEs un gel de acción rápida que produce una sensación de mayor turgencia de la erección y maximiza la satisfacción pues aumenta la sensibilidad",
          detalles:
            "¿Qué sentirás al usar OH ?\nLas sensaciones son fácilmente apreciables, pueden ir desde un cosquilleo, calidez, aumento de circulación sanguínea, y un un pequeño aumento en el contorno del pene. Estos efectos siempre pueden variar de un individuo a otro.\n\n¿Cuánto dura su efecto?\n\nEs temporal; puede durar hasta una hora, dependiendo de la cantidad aplicada.\nPuede aplicarse previo al coito para lograr el efecto deseado y antes de penetrar debe quitarse el exceso de producto. Las sensaciones placenteras seguirán.\n\nEs compatible con el uso de condón, sus componentes no dañan el látex; pero sí debe quitarse el exceso de producto antes de colocar el preservativo pues corre riesgo de deslizarse.\n\n\nContenido: 50ml",
          instagram: "",
          precio: 18990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1355,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/13946328/thumb/540/540?1633723691",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 9,
              nombre: "Potenciadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 507,
          codigo: "7648795033105",
          nombre: "Estimulante Femenino OH",
          descripcion:
            "Gel para la estimulación del deseo sexual y la intensificación de las sensaciones del clítoris, acercándote a la posibilidad de orgasmos más potentes.",
          detalles:
            "Experimenta la calidez, la sensación vibrante con ésta fórmula en gel, fácil de aplicar y sin molestos derrames del producto. Al aplicarlo sobre el clítoris crea una sensación pulsante y vibrante que te excitará y hasta provocará un aumento de tu propia lubricación.\n\nFórmula inocua a base de agua con componentes de origen herbal 100% naturales.\nPodrás obtener hasta más de 100 aplicaciones, ya que con una gota o dos es suficiente para gatillar el deseo y aumentar la excitación\n\nContenido 30ml",
          instagram: "",
          precio: 18990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1356,
              nombre: "",
              url: "https://cdnx.jumpseller.com/secretos-de-amor-cl/image/8613930/resize/635/635?1609193693",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 572,
          codigo: "22007571",
          nombre: "Esposas Afelpadas Rojas",
          descripcion:
            "Ser la/el am@ o ser la/el sumis@...\nDéjate seducir por estas hermosas esposas para comenzar en tu aventura al interior del BDSM, ideales para comenzar e infaltable en cualquier colección de los amantes de esta práctica, estas esposas afelpadas serán cómodas, bellas y seguras para ti y tu sumis@...\n\nJamás olvides el consentimiento y la palabra de seguridad... 😉",
          detalles:
            "Colores:\n- Rojo\n\nEspecificación:\nMaterial: piel sintética + felpa\nLongitud: 31cm \nAncho: 5 cm \nDiámetro máximo: 7,5 cm\n\nLargo Cadena: 8cm",
          instagram: "",
          precio: 7990,
          descuento: 14.0,
          stock: 14,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1449,
              nombre: "",
              url: "https://i.ibb.co/HYgYtqM/Pics-Art-04-19-12-55-11.jpg",
              descripcion: "",
            },
            {
              id: 1453,
              nombre: "",
              url: "https://i.ibb.co/SsSzDzS/Pics-Art-04-19-12-54-11.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 32,
              nombre: "BDSM",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 511,
          codigo: "638258901413",
          nombre: "Gel Relajante Anal Tush Ease ",
          descripcion:
            "Relajante anal, agradable durante la aplicación, este gel disminuye la sensibilidad del ano, permitiendo avanzar más cómodamente en el juego anal.",
          detalles:
            "Características\n\nGel cosquilleante, ideal para disminuir la sensibilidad del ano.\n\nNo debe usarse en lugar de lubricante\nNeutro. sin fragancia\n\nIdeal para uso con juguetes, en soledad o con la pareja\nEste producto contiene 4,9% de benzocaína.\nContenido: 20 ml",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1360,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/7180765/thumb/540/540?1633195908",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 188,
          codigo: "7720100160611",
          nombre: "Aceite para Masajes Sabor Coco (30ml)",
          descripcion:
            "Gel comestible con rico sabor a coco y exquisito efecto calor para disfrutar en pareja",
          detalles:
            "Ingredientes: Prunus Amygdalus Dulcis (Sweet almond) Oil, Capsicum Frutes cens Fruit Extract, Aroma, Tocopheryl Acetate, Propylparaben, Bht, CI 16035, CI 19140, CI 42090.\n\nCaracterísticas:\nSabor: Coco\nContenido: 30 ml\nEfecto Calor\n\nObservaciones:\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. Eliminar el exceso con agua tibia y jabón neutro.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 6,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 413,
              nombre: "",
              url: "https://i.ibb.co/px6hYGD/Pics-Art-01-18-02-36-09.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 542,
          codigo: "7770200100208",
          nombre: "Lubricante Retardante Masculino (60ml)",
          descripcion:
            "El lubricante retardante, es un retardante eyaculador que prolonga la erección sin perder la sensibilidad, obteniendo relaciones sexuales más intensas, prolongadas y placenteras. Solo debe aplicar y masajear una pequeña cantidad sobre el pene erecto. ",
          detalles:
            "Formato: 100 ml.\nCaracterísticas:\nHipoalergénico\nA base de agua\nHecho en Chile",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 15,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1397,
              nombre: "",
              url: "https://sexshopmayorista.cl/15569-large_default/lubricante-retardante-60ml-pet.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 639,
          codigo: "9111261679247",
          nombre: "Body Diablita Candy Talla S",
          descripcion:
            "Candy es un body de diablita de color negro y rojo, con el sujetador abierto de tirantes para un amarrare a medida, detalle en el abdomen con encaje rojo y cinta negra, además la parte del colales viene con tirantes regulable. Incluye cintillo, tridentes y liga.",
          detalles:
            "Características.\n\nMaterial: polyester y spandex    \n\nAncho busto: 30cm / Tirantes 44cm\n\nLargo body: 39cm\n\nColor: Negro y Rojo\n\nPrendas: body, tridente, cintillo y liga\n\nTalla: S\n\nRecomendaciones\n\nLavar a mano y escurrir sin estrujar, no planchar ni lavar en seco.",
          instagram: "",
          precio: 21990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1538,
              nombre: "",
              url: "https://sexshopmayorista.cl/15781-large_default/body-diablita-candy-talla-s.jpg",
              descripcion: "",
            },
            {
              id: 1539,
              nombre: "",
              url: "https://sexshopmayorista.cl/15782-large_default/body-diablita-candy-talla-s.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 640,
          codigo: "6959532320084",
          nombre: "Bala Vibradora Justin - Fucsia",
          descripcion:
            "Balita Vibradora fabricada de silicona y diseño realístico, posee 10 patrones de vibración. Utiliza 1 pila AAA",
          detalles:
            "Descubre la increíble balita vibradora Justin, un juguete versátil, compacto y discreto. Cualidades que la convierten en un potencial 1° juguete. Posee un diseño realístico que está levemente curvado en la primera porción lo que brindará una precisa estimulación interna de Zona G y gracias a sus leves realces entregará una experiencia más realística de penetración. Solo necesitarás 1 pila AAA para disfrutar su poder de estimulación a través de sus 10 funciones de vibración.\n\nCaracterísticas\nBalita vibradora, estimulador interno y externo (solo uso vaginal)\nMaterial: Silicona\nColor: Rosado- Fucsia- Morado – Negro y celeste (ENVÍO DE COLORES SUJETO A DISPONIBILIDAD/ NO SELECCIONABLES)\n10 funciones de vibración",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1540,
              nombre: "",
              url: "https://sexshopmayorista.cl/15187-large_default/bala-vibradora-justin-.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 173,
          codigo: "7730963251043",
          nombre: "Preservativo Corrugado Prudence",
          descripcion:
            "Preservativo Prudence Corrugado de látex lubricado, tiene texturas en círculo ocasionando una mayor estimulación con ligero aroma a vainilla",
          detalles:
            "Características:\n\n\nMaterial: látex\nProveedor: Prudence\nContiene 3 unidades\nColor: incoloro\n\n\nObservaciones\nUsar con lubricante a base de agua\nNo recomendado para sexo oral\nRevisar componentes para evitar reacciones alérgicas\nEn caso de irritación suspender y consultar a su médico.",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 6,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 524,
              nombre: "",
              url: "https://i.ibb.co/89jq3Hw/1609808562299.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 641,
          codigo: "6959532320152",
          nombre: "Bala Vibradora Justin - Negro",
          descripcion:
            "Balita Vibradora fabricada de silicona y diseño realístico, posee 10 patrones de vibración. Utiliza 1 pila AAA",
          detalles:
            "Descubre la increíble balita vibradora Justin, un juguete versátil, compacto y discreto. Cualidades que la convierten en un potencial 1° juguete. Posee un diseño realístico que está levemente curvado en la primera porción lo que brindará una precisa estimulación interna de Zona G y gracias a sus leves realces entregará una experiencia más realística de penetración. Solo necesitarás 1 pila AAA para disfrutar su poder de estimulación a través de sus 10 funciones de vibración.\n\nCaracterísticas\nBalita vibradora, estimulador interno y externo (solo uso vaginal)\nMaterial: Silicona\nColor: Rosado- Fucsia- Morado – Negro y celeste (ENVÍO DE COLORES SUJETO A DISPONIBILIDAD/ NO SELECCIONABLES)\n10 funciones de vibración\n\n\nMedidas\nLargo Total: 13.5 cm\nDiámetro mayor: 2.9 cm",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1541,
              nombre: "",
              url: "https://sexshopmayorista.cl/15187-large_default/bala-vibradora-justin-.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 642,
          codigo: "6959532321654",
          nombre: "Bala Vibradora Delfín - Negro",
          descripcion:
            "Bala Vibradora estimuladora de zonas erógenas de 10 patrones de vibración, Utiliza 1 pila AAA ",
          detalles:
            "La Balita Vibradora Delfín es una excelente alternativa para personas principiantes en el mundo de la juguetería ya que es un fantástico estimulador externo de zonas erógenas. Ideal para estimular pezones o clítoris gracias al diseño de la primera porción que permitirá la estimulación directa. \nFabricado de suave silicona, requiere tan solo de 1 pila AAA para entregar placenteros estímulos mediante sus 10 funciones de vibración.\n\nCaracterísticas\nBalita vibradora, estimulador externo\nMaterial: Silicona\nColor: Rosado\n10 funciones de vibración\n\nMedidas\nLargo Total: 14.5 cm\nDiámetro mayor: 2.9 cm",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1542,
              nombre: "",
              url: "https://sexshopmayorista.cl/15189-large_default/bala-vibradora-edward.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 643,
          codigo: "825156102343",
          nombre: "Juego de Cartas ¡Sex! Gay",
          descripcion:
            "Cada carta de sexo gay ilustra explícitamente alguna propuesta de actividad previa o una posición erótica. El color representa la intensidad de la posición",
          detalles:
            "Incluye : 50 cartas de sexo gay, 3 cartas con las reglas del juego y 1 carta para descubrir tu fantasía",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1543,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/5298782/thumb/540/540?1642513434",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 261,
          codigo: "825156107959",
          nombre: "Juego de Trivia Sexual - The Hump!",
          descripcion:
            "Hump! The Game. Un juego pensado para que conozcas a pleno tu vida sexual y la de tu pareja. Las trivias sexuales son perfectas para unir aun más a las parejas y conozcan sus deseos sexuales.",
          detalles:
            "Contenido: \n- 40 Tarjetas enfocadas a posturas sexuales (color rojo)\n- 40 Tarjetas enfocadas a juegos sexuales preliminares (color Morado)\n- 40 Tarjetas enfocadas a experiencias sexuales (color verde)\n- 40 Tarjetas con preguntas subidas de tono (color amarillo)\n- 1 Ruleta con flecha giratoria\n- Reglas del juego\n\nObservaciones: Juego dirigido a parejas\nDisponible en español e inglés",
          instagram: "",
          precio: 10990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 741,
              nombre: "",
              url: "https://i.ibb.co/mHk50Ph/Pics-Art-03-21-12-32-04.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 644,
          codigo: "4061504001135",
          nombre: "Vibrador Satisfyer Helado",
          descripcion:
            "Estimulador de Clítoris Dulce Tentación\nEste estimulador con forma de helado será capaz hasta de derretir tu clítoris gracias a sus profundas y potentes vibraciones. Además te ofrece un diseño que lo hace elegante, original y divertido.\nEste juguete cuenta con unas láminas que giran abarcando todo tu clítoris desde cualquier ángulo, lo podrás colocar tanto de lado como de manera vertical y sentir el verdadero\nclímax.",
          detalles:
            "Además podrás sentir cómo sus 11 modos diferentes de vibración y sus 7 ritmos de rotación actúan en tu zona íntima.\n\nCaracterísticas del producto:\n\nSilencioso\n• 7 Ritmos de rotación.\n• Resistente al agua\n• Incluye cable de carga magnética USB\n• Material: silicona grado médico, hipoalergénica\n• Batería de iones de litio.\n• Fácil de limpiar.\n• Medidas: alto 10,5 cm ancho máximo 5 cm.",
          instagram: "",
          precio: 54990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1544,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/9322568/thumb/540/540?1639669553",
              descripcion: "",
            },
            {
              id: 1545,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/9322571/thumb/540/540?1639669553",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 632,
          codigo: "6959532326246",
          nombre: "Vibrador Phoenix",
          descripcion:
            "Posee 7 patrones de vibración, un estimulante clitorial y ahí no acaban las sorpresas de Fénix, ya que su estimulador viene equipado con un insuflador que no te dará descanso con sus 4 niveles de estimulación y eso no es todo, ya que aparte de sus relieves y surcos, en la punta del juguete viene una lengüita que no conoce de treguas y eso lo sabrá tu punto G.",
          detalles:
            "Características:\nMaterial: Silicona\nColor: Fucsia\nImpermeable (resistente al agua, no sumergible) \n4 patrones de insuflación\n7 patrones de vibración\n\nMedidas:\nLargo total 20.2 cm\nDiámetro mayor: 3.8 cm\n\nObservaciones:\nLavar con agua tibia y jabón neutro antes y después de cada uso, dejar secar y guardar en un lugar fresco y seco.\nUtiliza 2 pilas AA no incluidas",
          instagram: "",
          precio: 35990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1530,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/pretty-love-vibrador-fenix-36529452712169_2000x.jpg?v=1642009299",
              descripcion: "",
            },
            {
              id: 1531,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/pretty-love-vibrador-fenix-36529401659625_2000x.jpg?v=1642008760",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 574,
          codigo: "666577657567",
          nombre: "Campanilla Ring for Sex",
          descripcion:
            'El llamado del placer te hace ring ¿Responderás?\nPorque las palabras sobran cuando hay pasión...\nHazle saber de una forma "sutil" a tu pareja que le necesitas "AHORA YA!", sólo haz ring en la campanilla para que le queden claras tus intenciones. Además puedes usarlo para complementar tu juego de roles y que tu sumiso o sumisa obedezca inmediatamente.',
          detalles: "",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 16,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1451,
              nombre: "",
              url: "https://www.partyvalue.com/media/product/1ea/ring-for-sex-bell-1ct-993.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 504,
          codigo: "4061504007359",
          nombre: "Satisfyer Pro Penguin",
          descripcion:
            "Diseño bonito y divertido unido a la tecnología revolucionaria Air Pulse\n\n",
          detalles:
            "Ideal para principiantes\n\nSu forma delgada de diseño ergonómico se adapta perfectamente a la mano y es muy cómoda. Por su diseño manejable y su facilidad de manejo, es ideal para quienes quieran iniciarse en estos juegos solitarios.\nGracias a su excelente estanqueidad, el Satisfyer Pro Penguin es mucho más silencioso durante el uso que otros productos similares en el mercado. Su suave caperuza de silicona antialergénica es higiénica, y protege el interior contra la entrada de agua, lubricantes u otros fluidos.\n\n11 programas\n\nModo de susurro\n\nOnda de presión y estimulación del clítoris\n\nResistente al agua\n\nCable de carga magnético por USB\n\nBatería de iones de litio",
          instagram: "",
          precio: 59990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1352,
              nombre: "",
              url: "https://cdnx.jumpseller.com/secretos-de-amor-cl/image/11757668/resize/635/635?1622327387",
              descripcion: "",
            },
            {
              id: 1353,
              nombre: "",
              url: "https://cdnx.jumpseller.com/secretos-de-amor-cl/image/11763216/resize/635/635?1622327387",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 566,
          codigo: "6970556030544",
          nombre: "Cerdito Estimulador de Clítoris. ",
          descripcion:
            "Cerdito vibrador estimulador de clítoris. Lengua vibradora.",
          detalles:
            "En la parte inferior posee una lengua vibradora, la cual tiene seis intensidades de vibración.\nCarga Vía UBS (Cable incluido)\nDuración de la Batería: 1 Hr.\nTiempo de carga: 30 Min aprox.\n",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 7,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1455,
              nombre: "",
              url: "https://ae01.alicdn.com/kf/H8bdcfc26e2894a4983d99a74bd678d60s/Vibrador-de-lengua-de-cerdo-rosa-para-mujer-juguete-sexual-para-lamer-con-10-modos-de.jpg_q50.jpg",
              descripcion: "",
            },
            {
              id: 1456,
              nombre: "",
              url: "https://ae01.alicdn.com/kf/H2b3ea22c203f47b78a6b32044ac2aa7ez.jpg",
              descripcion: "",
            },
            {
              id: 1457,
              nombre: "",
              url: "https://ae01.alicdn.com/kf/H51102467caf2436d8e7f119d2904e277G.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 400,
          codigo: "6931028870341",
          nombre: "Body Tiras de malla",
          descripcion:
            "Body de malla con diseño en la parte superior e inferior y tiras en el centro.",
          detalles:
            "Color Negro\nTalla Estándar (abarca desde la XS a M)\nNo incluye accesorios. ",
          instagram: "",
          precio: 6990,
          descuento: 28.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1002,
              nombre: "",
              url: "https://i.ibb.co/kqBg3rW/Pics-Art-07-07-02-10-08.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 633,
          codigo: "855559007389",
          nombre: "Sachet Estimulante Zona G",
          descripcion:
            "El estimulante G, how I Adore You es una crema estimulante de punto G, que brindara mayor placer y posiblemente ayude a llegar al orgasmo.",
          detalles:
            "G How I Adore You es una crema estimulante del punto G elaborada con ingredientes naturales como L-arginina, Maca, Guarana y mentol lo que dilata los vasos y aumenta el flujo sanguíneo en el área, también despierta las terminaciones nerviosas para mayor sensibilidad. Es hidratante y con un PH equilibrado para complementar la lubricación natural del cuerpo.\n\nCon mayor estimulación, el área del punto G puede volverse mas pronunciada durante el juego manual o la penetración lo que brindara a la mujer mas placer lo que desencadenara el orgasmo.\n\n \n\nCaracterísticas:\nContenido: Sachet 6ml (un solo uso)\nSabor: Sin sabor\nEfecto: Estimulante punto G\n\nSin aceite, Sin glicerina, Apto para Latex\n\nModo de uso:\nAplique la cantidad deseada de gel en su dedo y luego aplíquelo directamente sobre el punto G. Repetir misma acción en caso que desee.\n\nIngredientes:\nagua. L-arginina, propanodiol, mentol, lepidium mayenii (maca), Paullinia cupana (guaraná), celulosa, ácido cítrico, poliacrilato, poliisobuteno, polisorbato, gluconolactona, bencoato de sodio, sorbato de potasio.\n\n\nObservaciones:\nNo esta destinado a ser utilizado como lubricante\n\n ",
          instagram: "",
          precio: 2990,
          descuento: 14.0,
          stock: 11,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1532,
              nombre: "",
              url: "https://sexshopmayorista.cl/15707-large_default/sachet-estimulante-punto-g.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 161,
          codigo: "7798297917271",
          nombre: "Consolador Caiman Cyber Skin N°3",
          descripcion:
            "Amarás este Dildo Realístico  super suave y flexible , hipoalergénico con ventosa super adherente ideal para superficies lisas, además posee una agradable aroma Dulce para incentivar el instinto olfativo del Deseo  , Totalmente flexible y manejable para dar rienda suelta a tu placer . Su diseño protuberante en la punta es ideal para estimular toda tu zona G y Zona P en caso de personas con Pene.\n\nNo te pierdas de las mejores experiencias de este Dildo realístico diseñado para entregar el máximo placer ! disfruta tanto en pareja como en solitario.",
          detalles:
            "Características:\n\nVentosa ultra adherente\nCyber super suave y flexible\nHipoalergénico \nLibre de ftalatos\nMedidas : 17 cm\nDiametro de circunferencia : 14 cm de contorno en su area mas gruesa \n \n\nRecomendaciones :\n\nLimpiar con agua tibia y jabón neutro, antes y despues de cada uso\nGuardar en un lugar seco , no expuesto al sol  y alejado de otros juguetes",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1078,
              nombre: "",
              url: "https://i.ibb.co/wz8tF4y/Pics-Art-07-07-02-14-19.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 12,
              nombre: "Consoladores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 259,
          codigo: "334010030475",
          nombre: "Vara Anal Vibradora Max",
          descripcion:
            "La Vara Anal Vibradora Fucsia tiene un diseño ergonómico que le permite entregar máximo conforte, tanto para principiantes como experimentados. A su vez posee una bala vibradora con 3 intensidades de vibración y 4 pulsaciones. Su material es de Silicona aterciopelada, (libre de ftalatos), la cual permite ser gentil con la piel además de resistente al agua, (no sumergible) y compatible con lubricantes en base a agua o híbridos. Usa de 3 Baterías LR44 que vienen incluidas.",
          detalles:
            "Características\nMaterial: Silicona\nColor Fucsia\n3 intensidades de vibración\n4 pulsaciones\nUso 3 Baterías LR44 incluidas\n\nMedidas\nLargo Total: 18.5cm\nLargo insertable: 12.0cm\nDiámetro: 3.2cm\n\nObservaciones\nDiseño ergonómico\nResistente al agua / No sumergible\nApto para Lubricantes base a agua o híbridos",
          instagram: "",
          precio: 21990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 626,
              nombre: "",
              url: "https://i.ibb.co/wgcfm5J/Pics-Art-02-28-08-22-35.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 184,
          codigo: "7898626040912",
          nombre: "Gel Estimulante Anal Beso Griego",
          descripcion:
            "La cosmética erótica de Intt nos continúa sorprendiendo con sus novedosos e innovadores geles de uso íntimo, con efectos excitantes y sabores exquisitos. El Estimulante Anal Beso Griego, es un gel comestible con sabor a cereza menta, lo cual contiene efecto frío y de vibración. Este permite practicar sexo oral anal proporcionando un efecto estimulante. Solo basta aplicar de dos a tres porciones para obtener el efecto del Beso Griego.",
          detalles:
            "Ingredientes: Aqua, Glycerin, Menthol, Propylene Glycol, Lodopropynyl Butylcarbamate, Sodium Saccarin e CI 42090.\n\nCaracterísticas:\nGel estimulante anal\nContenido: 17ml\nSabor: Cereza – Menta\nEfecto: Frío - Vibración\n\nObservaciones:\n\nGel comestible para el sexo oral anal\nEstimulante anal\nDe uso unisex\nFormato de rociador\n\nModo de aplicación:\nAplicar de dos a tres porciones sobre la zona anal\nComplementar con lubricante",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 324,
              nombre: "",
              url: "https://i.ibb.co/RDsdd07/1609808562978.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 37,
          codigo: "7898450819722",
          nombre: "Gel para Sexo Oral Fuego & Hielo",
          descripcion:
            "En tus encuentros sexuales ya no puede faltar este maravilloso producto de Adao & Eva, marca brasileña especialista en cosmética erótica, llegó el maravilloso Gel Fogo & Gelo Menta Ice, su sensacional efecto frío y calor hará que tus orgasmos sean perfectos! además tiene un exquisito sabor, el Gel Fogo & Gelo es perfecto para el sexo oral. Comienza a gozar como nunca antes! ",
          detalles:
            "Características:\nGel comestible\nPara sexo oral\nEfecto: Frío y Calor\nAplicar en zonas intimas\nNo es lubricante\n\nModo de aplicación: Colocar la cantidad deseada en la punta de los dedos y aplicar en el lugar del cuerpo deseado masajeando suavemente, con movimientos rápidos sobre la piel se provoca el efecto calor y soplando sobre ella se provoca el efecto frio.",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 251,
              nombre: "",
              url: "https://i.ibb.co/w7fJcXg/1608164511636.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 114,
          codigo: "7770005250016",
          nombre: "Estrechante Vaginal - Liquid Virgin (Sachet)",
          descripcion:
            "Rejuvenecedor Vaginal Liquid Virgin, disfruta de un encuentro sexual mágico como si fuera la primera vez!\nEl lubricante estrechante vaginal Liquid Virgin produce una sensación rejuvenecedora única la cual, creando una sensación de presión provocando el aumento de placer sexual! Posee un delicioso aroma a Fresas.\n",
          detalles: "",
          instagram: "",
          precio: 2490,
          descuento: 14.0,
          stock: 9,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 154,
              nombre: "",
              url: "https://i.ibb.co/jRK21zL/IMG-20201108-162106.jpg",
              descripcion: "",
            },
            {
              id: 155,
              nombre: "",
              url: "https://i.ibb.co/vDh0x0L/IMG-20201108-161255.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 342,
          codigo: "7809604505026",
          nombre: "Preservativos SensorPlus - Dotted",
          descripcion:
            "Deleita tus sentidos y prepárate para un placer sin límites, de manera segura y preventiva gracias a los preservativos Sensor Plus Dotted.",
          detalles:
            "Características\nPreservativo de látex\nTesteados Electrónicamente\nCaja incluye 3 unidades\nPosee Relieves en Puntos\n\nMedidas\n18cm de largo\n5,2cm de ancho\n0,07cm de espesor",
          instagram: "",
          precio: 1990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1023,
              nombre: "",
              url: "https://i.ibb.co/N6v54z7/Pics-Art-07-07-02-31-08.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 325,
          codigo: "646709100704",
          nombre: "Pluma Traviesa Negra",
          descripcion: "Despierta la piel con suaves trazos eróticos",
          detalles: "Estimulador de plumas.\nLargo total: 18 cm.\nColor Negro",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 904,
              nombre: "",
              url: "https://i.ibb.co/XLg7JvW/Pics-Art-05-16-02-35-04.jpg",
              descripcion: "",
            },
            {
              id: 905,
              nombre: "",
              url: "https://i.ibb.co/0Zts85C/Pics-Art-05-16-02-36-37.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 32,
              nombre: "BDSM",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 151,
          codigo: "7765432100171",
          nombre: "Lubricante Anal (Sachet 5ml)",
          descripcion:
            "Lubricante Anal Dilatador de 5ml. Es ideal para juegos eróticos y penetraciones anales ya que prepara, lubrica y dilata la zona anal, haciendo más fácil la penetración. Perfecto para utilizar durante el acto, compatible con preservativos y con juguetes anales.",
          detalles:
            "Características:\r\n\r\nLubricante anal\r\nPresentación de 5 ml\r\nCompatible con preservativos\r\nCompatible con juguetes\r\nLubricante a base de agua\r\n\r\nIngredientes: Agua, Carboximetilcelulosa, Metilparabeno, Proliparabeno, Glicerina, Propilenglicol, Simeticona, Aloe Barbadensis.",
          instagram: "",
          precio: 1790,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 238,
              nombre: "",
              url: "https://i.ibb.co/txvscrc/1608164511335.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 194,
          codigo: "7804665860015",
          nombre: "Lubricante Chocolub (30ml)",
          descripcion:
            "El lubricante ideal para el sexo oral, vaginal y anal. Vive un encuentro sexual único gracias al lubricante Chocolub de absorción lenta y con sabor a chocolate.\nLubricación por más tiempo y con exquisito sabor a chocolate, perfecto para ese encuentro muy esperado!",
          detalles:
            "Ingredientes: Agua pura, aceite de silicona, glicerina, hidroxietilcelulosa, esencia de chocolate y otros.\n\nContenido: 30ml\nSabor: Chocolate\n\nObservaciones:\nRico y suave sabor a Chocolate que intensifica el sexo oral.\nConservar en un lugar fresco y seco, lejos de la luz directa del sol.\nEvitar el contacto con los ojos.\nNo usar en caso de irritación.\n\nImportante:\nLos resultados dependerán del uso correcto del producto y del organismo de cada persona. Antes de usa un producto de cosmética erótica se recomienda revisar los componentes de este para evitar reacciones alérgicas.\nEn caso de irritación suspender y consultar con su dermatólogo.",
          instagram: "",
          precio: 5990,
          descuento: 14.0,
          stock: 31,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 409,
              nombre: "",
              url: "https://i.ibb.co/SVp0pjh/Pics-Art-01-18-02-37-32.jpg",
              descripcion: "",
            },
            {
              id: 410,
              nombre: "",
              url: "https://i.ibb.co/V362DC0/Pics-Art-01-18-02-31-06.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 516,
          codigo: "771956401035",
          nombre: "Feromona Masculina PureSex Weed",
          descripcion:
            "Pure Sex Weed es un perfume que potencia la producción natural de las feromonas masculinas con extracto de semilla de cáñamo. Sus notas altas son una mezcla de pimienta negra y naranja. Sus notas de corazón revelan acordes de laurel con notas marinas, (ozonicas). Las notas de fondo son una combinación de ámbar gris y extractos de semilla de cáñamo.",
          detalles:
            "Ingredientes: Agua, Alcohol, Esencia de Pimienta Negra, Naranja, Laurel, Ozonicas, Ámbar Gris y Extracto de Semilla de Cañamo.\n\nContenido: 30ml\nAroma: Cítrico\n\nObservaciones:\nPerfume Masculino\nPotencia la producción de feromonas\nDuración moderada con aura suave",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1369,
              nombre: "",
              url: "https://sexshopmayorista.cl/14681-large_default/perfume-pure-sex-weed.jpg",
              descripcion: "",
            },
            {
              id: 1370,
              nombre: "",
              url: "https://sexshopmayorista.cl/13168-large_default/perfume-pure-sex-weed.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 18,
              nombre: "Feromonas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 328,
          codigo: "853858007109",
          nombre: "Funda Peneana Stay Hard Tornado",
          descripcion:
            "Prueba cualquiera de éstas fundas texturadas para sentirte poderoso y capaz de provocar una estimulación intensa\nTalla única que se adecua por su elasticidad a casi todos los tamaños de pene\nLibre de Látex, ftalatos y cualquier otra sustancia potencialmente peligrosa\nMaterial TPE",
          detalles: "",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 914,
              nombre: "",
              url: "https://i.ibb.co/KXz25rQ/Pics-Art-05-16-02-37-46.jpg",
              descripcion: "",
            },
            {
              id: 915,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/8704782/thumb/540/540?1612293609",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 323,
          codigo: "646709100728",
          nombre: "Pluma Traviesa Roja",
          descripcion:
            "Despierta la piel con suaves trazos eróticos utilizando estas suaves y divertidas plumas.",
          detalles: "Estimulador de plumas. \nLargo total: 18 cm.\nColor Rojo",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 902,
              nombre: "",
              url: "https://i.ibb.co/2Fkr24L/Pics-Art-05-16-02-36-01.jpg",
              descripcion: "",
            },
            {
              id: 903,
              nombre: "",
              url: "https://i.ibb.co/0Zts85C/Pics-Art-05-16-02-36-37.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 32,
              nombre: "BDSM",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 262,
          codigo: "825156107690",
          nombre: "Juego de Sexo Oral",
          descripcion:
            "Enciende la mecha del erotismo en la relación con el juego Sexo Oral, y disfruta de encuentros íntimos. Sumérgete en una aventura pasional que jamás olvidarás.\n\n",
          detalles:
            "Contenido:\n1 Tablero\n2 fichas de jugador\n2 Dados\n10 técnicas de sexo oral\n1 Sachet Lubricante con sabor\n\nObservaciones:\nJuego erótico para parejas\nLas reglas del juego contienen 5 técnicas de sexo oral para ella y 5 para él.\nUn dado es tradicional y el otro contiene acciones de sexo oral.\nDisponible en Español, inglés, francés y alemán.",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 549,
              nombre: "",
              url: "https://i.ibb.co/M15ctjM/Pics-Art-02-27-01-55-13.jpg",
              descripcion: "",
            },
            {
              id: 550,
              nombre: "",
              url: "https://www.adultplay.es/wp-content/uploads/2020/08/44/juego-de-parejas-the-oral-sex-game-en-es-de-fr.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 22,
              nombre: "Kits",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 519,
          codigo: "714569705886",
          nombre: "Medias Ligas con Moño",
          descripcion:
            "Las Medias Ligas Moño son medias que pueden ser utilizadas por si solas o con portaligas, lisas, siendo aptas así para ser utilizadas con cualquier prenda. Poseen detalles de moños satinados.",
          detalles:
            "CARACTERÍSTICAS\nMaterial: Nylon - Spandex\nColor: Negro\nPrendas: Medias Ligas\n\nTALLA: Única\n\nIMPORTANTE\nLavar a mano\nDetalles de transparencia y encaje\nRecomendamos antes de realizar su compra ingresar a la Tabla de Tallas para revisar las medidas de la prenda",
          instagram: "",
          precio: 5990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1378,
              nombre: "",
              url: "https://sexshopmayorista.cl/13824-large_default/medias-ligas-mono.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 515,
          codigo: "7898626041452",
          nombre: "Gel Excitante Súper Macho - Intt",
          descripcion:
            "Súper Macho es un producto estimulante y vasodilatador que al aplicarse en el pene dilata los vasos sanguíneos haciendo que la sangre fluya más rápido y en mayor cantidad. La combinación de sus componentes afrodisíacos Ginseng, Arginina y Catuaba, aseguran la mejora del desempeño sexual en los hombres. Su uso potencia la erección, aumenta el deseo sexual, provoca sensación de hinchazón en el pene y proporciona orgasmos más intensos.",
          detalles:
            "Componentes:\n\nGinseng: mejora la erección\nCatuaba: aumenta el deseo sexual\nArginina: proporciona un efecto de calor dando más placer y tiene una acción vasodilatadora que provoca una sensación de hinchazón, dejando el pene más receptivo a los estímulos.\n\nModo de uso: Aplicar de 1 a 2 gotas sobre el glande y el resto del pene. Luego, dejar que tu organismo absorba el gel para comenzar a sentir los efectos. Aplicarlo antes del acto sexual.\n\nPrecauciones: No aplicar el producto en zonas con lesiones, laceraciones o inflamación. Mantener alejado de la luz y el calor. Mantener fuera del alcance de los niños. \n\nIngredientes: aqua, glycerin, triethanolamine, carbomer, sodium benzoate, aroma de catuaba, corante azul brihante CI 42090, vanillyl butyl ether, arginine, panax ginseng root extract. \n\nContenido: 17ml\n\nObservaciones:\nProducto no testeado en animales\nMarca brasilera.\n\nImportante:\nLos resultados dependerán del uso correcto del producto y del organismo de cada persona.\nAntes de usa un producto de cosmética erótica se recomienda revisar los componentes de este para evitar reacciones alérgicas.\nEn caso de irritación suspender y consultar con su dermatólogo.",
          instagram: "",
          precio: 23990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1368,
              nombre: "",
              url: "https://sexshopmayorista.cl/14600-large_default/gel-excitante-super-macho-intt.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 9,
              nombre: "Potenciadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 592,
          codigo: "409692028022",
          nombre: "Body Victoria",
          descripcion:
            "Victoria es un sensual body de encaje negro elasticado de manga larga con un escote tipo sostén con barba. Su parte inferior posee un broche para uso fácil y su corte alto deja a la vista la curvatura de las caderas",
          detalles:
            "Con forro en sostén\nCon barba metálica\nBroche de seguridad\nMaterial: 95% poliéster 5% spandex\nTalla: M\n\nMedidas: \nAncho contorno: 74 cm \nLargo total: 66 cm \n\nColor: Negro\nRecomendación lavar a mano",
          instagram: "",
          precio: 21990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1476,
              nombre: "",
              url: "https://sexshopmayorista.cl/14498-large_default/body-victoria.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 562,
          codigo: "9780201376005",
          nombre: "Lubricante Afrodisiaco 60ml Sexlub",
          descripcion:
            "Lubricante afrodisiaco intimo base agua sin olor ni sabor Sexlub Afrodisiac. No mancha, espeso y compatible con condones de latex",
          detalles:
            "Sexlub Afrodisiac, como su nombre lo dice, es un lubricante afrodisiaco a base de agua, un clásico lubricante que te ayuda a complementar y mejorar tu nivel sexual con componentes y esencias afrodisiacos.\nEsta fórmula sedosa, a base de agua y perfecto para condones , revolucionara los sentidos de el y ella. Dé rienda suelta a un nuevo nivel de atracción mas allá de tu conciencia.",
          instagram: "",
          precio: 11990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1432,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2016/09/Athenalabs-Sexlub-Aphrodisiac-60-ml-F1-1.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 125,
          codigo: "7765432110194",
          nombre: "Anillo Vibrador Desechable Odín",
          descripcion:
            "Maten firma tu erección y retarda la eyaculación mientras estimulas el clítoris o el ano de tu pareja con este anillo peneano.\nHa sido concebido para transmitir el máximo de sensaciones mientras practicas el sexo con tu pareja. \nEl anillo vibrador, es un juguete erótico que fue diseñado para mantener la erección del pene durante mas tiempo y estimular el clítoris con su vibración. El vibrador posee protuberancias para aumentar el placer en el clítoris y zonas de excitación al momento de la penetración en la relación sexual.",
          detalles:
            "Características:\nAnillo para pene\nMaterial: Jelly\nBaterías: 2 - LR41 (incluidas)\nColor: Rosado\n\nObservaciones:\nAnillo desechable\nBatería dura 15 minutos aproximadamente.",
          instagram: "",
          precio: 5990,
          descuento: 14.0,
          stock: 11,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1196,
              nombre: "",
              url: "https://sexshopmayorista.cl/14993-large_default/anillo-vibrador-estimulante.jpg",
              descripcion: "",
            },
            {
              id: 1197,
              nombre: "",
              url: "https://sexshopmayorista.cl/14323-large_default/anillo-vibrador-estimulante.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 586,
          codigo: "7000020210913",
          nombre: "Plug Anal Con Joya Mediano - Negro",
          descripcion:
            "El Plug anal dilatador Joya, posee un diseño sofisticado y elegante, perfecto para los más exigentes. El plug de metal dorado es inoxidable y su característica principal es su brillante en tono oscuro en la base del plug anal. Tamaño mediano de forma cónica. El Plug Anal Metalizado Joya Rosado entrega una estimulación segura y placentera gracias a su diseño, material inoxidable y de fácil limpieza",
          detalles:
            "Características:\nPlug metalizado de acero inoxidable\nTalla M\nPlug anal dilatador\nDilatación anal\nEstimulación anal\nColor metal: Plateado\nColor joya: Negro\n\nMedidas:\nLargo: 8 cm\nDiámetro mayor: 3.5 cm\nDiámetro menor: 1 cm\n\nObservaciones: Utilizar con lubricante. De fácil limpieza",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1470,
              nombre: "",
              url: "https://ae01.alicdn.com/kf/Uf6700971b0dd491a97f1fd08a094f268S.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 212,
          codigo: "5580000210111",
          nombre: "Funda Cactus con Estimulador de Clítoris",
          descripcion:
            "Nada mejor que un juguete sexual en el que tanto el hombre como la mujer puedan obtener placer y orgasmos más intensos. Eso es lo que justamente ofrece la funda para  Cactus. Se trata de una funda que cumple con entregarle un mayor grosor y también más centímetros al pene, pero que además tiene un diseño que se destaca por tener variadas protuberancias que no solo lo hacen agradable al tacto, sino que también permiten estimular de manera intensa el clítoris, un órgano femenino, considerado como una de las zonas erógenas más potentes de la mujer y mediante el cual muchas consiguen experimentar orgasmos placenteros. La forma de usar este juguete es muy simple. Se coloca como una funda sobre el pene erecto, agregando de esta forma este artículo en la penetración. Se recomienda agregar un lubricante íntimo hecho en base a agua para maximizar la experiencia con el producto y para evitar que hayan roces molestos. Está fabricado de silicona, un material que se destaca por ser muy resistente, flexible y suave al tacto. Además, es totalmente compatible con la salud íntima, por lo que se puede usar con total y plena confianza",
          detalles:
            "Características:\nFunda para pene\nEstimulador de clítoris\nMaterial: Silicona\nSuperficie: Texturada con nódulos externos\nColor: Transparente\nTamaño real: 13cm\nDiámetro: 3 cm\nPeso: 33 grs.\n\nObservaciones:\nAconsejar el uso de preservativo y lubricante cuando se utilicen juguetes eróticos, de esta forma se obtendrá un resultado de óptima calidad que asegurara la vida útil de producto.\nLavar antes y después de usar.",
          instagram: "",
          precio: 5990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 876,
              nombre: "",
              url: "https://i.ibb.co/M5bJTPD/Pics-Art-05-08-12-03-39.jpg",
              descripcion: "",
            },
            {
              id: 877,
              nombre: "",
              url: "https://i1.wp.com/www.moira.cl/wp-content/uploads/2020/06/funda-cactus-con-estimulador-de-clitoris.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 587,
          codigo: "7050020210913",
          nombre: "Plug Anal Con Joya Mediano - Morado",
          descripcion:
            "El Plug anal dilatador Joya, posee un diseño sofisticado y elegante, perfecto para los más exigentes. El plug de metal dorado es inoxidable y su característica principal es su brillante en tono oscuro en la base del plug anal. Tamaño mediano de forma cónica. El Plug Anal Metalizado Joya Rosado entrega una estimulación segura y placentera gracias a su diseño, material inoxidable y de fácil limpieza",
          detalles:
            "Características:\nPlug metalizado de acero inoxidable\nTalla M\nPlug anal dilatador\nDilatación anal\nEstimulación anal\nColor metal: Plateado\nColor joya: Morado\n\nMedidas:\nLargo: 8 cm\nDiámetro mayor: 3.5 cm\nDiámetro menor: 1 cm\n\nObservaciones: Utilizar con lubricante. De fácil limpieza",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1471,
              nombre: "",
              url: "https://ae01.alicdn.com/kf/Ud701de4167ec47a2b579fb800db355afF.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 588,
          codigo: "7150020210913",
          nombre: "Plug Anal Con Joya Grande (L) - Negro",
          descripcion:
            "El Plug anal dilatador Joya, posee un diseño sofisticado y elegante, perfecto para los más exigentes. El plug de metal plateado es inoxidable y su característica principal es su brillante en tono oscuro en la base del plug anal. Tamaño grande de forma cónica. El Plug Anal Metalizado Joya Rosado entrega una estimulación segura y placentera gracias a su diseño, material inoxidable y de fácil limpieza",
          detalles:
            "Características:\nPlug metalizado de acero inoxidable\nTalla L\nPlug anal dilatador\nDilatación anal\nEstimulación anal\nColor metal: Plateado\nColor joya: Negro\n\nMedidas:\nLargo: 9.2 cm\nDiámetro mayor: 4.0 cm\nDiámetro menor: 1 cm\n\nObservaciones: Utilizar con lubricante. De fácil limpieza",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1472,
              nombre: "",
              url: "https://ae01.alicdn.com/kf/U61e30501a1284449af82e6980f5208c8m.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 589,
          codigo: "7150020210914",
          nombre: "Plug Anal Con Joya Grande (L) - Rojo",
          descripcion:
            "El Plug anal dilatador Joya, posee un diseño sofisticado y elegante, perfecto para los más exigentes. El plug de metal plateado es inoxidable y su característica principal es su brillante en tono Rojo en la base del plug anal. Tamaño grande de forma cónica. El Plug Anal Metalizado Joya Roja entrega una estimulación segura y placentera gracias a su diseño, material inoxidable y de fácil limpieza",
          detalles:
            "Características:\nPlug metalizado de acero inoxidable\nTalla L\nPlug anal dilatador\nDilatación anal\nEstimulación anal\nColor metal: Plateado\nColor joya: Rojo\n\nMedidas:\nLargo: 9.2 cm\nDiámetro mayor: 4.0 cm\nDiámetro menor: 1 cm\n\nObservaciones: Utilizar con lubricante. De fácil limpieza",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1473,
              nombre: "",
              url: "https://ae01.alicdn.com/kf/Ue478e3b993bc4d299f7ee49e3de8ca53w.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 590,
          codigo: "7150020210915",
          nombre: "Plug Anal Con Joya Grande (L) - Verde",
          descripcion:
            "El Plug anal dilatador Joya, posee un diseño sofisticado y elegante, perfecto para los más exigentes. El plug de metal plateado es inoxidable y su característica principal es su brillante en tono verdoso en la base del plug anal. Tamaño grande de forma cónica. El Plug Anal Metalizado Joya Verde entrega una estimulación segura y placentera gracias a su diseño, material inoxidable y de fácil limpieza",
          detalles:
            "Características:\nPlug metalizado de acero inoxidable\nTalla L\nPlug anal dilatador\nDilatación anal\nEstimulación anal\nColor metal: Plateado\nColor joya: Verde\n\nMedidas:\nLargo: 9.2 cm\nDiámetro mayor: 4.0 cm\nDiámetro menor: 1 cm\n\nObservaciones: Utilizar con lubricante. De fácil limpieza",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1474,
              nombre: "",
              url: "https://ae01.alicdn.com/kf/U128c974e65f948e6b6ee13202e059706n.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 399,
          codigo: "6931028870822",
          nombre: "Body de Malla ",
          descripcion: "Sexy body de malla, con escote pronunciado. ",
          detalles:
            "Tipo Colales.\nColor Negro\nTalla Estándar (abarca desde XS a M)\nNo incluye accesorios",
          instagram: "",
          precio: 6990,
          descuento: 28.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1001,
              nombre: "",
              url: "https://i.ibb.co/n6ZbNsG/Pics-Art-07-07-02-09-41.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 591,
          codigo: "904796010154",
          nombre: "Conjunto Gala Rojo",
          descripcion:
            "Gala es un conjunto de encaje rojo de 2 piezas. El bralette de encaje trae tirantes negros de hombros ajustables para mejor calce y cintas de tela roja para abrochar al frente, su calzón tipo bikini se abrocha a los costados con el mismo sistema de cintas rojas, dando la opción para ajustarlo de mejor manera a su cuerpo.",
          detalles:
            "Sin Forro\n\nTirantes de hombro ajustables\nCintas de calzón y bralette ajustables\nMaterial: 95% poliéster 5% spandex\nTalla: L\nMedidas Bralette: \nAncho contorno: 70 cm // Cintas: 60 cm \nMedidas Calzón: \nAncho tela encaje: 28 cm // cintas: 60cm ",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1475,
              nombre: "",
              url: "https://sexshopmayorista.cl/14514-large_default/Conjunto-gala.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 593,
          codigo: "604684049621",
          nombre: "Conjunto de Encaje Andrómeda",
          descripcion:
            "Andrómeda es un conjunto de encaje de 3 piezas. Un hermoso bralette de encaje negro elástico con tirantes de hombro ajustables para mejor calce. \nSu calzón de encaje negro, es de tiro y corte alto, que se complementa con los guantes de encaje negro largos que trae.",
          detalles:
            "Sin forro\nTirantes ajustables de hombro\nCalzón corte alto\nMaterial: 95% poliéster 5% spandex\nTalla: L\n\nMedidas: \nAncho contorno Bralette: 72 cm \nAncho contorno calzón: 76 cm \nLargo calzón: 38 cm  \nLargo mangas: 50 cm ",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1477,
              nombre: "",
              url: "https://sexshopmayorista.cl/14497-large_default/conjunto-andromeda.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 350,
          codigo: "716222204262",
          nombre: "Lubricante Comestible Sabor Cereza - 89ml",
          descripcion:
            "La elaboración del Lubricante íntimo de Flavors lo hace un producto estimulante, por sus principales características que es lubricar para facilitar la penetración, proporciona efecto calor lo cual brinda una rápida excitación, donde sus componentes permiten su uso como un gel para masajes eróticos y además es comestible para un delicioso sexo oral.",
          detalles:
            "Ingredientes: Glicerina y saborizantes artificiales.\n\nCaracterísticas:\nContenido: 89 ml\nSabor: Cereza\nEfecto: Calor\n\nObservaciones:\nNo contiene azúcar\nNo contiene colorantes\nLibre de manchas\n\nModo de aplicación:\nAplicar la cantidad deseada para lubricar las zonas íntimas o realizar masajes.\nEnjuagar con agua fría y jabón suave.",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1055,
              nombre: "",
              url: "https://i.ibb.co/VpGY9Hr/Pics-Art-07-07-01-15-48.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 594,
          codigo: "6937526503439",
          nombre: "Succionador Ivan",
          descripcion:
            "Succionador de clítoris o pezones, funciona con tecnología Pleasure Air que posee 4 patrones de succión y soplido.\nEl fantástico juguete sexual que funciona con tecnología Pleasure Air, la cual estimula el clítoris o cualquier zona erógena del cuerpo como los pezones, a través de sus pulsaciones de aire que generan succión y soplido, generando una intensa estimulación sin contacto directo. Posee 4 funciones de pulsación que permite explorar distintas intensidades y variedades en su uso. Su forma ergonómica está diseñada de plástico ABS suave y liso y una cubierta de silicona en la zona de succión, que entrega una especial suavidad y blandura para acomodarse al contacto con la zona del cuerpo donde se utilice.",
          detalles:
            "Características\n\nMaterial: Plástico ABS y Silicona\nLargo: 13 cm\nAncho: 4,5 cm\nColor: rosado\nBaterías: 2AAA (no incluidas)\n\nObservaciones\n\nA prueba de agua\nBaterías no incluidas\n\nRecomendaciones\nLavar antes y después de cada uso.\nLavar con agua y jabón neutro.\nApto para Lubricantes base a agua y silicona.\nGuardar en un sitio limpio y seco, sin mezclar con otros juguetes.\nEvitar mojar compartimento para pilas durante la limpieza.\nRetirar las pilas antes de lavarlo y cuando esté en desuso.\nNo compartir juguete con otras personas.",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1478,
              nombre: "",
              url: "https://sexshopmayorista.cl/15440-large_default/succionador-ivan.jpg",
              descripcion: "",
            },
            {
              id: 1479,
              nombre: "",
              url: "https://sexshopmayorista.cl/15437-large_default/succionador-ivan.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 236,
          codigo: "6959532320312",
          nombre: "Dedo Vibrador Abbott",
          descripcion:
            "El nuevo Dedo Vibrador Abbott es ergonómico y muy fácil de usar. Fabricado de material de silicona aterciopelada unido a su diseño estriado y su bala vibradora buscan dar el mayor placer a quien la utilice. Pensado tanto para la auto-satisfacción como para el juego con tu compañero(a). Apto para lubricantes base a agua o híbridos, por tanto se puede lavar sin problemas. Sumergible. Uso dos baterías 3LR41.",
          detalles:
            "Características\nMaterial Silicona\nColor Negro\nCon bala vibradora\nUso dos baterías 3LR41 (incluidas)\n\nMedidas\nLargo Total: 9,5cm\nLargo insertable: 7,5cm\nDiámetro: 4,5cm\n\nObservaciones\nResistente al agua / sumergible\nApto para Lubricantes base a agua o híbridos\nDiseño ergonómico",
          instagram: "",
          precio: 29990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 567,
              nombre: "",
              url: "https://i.ibb.co/dmSq06N/Pics-Art-02-09-02-37-52.jpg",
              descripcion: "",
            },
            {
              id: 568,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/12576-large_default/dedo-vibrador-abbott-.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 35,
          codigo: "6039122584864",
          nombre: "Feromonas Masculinas con Aroma PureSex",
          descripcion:
            "Este producto es una feromona con aroma maderoso. Se debe aplicar en el cuello, detrás de las orejas o muñecas para que se impregne mejor al cuerpo. Pure Sex ayuda a intensificar las feromonas que son producidas naturalmente por el cuerpo, logrando aumentar el atractivo de quien lo use. Sin embargo, debe ser usado con actitud para lograr conquistar a la persona deseada.\n\nSus notas altas son una mezcla de pera, albahaca, bergamota italiana y limón persa. Sus notas de corazón revelan acordes de melón, menta, cardamono, lirio del valle y nenúfar. Las notas de fondo son una combinación de pimienta negra, patchouli de indonesia, cedro virginia y almizcles",
          detalles:
            "Características:\nContenido: 30 ml.\nConcentración: 100%\n\nObservaciones:\nPerfume Masculino PureSex con feromona.\nAroma Maderoso\n\nIngredientes: water, alcohol, fixer, pheromone, aromatic, ozonic, amber, anise (Pimpinella Anisum), Musk, Cedar, Diazolidinyl Carbamide, Blue 1 (CI N. 42090), Androstenedione (5-androsten-3b, 17b-diol)\n\nIMPORTANTE\nLos resultados dependerán del uso correcto del producto y del organismo de cada persona.\nAntes de usa un producto de cosmética erótica se recomienda revisar los componentes de este para evitar reacciones alérgicas.\nEn caso de irritación suspender y consultar con su dermatólogo.",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 121,
              nombre: "",
              url: "https://i.ibb.co/SwSCLMq/1606606137865.jpg",
              descripcion: "",
            },
            {
              id: 122,
              nombre: "",
              url: "https://i.ibb.co/2gHpNG2/1606606137813.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 18,
              nombre: "Feromonas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 595,
          codigo: "8717703522297",
          nombre: "Kit Fuck Me",
          descripcion:
            "FUCKME es uno de los traviesos juegos de la serie¨XXX-ME¨ de ¨Tease & Please¨, para el máximo placer y la fantasía sin límite entre la\npareja de enamorados.\n\nCon este juego van a descubrir posturas que no son fáciles al principio, pero que, sin duda, pueden ser placenteras. Los\ncaracterísticos juguetes sirven para ayudar y/o aumentar el placer mutuo de forma que saltarán las chispas.",
          detalles:
            "En la caja de juegos tienes los siguientes objetos eróticos: un anillo de amor, un anillo de amor vibrador y dados eróticos. Este juego\ncontiene también 6 extensos libritos con tareas en 10 idiomas: inglés, español, francés, alemán, holandés, polaco, ruso, italiano, sueco y\nnoruego.Por último, pero no menos importante, este juego contiene una baraja de cartas de 52 posturas del ¨Kamasutra¨para no olvidar jamás.\n\nDéjate sorprender por las tareas en las que el placer mutuo es lo principal. Cada postura tiene sus ventajas propias, en las que se\nestimulan de manera especial algunas zonas y gracias a las que , además,te pondrás más flexible también. Tease & Please os desea que alcancéis el máximo placer con el juego. Para terminar: FUCKME está recomendado, sin lugar a dudas, para todos aquellos que quieran experimentar con diferentes, placenteras posturas, en cuya caja de juegos también hay objetos eróticos de ayuda.",
          instagram: "",
          precio: 22990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1480,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/4450158/thumb/540/540?1636720616",
              descripcion: "",
            },
            {
              id: 1481,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/4450159/thumb/540/540?1636720616",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 596,
          codigo: "8717703522266",
          nombre: "Kit Tease Me",
          descripcion:
            "TEASEME o bien ¨provócame¨, es uno de los traviesos juegos de la serie¨XXX-ME¨ de ¨Tease & Please¨,\npara el máximo placer y la fantasía sin límite entre la pareja de enamorados.",
          detalles:
            "Con este juego podéis descubrir variantes sexuales en las que, quizás, todavía nunca antes habíais pensado. ¨Ser dominante¨o ¨sumiso¨; ¿qué será? Os mimaréis jugando y fantaseando al máximo con los característicos juguetes que se incluyen en la caja del juego, tales\ncomo una venda para los ojos, cuerdas para ataduras eróticas y delicadas (regulables) pinzas para pezones. Este juego contiene también 6 extensos libritos con tareas en 10 idiomas: inglés, español, francés, alemán, holandés, polaco, ruso, italiano, sueco y noruego.\n\nLo que va a suceder durante este sorprendente juego de poder y sumisión es impredecible, nunca sabes. ¿Te gusta ser el que sostiene las cuerdas o prefieres ser atado? ¿Has experimentado alguna vez en cuántos lugares puedes tener una (dolorosa) placentera sensación si te pusieras unas pinzas (para pezones)? ¿Cómo experimentas el roce suave o algo doloroso de tu pareja si tú mismo no puedes ver nada porque llevas una venda en los ojos?\n\nDéjate sorprender por las tareas en las que unas veces actuarás como ¨dominante¨ y otras como ¨sumiso¨ Garantizado que vuestros sentidos llegarán a estar a punto de explotar, sobre todo, gracias a los indispensables objetos eróticos como la venda para los ojos, cuerda para ataduras(6 metros) y las pinzas de pezones regulables. Tease & Please os desea que alcancéis el máximo placer con el juego.",
          instagram: "",
          precio: 22990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1482,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/4450173/thumb/540/540?1632428355",
              descripcion: "",
            },
            {
              id: 1483,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/4450172/thumb/540/540?1632428355",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 509,
          codigo: "855559007587",
          nombre: "Tush Tingle - Gel Anal Estimulante",
          descripcion:
            'Descubre nuevos placeres anales con este gel de estimulación.\n\nCreará una excitante sensación de cosquilleo en el ano. Adecuado para "sazonar" el sexo anal y acrecentar las sensaciones durante la penetración anal.',
          detalles:
            "Instrucciones: Aplica gel en las yemas de los dedos y luego frótalo suavemente por el ano. Puede aplicarse toda la cantidad que se desee. Puede complementar el uso de un lubricante o relajante anal\n\nLibre de glicerina y parabenos\n\nContenido: 59ml",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1358,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/14116181/thumb/540/540?1627677791",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 283,
          codigo: "646709100025",
          nombre: "Antifaz de Satín Rojo",
          descripcion:
            "Este antifaz te ayudará a disfrutar de excitantes noches amorosas. Ideales para combinar con el uso de cosmética sensual para exacerbar todos los sentidos y desinhibir la fantasía.",
          detalles: "Color Rojo",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 6,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 774,
              nombre: "",
              url: "https://i.ibb.co/pKgHqgg/Pics-Art-03-21-12-35-22.jpg",
              descripcion: "",
            },
            {
              id: 775,
              nombre: "",
              url: "https://i.ibb.co/C5wXL6M/Pics-Art-03-21-12-34-34.jpg",
              descripcion: "",
            },
            {
              id: 776,
              nombre: "",
              url: "https://cdnx.jumpseller.com/secretos-de-amor-cl/image/4161960/resize/635/635?1584240771",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 32,
              nombre: "BDSM",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 22,
              nombre: "Kits",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 305,
          codigo: "7720100171419",
          nombre: "Aceite para Masajes Sabor Uva (30ml)",
          descripcion:
            "Disfruta del nuevo Gel comestible Erotic Uva 30 ml. y aplícalo donde gustes y cuando quieras ya que su nueva fórmula permite que se puede lamer desde todas las partes del cuerpo para que tu y tu pareja se disfruten a mas no poder",
          detalles:
            "Ingredientes: Prunus Amygdalus Dulcis (Sweet almond) Oil, Capsicum Frutes cens Fruit Extract, Aroma, Tocopheryl Acetate, Propylparaben, Bht, CI 16035, CI 19140, CI 42090.\n\nCaracterísticas:\nSabor: Uva\nContenido: 30 ml\nEfecto Calor\n\nObservaciones:\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. Eliminar el exceso con agua tibia y jabón neutro.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 819,
              nombre: "",
              url: "https://i.ibb.co/CzWccvk/Pics-Art-04-19-12-59-45.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 597,
          codigo: "8717703522341",
          nombre: "Kit Anal Me",
          descripcion:
            "ANALME is uno de los juegos picantes de la serie “XXX-ME” de Tease & Please, para una gran diversión y aventura erótica entre dos amantes. ¿Listo(a) para experimentar con el sexo anal? ¿Excitante, doloroso o encantador?",
          detalles:
            "Los atributos de juego que se incluyen en esta caja de juegos como un butt plug, cuentas anales  os mimarán de forma lúdica y cuidadosa. Además, este juego contiene 6 libretas con cantidad de tareas en 10 idiomas:\ninglés, español, francés, alemán, neerlandés, polaco, ruso,italiano, sueco y noruego.\n\nDéjate sorprender por las tareas y los consejos prácticos centrados en el placer mutuo. Cada tarea o postura tiene sus propias ventajas específicas para que vayas experimentando lo que más te guste. Toma tu tiempo, hazlo lentamente, con\ndestreza y disfruta de tu pareja. Tease & Please os desea mucha diversión",
          instagram: "",
          precio: 22990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1484,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/4450180/thumb/540/540?1634155897",
              descripcion: "",
            },
            {
              id: 1485,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/4450179/thumb/540/540?1634155897",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 43,
          codigo: "772933000203",
          nombre: "Aceite para Masajes Sabor Café Helado (30ml)",
          descripcion:
            "Descubre los Geles Comestibles Erotic, con efecto calor y 100% libres de azúcar, de exquisito aroma y sabor, entrega a tu pareja el mejor sexo oral de la vida y logra una noche inolvidable, se puede retirar fácilmente con agua tibia y jabón neutro. No dejes pasar más tiempo y prueba nuestra línea de Geles comestibles en diferentes sabores. \nStarsex te trae nuevos sabores para que disfrutes junto a quien más quieras de exquisitos juegos eróticos, masajes y un delicioso sexo oral lo que aumentará la excitación en ambos. ",
          detalles:
            "Características:\nSabor Café Helado\nGel comestible con efecto calor\nEfecto: efecto calor y comestible\nContenido: 30 ml\nIngredientes: Glicerina, propilengycol, Agua, Saborizante\n\nObservaciones:\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. También se puede aplicar en distintas zonas erógenas para un rico sexo oral. Después de aplicarlo debes soplar y tu pareja sentirá una agradable sensación de calor.\nEliminar el exceso con agua tibia y jabón neutro.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 8,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 607,
              nombre: "",
              url: "https://i.ibb.co/CzzBSH9/Pics-Art-02-27-02-13-10.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 18,
          codigo: "555000018123",
          nombre: "Anillo Vibrador Reutilizable ",
          descripcion:
            "Con el anillo Vibrador reutilizable, obtendrás erecciones más firmes y prolongadas, y con su bala vibradora de 5 velocidades podrás estimular clítoris o testículos, dándoles a amb@s una experiencia sexual fuera de serie.\nPoner sobre el pene erecto.",
          detalles:
            "\nObservaciones:\n3 intensidades de vibración\n2 Intensidades de pulsación\nLimpiar antes y después de utilizar, con agua tibia y jabón neutro.\nGuardar en un sitio limpio y seco, sin mezclar con otros juguetes.\nUsar con lubricante a base de agua y/o con preservativo.\nLibre de Ftalatos\nNo mojar ni sumergir las partes eléctricas\nFuente de Energía: 2 Pilas LR1130 (Incluidas).\n\nRecomendaciones: \nSolo debes estar erecto, aplicar lubricante y poner el anillo en la base del pene. Utilizar por un máximo de 20 min por acto sexual. Esto ayudará a mantener una buena erección durante el acto y a retardar un poco la eyaculación. Recomendamos rebajar el vello para evitar incomodidad en el uso.",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 8,
          activo: true,
          destacado: true,
          imagenes: [
            {
              id: 600,
              nombre: "",
              url: "https://i.ibb.co/6PgfTYv/Pics-Art-02-27-01-45-24.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 598,
          codigo: "818631020287",
          nombre: "Dickalicious Frambuesa",
          descripcion:
            "Estimulador masculino para sexo oral sabor frambuesa, con sensación de hormigueo. \n\nEl nuevo Dickalicious Raspberry es un gel estimulante  para sexo oral que entrega un excitante efecto hormigueo y un delicioso sabor a frambuesa.",
          detalles:
            "Ingredientes: Petrolatum, Mineral oil, Sorbitan Oreate, Flavor, Menthol, Sodium Saccharin, Yellow 11(CI#47000), Red 17 (CI# 26100), Propylparaben.\n\nContenido: 56g\nSabor: Frambuesa\nEfecto: Hormigueo\n\nCaracterísticas:\nEstimulante\nGel para sexo oral\nFormula soluble al agua\nNo pegajoso\nHidratante ",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1486,
              nombre: "",
              url: "https://sexohouse.cl/3028-thickbox_default/gel-dickalicious-peneano-frambuesa.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 494,
          codigo: "6652161154012",
          nombre: "Gel Oralicious Sachet",
          descripcion:
            "Gel intimo para sexo oral con efecto hormigueo y agradables sabores.",
          detalles:
            "Gel íntimo Oralicious  para sexo oral.\nDescubre nuevas sensaciones al momento de practicar sexo oral, ya que Oralicious te hará sentir efecto hormigueo, con agradable sabor y aroma.\n\nCaracterísticas:\nContenido: 3 ml\nSabores: Piña colada, banana split, frutilla, duraznos con crema y guinda.\n\nObservaciones:\nVariedad de sabores según stock.",
          instagram: "",
          precio: 2490,
          descuento: 14.0,
          stock: 24,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1311,
              nombre: "",
              url: "https://i.ibb.co/7NvJXyy/Pics-Art-08-14-01-13-01.jpg",
              descripcion: "",
            },
            {
              id: 1312,
              nombre: "",
              url: "https://sexshopmayorista.cl/9495-large_default/lubricante-oralicious-sachet.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 174,
          codigo: "7730963250343",
          nombre: "Preservativos Extra Grande y Ultra Sensible Prudence ",
          descripcion:
            "Preservativo Prudence Extra Grande/Ultra Sensible, un preservativo liso lubricado y de tamaño XL con mayor diámetro para una extrema comodidad, tiene un ligero sabor a vainilla, fabricado de látex fino para mayor sensibilidad.",
          detalles:
            "Características:\nMaterial: látex\nProveedor: Prudence\nContiene 3 unidades\nColor: incoloro\n\nMedidas\nAncho: 5.6 cm\nLargo: 17 cm\n\nObservaciones\nUsar con lubricante a base de agua\nRevisar componentes para evitar reacciones alérgicas\nEn caso de irritación suspender y consultar a su médico",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 10,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 520,
              nombre: "",
              url: "https://i.ibb.co/2d0Dhs8/1609808562178.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 107,
          codigo: "7804638900021",
          nombre: "Cápsulas Libi Men",
          descripcion:
            "Libi Man - Libera tu pasión, esta especialmente hecho para el hombre ya que posee un componente natural llamado Guanarpo, el cuál bloquea los receptores alfa-adrenérgicos que reducen el efecto de las hormonas que causan vasoconstricción de los vasos sanguíneos en los tejidos del pene y aumentan la producción de noradrenalina, que es esencial en el mantenimiento de la función eréctil.\nSe recomienda consumirlas como tratamiento y se comenzarán a ver resultados permanentes a partir del 5to día de uso. Su uso se puede complementar con otro tipo de juguetes potenciadores como anillos peneanos por ejemplo.\n",
          detalles:
            "Importante:\n-Recomendado para hombres, independiente de su edad u orientación sexual.\n-No genera dependencia ni tiene efectos colaterales.\n-Sus componentes actúan fuertemente sobre el desinterés sexual, mejorando la libido y el deseo y estimulando de manera natural la sexualidad. Esto, ya que provoca excitación en la zona genital, por lo que ayuda a sentir apetito sexual en las personas que lo ingieren\n-Sus componentes están libres de excipientes, esto quiere decir que se encuentran en su calidad más pura, sin químicos.\n-Libi Man es un producto que cuenta con la certificación del Seremi de Salud.\n\nIngredientes: Guanarpo, Clavo de Huasca, Ginseng, L-Arginina, Cápsula vegetal (HPMC 100%).\n\nContenido: 60 cápsulas (duración 1 mes).\n\nDosis recomendada: 1 cápsula cada 12 horas.\n\nModo de Empleo: Tomar con un vaso de agua (200 ml).\n\nAlmacenamiento: Mantener el envase bien cerrado en un lugar fresco y seco a temperaturas entre -15-25° C \n\nPrecauciones: Su consumo no es recomendable para menores de 8 años, embarazadas y nodrizas, salvo indicación profesional competente y no reemplaza a una alimentación balanceada",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 144,
              nombre: "",
              url: "https://i.ibb.co/cCv5yXL/IMG-20201108-145356.jpg",
              descripcion: "",
            },
            {
              id: 145,
              nombre: "",
              url: "https://i.ibb.co/vzzd43n/IMG-20201108-144904.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 144,
          codigo: "22007568",
          nombre: "Esposas Afelpadas Negras",
          descripcion:
            "Ser la/el am@ o ser la/el sumis@...\nDéjate seducir por estas hermosas esposas para comenzar en tu aventura al interior del BDSM, ideales para comenzar e infaltable en cualquier colección de los amantes de esta práctica, estas esposas afelpadas serán cómodas, bellas y seguras para ti y tu sumis@...\n\nJamás olvides el consentimiento y la palabra de seguridad... 😉",
          detalles:
            "Colores:\n- Negro\n\nEspecificación:\nMaterial: piel sintética + felpa\nLongitud: 31cm \nAncho: 5 cm \nDiámetro máximo: 7,5 cm\n\nLargo Cadena: 8cm",
          instagram: "",
          precio: 7990,
          descuento: 14.0,
          stock: 13,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 800,
              nombre: "",
              url: "https://i.ibb.co/myvQGcY/Pics-Art-04-19-12-54-36.jpg",
              descripcion: "",
            },
            {
              id: 1454,
              nombre: "",
              url: "https://i.ibb.co/SsSzDzS/Pics-Art-04-19-12-54-11.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 32,
              nombre: "BDSM",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 599,
          codigo: "697309064033",
          nombre: "Lubricante Melón/Mango Toko",
          descripcion:
            "La línea Aroma de Shunga tiene exquisitos lubricantes con sabores frutales, especiales para excitar y guiar los sentidos. Además se caracterizan por su sensación ultra sedosa y larga duración.\n\nTodos los lubricantes en base de agua están pensados para facilitar la penetración, el sexo manual, o simplemente para ofrecer una sensación aún más placentera. La gracia de esta línea es que además brinda la posibilidad de estimular el cuerpo del otro a través del sentido del gusto, aprovechando los deliciosos sabores que trae esta excelente línea de productos.",
          detalles:
            "Características:\n- 165 ml\n- Hipoalergénico\n- Base de agua\n- Comestible\n- No mancha\n- No contiene azúcar\n- Compatible con látex y juguetes de silicona\n- Fabricado en Canadá",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1487,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/657000/thumb/540/540?1637180321",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 204,
          codigo: "J303",
          nombre: "Portaligas",
          descripcion:
            "Portaligas con detalles de encaje en la parte inferior. \nPerfectas para combinar con tus pantys ligas favoritas. ",
          detalles:
            "Talla Estándar\nMaterial: Razo y encaje\nColores:\n- Rojo\n- Negro\n- Blanco",
          instagram: "",
          precio: 7990,
          descuento: 28.0,
          stock: 10,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 360,
              nombre: "",
              url: "https://i.ibb.co/G7HtTCJ/Pics-Art-01-18-11-22-25.jpg",
              descripcion: "",
            },
            {
              id: 361,
              nombre: "",
              url: "https://i.ibb.co/VjZ9Fdr/Pics-Art-01-18-11-26-31.jpg",
              descripcion: "",
            },
            {
              id: 362,
              nombre: "",
              url: "https://i.ibb.co/WxwgG2D/Pics-Art-01-18-11-27-27.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 191,
          codigo: "700604737158",
          nombre: "Corset Explosión de Color Verde",
          descripcion:
            "Hermoso Corset explosión de colores verde. Posee ajuste con cintas en la parte trasera.\n",
          detalles:
            "Talla XL \nColor Verde con detalles de otros colores\nMaterial Polyester y Spandex\nIncluye Colales \n\nObservación: Talla pequeña, equivale a un L/XL Nacional.",
          instagram: "",
          precio: 21990,
          descuento: 28.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 810,
              nombre: "",
              url: "https://i.ibb.co/CmwcQHN/Pics-Art-04-19-12-51-49.jpg",
              descripcion: "",
            },
            {
              id: 811,
              nombre: "",
              url: "https://i.ibb.co/j64FpgD/Pics-Art-04-19-12-52-17.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 600,
          codigo: "7770100420208",
          nombre: "Lubricante 4 En 1 Chocolate Caliente 30ml",
          descripcion:
            "Lubricante híbrido formulado a base de agua y silicona que proporciona lubricación por más tiempo. Brinda un estimulante efecto calor y un delicioso sabor y aroma a chocolate. Ideal para Sexo oral, anal, vaginal, masturbación. Compatible con preservativos y juguetería. No contiene azúcar, fácil de lavar. ",
          detalles:
            "Características:\nContenido:30 ml\nSabor: Chocolate\nAroma: Chocolate\nEfecto: Calor\n\n\nModo de uso:\nAplicar directo en la zona a lubricar o masajear (vulva, pene, pezones, etc) reaplicar de ser necesario.\n\nIngredientes:\nPure Water, silicone Oil, Glycerol, Hydroxyethyl Cellulose, Chocolate Essence And Others",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 6,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1488,
              nombre: "",
              url: "https://sexshopmayorista.cl/15725-large_default/lubricante-4-en-1-chocolate-caliente-30ml.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
          ],
        },
        {
          id: 510,
          codigo: "855559007419",
          nombre: "Retardante On Bold ",
          descripcion:
            'Este gel dulzón y mentolado permite al hombre sentir todo el placer; mientras reduce la "sobre- estimulación" y ayuda a durar más tiempo. En otras palabras es un excelente apoyo como gel retardante para el hombre que desea demorar su eyaculación. Es seguro durante el juego oral y la penetración también.',
          detalles:
            "Para resultados más rápidos, masajee el glande con una pequeña cantidad unos 10 minutos antes del coito. Permita al gel retardante ser absorbido por la piel para lograr el efecto pleno deseado. Retire el excedente si lo hay previamente al coito.\nEnvase dispensador - Contenido 29ml\n\n• Hecho con benzocaína para prevenir la eyaculación prematura.\n• Seguro en la penetración\n• Seguro en el juego oral\n• Seguro para usar con condón de látex u otros materiales\n• Hecho a base de ingredientes saludables y preservantes naturales.\n• Sabor dulce y mentolado\n\n\nHecho en EEUU",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1359,
              nombre: "",
              url: "https://cdnx.jumpseller.com/secretos-de-amor-cl/image/4162190/resize/635/635?1629388907",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 344,
          codigo: "7809604500397",
          nombre: "Preservativo SensorPlus - Super Long XL",
          descripcion:
            "Sensor Plus - Super Long XL\n\nLubricados interior y exteriormente. Látex transparente.\nCon un mayor tamaño, 52 x 200 mm.\n\nCantidad: 3 unidades",
          detalles: "",
          instagram: "",
          precio: 1990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1032,
              nombre: "",
              url: "https://i.ibb.co/1JfX5fV/Pics-Art-07-07-02-27-57.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 602,
          codigo: "7770100420307",
          nombre: "Sachet Lubricante 4 En 1 Chocolate Caliente 5ml",
          descripcion:
            "Chocolate caliente es la nueva fórmula 4 en 1 que STARSEX ha desarrollado para diversas ocasiones. Posee un delicioso sabor y aroma a Chocolate que además produce un cálido efecto en la zona aplicada. Es un lubricante ideal para practicar sexo oral o realizar masajes localizados en cualquier zona erógena del cuerpo. Su composición a base de agua y silicona brinda lubricación de lenta absorción convirtiéndolo en el lubricante perfecto para la masturbación o cualquier encuentro sexual, ya sea vaginal, anal u oral. No contiene azúcar, no mancha y es de fácil limpieza y aplicación.",
          detalles:
            "Características:\nContenido: 5 ml\nSabor: Chocolate\nAroma: Chocolate\nEfecto: Calor\n\n\nModo de uso:\nAplicar directo en la zona a lubricar o masajear (vulva, pene, pezones, etc) reaplicar de ser necesario.\n\nIngredientes:\nPure Water, silicone Oil, Glycerol, Hydroxyethyl Cellulose, Chocolate Essence And Others",
          instagram: "",
          precio: 1490,
          descuento: 14.0,
          stock: 9,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1490,
              nombre: "",
              url: "https://sexshopmayorista.cl/15726-large_default/sachet-lubricante-4-en-1-chocolate-caliente-5ml.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 209,
          codigo: "603912262674",
          nombre: "Peta Zeta Sabor Manzana Verde",
          descripcion:
            "Peta Zeta es un delicioso caramelo en forma de pequeños cristales que en contacto con la saliva “estallan”, provocando un pequeño hormigueo en la boca y en las partes intimas de tu pareja, es ideal para el sexo oral.\nPeta Zeta te ayuda a salir de la rutina y jugar con distintos movimientos para el disfrute en compañía, pasar un rato distinto y divertido.\nEl uso de Peta Zeta es muy sencillo, solo debes poner los dulces dentro de la boca antes de comenzar a dar sexo oral a la pareja y listo.\nCada sachet trae el contenido suficiente para poder realizar varias sesiones de sexo oral e incluso intercambiar en la pareja si se desea ya que es compatible tanto para hombres como para mujeres.\nEs ideal para quienes buscan nuevas sensaciones y salir de la rutina o para quienes simplemente aman el sexo oral y quieren seguir experimentándolo y lo más importante, a diferencia de otro tipo de elementos comestibles que se utilizan en el sexo, Peta Zeta no deja pegajoso.\nDespués de probar Peta Zeta de seguro que el sexo oral será una de tus prácticas favoritas en la cama o donde quieras.",
          detalles:
            "Características:\nCaramelo efervescente\nPara sexo oral \nUnisex\n\nIngredientes: Azúcar, jarabe de maíz, ácido cítrico, ácido láctico, lactosa\n\nObservaciones: Vierte una porción en tu boca del caramelo efervescente y practica sexo oral. Libre de grasas saturadas y trans, colesterol, sodio. No aplicar directo en genitales \n\nImportante: Este producto contiene azúcar y lactosa por lo que si eres intolerante a la lactosa, no es recomendable que lo utilices.",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 629,
              nombre: "",
              url: "https://i.ibb.co/HVCX6bj/Pics-Art-02-28-08-17-34.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 34,
          codigo: "6039122584963",
          nombre: "Feromonas Femeninas con Aroma  PureSex",
          descripcion:
            "Perfume con feromonas y un exquisito aroma dulce para llamar la atención de quien tanto te gusta o bien para atraer y llamar la atención de nuevas personas a tu vida.\n\n100% concentracion de feromonas, sus notas altas son una mezcla de bergamota y limón, sus notas de corazón revelan acordes florales y sus notas de fondo son una combinación de vainilla, almizcle y cedro.",
          detalles:
            "Características:\nContenido: 30 ml.\nConcentración: 100% feromonas\n\nObservaciones:\nPerfume Femenino PureSex con feromonas.\nAroma dulce.\nPara mayor información visitar www.puresex.biz\n\nIngredientes: agua, alcohol, fijador, feromona, glicerina, aroma oriental, maderoso, fresco, musk, cedro, androstenedione (5-androsten-3b, 17b-diol)\n\nRecomendamos aplicarla en las zonas donde hay pulso como cuello, muñecas, detrás de las orejas, incluso detrás de las rodillas para intensificar su efecto\n\nIMPORTANTE\nLos resultados dependerán del uso correcto del producto y del organismo de cada persona.\nAntes de usa un producto de cosmética erótica se recomienda revisar los componentes de este para evitar reacciones alérgicas.\nEn caso de irritación suspender y consultar con su dermatólogo.",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 6,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 119,
              nombre: "",
              url: "https://i.ibb.co/tsv6YGr/1606606137830.jpg",
              descripcion: "",
            },
            {
              id: 120,
              nombre: "",
              url: "https://i.ibb.co/2gHpNG2/1606606137813.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 18,
              nombre: "Feromonas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 603,
          codigo: "7770100420505",
          nombre: "Sachet Lubricante 4 En 1 FrutiLub 5ml",
          descripcion:
            "Es un lubricante híbrido, a base de agua y silicona, de lenta absorción, compatible con juguetería y preservativos. Posee efecto calor, sabor y aroma a frutilla y una ligera consistencia, fácil de limpiar y no pegajosa que permite realizar masajes localizados en cualquier zona erógena del cuerpo. Además lubrica y estimula en cualquier encuentro sexual ya sea oral, anal o vaginal.",
          detalles:
            "Características:\nContenido:5 ml\nSabor: Frutilla\nAroma: Frutilla\nEfecto: Calor\n\n\nModo de uso:\nAplicar directo en la zona a lubricar o masajear (vulva, pene, pezones, etc) reaplicar de ser necesario.\n\nIngredientes:\nPure Water, silicone Oil, Glycerol, Hydroxyethyl Cellulose, Strawberry Essence And Others\n",
          instagram: "",
          precio: 1490,
          descuento: 14.0,
          stock: 7,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1491,
              nombre: "",
              url: "https://sexshopmayorista.cl/15723-large_default/sachet-lubricante-4-en-1-frutilub-5ml.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 604,
          codigo: "855559005927",
          nombre: "Sachet On Coffee Cake Para Ella 6ml",
          descripcion:
            "El Gel excitante femenino ON arousal GEL coffe cake proporciona una increíble sensación cálida y vibrante. Con su fórmula segura a base de agua, fácil de usar y sin ensuciar On arousal Gel crea una sensación excitante que pulsa y vibra cuando se aplica directamente en el clítoris.",
          detalles:
            "Características:\nContenido: Sachet 4ml (un solo uso)\nSabor: Coffe Cake\nEfecto: Gel excitante, proporciona calor y vibración.\n\n \n\n \n\nModo de uso:\nAplique una pequeña cantidad de gen en el clítoris. Espere 2 minutos para un efecto completo, la sensación puede durar hasta 30 minutos, aplique nuevamente si desea.\n\nIngredientes:\nAgua, propanodiol, prunus amygdalus dulcis (aceite de almendras dulces) Rosmarinus Offcinalis (romero), Cinnamomun zeylanicum (aceite de canela), celulosa, extracto de hoja de Stevia Rebadiana, palmitato de isopropilo, monoestearato de sorbitán, benzoato de sodio, gluconalactona sorbato americano, zanthoxylum sabores naturales",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 7,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1492,
              nombre: "",
              url: "https://sexshopmayorista.cl/15704-large_default/sachet-on-coffee-cake-para-ella-6ml.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 606,
          codigo: "448001020113",
          nombre: "Mini Bala Vibradora",
          descripcion:
            "Mini Bala Vibradora es un juguete sexual unisex de estimulación externa, ideal para estimular zonas erógenas tales como: pezones, testículos, muslos, vulva, pene, perineo, etc. Su diseño discreto es perfecto para llevarlo a todas partes y disfrutar el uso individual o en pareja. No es insertable en la vagina ni en el ano.",
          detalles:
            "Características\n\nMaterial: Plástico\nLargo total: 6 cm.\nBaterías: 3-LR44\nColores: VARIADOS, SE ENVÍAN SEGÚN DISPONIBILIDAD (no seleccionables)\n\nObservaciones\n\nBaterías incluidas\nA prueba de agua\nUna velocidad\nApto para Lubricantes base a agua o híbridos\n\nRecomendaciones\n\nRetirar las pilas cuando no esté en uso\nLavar con agua tibia y jabón neutro\nEvitar generar golpes o caídas",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 10,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1494,
              nombre: "",
              url: "https://sexshopmayorista.cl/10195-large_default/mini-bala.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 605,
          codigo: "855559003817",
          nombre: "Sachet Premium Silicone",
          descripcion:
            "Lubricante Premium Silicone está formulado a base de tres siliconas de grado médico de alta calidad. Brinda un deslizamiento como ningún otro lubricante, no es pegajoso y es de lenta absorción, su sedosidad y máxima resistencia lo convierten en el lubricante perfecto para practicar sexo bajo el agua y/o sexo anal ya que no requiere de aplicación constante.",
          detalles:
            "Características:\nContenido:6 ml\nFórmula siliconada\nCruelty Free\nLibre de Parabenos\nLibre de Gluten\nVegano\nLibre de Glicerina \n\n\nModo de uso:\nAplicar directamente en la zona a lubricar.\n\nIngredientes:\nDimethicone, Cyclopentasiloxane, Dimethiconol\n\nObservaciones:\nCompatible con preservativos de Látex, no se sugiere su uso con juguetería de silicona ya que por su alta pureza puede ser corrosivo a largo tiempo con los juguetes.",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1493,
              nombre: "",
              url: "https://sexshopmayorista.cl/15816-large_default/sachet-premium-silicone-.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 491,
          codigo: "855559006023",
          nombre: "Aceite Masaje Provocatife",
          descripcion:
            "Aceite de masaje con Infusión de aceite de Cáñamo y Feromonas para ambos sexos.",
          detalles:
            "El nuevo Aceite para masajes Provocatife está creado con los más finos ingredientes naturales los cuales hidratan y acondicionan la piel, ayudando así a mantenerla suave y brillante. Posee una sensación aterciopelada de forma que quien realiza el masaje como quien lo recibe, puedan disfrutar de la experiencia. Sus principales ingredientes son la infusión de aceite de Cáñamo y feromonas para ambos sexos.\n\nCARACTERÍSTICAS\nContenido 125ml\nOlor suave y placentero\nLibre de Glicerina - Parabeno - Gluten\nSólo uso externo\n\nINGREDIENTES\nAceite de Glicina de Soya, Aceite de Semilla de Carthamus tinctorius (Alazor), Aceite de Semilla de Cannabis Sativa (Marihuana), Aceite de Núcleo de Prunus Armeniaca (Damasco), Tocoferol Natural (Vitamina E), Ciclometicona, Extracto de Oleoresina de Hoja de Rosmarinus Officinalis (Romero), Extracto de Melaleuca Alternifolia (Árbol de té), Mezcla de Aceites esenciales, Fragancia y Feromonas.\n\nMODO DE USO\nAplicar en la piel la cantidad que requiera el sector a masajear. Agregar más si lo desea.",
          instagram: "",
          precio: 21990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1326,
              nombre: "",
              url: "https://i.ibb.co/4ZVY3CH/Pics-Art-08-14-01-19-36.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 18,
              nombre: "Feromonas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 503,
          codigo: "7798297919589",
          nombre: "Arnés Con Dildo Negro- Protesis Maciza Lisa",
          descripcion:
            "Arnés con prótesis maciza de color negro. Fabricados en Argentina, de correas ajustables y doble costura. Incluye consolador hecho de PVC, suave, flexible y libre de Ftalatos. El diseño del dildo posee un glande que proporciona una textura abultada para jugar con las sensaciones y luego se mantiene liso hasta su base. El arnés es fabricado en nylon, que permite el intercambio de consolador y sus correas ajustables van sobre las caderas y entre las piernas.",
          detalles:
            "Características\n\nConsolador macizo\nColor: negro\nMaterial Consolador: PVC\nArnés ajustable\nLargo Total: 15 cm\nDiámetro: 3.5 cm\nArnés: Cintura adaptable \n\nObservaciones\n\nArnés con consolador\nPrótesis maciza\n\nRecomendaciones\n\nMantener lejos del calor\nLavar antes y después de cada uso.\nLavar con agua y jabón neutro.\nApto para Lubricantes base a agua o híbrido.\nGuardar en un sitio limpio y seco, sin mezclar con otros juguetes.",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1340,
              nombre: "",
              url: "https://sexshopmayorista.cl/15162-large_default/arnes-con-dildo-negro-protesis-maciza-lisa.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 12,
              nombre: "Consoladores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 28,
              nombre: "Arnés",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 555,
          codigo: "814843010658",
          nombre: "Palmeta Corazón en Llamas",
          descripcion:
            "Palmeta para practicas de sumisión y azotes para principiantes.",
          detalles:
            "Para una bonita pero definitivamente sexy introducción a los azotes juguetones, el Spank Me Silly de Hustler es un producto elegante y extremadamente manejable, perfecto para las parejas aventureras que pueden ser nuevas en la escena de los azotes. Con una adorable forma de corazón rojo brillante, la paleta tiene un diseño plano y firme y un mango grande para un buen agarre, lo que hace que sea fácil y cómodo de usar.\n\nLa Spank Me Silly mide unos 25,4 cm de largo, con una anchura total de 15,2 cm.\n\nTraducción realizada con la versión gratuita del traductor www.DeepL.com/Translator",
          instagram: "",
          precio: 11990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1419,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2015/10/Spank-Me-Silly-F1.jpeg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 32,
              nombre: "BDSM",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 196,
          codigo: "777010043012",
          nombre: "Lubricante Hot (30ml)",
          descripcion:
            "Hot es un lubricante intimo intensificador del placer, formulado para otorgar lubricación extra durante tus  encuentros íntimos.\nHecho a base de agua y silicona  de lenta absorción, te permitirá sentir mayor lubricación durante más tiempo, además posee un riquísimo efecto de calor que  no te dejara indiferente .Hot  con su sensación de calor maximiza el placer generando un estimulo extra que sin duda tienes que probar.",
          detalles:
            "Ingredientes: Pure water, Silicone Oil, Glycerol, Hydroxyethyl cellulose, Carbomer resin, Others.\n\nContenido: 30 ml.\n\nModo de Uso:\nAplica la cantidad suficiente de lubricante HOT sobre la zona a lubricar, sopla suavemente y sentirás una agradable sensación de calor. Incoloro, sin olor, no mancha, no es graso.\n\nImportante: Los resultados dependerán del uso correcto del producto y del organismo de cada persona. Antes de usa un producto de cosmética erótica se recomienda revisar los componentes de este para evitar reacciones alérgicas. En caso de irritación suspender y consultar con su dermatólogo. Fabricado y envasado por laboratorio CQC. RPC. Distribuido por Importadora y Comercializadora Ruta 160 Ltda. Catedral N°1233, local 9, Santiago de Chile. Dispositivo médico según artículo 101 del código sanitario y reglamento DS825/98.",
          instagram: "",
          precio: 5990,
          descuento: 14.0,
          stock: 30,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 544,
              nombre: "",
              url: "https://i.ibb.co/BcLkZkT/Pics-Art-01-18-02-36-33.jpg",
              descripcion: "",
            },
            {
              id: 545,
              nombre: "",
              url: "https://i.ibb.co/2hwkHBW/Pics-Art-01-18-02-27-54.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 345,
          codigo: "7809604500502",
          nombre: "Preservativo Sensor Plus - Colored",
          descripcion:
            "Preservativos de látex transparente y lubricados de color para mayor diversión.",
          detalles:
            "Lubricados interior y exteriormente.\nPreservativo de color turquesa\n\nContenido: 3 unidades",
          instagram: "",
          precio: 1990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1034,
              nombre: "",
              url: "https://i.ibb.co/cYTGPR3/Pics-Art-07-07-02-27-36.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 557,
          codigo: "848518016782",
          nombre: "Arnés strapless punto G y estimulación de clítoris",
          descripcion:
            "Dildo strapless punto G, funciona como prótesis de arnés pero sin cuerdas ni calzón. Excelente para cambios de rol y juegos lésbicos. Importado desde USA",
          detalles:
            "Características:\n\nNo se requiere arnes.\nA prueba de Agua.\nDiseño ergonómico.\nPunta angulada para punto G.\nPenetración en forma de vulva para mejor agarre.\nAyuda a la ejercitación Kegel.\nTexturizado para estimulación clitorial.\nEspecificaciones:\n\nClasificación: Strapless.\nColor: Negro.\nLargo 1: 16 cm.\nDiámetro máx. 1: 3,5 cm\nLargo 2: 8 cm.\nDiámetro máx. 2: 3,5 cm.\nMaterialidad: TPR (elastómero termoplástico).\nPilas: No usa.\nPeso: 260 grs.\nPeso empaque: 398 grs.\nMarca: Strap U™\nFabricado en China.",
          instagram: "",
          precio: 44990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1422,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2016/05/Strap-U-ae169-Revolver-Strapless-Strap-On-G-Spot-Dildo-F2.jpg",
              descripcion: "",
            },
            {
              id: 1423,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2016/05/Strap-U-ae169-Revolver-Strapless-Strap-On-G-Spot-Dildo-F4.jpg",
              descripcion: "",
            },
            {
              id: 1424,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2016/05/Strap-U-ae169-Revolver-Strapless-Strap-On-G-Spot-Dildo-F5.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 28,
              nombre: "Arnés",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 611,
          codigo: "7765432100331",
          nombre: 'Spanish Fly, "La Mosca Española"',
          descripcion:
            'Spanish Fly, "La mosca española" es un estimulante natural que busca ayudar a las personas con un golpe anímico para sacarlas de su estado de cansancio y volverlas más dispuestas a intimar con su pareja. Se puede consumir media hora antes del acto en un poco de agua o bien consumir como tratamiento diario. Recomendado para deportistas. \n\nContraindicaciones: No la pueden consumir personas con problemas cardiacos, de presión alta, ni embarazadas ni mujeres que estén dando pecho.',
          detalles:
            "Características:\nContenido Neto: 30 ml.\nPorción: 30 gotas\nNumero de porciones: 17\nConsumo: 1 porción 3 veces al día\n\nObservaciones:\nAfrodisíaco para hombres y mujeres muy conocido en Europa y USA, con años de calidad garantizada. Sólo se debe agregar 30 gotas del afrodisíaco en agua o jugo (puede también agregar las gotas directamente bajo la lengua). Provocara una mejora en el estado anímico ayudando así a que las parejas puedan disfrutar mas seguido y de mejores encuentros sexuales.\n\nNO RECOMENDABLE PARA PERSONAS MENORES DE 15 AñOS, EN EMBARAZOS Y LACTANCIA, NI PERSONAS CON LA PRESIÓN ALTA",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1504,
              nombre: "",
              url: "https://sexshopmayorista.cl/4787-large_default/spanish-fly-la-mosca-espanola.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 607,
          codigo: "603912320589",
          nombre: "Dilatador Anal Fetish",
          descripcion:
            "Plug anal negro, perfecto para principiantes de punta cónica para facilitar su uso y de base ancha para una extracción segura.",
          detalles:
            "Largo total: 11.5 cm.\nLargo Insertable: 9.5 cm\nDiámetro: 2.5 cms aprox.\nColor: Negro\nMaterial: Jelly\n\nObservaciones:\nLibre de Phthalate",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1495,
              nombre: "",
              url: "https://sexshopmayorista.cl/6094-large_default/dilatador-anal-fetish.jpg",
              descripcion: "",
            },
            {
              id: 1496,
              nombre: "",
              url: "https://sexshopmayorista.cl/6095-large_default/dilatador-anal-fetish.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 198,
          codigo: "777010080024",
          nombre: "Lubricante Ice (Sachet 5ml)",
          descripcion:
            "Ice es un lubricante intimo con efecto frío e intensificador del placer, formulado para otorgar lubricación extra durante tus relaciones sexuales. Hecho a base de agua y silicona  de lenta absorción, te permitirá sentir mayor lubricación durante más tiempo. ICE complementa la lubricación natural del cuerpo y posee efecto frío que maximiza el placer.",
          detalles:
            "Ingredientes: Pure water, Silicone Oil, Glycerol, Hydroxyethyl cellulose, Menthol , Others.\n\nContenido: 5ml.\n\nModo de Uso:\nAplica la cantidad suficiente de lubricante ICE sobre la zona a lubricar, sopla suavemente y sentirás una agradable sensación de frescura. Incoloro, sin olor, no mancha, no es graso, de base acuosa, totalmente compatible con el uso de preservativos y juguetes eróticos.\n\nPrecauciones:\nConservar a temperatura ambiente en un lugar fresco, seco y protegido de la luz. No utilizar en caso de alergia a alguno de sus componentes. En caso de irritación o incomodidad, descontinúe su uso y consulte a su médico. Mantener fuera del alcance de los niños. Fabricado y envasado por laboratorio CQC. RPC. Dispositivo médico según artículo 101 del código sanitario y reglamento DS825/98.",
          instagram: "",
          precio: 1490,
          descuento: 14.0,
          stock: 82,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 411,
              nombre: "",
              url: "https://i.ibb.co/RB9zp8z/Pics-Art-01-18-02-35-03.jpg",
              descripcion: "",
            },
            {
              id: 412,
              nombre: "",
              url: "https://i.ibb.co/tP3wkMq/Pics-Art-01-18-02-34-17.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 213,
          codigo: "558001052004",
          nombre: "Funda Con Vibración Dragon",
          descripcion:
            "Un pene Texturizado,  más grueso con una erección más prolongada y que además vibra para estimular directamente el clítoris de la mujer. Esto, que parece el sueño de muchos hombres y mujeres ya no es una mera fantasía sino que una realidad. Hablamos de la funda y extensión peneana Gran Dragón con vibración. Se trata de una funda para el pene que además de otorgarle centímetros y grosor extra al miembro sexual masculino también cuenta con una función vibradora que genera una estimulación potente e intensa a la mujer durante la penetración. Está fabricado de silicona, considerado como uno de los mejores materiales para juguetes sexuales ya que es resistente, suave al tomar contacto con la piel íntima y fácil de limpiar. Además, no representa ningún riesgo para la salud de quien lo ocupa ni para la pareja.\nEsta novedosa y multifuncional funda cuenta también con un diseño lleno de relieves que también generan una estimulación extra a la pareja durante la penetración. Como todo juguete sexual, esta funda tiene sus cuidados. Su higiene es primordial, por lo que se recomienda lavarla antes de usarla y posterior a su utilización, ya que de este modo, se evitará cualquier riesgo de infección",
          detalles:
            "Características:\nFunda para pene\nFunda Extensora de pene\nEstimulador de clítoris\nFunda vibradora\nMaterial: Silicona\nColor: Transparente\nLargo total: 12cm\n\nObservaciones:\nSe ajusta al tamaño del pene\nBaterías incluidas\nBala vibradora desechable.",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1341,
              nombre: "",
              url: "https://sexshopmayorista.cl/10891-large_default/funda-y-extencion-peneana-gran-dragon-con-vibracion.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 614,
          codigo: "7770200020209",
          nombre: "Lubricante Femenino (60ml botella)",
          descripcion:
            "El Lubricante Vaginal Femenino Starsex intensifica tu placer sexual ya que humedece tu vagina dejándola lista para una noche placentera. Es a base de agua, no quema ni provoca irritación y su uso es compatible con preservativos y juguetes eróticos. Además posee un agradable y muy suave sabor y aroma a frutilla que brindará un rico y estimulante aroma mientras lo usas, ya sea con tu amante o en tu momento de intimidad. \n\n Formato: 60ml",
          detalles:
            "Características:\nDe base acuosa.\nEnvase con dispensador para una fácil aplicación.\nCompatible con preservativos y juguetes sexuales.\nSuave olor y sabor a frutilla.\nComestible.\nUso externo, interno y sexo oral.\n\nIngredientes: Agua, Metilparabeno, Propilparabeno, Glicerina, Carboximetilcelulosa, Glicolpropileno, Metilsalicilato, Saborizante Frutilla.\n\nModo de uso:\nAplicar una pequeña cantidad en la zona a penetrar, esparcir hasta que el lubricante cubra toda el área. Se puede usar externa e internamente, así como para el sexo oral. ",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 9,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1507,
              nombre: "",
              url: "https://sexshopmayorista.cl/15573-large_default/lubricante-femenino-60ml-pet.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 517,
          codigo: "6959532316339",
          nombre: "Estimulador Abner Bluetooth",
          descripcion:
            "El Estimulador Abner de Pretty Love está fabricado de silicona médica, un juguete erótico diseñado principalmente para la estimulación vaginal. Su forma ovalada de terminaciones redondeadas permite introducirlo de forma segura en la vagina.\n\nAdemás, Abner presenta una aplicación para celular la cual funciona como control remoto del estimulado, logrando acceder al portal del orgasmo. Con ella podrás controlar las infinitas opciones de vibración que ofrece, solo debes descargar la aplicación en tu celular y obtendrás un vibrador inalámbrico con el alcance que el bluetooth te permita. Además podrás obtener noventa minutos continuos de estimulación ya que Abner es recargable mediante un cable con puerto USB.",
          detalles:
            "Con el Estimulador vaginal con aplicación de celular Abner de Pretty Love, tendrás el placer que nunca antes habías experimentado, es por esto que te recomendamos probar nuevas sensaciones, descubrir tu cuerpo y tus puntos sensibles para que vivas tu sexualidad plenamente.\nCaracterísticas\nMaterial Silicona ABS\nColor Fucsia\n3 intensidades de vibración\n9 tipos de pulsaciones\nRecargable (vía cable USB)\n\nMedidas\nLargo Total: 18.5cm\nLargo Insertable: 7,5cm\nDiámetro: 3,3cm\n\nObservaciones\nDiseño ergonómico y curvo.\nEstimulador de zonas Erógenas.\nCarga de 60 min./ duración 90 min.\nResistente al agua / No sumergible.\nApto para Lubricantes base a agua o híbridos\nEncendido y apagado de 2 segundos.\nProducto no debe ser utilizado mientras está siendo cargado.\n\nRecomendaciones:\nLavar antes y después de usar\nLavar con agua tibia y jabón neutro\nNo secar con paños para evitar residuos\nUsar con preservativo o\nUsar con lubricante a base de agua",
          instagram: "",
          precio: 69990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1371,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/pretty-love-juguetes-estimulador-femenino-abner-29868404310205_2000x.jpg?v=1628353993",
              descripcion: "",
            },
            {
              id: 1372,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/pretty-love-juguetes-estimulador-femenino-abner-29868403130557_2000x.jpg?v=1628353993",
              descripcion: "",
            },
            {
              id: 1373,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/pretty-love-juguetes-estimulador-femenino-abner-23263759696061_2000x.jpg?v=1628353993",
              descripcion: "",
            },
            {
              id: 1374,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/pretty-love-juguetes-estimulador-femenino-abner-18635712987286_2000x.jpg?v=1628353993",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 163,
          codigo: "7798297917288",
          nombre: "Consolador Caiman Cyber Skin N°2",
          descripcion:
            "Amarás este Dildo Realístico  super suave y flexible , hipoalergénico con ventosa super adherente ideal para superficies lisas, Además posee una agradable aroma Dulce para incentivar el instinto  olfativo del Deseo, Totalmente flexible y manejable para dar rienda suelta a tu placer. Su diseño protuberante en la punta es ideal para estimular toda tu zona G y Zona P en caso de personas con Pene \n\nNo te pierdas de las mejores experiencias de este Dildo realístico diseñado para entregar el máximo placer ! disfruta tanto en pareja como en solitario.",
          detalles:
            "Características:\nVentosa ultra adherente\nCyber super suave y flexible\nHipoalergénico \nLibre de ftalatos\nMedidas : 17 cm \nDiámetro de circunferencia : 12 cm de contorno en su área mas gruesa \n \nRecomendaciones :\nLimpiar con agua tibia y jabón neutro, antes y después de cada uso\nGuardar en un lugar seco , no expuesto al sol  y alejado de otros juguetes",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1079,
              nombre: "",
              url: "https://i.ibb.co/jHXD6ck/Pics-Art-07-07-02-13-30.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 12,
              nombre: "Consoladores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 230,
          codigo: "6959532320572",
          nombre: "Anillo Vibrador Extra Largo Osborn",
          descripcion:
            "Anillo Vibrador Extra Largo Osborn para erecciones mas firmes y relaciones mas duraderas todo acompañado de una exquisita vibración.\nOsborn es uno de nuestros juguetes más poderosos, su potente vibración hará que el disfrute llegue a otro nivel. Sus materiales de alta calidad hacen que el producto sea suave y cómodo para su uso. Este increíble anillo ralentiza el flujo sanguíneo, el músculo esponjoso se alarga y congestiona por completo, haciendo que la erección dure mas tiempo y retrasando la eyaculación para una relación sexual mas duradera, además gracias a la bala vibradora que posee, estarás a la vez estimulando el clítoris de tu pareja y será inolvidable.\n\nUna característica no menos importante es que es resistente al agua, lo que hará que puedas disfrutarlo donde tu quieras.",
          detalles:
            "Características\nMaterial Silicona\nColor Negro\nBala Vibradora integrada (3 batería LR41)\nResistente al agua\n\nMedidas\nLargo bala: 5.6 cm\nLargo total: 3.1 cm\nAncho: 4.6 cm\n\nObservaciones \nUsar con lubricante a base de agua\nResistente al agua\nNo sumergible\nReutilizable\n\nRecomendaciones\nLavar con agua tibia y jabón neutro antes y después de cada uso, dejar secar y guardar en un lugar fresco y seco.",
          instagram: "",
          precio: 26990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 590,
              nombre: "",
              url: "https://i.ibb.co/X3NGy8k/Pics-Art-02-09-02-35-52.jpg",
              descripcion: "",
            },
            {
              id: 591,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14841-large_default/anillo-vibrador-extra-largo-osborn.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 3,
          codigo: "7765430100500",
          nombre: "Dados Love Dice",
          descripcion:
            "Dados eróticos que brillan en la oscuridad. Los dados contienen palabras que señalan la acción a realizar y el lugar del cuerpo. ",
          detalles: "Contenido: 2 Dados\nPruebas Suaves y no invasivas.",
          instagram: "https://www.instagram.com/sexduceme.cl",
          precio: 3490,
          descuento: 14.0,
          stock: 8,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 9,
              nombre: "Dados Love Dice",
              url: "https://i.ibb.co/9T6NY7B/IMG-20201012-192515.jpg",
              descripcion: null,
            },
          ],
          categorias: [
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 608,
          codigo: "334000027119",
          nombre: " Plug Anal Diamante ",
          descripcion:
            "El nano plug es una muy buena opción para iniciarse en la práctica del sexo anal ya que, es un juguete pequeño que ayuda a dilatar la zona anal, preparándola para la penetración o bien para utilizar otro juguete. Recomendados para quienes busquen experimentar las sensaciones y el placer que brinda el sexo anal.",
          detalles:
            "Características:\nMaterial: PVC\nLargo total: 7 cm.\nDiámetro mayor: 2.5 cm.\nColor: Celeste\nUnidades: 1\n\nObservaciones:\nLibre de Ftalato y látex.",
          instagram: "",
          precio: 5990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1497,
              nombre: "",
              url: "https://sexshopmayorista.cl/11857-large_default/nano-plug-anal.jpg",
              descripcion: "",
            },
            {
              id: 1498,
              nombre: "",
              url: "https://sexshopmayorista.cl/6398-large_default/nano-plug-anal.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 558,
          codigo: "814843011051",
          nombre: "Cachos de diablita vibradores",
          descripcion:
            "Cintillo con cachos de diablo, que se pueden extraer y utilizar como vibrador externo.",
          detalles: "",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1425,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2015/09/Horny-Devil-Vibrating-Headband-F1-150x150.jpeg",
              descripcion: "",
            },
            {
              id: 1426,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2015/09/Horny-Devil-Vibrating-Headband-F2.jpeg",
              descripcion: "",
            },
            {
              id: 1427,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2015/09/Horny-Devil-Vibrating-Headband-F3.jpeg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 460,
          codigo: "605632291156",
          nombre: "Anillo Peneano Y Dedo Estimulador STUDDED TOUCH",
          descripcion:
            "Anillo vibrador, retarda la eyaculación, potencia erección y estimula. Dedo diseñado especialmente para mejor la experiencia de masturbación",
          detalles:
            "Anillo vibrador, retarda la eyaculación, potencia erección y estimula. Dedo diseñado especialmente para mejor la experiencia de masturbación\nMaterial: Jelly\nBaterias: 3-L1154, incluidas\nColor: Beige \n\nObservación:\nBala vibradora con anillo y gusano.",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1320,
              nombre: "",
              url: "https://i.ibb.co/8651kpR/Pics-Art-08-14-01-17-40.jpg",
              descripcion: "",
            },
            {
              id: 1321,
              nombre: "",
              url: "https://sexshopmayorista.cl/15340-large_default/anillo-peneano-y-dedo-estimulador-studded-touch.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 529,
          codigo: "7898626041889",
          nombre: "Aceite para Masajes Fruit Sexy Sabor Manzana Confitada",
          descripcion:
            "Gel comestible de sabor Manzana Confitada, ideal para masajear zonas erógenas externas. Es un producto comestible lo que permite practicar sexo oral, genera un leve efecto calor en la zona de aplicación",
          detalles:
            "Ingredientes: Glicerina, sorbitol, sorbato de potasio, TBHQ, BHA, saborizante y aromatizante.\n\nCaracterísticas:\nAceite corporal\nContenido: 40ml\nSabor: Manzana Confitada\nEfecto: Leve efecto calor\n\nObservaciones:\nAplicar sobre las zonas erógenas y masajear con las manos o lamiendo las partes deseadas.\nSi desea intensificar el efecto calor se recomienda soplar sobre la zona.\nEnjuagar con abundante Agua.\nNo contiene Gluten.\nElaborado con Aspartamo, apto para diabéticos con uso moderado.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1384,
              nombre: "",
              url: "https://sexshopmayorista.cl/15681-large_default/aceite-corporal-oral-sex-manzana-confitada.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 47,
          codigo: "7898943080363",
          nombre: "Aceite para Masajes Fruit Sexy Sabor Frambuesa",
          descripcion:
            "Ten una experiencia de masaje única con el aceite corporal Oral Sex de Intt. Disfruta de exquisitos y eróticos masajes en las zonas más erógenas de tu cuerpo acompañado de placenteras sensaciones de leve efecto calor.\nEl aceite corporal es comestible perfecto para practicar un rico sexo oral. Este aceite te permite disfrutar de los masajes en pareja y ayuda a que terminen en un placentero juego sexual con un delicioso sabor.\nSiente el tacto, calor y erotismo en tu piel",
          detalles:
            "Aceite de masajes sabor Frambuesa\nContenido: 40 ml.\nEfecto: Leve efecto calor. \n\nObservaciones: Aplicar sobre las zonas erógenas y masajear con las manos o lamiendo las partes deseadas.\nSi desea intensificar el efecto se recomienda soplar sobre la zona.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 779,
              nombre: "",
              url: "https://i.ibb.co/fHPVxfK/Pics-Art-03-21-12-28-34.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 395,
          codigo: "7765432910121",
          nombre: "Vibrador Cliterific Morado",
          descripcion:
            "El vibrador Cliterific Morado fue fabricado de silicona y diseñado con seductoras texturas que acariciarán suave y placenteramente tanto punto G, como entrada y canal vaginal debido a su fascinante grosor.",
          detalles:
            "Disfruta solx o acompañadx de sus 17 centímetros insertables y sus 3.5 centímetros de diámetro con su siempre bien oportuna vibración regulable en intensidad. Haz de este juguete tu aliado perfecto a la hora de experimentar nuevas sensaciones, puedes estimular de manera externa cualquier parte de tu cuerpo.\nCaracterísticas\nMaterial: Silicona\nColor: Morado\nPeso: 159grs \nLargo Total: 20cm\nLargo Insertable: 17cm\nDiámetro: 3.5cm\nBaterías: 2-AA \n\nObservaciones:\nBaterías no incluidas\nVelocidad regulable\n\nRecomentaciones de uso:\nLimpiar con agua y jabón neutro antes y después de usar.\nUtilizar con lubricante en base a agua.\nQuitar las baterías después de cada uso.",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1114,
              nombre: "",
              url: "https://i.ibb.co/7gLNDCb/Pics-Art-07-09-01-11-05.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 616,
          codigo: "827160113148",
          nombre: "Lubricante vegano 30ml Woman Vegan Pjur",
          descripcion:
            "El placer natural ha llegado. Pjur, la prestigiosa marca de lubricantes con más de 20 años de experiencia a sus espaldas, ha creado un lubricante a base de agua, específicamente diseñado para la piel de las mujeres y hecho exclusivamente con ingredientes veganos. ¿No te lo crees? Pues es cierto, este producto 100 por cien vegano y sin experimentación con animales ha obtenido el sello de calidad de la Vegan Society.El Pjur Woman Vegan está libre de conservantes, parabenos, parafinas, gluten, glicerina, perfumes sintéticos y aditivos de color. Adaptado al pH natural y con una alta tolerancia cutánea probada dermatológicamente, este lubricante deja la piel hidratada y sedosa, lista para lo que se presente. Apto para usar con condones de látex y con juguetes eróticos, el lubricante vegano de Pjur es completamente idóneo para usar a diario sin ningún tipo de inconveniente.",
          detalles:
            "Lubricante de alta gama, de base acuosa y con ingredientes 100 por cien veganos.\nNo ha sido testado en animales.\nLibre de conservantes, parabenos, parafinas, gluten, glicerina, perfumes sintéticos y aditivos de color.\nCombate la sequedad vaginal.\nSu compatibilidad cutánea y de las mucosas ha sido comprobada dermatológicamente.\nCompletamente adecuado para usar con condones de látex, bolas chinas y juguetes eróticos.\nNo altera el pH natural.\nHidrata y protege la piel dejándola suave y sedosa.\nNo mancha.\nPenetra en la piel.",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1509,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2021/09/PJ93-3.png",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 31,
          codigo: "77700042000",
          nombre: "Lubricante Weed (Sachet 8ml)",
          descripcion:
            "Este lubricante orgánico está hecho en base a aceite de cáñamo y L-Arginina, ambos compuestos que aportan un sinfín de beneficios. El aceite de cáñamo ayuda a la regeneración de la piel, gracias a su alto contenido de Omega 3 y 6, haciendo que quede mucho más suave, convirtiéndolo en un producto ideal para el sexo anal o vaginal. Por su parte, el L - Arginina previene la disfunción eréctil, favoreciendo la erección natural en el hombre. El proceso de extracción del aceite de la semilla de marihuana, se realiza en frío, asegurando que sus propiedades medicinales y nutritivas se mantengan intactas y al mismo tiempo su fórmula sea libre de THC, compuesto psicoactivo de la marihuana, procurando así la salud de quienes lo utilizan. Además, por ser orgánico, no contiene persevantes. La absorción del lubricante es inmediata, y deja un aroma muy rico en todo momento, algo que hará que se abran todos los sentidos con el contacto del lubricante con el resto del cuerpo. Otra de sus funciones es que ayuda a la movilidad y producción propia de espermas con lo cual, el contacto con este tipo de lubricantes no sólo beneficia al acto sexual sino que cooperar en la salud de quien lo utilice.",
          detalles:
            "El lubricante íntimo Weed es compatible con cualquier juguete erótico y al venir en un sachet de 8 ml es completamente transportable. El lubricante Weed en base a aceite de cáñamo permite que por su textura se pueda jugar mucho más a dar masajes y otras sensaciones que un lubricante de silicona que tiene una textura mucho más espesa.\n\nCaracterísticas:\nLubricante intimo sin preservantes en sachet\nCon aceite de semilla de cáñamo orgánico 100% puro\nLibre de THC con L-Arginina\nContenido: 8 ml\nIngredientes: Agua purificada, Glicerina, Polygel Ca, Aceite de cáñamo de primera extracción en frío, L-Arginina, Aceite de lemon Verbena, T.E.A, Geogard Ultra.",
          instagram: "",
          precio: 1990,
          descuento: 14.0,
          stock: 25,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 36,
              nombre: "",
              url: "https://i.ibb.co/hX7rr5p/IMG-20201031-155149.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 612,
          codigo: "7770200080203",
          nombre: "Lubricante Anal Starsex (60ml botella) ",
          descripcion:
            "El Lubricante Anal Dilatador es de base acuosa, lo que permite que se pueda utilizar con todo tipo de juguetes y es compatible con preservativos también. Es libre de aroma y sabor y su envase es muy cómodo y fácil de utilizar.\n\nEl Lubricante Anal Dilatador puede ser usado tanto interna como externamente ya que es muy seguro, además gracias a su componente llamado Simeticona, ayuda a relajar la zona anal y prepararla mejor para la penetración o para el uso de juguetes.",
          detalles:
            "CARACTERÍSTICAS\nLubricante anal a base de agua\nContenido: 60 ml\nUso: Unisex\n\nINGREDIENTES\nAgua, Metilparabeno, Propilparabeno, Glicerina, Carboximetilcelulosa, Simeticona, Glicol Propileno.",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 12,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1505,
              nombre: "",
              url: "https://sexshopmayorista.cl/15571-large_default/lubricante-anal-dilatador-60ml-pet.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 618,
          codigo: "827160110192",
          nombre: "Lubricante para pieles sensibles Nude 30ml pjur",
          descripcion:
            "Nude: Un lubricante para pieles sensibles\nLas mujeres en particular están constantemente en busca de un modo de vida más consciente, y están dispuestos a centrarse en lo que es realmente esencial. Y este estilo de vida puede tener repercusiones positivas en su vida amorosa también.\n\nEs por eso que hemos desarrollado pjur® WOMAN Nude – un lubricante para pieles sensibles a base de agua sin ningún tipo de aditivos. En el mundo de la moda y de la cosmética, “nude” rima con “natural” y “puro”. ¡Naturalmente, entonces, pjur WOMAN Nude no contiene glicerina, parabenos, aceite, grasa, perfume o conservantes!\n\nSe desarrolló especialmente para la piel más sensible de muchas mujeres y por lo tanto es completamente neutro en sabor y olor. pjur WOMAN Nude es un lubricante para pieles sensibles de alta calidad: para la sexualidad con mayor conciencia y sin aditivos – porque a veces, menos es más.",
          detalles:
            "Sin parabenos y sin glicerina\nDermatológicamente testeado\nIngredientes: Agua (Agua), Propilenglicol, Ethoxydiglycol, Hidroxipropil Guar, Cloruro de Hidroxipropiltrionio, Hidroxietilcelulosa, Sacarina Sódica, Ácido Cítrico.",
          instagram: "",
          precio: 11990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1511,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2021/06/PJ86-2.png",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 91,
          codigo: "6959532316742",
          nombre: "Bala Vibradora Inalambrica Dawn",
          descripcion:
            "Bala Vibradora Inalámbrica con control remoto, que tiene hasta 3 Intensidades de Vibración y 9 Tipos de Pulsaciones\nLa Bala Vibradora Inalámbrica Dawn presenta un diseño redondeado pensado para ser gentil con la piel al momento de estimular el clítoris la cual se maneja a través de su control remoto inalámbrico, operando así sus 3 intensidades de Vibración y 9 Tipos de Pulsaciones el cual tiene Radio de alcance de hasta 10 mts. La Bala Vibradora Inalámbrica Dawn es de Silicona ABS, lo que la hace fácil de limpiar al ser Resistente al agua, (no sumergible). Apta para lubricantes en base a agua o híbridos. Usa 2 Baterías AAA.\n",
          detalles:
            "Características\nMaterial Silicona ABS\nColor Fucsia\n3 intensidades de vibración\n9 tipos de pulsaciones\nUsa 2 Baterías AAA\nMedidas\nLargo Total Bala: 8,7cm\nDiámetro Bala: 2,8cm\nObservaciones\nDiseño ergonómico\nEstimulador de Clítoris y punto G\nCarga de 60 min./ duración 90 min\nResistente al agua / No sumergible\nApto para Lubricantes base a agua o híbridos\nEncendido y apagado de 2 segundos.\nControl con hasta 10 metros de radio de alcance\n",
          instagram: "",
          precio: 24990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 156,
              nombre: "",
              url: "https://i.ibb.co/PjK084N/IMG-20200919-193400.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 317,
          codigo: "6914421000034",
          nombre: "Plug Anal - Colitas de Gatito con Orejitas Rosado",
          descripcion:
            "Plug Anal con cola de gatito, en tono blanco con rosado, es perfecto para quienes se atreven a probar nuevas experiencias y sensaciones, ya que con su plug anal metalizado en forma cónica, permite dilatar de mejor forma el ano. ",
          detalles:
            "Plug Anal Metalizado\nColor: Rosado con Blanco\nIncluye diadema con Orejitas y cinta de razo.\n\nSe recomienda el uso con lubricante Híbrido o de Silicona.",
          instagram: "",
          precio: 29990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 848,
              nombre: "",
              url: "https://i.ibb.co/NNVvT53/Pics-Art-04-26-12-24-39.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 177,
          codigo: "7898626040264",
          nombre: "Gel Estimulante Vibration Sabor Chocolate",
          descripcion:
            "La cosmética erótica de Intt nos continúa sorprendiendo con sus novedosos e innovadores geles de uso íntimo, con efectos excitantes y sabores exquisitos.\n\nEl Gel Estimulante Vibration Chocolate permite la aplicación en las zonas erógenas, proporcionando un efecto electrizante o de vibración liquida, la cual provoca una rápida excitación. Además es comestible lo que permite implementarlo para el sexo oral con un delicioso sabor a chocolate.",
          detalles:
            "Contenido: 17ml\nEfecto: Vibratorio\nSabor: Chocolate\n\nObservaciones:\nAplicar en las partes deseadas esparcir dando masajes suaves y comenzará a sentir los efectos vibratorios.\nMantener en lugar fresco y seco.\n\nImportante\nLos resultados dependerán del uso correcto del producto y del organismo de cada persona.\nAntes de usa un producto de cosmética erótica se recomienda revisar los componentes de este para evitar reacciones alérgicas.\nEn caso de irritación suspender y consultar con su dermatólogo.",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 321,
              nombre: "",
              url: "https://i.ibb.co/dm94Hpy/1609808562915.jpg",
              descripcion: "",
            },
            {
              id: 322,
              nombre: "",
              url: "https://i.ibb.co/CQd9tQX/1609808562667.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 182,
          codigo: "777000420014",
          nombre: "Lubricante Weed (50ml).",
          descripcion:
            "Weed ha sido creado para el acondicionamiento y la lubricación del área vaginal y anal. La L-Arginina potencia la circulación sanguínea mejorando la sensación de placer en la mujer y potenciando la erección natural en el hombre, ayudando a optimizar el acto sexual. Su componente aceite de cáñamo, es rico en ácidos grasos esenciales Omega-3 y Omega-6 aportando nutrientes a la piel. Tiene la propiedad de relajar la zona de aplicación ayudando a la eyaculación precoz y problemas de dilatación.",
          detalles:
            "Ingredientes: Agua purificada, Glicerina, Polygel Ca, Aceite de cáñamo de primera extracción en frio, L-Arginina, Aceite de lemon Verbena, T.E.A, Geogard Ultra.\n\nModo de uso: Aplicar una pequeña cantidad de lubricante sobre la zona externa de la vagina y esparcir cuidadosamente previo a la masturbación y/o la penetración para una mayor sensación. Aplicar las veces que sea necesario.\n\nCaracterísticas:\nLubricante intimo sin preservantes.\nCon aceite de semilla de cáñamo orgánico 100% puro\nLibre de THC con L-Arginina\nContenido: 50 ml",
          instagram: "",
          precio: 7990,
          descuento: 14.0,
          stock: 10,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 313,
              nombre: "",
              url: "https://i.ibb.co/xzTHLLm/1609808561987.jpg",
              descripcion: "",
            },
            {
              id: 314,
              nombre: "",
              url: "https://i.ibb.co/k8WnHZd/1609808561923.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 545,
          codigo: "7898495414234",
          nombre: "Lubricante Vibro Hot",
          descripcion:
            "Vibro hot es un lubricante con efecto hormigueo y calor, que en conjunto producen una excelente estimulación al momento de la penetración. Altamente recomendable si se buscan sensaciones distintas y estimulantes ya que ayuda con la excitación y con la sensación de placer. Producto Unisex.",
          detalles:
            "Ingredientes: agua, Glicerina, aguamarina, extracto de Acmella oleacera, fenoxietanol \n\nContenido: 15g\n\nEfecto: Hormigueo y calor\n\nObservaciones:\nComestible\nMantener en lugar fresco\nEvitar contacto con los ojos\nUnisex\n\nModo de aplicación:\nColocar una cantidad a gusto en el lugar deseado y masajear suavemente",
          instagram: "",
          precio: 17990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1398,
              nombre: "",
              url: "https://sexshopmayorista.cl/11673-large_default/lubricante-vibro-hot.jpg",
              descripcion: "",
            },
            {
              id: 1399,
              nombre: "",
              url: "https://sexshopmayorista.cl/11674-large_default/lubricante-vibro-hot.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 231,
          codigo: "6959532325409",
          nombre: "Dedo Vibrador Pegasus",
          descripcion:
            "Sofisticado y elegante dedo vibrador con baño de oro en sus detalles, el truco de este producto es su electroestimulación.\nCon este producto podrás incursionar en el mundo de la electroestimulación gracias a sus 7 patrones de electroestimulación los cuales se controlan presionando el botón del borde, este posee dos circuitos eléctricos dentro del juguete, ocasionando que la estimulación electrónica funcione en el dedo insertado y también en la parte enchapada en oro. Fabricado con silicona suave aterciopelada, libre de ftalatos.",
          detalles:
            "Características:\nMaterial Silicona ABS     \n7 patrones de vibración y 1 patrón de descarga\nPoder: carga usb\nColor: negro\nDetalles chapados en oro 24 k\nProveedor: Pretty Love\n\nMedidas\nLargo: 10.5 cm\nLargo insertable: 8 cm\nDiámetro: 3.1 cm\n\nObservaciones\nDedo Vibrador\nApto para lubricantes a base de agua\n\nModo de uso\nDedo Vibrador Pegasus posee a un costado un botón para encenderlo, apagarlo y cambiar intensidades de vibración.",
          instagram: "",
          precio: 69990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 565,
              nombre: "",
              url: "https://i.ibb.co/Br8kMwL/Pics-Art-02-09-02-36-17.jpg",
              descripcion: "",
            },
            {
              id: 566,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14742-large_default/dedo-vibrador-pegasus.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 609,
          codigo: "222000053101",
          nombre: "Consolador Realístico Doble Penetración Nº15",
          descripcion:
            "Consolador Realístico Doble Penetración de Silicona, con este juguete se puede disfrutar de la estimulación vaginal y anal al mismo tiempo. Lo puede usar una persona para estimularse doblemente, o se pueden estimular dos personas a la vez.\n\nEl consolador realístico doble es un juguete muy buscado entre las amantes con relaciones lésbicas, para entregarse doble placer. ",
          detalles:
            "Características:\nMaterial: Silicona\nLargo Total: 38 cm.\nDiámetro: 4.5 cm\nColor: Piel\n\nObservaciones:\nlibre de Látex",
          instagram: "",
          precio: 29990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1499,
              nombre: "",
              url: "https://sexshopmayorista.cl/11922-large_default/consolador-realistico-doble-penetracion-na15.jpg",
              descripcion: "",
            },
            {
              id: 1500,
              nombre: "",
              url: "https://sexshopmayorista.cl/7108-large_default/consolador-realistico-doble-penetracion-na15.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 12,
              nombre: "Consoladores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 496,
          codigo: "782421175504",
          nombre: 'Estimulante afrodisiaco "La Mosca Española" Frutilla',
          descripcion:
            "Spanish Fly es una pócima compuesta por ingredientes seleccionados especialmente para aumentar la energía en ti, potenciar tus relaciones sexuales y obtener una mejor respuesta a los estímulos externos. Para esto solo debes aplicar 20 gotitas en un vaso con agua o jugo (no se recomienda alcohol) y luego beberlo con total tranquilidad.",
          detalles:
            "Posee un exquisito sabor a frutilla, para que se convierta en tu refresco favorito. Verás cómo tu cuerpo comienza a tener mejor respuesta a los estímulos gracias este brebaje secreto.\n\nCaracterísticas Estimulante:\n\nProducto hecho con diversas hierbas\nAumenta la energía en ti\nMejora la respuesta a estímulos\nExperiencias sexuales más intensas\nSin parabenos ni azúcar\nSabor frutilla salvajes\n\nModo de uso: Tomar 20 gotitas con agua o algún refresco. El efecto puede tardar de 15-30min dependiendo del organismo de cada persona.\n\nIngredientes:\n\nAgua (Aqua), Glicerina, Sabor (Aroma), Extracto de semilla de Paullinia Cupana (Guaraná), Extracto de raíz Polygonum Multiflorum (Fo-Ti), Extracto de hoja de Turnera Diffusa (Damiana), Corynanthe Yohimbe Bark Extracto, extracto de hoja de ginkgo biloba, extracto de epimedium grandiflorum (hierba de cabra en celo), extracto de raíz de panax ginseng, hidroxietilcelulosa, taurina, nitrato de tiamina, ácido pantoténico, niacinamida, piridoxina HCl, riboflavina, isomalta, cianocobalamina, acida, cecidina, acida, citrato de cocaína, acida, citrato de cocaína , Sucralosa, benzoato de sodio, sorbato de potasio, rojo 40 (CI 16035)",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1327,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/holiday-cosmetica-spanish-fly-frutilla-salvaje-28680856830141_2000x.jpg?v=1628377213",
              descripcion: "",
            },
            {
              id: 1328,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/holiday-cosmetica-spanish-fly-frutilla-salvaje-28680856862909_2000x.jpg?v=1628377213",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 109,
          codigo: "7898943080592",
          nombre: "Estimulante Anal Anaís",
          descripcion:
            "Si quieres aumentar el placer anal el Gel Estimulador Anal Anais de la reconocida marca brasileña Intt es lo que tienes que probar. Este gel provocará un efecto excitante sobre la zona anal por su principal característica que es la sensación de pulsación. Te entrega una experiencia totalmente única. No te quedes sin probarlo.",
          detalles:
            "Contenido: 15ml\n\nCaracterísticas\n\nGel Estimulador Anal\n\nEfecto pulsante\n\nDosificador tipo aerosol\n\nModo de aplicación\n\nAplicar la cantidad deseada y masajear suavemente.\n\nSiempre complementar con lubricante.\n\nObservaciones\n\nNo es lubricante\n\nMantener en lugar fresco\n\nEvitar contacto con los ojos\n\nApto para ingerir\n\nIngredientes: Glicerina, hialuronato de sodio, parafina líquida, Eugenia caryophyllus aceite de la flor, propilparabeno, metilparabeno, CI 19140, CI 15625.",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 329,
              nombre: "",
              url: "https://i.ibb.co/yhDxFD7/1609808562544.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 408,
          codigo: "6980231119624",
          nombre: "Nipple Bálsamo para Pezones",
          descripcion:
            'Bálsamo Comestible para Pezones\nEste bálsamo cremoso y fresco hará "sonreir" a tus pezones y les dará un atractivo sabor.\n\nPuede usarse en los labios de la boca también pero no es recomendable\nen otras zonas íntimas pues puede resultar demasiado potente su efecto.',
          detalles:
            "Para esos momentos de placer y pasión, siempre necesitarás originalidad o esa creatividad que hará únicos esos momentos carnales con tu pareja o amante, incluso al darte placer a ti mismo, y que mejor que elevar esas sensaciones llevando el juego a otro nivel con orgasmos inimaginables. Explora tu cuerpo y ese universo de nuevas sensaciones que se abren ante ti.\n\nSabores Disponibles:\n- Melón\n- Frutilla \n- Frambuesa\n\nContenido : 3 grs.",
          instagram: "",
          precio: 5490,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1299,
              nombre: "",
              url: "https://i.ibb.co/mGnQwnZ/Pics-Art-08-14-01-14-38.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 314,
          codigo: "6959532313468",
          nombre: "Estimulador Caesar",
          descripcion:
            "Estimulador perfecto para novatos, con 10 tipos de pulsaciones y estimulador de clítoris.",
          detalles:
            "El Estimulador Caesar es ideal para iniciarse, con su diseño pequeño y su silicona suave la cual es gentil con la piel y es apta para lubricantes en base a agua o híbridos para una mejor experiencia al momento de la penetración , eso en conjunto con su diseño, permite estimular el clítoris con sus orejas de conejo. Tiene 10 tipos de pulsación. Uso una batería AAA.\n\nCaracterísticas\nMaterial: Silicona\nColor: Fucsia\n10 tipos de pulsaciones\nUso una batería AAA\nMarca: Pretty Love\n\nMedidas\nLargo total: 11,5cm\nLargo insertable: 6,5cm\nDiámetro: 2,5cm\n\nObservaciones\nDiseño ergonómico y dual\nEstimulador de Clítoris\nResistente al agua / No sumergible\nApto para Lubricantes base a agua o híbridos",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 878,
              nombre: "",
              url: "https://i.ibb.co/gV6k5vq/Pics-Art-05-08-12-00-30.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 219,
          codigo: "442002071112",
          nombre: "Rotador Delfin Aqua Lover",
          descripcion:
            "Sumérgete en el más profundo e intenso placer con el Rotador Delfín Aqua Lover resistente al agua. Su estructura móvil giratoria, en dirección a ambos sentidos, proporciona una mayor sensación de penetración. Sus perlas están diseñadas para masajear y estimular las paredes vaginales, permitiéndote a su vez, estimular tu punto G, por la curvatura que tiene en la parte superior.\nAdicionalmente, está acompañado de un pequeño estimulador de clítoris con 12 vibraciones y pulsaciones diferentes totalmente controlables, que te llevarán a graduar y prolongar tus orgasmos.",
          detalles:
            "Características:\nLargo total: 21,5 cm.\nLargo penetrable: 10,5 cm.\nDiámetro: 3 cm.\nCircunferencia: 10,5 cm.\nMaterial: Jelly\nPeso: 180 grs.\nBaterías: 3 pilas AAA (No incluidas).\nSku: 44200207111\n\nEstimulador de clítoris Delfín:\nEstimulador delfín con 12 pulsaciones y vibraciones distintas.\nLargo total: 5,5 cm.\n\nRecomendaciones:\nLimpiar antes y después de utilizar, con agua tibia y jabón neutro. Guardar en un sitio limpio y seco, sin mezclar con otros juguetes. Usar con lubricante a base de agua y con preservativo.",
          instagram: "",
          precio: 32990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 518,
              nombre: "",
              url: "https://i.ibb.co/sKXQHVv/Pics-Art-02-08-03-51-25.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 15,
              nombre: "Rotadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 471,
          codigo: "818631021512",
          nombre: "Gel Femenino Oralicious - Frutilla",
          descripcion: "Gel estimulador para sexo oral sabor frutilla.",
          detalles:
            "l nuevo Oralicious Strawberry Swirl es un gel estimulante para sexo oral con sensación única que aporta placer y que contiene un delicioso sabor a frutilla.\n\nIngredientes: Water, Mineral Oil, Isopropyl Palmitate, Glycerin, Sesamun Indicum, Seed Oil, Cetearyl Alcohol, Flavor, Cetearyl Glucoside, Caprylic / Capric Triglyceride, Helianthus Annus Oil, Glyceryl Stearate, Butyrospermum parkii Extract, PEG – 100 Stearate, Sodium Saccharin, Sodium Benzoate, Potassium Sorbate, Titanium Dioxide,Tetrasodium EDTA, Menthol, Xanthan Gum,Propylparaben, Methylparaben, Cittric Acid, Blue 1, Red 40, Red 33, Yellow 5.\n\nContenido: 56g\nSabor: Frutilla\n\nCaracterísticas\nEstimulante femenino\nGel para sexo oral\nFormula soluble al agua\nNo pegajoso\nHidratante",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1313,
              nombre: "",
              url: "https://i.ibb.co/cwJSqH7/Pics-Art-08-14-01-22-02.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 155,
          codigo: "7898252501115",
          nombre: "Gel para Sexo Oral Lengua de Gato - Sabor Menta",
          descripcion:
            "El gel Lengua de Gato Black Ice, pensado para el sexo oral, su nombre se debe al efecto que producto el aplicarlo en la boca, te entrega una textura áspera similar a la de lengua de gato! con sabor a menta y efecto frío, perfecto para el juego previo a las relaciones sexuales!",
          detalles:
            "Ingredientes: Glicerina, Idénticos aromas de menta y mentol naturales.\r\n\r\nCaracterísticas:\r\n\r\nGel comestible\r\nContenido: 15g\r\nEfecto: Frio\r\nObservaciones:\r\n\r\nComestible\r\nAgitar antes de usar\r\nNo contiene gluten\r\nMantener en lugar fresco\r\nEvitar contacto con los ojos\r\n \r\n\r\nModo de aplicación:Colocar la cantidad deseada en la lengua y otro poco en la zona deseada para practicar sexo oral.Al frotar entrega efecto calor y al soplar entrega efecto frío.Después de abierto consumir antes de 15 días.\r\n\r\nImportante:\r\nAntes de utilizar cualquier producto de cosmética erótica, se recomienda revisar componentes de este para evitar cualquier reacción alérgica.En caso de irritación suspender su uso y consultar a un dermatólogo.",
          instagram: "https://www.instagram.com/sexduceme.cl",
          precio: 6990,
          descuento: 14.0,
          stock: 7,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 239,
              nombre: "",
              url: "https://i.ibb.co/f1jprPq/1608164511535.jpg",
              descripcion: "",
            },
            {
              id: 240,
              nombre: "",
              url: "https://i.ibb.co/zXrF6h0/1608164511496.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 560,
          codigo: "1234560294596",
          nombre: "Disfraz de Colegiala Sexy Talla L/XL",
          descripcion:
            "Disfraz de colegiala con blusa tipo peto spandex con amarre y falda con pretina extra elasticada diseño escocesa.",
          detalles:
            "Especificaciones:\n\nClasificación: Disfraz.\nColor: Blanco y Escocés azul.\nTallas: L/XL\nMaterialidad: Nylon, spandex y algodón.\nPeso: 198 grs.\nPeso empaque: 268 grs.\nDimension del empaque: 30 x 18 x 5,5",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1429,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2020/06/Lady-genny-O-207-F1.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 613,
          codigo: "7898626040332",
          nombre: "Desensibilizarte Anal - Passion Ball",
          descripcion:
            "El Desensibilizante Anal Passion Ball tendrás los juegos anales más cómodos y placenteros de tu vida, ya que te ayudará a reducir las molestias de la penetración gracias a su ingrediente secreto basado en aceite de semilla para cuidar tu retaguardia.\n\nAbre el paquete, retira una bola y colócala en contacto con el calor de tu piel o agua la cápsula se disolverá fácilmente, aplica el lubricante que sale de la bola dentro del ano y espera unos segundos para sentir el efecto. No introduzcas la bola directamente en el ano.",
          detalles:
            "Ingredientes:\nHellanthus annuus, aceite de semilla, fragancia, tocoferilo, sílice, gelatina, glicerina, acua, metilparabeno, propilaparabeno.\n\n \n\nCaracterísticas\nDesensibilizante anal unisex.\nContiene aceite de semilla de girasol agente reparador de la piel.\nCápsulas hechas de gelatina.\nContenido envase con dos capsulas de 3g. c/u.",
          instagram: "",
          precio: 5990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1506,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/intt-cosmetica-desensibilizante-anal-passion-ball-30063397109949_2000x.jpg?v=1635442549",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 518,
          codigo: "701417051860",
          nombre: "Conjunto Disfraz De Mucama",
          descripcion:
            "Este infartante disfraz de mucama incluye sujetador, falda con liguero y medias, de malla negra semitransparente con detalles de encaje blanco. El sostén cuenta con apertura sugerente al centro de cada pecho con un encantador lazo y tirantes en cuello y espalda. ¡Es el disfraz perfecto para hacer de tu juego de roles algo más entretenido!",
          detalles:
            "Incluye: Sostén, falda con liguero y medias\nDetalles de encaje y transparencias\nColor: Negro con detalle de encaje blanco\n\nTalla: TALLA ÚNICA \nMedidas: \n\nAncho falda: 30 cm (elasticado)\n\nLargo falda: 26 cm\n\nContorno tiras sostén ajustable: 143 cm\n\nMaterial: 93% nylon – 7% Elastano\n\nRecomendaciones: Recomendamos lavar tu producto a mano para cuidar su vida útil, escurrir suavemente sin estrujar, para evitar estropear el tejido. No planchar, no lavar en seco, Puede afectar la calidad del producto y cualquier proceso de garantía.",
          instagram: "",
          precio: 21990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1375,
              nombre: "",
              url: "https://sexshopmayorista.cl/15401-large_default/conjunto-disfraz-de-mucama-.jpg",
              descripcion: "",
            },
            {
              id: 1376,
              nombre: "",
              url: "https://sexshopmayorista.cl/15400-large_default/conjunto-disfraz-de-mucama-.jpg",
              descripcion: "",
            },
            {
              id: 1377,
              nombre: "",
              url: "https://sexshopmayorista.cl/15399-large_default/conjunto-disfraz-de-mucama-.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 16,
          codigo: "605632501804",
          nombre: "Anillo Peneano Vibrador Élan Fucsia",
          descripcion:
            "El anillo vibrador Élan Anneau de Bonheur es un anillo vibrador con estimulador de clítoris de gran tamaño.\r\nMejora y prolonga tus erecciones mientras estimulas el clítoris de tu pareja con este poderoso anillo vibrador.\r\nSe recomienda su uso con lubricante en base a agua.",
          detalles:
            "Diámetro: 2,5cm expandible.\r\nFuente de Energía: 3-LR44 (Incluye 3)",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 598,
              nombre: "",
              url: "https://i.ibb.co/q7rrxdb/Pics-Art-02-27-01-58-18.jpg",
              descripcion: "",
            },
            {
              id: 599,
              nombre: "",
              url: "https://i.ibb.co/hLtMFdP/Pics-Art-02-22-12-40-46.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 414,
          codigo: "6652223540128",
          nombre: "Lubricante Sabores Sexlub (10ml)",
          descripcion:
            "Lubricantes Sexlub a base de agua de 10 ml (Valor por unidad)",
          detalles:
            "Variedad de sabores:\n\nChocolate\nCherry\nFrutilla\nClassic (sin sabor)\n\nNo tóxicos y compatibles con preservativos.",
          instagram: "",
          precio: 2490,
          descuento: 14.0,
          stock: 13,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1125,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2021/06/AT-20-sexlub-10ml-F1.jpg",
              descripcion: "",
            },
            {
              id: 1303,
              nombre: "",
              url: "https://i.ibb.co/DDxgX40/Pics-Art-08-14-01-10-06.jpg",
              descripcion: "",
            },
            {
              id: 1304,
              nombre: "",
              url: "https://i.ibb.co/G7fMgcR/Pics-Art-08-14-01-10-45.jpg",
              descripcion: "",
            },
            {
              id: 1305,
              nombre: "",
              url: "https://i.ibb.co/Ky9dtj6/Pics-Art-08-14-01-11-28.jpg",
              descripcion: "",
            },
            {
              id: 1306,
              nombre: "",
              url: "https://i.ibb.co/VQYBWBg/Pics-Art-08-14-01-11-58.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 520,
          codigo: "714569759087",
          nombre: "Medias Negras Smooth",
          descripcion:
            "Medias altas y ligeras color negro transparente ideal para toda ocasión. Detalle de rallas en la parte alta del muslo ideal para lucir tus piernas hermosas y atractivas. Al ser elásticas aseguran una postura cómoda y suave.",
          detalles:
            "Características\n\nTalla Única\n\nDetalle Rallas parte superior\n\nNylon, Spandex\n\nColor negro\n\n\nRecomendaciones\n\nLavar a Mano\n\nNo estrujar, no planchar, no lavar en seco",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 6,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1379,
              nombre: "",
              url: "https://sexshopmayorista.cl/14967-large_default/medias-lisas-.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 53,
          codigo: "7898943080509",
          nombre: "Spray para Sexo Oral Suction",
          descripcion:
            "El Spray Para Sexo Oral Suction de la reconocida marca brasileña Innt, provoca un efecto anti arcadas haciendo del sexo oral una experiencia confortable y excitante. Con Aroma a Canela. Que nada te prive del rico sexo oral, compra hoy mismo Suction Intt y disfruta del sexo sin tapujos.",
          detalles:
            "Ingredientes: Glicerina, Sodiun Hialuronato, parafina líquida, aceite de la flor Eugenia caryophyllus, propilparabeno, metilparabeno,\n\nCaracterísticas:\nContenido: 15ml\nGel para sexo oral\nEfecto anti arcadas\nDosificador tipo aerosol\nMantener en lugar fresco\nEvitar contacto con los ojos\n\nModo de aplicación:\nAplicar directamente en la garganta antes del sexo oral.",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 12,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 305,
              nombre: "",
              url: "https://i.ibb.co/9wVBSM4/IMG-20200925-231222.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 621,
          codigo: "845830073554",
          nombre:
            "Micro tanga masculina labios traslúcidos Kiss Me - Talla S/M",
          descripcion:
            "Micro tanga de talle bajo, cinturilla elástica en relieve de felpa con bolsa sostenedora de tela completa traslúcida en estampado, y parte posterior con inserción entre los glúteos.",
          detalles:
            "Características y Especificaciones:\n1 Pieza: 1 micro tanga masculina, diseño de tela labios traslúcida.\nDescripción de la tela: tela pura, suave, completamente elástica, estampado de labios.\nContenido de la tela: 92% nailon, 8% elastano\nColor\n\nNegro con estampado besos.\n\nTalla:\nS/M",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1516,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2021/08/Male-Power-Kiss-Me-454-232-F1-700x630-1.jpg",
              descripcion: "",
            },
            {
              id: 1517,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2021/08/Male-Power-Kiss-Me-454-232-F3-700x630-1-600x540.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 3,
              nombre: "Lencería Masculina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 486,
          codigo: "716222204293",
          nombre: "Lubricante Comestible sabor Sandía (89ml)",
          descripcion:
            "Wet con su línea de lubricantes íntimos Fun Flavors, donde encuentras cuatro productos en uno; lubricante íntimo, gel de masaje, gel con efecto calor y gel comestible. Es por esto que nuestro sexshop dispone de lubricantes de alta gama como lo son los geles multifuncionales de la reconocida marca estadounidense Wet, para un encuentro sexual placentero. El Lubricante Fun Flavors Sabor Sandía de Wet es elaborado de glicerina y saborizantes libres de azúcares, en formato de 89ml, de envase discreto y cómodo dosificador. Fun Flavors es un lubricante íntimo multiuso, ya que cumple con cuatro funciones específicas para obtener un encuentro sexual placentero. La elaboración del Lubricante íntimo de Fun Flavors lo hace un producto estimulante, por sus principales características que es lubricar para facilitar la penetración, proporciona efecto calor lo cual brinda una rápida excitación, donde sus componentes permiten su uso como un gel para masajes eróticos y además es comestible para un delicioso sexo oral. Con el Lubricante Fun Flavors Passion Sandia de Wet podrás innovar en el sexo ya que te permite lubricar la zona vaginal o anal para una penetración cómoda, confortable y excitante, realizar un masaje sensual con efecto calor y practicar sexo oral con delicioso sabor a Sandía.",
          detalles:
            "Ingredientes: Glicerina y saborizantes artificiales.\n\nCaracterísticas:\nContenido: 89ml\nSabor: Sandía\nEfecto: Calor\n\nObservaciones:\nNo contiene azúcar\nNo contiene colorantes\nLibre de manchas\n\nModo de aplicación:\nAplicar la cantidad deseada para lubricar las zonas íntimas o realizar masajes.\nEnjuagar con agua fría y jabón suave.\n\nImportante:\nLos resultados dependerán del uso correcto del producto y del organismo de cada persona. Antes de usa un producto de cosmética erótica se recomienda revisar los componentes de este para evitar reacciones alérgicas.\nEn caso de irritación suspender y consultar con su dermatólogo.",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1271,
              nombre: "",
              url: "https://i.ibb.co/8zpJfw4/Pics-Art-08-14-01-24-45.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 50,
          codigo: "7898495410328",
          nombre: "Gel Estimulante Electroshock Sabor Menta",
          descripcion:
            "El Gel Excitante Electro Shock de Intt presenta un formato de 8g, de diseño discreto con un gel estimulante, efecto de vibración. Al aplicar Electro Shock en los genitales y zonas erógenas, sentirás por primera vez lo que es la vibración líquida, dando paso a un aumento del libido. Además viene con exquisitos sabores, lo cual permite que lo apliques y lo comas de las zonas deseadas del cuerpo de tu amante.\nCon el Gel Excitante Electro Shock, podrás potenciar tus encuentros sexuales, ya que elevará tu libido proporcionando un momento inolvidable.",
          detalles:
            "Características:\nSabor: Menta\nGel excitante unisex\nContenido: 8g\nEfecto: Vibración\n\nIngredientes: Aqua, Glycerin, Menthol, Propylene Glycol, Iodopropynyl Butylcarbamate, Sodium, Saccarin, Triethanolamine. Contenido: 8g Efecto: Vibración.\n\nObservaciones: Gel excitante con efecto de vibración. De uso unisex. Producto comestible. Mantener en lugar fresco. Evitar contacto con los ojos.\n\nModo de aplicación: Aplicar la cantidad deseada y masajear suavemente.",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 20,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 798,
              nombre: "",
              url: "https://i.ibb.co/D4CVwkP/Pics-Art-04-19-12-58-00.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 4,
          codigo: "920010051017",
          nombre: "Dados Hot",
          descripcion:
            "Si buscas nuevas emociones y expandir tu repertorio íntimo.\n¡¡Atrévete a jugar!! ¡Lánzalos y que comience el juego!\nSon dos dados que brillan en la oscuridad, uno indica partes del cuerpo y el otro la acción a realizar. HOT DICE te desafía a romper con la rutina y dejarte llevar por el azar del destino de los dados atrévete con la apuesta más caliente…\n",
          detalles: "Contenido: 2 Dados\nCombinaciones de sexo explícito.",
          instagram: "https://www.instagram.com/sexduceme.cl",
          precio: 4990,
          descuento: 14.0,
          stock: 16,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 556,
              nombre: "",
              url: "https://i.ibb.co/VxDvxy4/Pics-Art-02-13-09-23-38.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 32,
          codigo: "777000806009",
          nombre: "Lubricante Hot (Sachet 5ml)",
          descripcion:
            "Si quieres una experiencia sexual única, el Lubricante Hot con efecto calor es para ti. Gracias a su increíble textura y efecto calor, intensifica el placer y aumenta la estimulación sexual. Con su formato sachet lo podrás llevar a cualquier lugar, incluso en tu billetera para estar preparado en cualquier momento.\nSu efecto calor único, intensificara la estimulación de tus zonas erógenas y gracias a su excelente lubricación tendrás experiencias inolvidables.",
          detalles:
            "Ingredientes:\nPure Water,Silicone Oil,Glycerol, Hydroxyethyl Cellulose, Carbomer Resin, Others.\n\nObservaciones:\nAplicar la cantidad suficiente de lubricante Hot sobre la zona a lubricar, soplar suavemente y se sentirá una sensación de calor.\n",
          instagram: "",
          precio: 1490,
          descuento: 14.0,
          stock: 81,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 403,
              nombre: "",
              url: "https://i.ibb.co/NS1dv8q/Pics-Art-01-18-02-33-25.jpg",
              descripcion: "",
            },
            {
              id: 404,
              nombre: "",
              url: "https://i.ibb.co/2hwkHBW/Pics-Art-01-18-02-27-54.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 615,
          codigo: "5022782333164",
          nombre: "Anillo Comestible - Candy Love",
          descripcion:
            "Candy Love es un anillo para pene fabricado de dulces con varios sabores, diseñado para practicar sexo oral y sorprender a tu amante. Puedes comerlos poco a poco para ir dándole misterio al ambiente, luego comienza a lamerlos mirando directamente a sus ojos, verás como tu amante se derrite por ti. ",
          detalles:
            "Características:\nAnillo para pene de dulces.\nProducto comestible.\nSabor: Multi-sabor.\nTalla: Única.\n\nIngredientes: dextrose, glucose syrup, citric acid, artificial flavors, artificial color, magnesium stearate.",
          instagram: "",
          precio: 5990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1508,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/hott-products-juguetes-anillo-comestible-candy-love-36242479153385_2000x.jpg?v=1638565183",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 3,
              nombre: "Lencería Masculina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
          ],
        },
        {
          id: 617,
          codigo: "827160104641",
          nombre:
            "Lubricante agua, potenciador y retardante Superhero 30ml pjur",
          descripcion:
            "Lubricante energizante Ginkgo a base de agua\nLa dosis extra de la sensación pjur para todos aquellos que quieren más. Con extractos de Ginkgo que mejoran la perfomance. El lubricante premium a base de agua fue desarrollado especialmente para los hombres que están dispuestos a aprovechar y prolongar su poder. El ginkgo se ha utilizado durante siglos en la medicina china en particular, y se valora para mejorar la circulación de la sangre. Es sinónimo de crecimiento, fuerza y energía. Pjur superhéroe tiene un efecto fortalecedor y vigorizante. Para todos los superhéroes – o aquellos que quieran unirse a sus filas.",
          detalles:
            "Lubricante Premium con Ginkgo\nGinkgo tiene un efecto vigorizante y estimulante\nColor y sabor neutro\nIngredientes: Glicerina, Agua (Agua), Hidroxipropil Guar, Cloruro de Hidroxipropiltrionio, Hidroxietilcelulosa, Extracto de Ginkgo Biloba (Hoja), Alcohol Benzílico, Ácido Benzoico, Ácido Sorbico, Propilenglicol, Fenoxietanol, Methylparaben, Ethylparaben, Propylparaben, Butylparaben, CI 19140 23), CI 42090 (Ácido Azul N ° 9).",
          instagram: "",
          precio: 13990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1510,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2021/07/pj74-1.png",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 9,
              nombre: "Potenciadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 623,
          codigo: "9780201378665",
          nombre: "Aceite de Masajes Comestible Frutos del Bosque - Sexlub",
          descripcion:
            "Aceite Para Masaje comestible y para mayor efecto y relajación con Efecto Calor; una rica combinación para hacer volar esa imaginación.\n\nJuégatela con un buen masaje de relajación, o disfruta del exquisito sexo oral o mejor aún comerse todo el cuerpo con el exquisito sabor a Frutos del Bosque.",
          detalles:
            "A Base de agua, no tóxicos, compatibles con todo los juguetes, y compatible con los preservativos de látex. De fácil lavado. Aromático y de delicioso sabor.\n\n60 ml.",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1521,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2020/06/sexlub-aceite-frutosdelbosque1-60ml-F.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 625,
          codigo: "9780201379709",
          nombre: "Lubricante sabor Ice Menta efecto frío 60ml Sexlub®",
          descripcion:
            "Lubricante intimo base agua sabor Ice Menta con efecto intensificante frío. No mancha, espeso y compatible con condones de latex. 60ml",
          detalles: "",
          instagram: "",
          precio: 11990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1523,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2020/05/sexlub-ice.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 199,
          codigo: "777010045016",
          nombre: "Lubricante Chocolub (Sachet 5ml)",
          descripcion:
            "El lubricante ideal para el sexo oral, vaginal y anal. Vive un encuentro sexual único gracias al lubricante Chocolub de absorción lenta y con sabor a chocolate.\nLubricación por más tiempo y con exquisito sabor a chocolate, perfecto para ese encuentro muy esperado!",
          detalles:
            "Ingredientes: Agua pura, aceite de silicona, glicerina, hidroxietilcelulosa, esencia de chocolate y otros.\n\nContenido: 5ml\nSabor: Chocolate\n\nObservaciones:\nRico y suave sabor a Chocolate que intensifica el sexo oral.\nConservar en un lugar fresco y seco, lejos de la luz directa del sol.\nEvitar el contacto con los ojos.\nNo usar en caso de irritación.\n\nImportante:\nLos resultados dependerán del uso correcto del producto y del organismo de cada persona. Antes de usa un producto de cosmética erótica se recomienda revisar los componentes de este para evitar reacciones alérgicas.\nEn caso de irritación suspender y consultar con su dermatólogo.",
          instagram: "",
          precio: 1490,
          descuento: 14.0,
          stock: 142,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 407,
              nombre: "",
              url: "https://i.ibb.co/5G9vpfm/Pics-Art-01-18-02-31-28.jpg",
              descripcion: "",
            },
            {
              id: 408,
              nombre: "",
              url: "https://i.ibb.co/V362DC0/Pics-Art-01-18-02-31-06.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 175,
          codigo: "7730963250336",
          nombre: "Preservativo Prudence Neón",
          descripcion:
            "Preservativo Prudence Neón, que es un preservativo lubricado que brilla en la oscuridad gracias a un pigmento el cual se activa con la luz y tiene un ligero sabor a vainilla. Para que brille, se debe exponer 30 segundos a la luz dentro del envase. Este preservativo te brindará seguridad, resistencia y efectividad. Enciente la pasión con Prudence Neón",
          detalles:
            "Características:\nMaterial: látex\nProveedor: Prudence\nContiene 3 unidades\nColor: neón\n\nObservaciones\nUsar con lubricante a base de agua\nRevisar componentes para evitar reacciones alérgicas\nEn caso de irritación suspender y consultar a su médico.",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 523,
              nombre: "",
              url: "https://i.ibb.co/BZcX4R2/Pics-Art-02-09-02-28-02.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 284,
          codigo: "825156107485",
          nombre: "Juego de Bondage Seducción",
          descripcion:
            "¡Crea la fantasía de 50 sombras de grey en tu intimidad! Sacia tu curiosidad y explora el mundo del bondage con tu amante. Explora de manera segura con este kit el excitante mundo de la dominación y la sumisión amorosa.\n",
          detalles:
            "Contiene:\n- 36 tarjetas\n- 2 cuerdas de seda\n- 1 vendaje para los ojos\n- 1 látigo de goma\n- Instrucciones",
          instagram: "",
          precio: 24990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 732,
              nombre: "",
              url: "https://i.ibb.co/ZgxcgSq/Pics-Art-03-20-09-19-43.jpg",
              descripcion: "",
            },
            {
              id: 733,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/2087775/thumb/540/540?1614279519",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 32,
              nombre: "BDSM",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 22,
              nombre: "Kits",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 552,
          codigo: "14110014113",
          nombre: "Boxer Con Tirantes Blanco",
          descripcion:
            "El Boxer es una prenda hecha de polyamida y spandex, materiales que lo hacen anti-bacterial, sin estática, secado rápido y muy cómodo de usar en el día a día.",
          detalles:
            "Material:90% Polyamida y 10% Spandex\nColor: Blanco con bordes rojos\nContenido: 1 unidad\n\nObservación:\nDiseño según imagen\nTela knitted (De punto)\n\nPropiedades de la tela:\nSe estira y vuelve a su forma original sin perder calidad.\nAnti-bacterial\nAnti-estática\nRespirable\nSecado rápido\nEco – amigable\nCómodo.\n\nTalla: L",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1411,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/starsex-lenceria-boxer-con-tirantes-colores-starsex-30441694331069_2000x.jpg?v=1632773694",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 3,
              nombre: "Lencería Masculina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 620,
          codigo: "788866000999",
          nombre: "Varita anal de PVC semi rígida Sex Please Rosada",
          descripcion:
            "Sex Please nos vuelve a sorprender con estas excelentes 9 bolitas anales estilo tailandesa de PVC.\n\nSexy Beads son unas resistentes bolitas anales estilo tailandesa de PVC con 9 bolitas de tamaños graduales para hacerlo más cómoda en su manipulación. Al ser de PVC hace que sea un producto resistente al agua y con una textura no porosa, facilitando la limpieza e higienización de estas bolitas anales.\n\nSu diámetro va desde los 7 mm hasta los 2,5 cms y de largo tiene 24,5 cms insertables mas un anillo para tirar de 3,5 cms. Es de PVC semi rígido lo que permite una mayor sensación interior y alcance. Además sirve perfectamente para principiantes por su fácil manejo y baja dificultad de dilatación. Su limpieza es fácil, solo debes limpiarlo con jabón antibacterial y agua tibia.\n\nLo recomendamos tanto para hombres como a mujeres que disfruten del sexo anal y con una buena relación precio calidad.",
          detalles:
            "Características:\n\n23,5 cms insertables.\n9 bolitas de distinto tamaño, 9 niveles de dilatación.\nAro de fácil manipulación.\nLisa, no porosa.\nCompacta, de una sola pieza.\nHecha de PVC.\nA prueba de agua y fácil de limpiar.\nEspecificaciones:\n\nClasificación: Anal.\nColor: Morado.\n27,5 cms de longitud. 23,5 cms insertable.\nDimension de empaque 35,5 cms x 7,5 cms x 3 cms.\nPeso: 66 grs.\nPeso total con empaque: 111 grs.\nMaterialidad: PVC.\nMarca: Sex Please!® USA\nFabricado en China por TopcoSales.",
          instagram: "",
          precio: 5990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1514,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2015/07/SexPlease-Sexy-Beads-Pink-F1.jpg",
              descripcion: "",
            },
            {
              id: 1515,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2015/07/SexPlease-Sexy-Beads-Pink-F2.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 522,
          codigo: "700604729221",
          nombre: "Medias Sensuales Negras",
          descripcion:
            "Media liga calada, con diseño de tramado grande, ideal para usar con portaligas",
          detalles:
            "Características:\nMaterial: Nylon\nColor: Negro\nPrendas: Panty-media\nNo posee silicona\nCompatible con ligueros\nTalla: Única\nDetalles de transparencia y encaje\nCaracterísticas:\nMaterial: Nylon\nColor: Negro\nPrendas: Panty-media\nNo posee silicona\nCompatible con ligueros\nTalla: Única\nDetalles de transparencia y encaje\n",
          instagram: "",
          precio: 4490,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1381,
              nombre: "",
              url: "https://sexshopmayorista.cl/5029-large_default/medias-sensuales-negras.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 49,
          codigo: "7898450818633",
          nombre: "Gel Retardante Japonés",
          descripcion:
            "El gel japonés es un retardante masculino de la eyaculación y además prolonga la erección haciendo que tus relaciones sexuales sean más duraderas, ya es tiempo que comiences a disfrutar cada encuentro sexual sin preocupaciones y solo te dediques a gozar!",
          detalles:
            "Características:\nRetardante masculino\nContenido: 8 g\nTubo plástico\nMantener en lugar fresco\nEvitar contacto con los ojos\n\nModo de aplicación: Aplicar una pequeña cantidad en el dedo y esparcir por el glande, también un poco en el cuerpo del pene.",
          instagram: "",
          precio: 5990,
          descuento: 14.0,
          stock: 7,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 821,
              nombre: "",
              url: "https://i.ibb.co/Z1c2wms/Pics-Art-04-26-12-24-02.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 627,
          codigo: "9780201308570",
          nombre: "Lubricante sabor Chocolate 60ml Sexlub®",
          descripcion:
            "Lubricante intimo base agua sabor y aroma chocolate. No mancha, espeso y compatible con condones de latex. 60ml",
          detalles: "",
          instagram: "",
          precio: 11990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1525,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2020/05/sexlub-chocolate.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 628,
          codigo: "9780201375183",
          nombre: "Lubricante íntimo sabor y aroma Cherry 60ml Sexlub®",
          descripcion:
            "ubricante intimo sabor y aroma a Cherry base agua Sexlub™ Cherry. No mancha, espeso y compatible con condones de latex. 60ml ",
          detalles: "",
          instagram: "",
          precio: 11990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1526,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2016/09/Athenalabs-Sexlub-Cherry-60-ml-F1-1.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 629,
          codigo: "127035247894",
          nombre: "Lubricante íntimo Base Agua 60ml Sexlub®",
          descripcion:
            "Lubricante intimo aquoso sin olor ni sabor Sexlub Classic. No mancha, espeso y compatible con condones de latex. 60ml",
          detalles: "",
          instagram: "",
          precio: 11990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1527,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2016/09/Athenalabs-Sexlub-Classic-60-ml-F1-1.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 425,
          codigo: "6970260901444",
          nombre: "Consolador Doble Penetración",
          descripcion:
            "Consolador macizo sujetable de silicona para practicar doble penetración.",
          detalles:
            "Logra nuevas experiencias e intensifica la excitación con el nuevo consolador macizo para doble penetración Silicone Fantasy. Fabricado con Silicona de alta calidad, de inserción fácil y confortable gracias a su diseño de punta firme y ligeramente curva. Este producto está pensado para ir sujeto al pene y así poder realizar una doble penetración, tanto vaginal como anal, y experimentar el placer en su máxima expresión.\n\nCaracterísticas:\nColor: Negro\nMaterial: Silicona\nLibre de Ftalatos\n100% silicona\nA prueba de agua\n\nMedidas:\nConsolador:\nLargo: 15 cm\nDiámetro mayor: 3.5 cm\nDiámetro menor: 2 cm.\nAnillo para testículos: 3 cm.\nAnillo para Pene: 4 cm.\n\nRecomendaciones de Uso:\nLavar antes y después de usarlo.\nAplicar antes de su uso lubricante a base de agua sobre el pene y testículos, luego estirar el anillo sobre el pene ajustando hasta encontrar una posición cómoda, para un ajuste más firme y una erección mas fuerte, estirar el segundo anillo sobre los testículos.",
          instagram: "",
          precio: 29990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1291,
              nombre: "",
              url: "https://i.ibb.co/fvwQQGG/Pics-Art-08-17-02-17-22.jpg",
              descripcion: "",
            },
            {
              id: 1292,
              nombre: "",
              url: "https://sexshopmayorista.cl/10057-large_default/consolador-doble-penetracion-silicone-fantasy.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 12,
              nombre: "Consoladores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 132,
          codigo: "7729300000024",
          nombre: "Aceite para Masajes Sabor Frutilla (50ml)",
          descripcion:
            "Descubre los Geles Comestibles Erotic, con efecto calor y 100% libres de azúcar, de exquisito aroma y sabor, entrega a tu pareja el mejor sexo oral de la vida y logra una noche inolvidable, se puede retirar fácilmente con agua tibia y jabón neutro. No dejes pasar más tiempo y prueba nuestra línea de Geles comestibles en diferentes sabores. \nStarsex te trae nuevos sabores para que disfrutes junto a quien más quieras de exquisitos juegos eróticos, masajes y un delicioso sexo oral lo que aumentará la excitación en ambos. ",
          detalles:
            "Características:\nSabor Frutilla\nGel comestible con efecto calor\nEfecto: efecto calor y comestible\nContenido: 50 ml\nIngredientes: Glicerina, propilengycol, Agua, Saborizante\n\nObservaciones:\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. También se puede aplicar en distintas zonas erógenas para un rico sexo oral. Después de aplicarlo debes soplar y tu pareja sentirá una agradable sensación de calor.\nEliminar el exceso con agua tibia y jabón neutro.",
          instagram: "",
          precio: 7990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 253,
              nombre: "",
              url: "https://i.ibb.co/84D460C/1608173289383.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 630,
          codigo: "7804918450703",
          nombre:
            "Preservativo Lifestyles TURBO - estimulante L-arginina y mentol.",
          descripcion:
            "LifeStyles Turbo te permitirá tener una experiencia llena de placer en todos los sentidos. Es un preservativo de látex premium, lubricado por dentro y por fuera para ayudarte a sentir una satisfacción total.\n\nEstá lubricado con gel estimulante en base a L-arginina y mentol. El primero permite la estimulación en pareja, aumentando sus niveles de excitación en ambos; mientras que el segundo genera una sensación de frescura inolvidable y placentera.",
          detalles:
            "LifeStyles Turbo te permitirá tener una experiencia llena de placer en todos los sentidos. Es un preservativo de látex premium, lubricado por dentro y por fuera para ayudarte a sentir una satisfacción total.\n\nEstá lubricado con gel estimulante en base a L-arginina y mentol. El primero permite la estimulación en pareja, aumentando sus niveles de excitación en ambos; mientras que el segundo genera una sensación de frescura inolvidable y placentera.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1528,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2021/01/LIF-TURBO-Lifestyles-TURBO-x3-Preservativo-latex-con-estimulante-L-arginina-y-mentol.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 631,
          codigo: "7804918403112",
          nombre: "Preservativo Sabores Lifestyles ",
          descripcion:
            "LifeStyle Sabores es un preservativo de látex con exquisitos deliciosos aromas y sabores de chocolate, limón y tutti frutti. Lubricado con SK-70, para más suavidad y confort en el momento de la intimidad. Despierta tus sentidos y estimula tu imaginación haciendo más entretenidos y divertidos los momentos con tu pareja.",
          detalles:
            "Presentación: Caja de 3 unidades\nAncho Nominal: 53 mm\nForma: Recta\nTextura: Lisa",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1529,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2021/01/LIF-SABOR-Lifestyles-Sabores-x3-preservativo-latex.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 521,
          codigo: "4541255190680",
          nombre: "Pantimedias Negras Con Flores",
          descripcion:
            "Comodas pantys estilo malla sin pies con delicadas flores a los costados. Llegan hasta la cintura haciendo que resalte tu silueta y saque el lado mas sexy que llevas en ti.\nGracias a su comodidad puedes combinarla para uso diario, o alguna ocasión mas intima. gracias al efecto que crea visualmente.\n\n ",
          detalles:
            "Caracteristicas:\n\nTalla única\n\nSpandex\n\nEstilo Malla\n\nColor negro\n\nRecomendaciones\n\nLavar a Mano\n\nNo estrujar, no planchar, no lavar en seco",
          instagram: "",
          precio: 4490,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1380,
              nombre: "",
              url: "https://sexshopmayorista.cl/14958-large_default/pantimedias-negras-de-red.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 551,
          codigo: "801553066249",
          nombre: "Disfraz Enfermera",
          descripcion:
            "El Disfraz Enfermera Sensual te dará un aire picante y sensual para que seas la enfermera más hot de toda la noche.",
          detalles:
            "Características:\nDisfraz para juegos de roles, noche de bodas, cosplay de Halloween, etc.\nTalla L (también sirve para personas talla M)\nMedidas: \nAncho falda: 32 cm (elasticado) \nLargo falda 33 cm \nContorno tiras sostén ajustable: 110cm\nIncluye gorro de enfermera, sostén, minifalda y tanga.\nColor blanco con diseños de cruz roja.\nMaterial: 65% Algodón + 35% poliéster. ",
          instagram: "",
          precio: 23990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1408,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/starsex-lenceria-disfraz-enfermera-sensual-30062230110397_2000x.jpg?v=1633029659",
              descripcion: "",
            },
            {
              id: 1409,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/starsex-lenceria-disfraz-enfermera-sensual-30062230175933_2000x.jpg?v=1633029659",
              descripcion: "",
            },
            {
              id: 1410,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/starsex-lenceria-disfraz-enfermera-sensual-30062230208701_2000x.jpg?v=1633029659",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 550,
          codigo: "507495009553",
          nombre: "Disfraz Conejita",
          descripcion:
            "Con el Disfraz Conejita SexBomb, le darás un toque atrevido a tu intimidad, enloqueciendo a tu amante que no sabrá si acariciarte o devorarte.",
          detalles:
            "Características:\nDisfraz para juegos de roles, noche de bodas, cosplay de Halloween, etc.\nDisfraz incluye cintillo con orejas de conejo, humita y brazaletes blancos.\nDiseño de body con transparencia en el pecho para lucir más sensual.\nBody súper ajustado para acentuar tus curvas.\nCómodo y sexy.\nTalla: S\nMedidas: \nLargo body: 53cm\nAncho body: 31cm \nColor negro.\nMaterial: 95% poliéster +5% Spandex",
          instagram: "",
          precio: 29990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1406,
              nombre: "",
              url: "https://sexshopmayorista.cl/15396-large_default/disfraz-conejita.jpg",
              descripcion: "",
            },
            {
              id: 1407,
              nombre: "",
              url: "https://sexshopmayorista.cl/15397-large_default/disfraz-conejita.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 437,
          codigo: "603912274561",
          nombre: "Bomba De Vacío Power Pump",
          descripcion: "Bomba de Vacío Power Pump, PIPEDREAM",
          detalles:
            "Bomba al vacío diseñada por selectos urólogos en Filadelfia junto al equipo creativo de productos PIPEDREAM, libre de efectos secundarios y efectividad garantizada.\n\nCaracterísticas:\nLargo: 19 cm.\nDiámetro base: 6.5 cm.\nMarca: Pipedream\nColor: Blanca\n\nEste set contiene:\nPera anatómica de alta potencia.\nVálvula de presión para control de volumen del aire.\nBase flexible y ajustable de máxima calidad.\nTubo de aire manejable.\nCuerpo rígido de PVC libre de tóxicos.",
          instagram: "",
          precio: 23990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1295,
              nombre: "",
              url: "https://i.ibb.co/L9Trd8H/Pics-Art-08-17-02-16-32.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 9,
              nombre: "Potenciadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 282,
          codigo: "646709100018",
          nombre: "Antifaz de Satín Negro",
          descripcion:
            "Este antifaz te ayudará a disfrutar de excitantes noches amorosas. Ideales para combinar con el uso de cosmética sensual para exacerbar todos los sentidos y desinhibir la fantasía.",
          detalles: "Color Negro",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 777,
              nombre: "",
              url: "https://i.ibb.co/dJnCvXc/Pics-Art-03-21-12-33-35.jpg",
              descripcion: "",
            },
            {
              id: 778,
              nombre: "",
              url: "https://i.ibb.co/Brm6z9H/Pics-Art-03-21-12-35-54.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 32,
              nombre: "BDSM",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 51,
          codigo: "7898495410380",
          nombre: "Gel Estimulante Electroshock Sabor Frutilla",
          descripcion:
            "El Gel Excitante Electro Shock de Intt presenta un formato de 8g, de diseño discreto con un gel estimulante, efecto de vibración. Al aplicar Electro Shock en los genitales y zonas erógenas, sentirás por primera vez lo que es la vibración líquida, dando paso a un aumento del libido. Además viene con exquisitos sabores, lo cual permite que lo apliques y lo comas de las zonas deseadas del cuerpo de tu amante.\nCon el Gel Excitante Electro Shock, podrás potenciar tus encuentros sexuales, ya que elevará tu libido proporcionando un momento inolvidable.",
          detalles:
            "Características:\nSabor: Frutilla\nGel excitante unisex\nContenido: 8g\nEfecto: Vibración\n\nIngredientes: Aqua, Glycerin, Menthol, Propylene Glycol, Iodopropynyl Butylcarbamate, Sodium, Saccarin, Triethanolamine. Contenido: 8g Efecto: Vibración.\n\nObservaciones: Gel excitante con efecto de vibración. De uso unisex. Producto comestible. Mantener en lugar fresco. Evitar contacto con los ojos.\n\nModo de aplicación: Aplicar la cantidad deseada y masajear suavemente.",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 20,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 248,
              nombre: "",
              url: "https://i.ibb.co/f2vq96B/1608164511436.jpg",
              descripcion: "",
            },
            {
              id: 247,
              nombre: "",
              url: "https://i.ibb.co/wCMZSwK/1608164511476.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 190,
          codigo: "52601",
          nombre: "Corset Explosión de Color Amarillo",
          descripcion:
            "Hermoso Corset explosión de color en Amarillo. Posee ajustes con cintas en la parte trasera. \n",
          detalles:
            "Color: Amarillo\nTalla L\nIncluye Colales\n\nObservaciones: Talla pequeña, equivale a un M/L Nacional ",
          instagram: "",
          precio: 21990,
          descuento: 28.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 808,
              nombre: "",
              url: "https://i.ibb.co/C0rTDnR/Pics-Art-04-19-12-53-13.jpg",
              descripcion: "",
            },
            {
              id: 809,
              nombre: "",
              url: "https://i.ibb.co/6b1kHJ3/Pics-Art-04-19-12-52-45.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 226,
          codigo: "6959532318364",
          nombre: "Anillo Vibrador Doble Ajustable Locker",
          descripcion:
            "Anillo Vibrador Doble Ajustable Locker, con bala vibradora integrada para estimulación de pene y testículos. Esta especialmente diseñado para brindar comodidad y funcionalidad mejorando efectivamente la relación sexual. Hecho de silicona que se estira fácilmente para máxima comodidad, además viene con una bala vibradora lo que estimulara tanto a él como a ella.",
          detalles:
            "Caracteristicas\nMaterial Silicona\nColor Negro\nBala Vibradora integrada (3 batería LR41)\nMedidas\nLargo: 13.6cm\nAncho: 5cm\n\nObservaciones \nUsar con lubricante a base de agua\nResistente al agua\nDiseño ergonómico\nUso Externo \n\nRecomendaciones\nLavar con agua tibia y jabón neutro antes y después de cada uso, dejar secar y guardar en un lugar fresco y seco.",
          instagram: "",
          precio: 26990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 592,
              nombre: "",
              url: "https://i.ibb.co/HXsykX6/Pics-Art-02-09-02-34-44.jpg",
              descripcion: "",
            },
            {
              id: 593,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14834-large_default/anillo-vibrador-doble-ajustable-locker.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 523,
          codigo: "714569770242",
          nombre: "Disfraz Criada Traviesa - Talla S",
          descripcion:
            'Disfraz Criada Traviesa que viene a darle un toque distinto a tus encuentros ya que puedes jugar a ser una criada obediente o una que desafia las ordenes de su "patrón". El Disfraz Criada Traviesa tiene encaje blanco transparente en el busto con un lindo toque de vuelitos en los hombros, además incluye delantal y corbatín, todo en una suave tela satinada.',
          detalles:
            "Características:\nMaterial: Polyester “Spandex\nColor: Negro - Blanco\nPrendas: Vestido - Colaless- Delantal - Corbatín\nDisfraz erotico tipo vestido con corte en la cintura. \nDetalles de encaje.\nTalla S",
          instagram: "",
          precio: 17990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1382,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/lenceria-lenceria-disfraz-criada-traviesa-30507536023741_2000x.jpg?v=1633031992",
              descripcion: "",
            },
            {
              id: 1383,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/lenceria-lenceria-disfraz-criada-traviesa-18708248625302_2000x.jpg?v=1633031992",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 449,
          codigo: "605632462167",
          nombre: "Pack Anillo y Funda Rosado",
          descripcion:
            "Pack de funda y anillo vibrador para pene, de material jelly que otorga elasticidad y comodidad.",
          detalles:
            "Una entretenida funda peneana con textura externa que proporciona mayor estimulación a la pareja. Además viene con un anillo vibrador para otorgar aun más placer, logrando una erección firme y duradera.\n\nCaracterísticas:\nMaterial: Jelly\nColor: Rosado-Morado (Según \nLargo de Funda: 10,5 cm expandibles\n\nObservaciones:\nAnillo re-utilizable\nUna intensidad de vibración\nIncluye 2 baterías modelo L1131\nLibre de ftalatos\n\nSe aconseja el uso de preservativo y lubricante cuando se utilicen juguetes eróticos, de esta forma se obtendrá un resultado de óptima calidad que asegurara la vida útil de producto.\nLavar antes y después de usar.",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1259,
              nombre: "",
              url: "https://i.ibb.co/XYNLRNP/Pics-Art-08-14-01-28-46.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 622,
          codigo: "845830073547",
          nombre:
            "Micro tanga masculina labios traslúcidos Kiss Me - Talla L/XL",
          descripcion:
            "Micro tanga de talle bajo, cinturilla elástica en relieve de felpa con bolsa sostenedora de tela completa traslúcida en estampado, y parte posterior con inserción entre los glúteos.",
          detalles:
            "1 Pieza: 1 micro tanga masculina, diseño de tela labios traslúcida.\nDescripción de la tela: tela pura, suave, completamente elástica, estampado de labios.\nContenido de la tela: 92% nailon, 8% elastano\nColor\n\nNegro con estampado besos.\nTalla L/XL",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1518,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2021/08/Male-Power-Kiss-Me-454-232-F1-700x630-1.jpg",
              descripcion: "",
            },
            {
              id: 1519,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2021/08/Male-Power-Kiss-Me-454-232-F2-700x630-1-600x540.jpg",
              descripcion: "",
            },
            {
              id: 1520,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2021/08/Male-Power-Kiss-Me-454-232-F3-700x630-1.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 3,
              nombre: "Lencería Masculina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 624,
          codigo: "9780201379150",
          nombre: "Aceite de Masajes Sabor Ron - Sexlub",
          descripcion:
            "Aceite Para Masaje comestible y para mayor efecto y relajación con Efecto Calor; una rica combinación para hacer volar esa imaginación.\n\nJuégatela con un buen masaje de relajación, o disfruta del exquisito sexo oral o mejor aún comerse todo el cuerpo con el exquisito sabor a ron.",
          detalles:
            "A Base de agua, no tóxicos, compatibles con todo los juguetes, y compatible con los preservativos de látex. De fácil lavado. Aromático y de delicioso sabor.\n\n60 ml.",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1522,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2020/05/aceite-sexlube-ron.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 495,
          codigo: "4541605370229",
          nombre: "Plug Anal Metálico Colita de Conejo",
          descripcion:
            "Plug anal cola de conejo, posee una cola corta blanca en forma de pompón, suave, de aspecto tierno y elegante, perfecto para quienes quieren iniciar en una experiencia anal gracias al tamaño del dilatador y forma cónica. El Plug Anal de metal ofrece una estimulación placentera y segura. Muy fácil de limpiar.",
          detalles:
            "Características:\nPlug anal dilatador\nDilatación anal\nEstimulación anal\nColor metal: Plateado\nColor Cola: Blanco\n\nMedidas:\nDiámetro mayor: 10 cm\nDiámetro menor: 2,8 cm\n\nObservaciones: Lavar antes y después de usar. Utilizar Lubricante, perfecto para principiantes. De fácil limpieza.",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1232,
              nombre: "",
              url: "https://i.ibb.co/9NbBdxR/IMG-20210807-162736.jpg",
              descripcion: "",
            },
            {
              id: 1233,
              nombre: "",
              url: "https://i.ibb.co/P1WgZXy/IMG-20210807-162755.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 186,
          codigo: "7898943080738",
          nombre: "Aceite para Masajes Fruit Sexy Sabor Caipirinha",
          descripcion:
            "Aceite multifuncional para el cuerpo, proporciona momentos únicos gracias a las diferentes opciones de uso, para sexo oral y para masajes en las partes erógenas, provocando durante su uso una sensación de leve efecto calor.",
          detalles:
            "Ingredientes: Glicerina, idéntica a caipirinha naturales arome y tartrazina colorante amarillo, azul y i ii azul.\n\nCaracterísticas:\nAceite corporal\nContenido: 40 ml.\nSabor: Caipirinha\nEfecto: Leve efecto calor",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 339,
              nombre: "",
              url: "https://i.ibb.co/T4ShFL0/1609808562483.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 530,
          codigo: "7770200050206",
          nombre: "Lubricante Retardante Masculino (100ml)",
          descripcion:
            "El lubricante retardante, es un retardante eyaculador que prolonga la erección sin perder la sensibilidad, obteniendo relaciones sexuales más intensas, prolongadas y placenteras. Solo debe aplicar y masajear una pequeña cantidad sobre el pene erecto. ",
          detalles:
            "Formato: 100 ml.\nCaracterísticas:\nHipoalergénico\nA base de agua\nHecho en Chile",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1385,
              nombre: "",
              url: "https://sexshopmayorista.cl/15570-large_default/lubricante-retardante-100ml-pet.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 456,
          codigo: "605632283007",
          nombre: "Anillo Vibrador Reutilizable Rosado",
          descripcion:
            "Anillo vibrador fabricado de jelly, reutilizable, flexible y fácil de utilizar.",
          detalles:
            "Anillo vibrador Top Cat Toys fabricado en material Jelly lo que lo hace un producto súper flexible. La principal característica del anillo para pene vibrador es prolongar la erección y a la vez proporcionar estimulación clitorial.\n\nCaracterísticas:\nMaterial: Jelly\nColor:Rosado, semi transparente\nBaterías: 2-L1131\n\nObservaciones:\nAnillo Peneano reutilizable\nMaterial flexible\nBaterías incluidas",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 10,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1319,
              nombre: "",
              url: "https://i.ibb.co/xMBq5q9/Pics-Art-08-14-01-20-05.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 626,
          codigo: "9780201308563",
          nombre: "Lubricante sabor Frutilla 60ml Sexlub®",
          descripcion:
            "Lubricante intimo base agua sabor y aroma frutilla. No mancha, espeso y compatible con condones de latex. 60ml",
          detalles: "",
          instagram: "",
          precio: 11990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1524,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2020/05/sexlub-frutilla.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 112,
          codigo: "555000025114",
          nombre: "Anillo para pene Donuts",
          descripcion:
            "eentregan una erección firme y duradera. Creados en material jelly, súper elasticados y de fácil uso. Reutilizables y dispuestos a ayudarte donde y cuando gustes\n\nSolo debes colocar el anillo en la base del pene. Utilizar por un máximo de 20 min por acto sexual. Esto ayudará a mantener una buena erección durante el acto y a retardar un poco la eyaculación. Recomendamos rebajar el vello para evitar incomodidad en el uso",
          detalles:
            "Características:\nAnillo para la erección.\nAnillo erector elasticado.\nErección fuerte y duradera Material: Jelly.\nDiámetro: 3.5 cm.\nColores: Transparente, Negro y Azul.\nContenido: 3 unidades.",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 9,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 588,
              nombre: "",
              url: "https://i.ibb.co/v45sgXj/Pics-Art-02-27-01-59-11.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 22,
              nombre: "Kits",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 316,
          codigo: "6914421000041",
          nombre: "Plug Anal - Colitas de Gatito con Orejitas Blanco",
          descripcion:
            "Plug Anal con cola de gatito, en tono blanco con rosado, es perfecto para quienes se atreven a probar nuevas experiencias y sensaciones, ya que con su plug anal metalizado en forma cónica, permite dilatar de mejor forma el ano. ",
          detalles:
            "Plug Anal Metalizado\nColor: Blanco con Rosado\nIncluye diadema con Orejitas y cinta de razo.\n\nSe recomienda el uso con lubricante Híbrido o de Silicona.",
          instagram: "",
          precio: 29990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 847,
              nombre: "",
              url: "https://i.ibb.co/gwJRDH3/Pics-Art-04-26-12-25-28.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 601,
          codigo: "7770100420406",
          nombre: "Lubricante 4 En 1 FrutiLub 30ml",
          descripcion:
            "Fruti Lub es un lubricante híbrido, formulado a base de agua y silicona que proporciona una lenta absorción. Posee un delicioso sabor y aroma a frutilla, ideal para practicar sexo oral. Su efecto calor encenderá cualquier encuentro incorporando un nuevo estímulo a los juegos previos, masturbación, sexo oral, anal o vaginal. No contiene azúcar, es de fácil limpieza, no mancha y puede ser el complemento perfecto para un masaje localizado en cualquier zona erógena.",
          detalles:
            "Características:\nContenido:30 ml\nSabor: Frutilla\nAroma: Frutilla\nEfecto: Calor\n\n\nModo de uso:\nAplicar directo en la zona a lubricar o masajear (vulva, pene, pezones, etc) reaplicar de ser necesario.\n\nIngredientes:\nPure Water, silicone Oil, Glycerol, Hydroxyethyl Cellulose, Strawberry Essence And Others\n",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1489,
              nombre: "",
              url: "https://sexshopmayorista.cl/15722-large_default/lubricante-4-en-1-frutilub-30ml.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 280,
          codigo: "4061504007335",
          nombre: "Succionador de Clítoris Satisfyer Pro 2",
          descripcion:
            "La espectacular tecnología de esta popular varita mágica ya ha demostrado con creces su eficacia: un excitante vacío, unido a unas enérgicas pulsaciones, estimulan el clítoris sin tocarlo, de una forma inesperada hasta ahora. ¡Llegan los orgasmos explosivos! El nuevo diseño de los botones abre el camino a toda una serie de orgasmos: el Satisfyer Pro 2 de nueva generación está equipado con un botón de +/- con el que puedes aumentar o reducir la intensidad en función de tus necesidades. La luz de los botones no es tan intensa como la de su predecesor y, por tanto, es menos llamativa.",
          detalles:
            "Especificaciones\nMaterial: silicona.\nLongitud total: 16,5 cms.\nModos: 12 programas de estimulación.\nModo de carga: recargable por USB (cable incluido).\nResistente al agua.(No sumergible)\nFácil de limpiar.",
          instagram: "",
          precio: 69990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 728,
              nombre: "",
              url: "https://i.ibb.co/S76JfT8/Pics-Art-03-20-09-15-02.jpg",
              descripcion: "",
            },
            {
              id: 729,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/6604637/thumb/540/540?1602168280",
              descripcion: "",
            },
            {
              id: 730,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/6604639/thumb/540/540?1602168281",
              descripcion: "",
            },
            {
              id: 731,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/6604638/thumb/540/540?1602168281",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 334,
          codigo: "638258901604",
          nombre: "Relajante Anal Tush Ease Sachet 2ml",
          descripcion:
            "Este gel disminuye la sensibilidad del ano, permitiendo avanzar más cómodamente en el juego anal.",
          detalles:
            "Contenido: 2 ml.\nLibre de fragancia.\nContiene 4,9% de benzocaína.\nComplementar siempre con lubricante en base de agua.\nNo contiene parabenos ni aceite mineral.",
          instagram: "",
          precio: 2490,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 880,
              nombre: "",
              url: "https://i.ibb.co/27QD16J/Pics-Art-05-16-02-33-18.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 295,
          codigo: "789185190057",
          nombre: "Bralette Negro Obsesión - Talla M",
          descripcion:
            "Bralette de encaje floral negro, detalles de tiras y breteles ajustables. Talla M.\nEl Bralette Negro Obsesión es una prenda de última tendencia en la lencería femenina, cómodo y a la vez sensual. Su diseño presenta encaje floral negro que va sobre el busto con diseños de tiras o strappy sobre el escote. El broche se encuentra alojado en la parte delantera, al medio del busto. Los breteles son ajustables lo que permite un mejor calce.",
          detalles:
            "Características:\nMaterial: Polyester - Spandex\nColor: Negro\nPrendas: Bralette\n\nTalla: M\n\nObservaciones:\nBreteles ajustables\nBroche ubicado en el centro delantero",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 718,
              nombre: "",
              url: "https://i.ibb.co/yWswkDF/Pics-Art-03-21-12-36-51.jpg",
              descripcion: "",
            },
            {
              id: 719,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/12619-large_default/bralette-negro-obsesion.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 85,
          codigo: "0956710058",
          nombre: "BabyDoll Lidia - Talla L",
          descripcion:
            "Lidia es un  babydoll rojo de escote halter con tirantes de hombre y cinta de tela en la cintura ajustables para mejor calce. Que mezcla el encaje con la tela semitransparente roja para jugar con sus texturas y delicado detalle de piedras brillantes al centro de su top.",
          detalles:
            "Características: \nIncluye colaless*\nSin Forro\nTirantes de hombro ajustables\nCinta ajustable a la cintura\nMaterial: 95% poliéster 5% spandex\nTalla: L\nColor: Rojo\n\nMedidas:\nAncho contorno: 81 cm\nLargo total: 61 cm ",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 770,
              nombre: "",
              url: "https://i.ibb.co/TbQTqwP/Pics-Art-03-21-12-38-58.jpg",
              descripcion: "",
            },
            {
              id: 771,
              nombre: "",
              url: "https://i.ibb.co/GR04X9K/Pics-Art-03-21-12-40-38.jpg",
              descripcion: "",
            },
            {
              id: 772,
              nombre: "",
              url: "https://i.ibb.co/GvptnJm/Pics-Art-03-21-12-39-37.jpg",
              descripcion: "",
            },
            {
              id: 773,
              nombre: "",
              url: "https://i.ibb.co/b2z1cZW/Pics-Art-03-21-12-40-11.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
          ],
        },
        {
          id: 11,
          codigo: "7809604500069",
          nombre: "Preservativo SensorPlus UltraSensible",
          descripcion:
            "Los Condones SensorPlus Ultra Sensibles están fabricados con látex natural y lubricados con aceite de silicona.",
          detalles: "- Dimensiones: 180mm x 52mm\r\n- Grosor: 0.065mm",
          instagram: "",
          precio: 1990,
          descuento: 14.0,
          stock: 10,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1025,
              nombre: "",
              url: "https://i.ibb.co/Kx9rHvP/Pics-Art-07-07-02-26-40.jpg",
              descripcion: "",
            },
            {
              id: 1026,
              nombre: "",
              url: "https://i.ibb.co/ymz73mZ/Pics-Art-02-28-08-15-10.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 383,
          codigo: "6914421000218",
          nombre: "Pintura de Chocolate Blanco",
          descripcion:
            "Elige la parte favorita del cuerpo de tu pareja, aplica el chocolate semi-líquido y comienza el ritual de lamer y chupar\n\nAbsolutamente comestible y de sabor delicioso",
          detalles: "* No mancha\nCada pote contiene 55grs",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1035,
              nombre: "",
              url: "https://i.ibb.co/QHMFYMR/Pics-Art-07-07-12-55-11.jpg",
              descripcion: "",
            },
            {
              id: 1036,
              nombre: "",
              url: "https://i.ibb.co/c8pzQXb/Pics-Art-07-07-12-54-26.jpg",
              descripcion: "",
            },
            {
              id: 1037,
              nombre: "",
              url: "https://i.ibb.co/qgs3yqt/Pics-Art-07-07-12-57-16.jpg",
              descripcion: "",
            },
            {
              id: 1038,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/7079929/thumb/540/540?1612294964",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 178,
          codigo: "7898626041063",
          nombre: "Gel Estimulante Vibration Ice Power",
          descripcion:
            "El Gel Vibration IcePower es fabricado a base de agua, saborizado y potenciado con su principal activo que es Jambú que es el responsable de generar el efecto vibratorio. El gel Vibration Ice Power presenta un efecto tres veces más potente que el original. Solo debe aplicar una pequeña pulsación sobre el clítoris, también se puede aplicar una pequeña cantidad sobre la cabeza del pene (dependiendo la sensibilidad de cada hombre). ",
          detalles:
            "Ingredientes: Aqua, propylene glycol, glicerina, triethanolamine, carbomer, acmella oleracea. Activo principal, Jambú\n\nCaracterísticas:\nContenido: 17ml\nSabor: Menta\nEfecto: Vibración fuerte\n\nObservaciones:\nGel estimulante y comestible\nAplicar sobre las zonas erógenas\nEfecto de vibración liquida intensa",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 317,
              nombre: "",
              url: "https://i.ibb.co/VSFQK2L/1609808562728.jpg",
              descripcion: "",
            },
            {
              id: 318,
              nombre: "",
              url: "https://i.ibb.co/CQd9tQX/1609808562667.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 39,
          codigo: "7765432100140",
          nombre: "Aceite para Masajes Sabor Vainilla (30ml)",
          descripcion:
            "Descubre los Geles Comestibles Erotic, con efecto calor y 100% libres de azúcar, de exquisito aroma y sabor, entrega a tu pareja el mejor sexo oral de la vida y logra una noche inolvidable, se puede retirar fácilmente con agua tibia y jabón neutro. No dejes pasar más tiempo y prueba nuestra línea de Geles comestibles en diferentes sabores. \nStarsex te trae nuevos sabores para que disfrutes junto a quien más quieras de exquisitos juegos eróticos, masajes y un delicioso sexo oral lo que aumentará la excitación en ambos. ",
          detalles:
            "Características:\nSabor Vainilla\nGel comestible con efecto calor\nEfecto: efecto calor y comestible\nContenido: 30 ml\nIngredientes: Glicerina, propilengycol, Agua, Saborizante\n\nObservaciones:\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. También se puede aplicar en distintas zonas erógenas para un rico sexo oral. Después de aplicarlo debes soplar y tu pareja sentirá una agradable sensación de calor.\nEliminar el exceso con agua tibia y jabón neutro.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 603,
              nombre: "",
              url: "https://i.ibb.co/7KSrGk8/Pics-Art-02-09-02-27-21.jpg",
              descripcion: "",
            },
            {
              id: 604,
              nombre: "",
              url: "https://i.ibb.co/Gkv0xdm/Aceites-para-Masaje.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 481,
          codigo: "7730963251081",
          nombre: "Preservativo Prudence Chocolate",
          descripcion:
            "Preservativos de látex, lubricados con sabor a Chocolate",
          detalles:
            "Disfruta de un Delicioso y seguro Sexo oral con la gran variedad de sabores que Prudence ofrece en su línea de PRESERVATIVOS. \nContiene 3 unidades de preservativos de látex, lubricados  y con sabor a chocolate.\n\nCaracterísticas:\n\nComposición: Látex natural, silicona, almidón de maiz.\nProveedor: Prudence\nContiene 3 unidades\nColor: Cafe\nAroma: Chocolate\nSabor: Chocolate\n\nLargo: 160 mm\nAncho Nominal: 52 mm \nEspesor: 0.063 mm aprox.",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 7,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1248,
              nombre: "",
              url: "https://i.ibb.co/7ywbvSc/Pics-Art-08-14-01-30-10.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 36,
          codigo: "7770200090202",
          nombre: "Lubricante Femenino (100ml)",
          descripcion:
            "El Lubricante Vaginal Femenino Starsex intensifica tu placer sexual ya que humedece tu vagina dejándola lista para una noche placentera. Es a base de agua, no quema ni provoca irritación y su uso es compatible con preservativos y juguetes eróticos. Además posee un agradable y muy suave sabor y aroma a frutilla que brindará un rico y estimulante aroma mientras lo usas, ya sea con tu amante o en tu momento de intimidad.",
          detalles:
            "Formato: 100ml\n\nCaracterísticas:\nDe base acuosa.\nEnvase con dispensador para una fácil aplicación.\nCompatible con preservativos y juguetes sexuales.\nSuave olor y sabor a frutilla.\nComestible.\nUso externo, interno y sexo oral.\nIngredientes: Agua, Metilparabeno, Propilparabeno, Glicerina, Carboximetilcelulosa, Glicolpropileno, Metilsalicilato, Saborizante Frutilla.\n\nModo de uso:\nAplicar una pequeña cantidad en la zona a penetrar, esparcir hasta que el lubricante cubra toda el área. Se puede usar externa e internamente, así como para el sexo oral.",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 60,
              nombre: "",
              url: "https://i.ibb.co/HGgT08m/IMG-20201031-153952.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 547,
          codigo: "7898626041353",
          nombre: "Gel Estimulante Vibration Sabor Chicle",
          descripcion:
            "Este producto tiene un intenso efecto vibración que ayuda a las personas a excitarse y/o estimularse y así disfrutar mucho más sus relaciones sexuales. Además, tiene un sabroso sabor a Chicle perfecto para practicar sexo oral.",
          detalles:
            "Se puede aplicar en todas las zonas erógenas, clítoris y glande (con moderación, 1 a 2 pulverizaciones).\n\nCaracterísticas:\nSabor: Chicle\nEfecto: Vibración\nProducto Unisex\nComestible\n\nObservaciones:\nGel estimulante y comestible\nAplicar sobre las zonas erógenas\nEfecto de vibración liquida intensa \n\nIngredientes: Aqua, glycerin,cabomer, bht, acmella oleracea extract, aroma idéntico al chicle, CI 45430, CI 16185 y CI 16255.\n\nContenido: 17ml",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1400,
              nombre: "",
              url: "https://sexshopmayorista.cl/14229-large_default/gel-vibration-chicle-intt.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 290,
          codigo: "714569735715",
          nombre: "Babydoll Red Halter - Talla L",
          descripcion:
            "Babydoll talla L con encaje en busto, de diseño cómodo con caída, de tirantes para amarrar en su espalda. Incluye colaless de encaje diseño flores en juego.\n",
          detalles:
            "Características:\n\nMaterial: 95% Polyester 5% Spandex\nColor: Rojo\nPrendas: Babydoll - Colaless\nTalla: L\nObservaciones:\nSin copa\nTirantes para amarrar\nEspalda descubierta",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 754,
              nombre: "",
              url: "https://i.ibb.co/qD5nRCv/Pics-Art-03-21-12-42-09.jpg",
              descripcion: "",
            },
            {
              id: 755,
              nombre: "",
              url: "https://i.ibb.co/3v4XbTq/Pics-Art-03-21-12-42-36.jpg",
              descripcion: "",
            },
            {
              id: 756,
              nombre: "",
              url: "https://i.ibb.co/PZMXkT5/Pics-Art-03-21-12-43-49.jpg",
              descripcion: "",
            },
            {
              id: 757,
              nombre: "",
              url: "https://i.ibb.co/vz35LCd/Pics-Art-03-21-12-43-22.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 340,
          codigo: "7809604500052",
          nombre: "Preservativo Sensor Plus - Extra Resistente Con Nonoxynol-9",
          descripcion:
            "Lubricados interior y exteriormente.\nContiene sustancia espermicida Nonoxynol-9.\nMáxima protección y estimulación.\n\nContenido: 3 unidades.",
          detalles: "",
          instagram: "",
          precio: 1990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1033,
              nombre: "",
              url: "https://i.ibb.co/MBPmtCW/Pics-Art-07-07-02-24-46.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 493,
          codigo: "6652162254018",
          nombre: "Gel Dickalicious Sachet",
          descripcion:
            "Gel Estimulante Masculino Para Sexo Oral, con Sabor a Frutilla, Banana y Piña Colada",
          detalles:
            "Características:\nContenido: 5ml\nSutil efecto frío. \nProduce pequeñas sensaciones de hormigueo.\nComestible\nSabor disponible Frutilla, Plátano, Piña Colada y Frambuesa. \n\nObservaciones:\nVariedad de sabores según stock.\nImportado desde E.E.U.U.",
          instagram: "",
          precio: 2490,
          descuento: 14.0,
          stock: 31,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1314,
              nombre: "",
              url: "https://i.ibb.co/25rx120/Pics-Art-08-14-01-12-34.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 470,
          codigo: "818631020270",
          nombre: "Dickalicious Strawberry",
          descripcion:
            "Estimulador masculino para sexo oral sabor frutilla, con sensación de hormigueo.",
          detalles:
            "El nuevo Dickalicious Strawberry es un gel estimulante  para sexo oral que entrega un excitante efecto hormigueo y un delicioso sabor a frutilla.\n\nIngredientes: Petrolatum, Mineral oil, Sorbitan Oreate, Flavor, Menthol, Sodium Saccharin, Yellow 11(CI#47000), Red 17 (CI# 26100), Propylparaben.\n\nContenido: 56g\nSabor: Frutilla\nEfecto: Hormigueo\n\nCaracterísticas:\nEstimulante\nGel para sexo oral\nFormula soluble al agua\nNo pegajoso\nHidratante",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1318,
              nombre: "",
              url: "https://i.ibb.co/QX8GNjk/Pics-Art-08-14-01-21-35.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 179,
          codigo: "7898626040998",
          nombre: "Gel Estimulante Vibration Sabor Vodka Bull",
          descripcion:
            "Vibration Vodka y Energética es un gel de uso erótico. Su principal característica es que contiene efecto de vibración liquida además de ser comestible, lo cual permite estimular las zona s erógenas y practicar sexo oral.\n\nEl Gel Vibration Vodka Bull es fabricado a base de agua, saborizado y potenciado con ingrediente que generan el efecto vibratorio, para que los juegos sexuales previos sean eróticamente atractivos lo que potenciará las relaciones sexuales. El gel Vibration presenta un efecto de vibración liquida tres veces más potente que el original.\n\nDisfruta de una tu vida sexual junto al Gel Vibration Vodka Bull de Intt.",
          detalles:
            "Ingredientes: Aqua, propylene glycol, glicerina, triethanolamine, carbomer, acmella oleracea. Activo principal, Jambú\n\nContenido: 17ml\nSabor: Vodka -Energética\nEfecto: Vibración\n\nObservaciones:\nGel estimulante y comestible\nAplicar sobre las zonas erógenas\nEfecto de vibración liquida intensa\n",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 315,
              nombre: "",
              url: "https://i.ibb.co/NsvRhGR/1609808562854.jpg",
              descripcion: "",
            },
            {
              id: 316,
              nombre: "",
              url: "https://i.ibb.co/CQd9tQX/1609808562667.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 513,
          codigo: "6959532325904",
          nombre: "Huevo Masturbador Passionate",
          descripcion:
            "Suave y Flexible Huevo Masturbador con textura interna y externa (intercambiable) ",
          detalles:
            "Huevo masturbador completamente texturizado para mayor estimulación, posee doble cara texturizada. Su suave y flexible material entrega máximo confort a la hora de la masturbación, es un producto ideal para personas con pene ya que al introducir el pene en la abertura, se podrá deslizar el huevo hasta su base para una estimulación completa y en vulvas gracias a sus increíbles protuberancias podrás estimular toda la zona imitando una estimulación manual o frotación.\nPara mejorar la experiencia, incluye siempre lubricante a base de agua o híbrido para su uso.\n\nCaracterísticas\nMaterial: TPR\nColor: Rosado\n\nMedidas\nLargo total: 8.6 cm (Flexible, aumenta su largo al estirar)\nAncho total: 4.6 cm\n\nObservaciones\nSuave y Flexible\nUtilizar con lubricantes a base de agua o híbrido.  \nLavar con agua tibia y jabón neutro antes y después de cada uso, dejar secar y guardar en un lugar fresco y seco.",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1364,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/15836771/thumb/540/540?1632421726",
              descripcion: "",
            },
            {
              id: 1365,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/15836772/thumb/540/540?1632421726",
              descripcion: "",
            },
            {
              id: 1366,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/15836773/thumb/540/540?1632421726",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 16,
              nombre: "Masturbadores Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 156,
          codigo: "7898617090148",
          nombre: "Gel para Sexo Oral Lengua de Gato Sabor Frutilla",
          descripcion:
            "Este gel es sumamente especial porque además su exquisito sabor a menta y frutilla, posee un efecto de calor al soplar y frío en la boca que te aseguramos no pasará desapercibido ni para ti ni para tu pareja. Al ser aplicado directamente en la lengua, te aconsejamos dejarlo sobre tu lengua un momento y así podrás apreciar que tu lengua queda con textura porosa ¡Tal cual como un gato!, tu pareja tampoco podrá dejar de disfrutarlo cuando sienta esas texturas de tu lengua recorriendo sus zonas más placenteras, no pierdas el tiempo y dale una oportunidad que te aseguramos será totalmente una experiencia felina. ",
          detalles:
            "Contenido: 15g\r\n\r\n\r\nObservaciones:\r\n\r\nComestible\r\nAgitar antes de usar\r\nNo contiene gluten\r\nMantener en lugar fresco\r\nEvitar contacto con los ojos\r\nDespués de abierto consumir antes de 15 días\r\n",
          instagram: "https://www.instagram.com/sexduceme.cl",
          precio: 6990,
          descuento: 14.0,
          stock: 7,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 241,
              nombre: "",
              url: "https://i.ibb.co/2ZTw9Qn/1608164511515.jpg",
              descripcion: "",
            },
            {
              id: 242,
              nombre: "",
              url: "https://i.ibb.co/f1jprPq/1608164511535.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 73,
          codigo: "7765432100119",
          nombre: "Aceite para Masajes sabor Caramelo (30ml)",
          descripcion:
            "Aceites para Masajes con efecto calor y 100% libres de azúcar, de exquisito aroma y sabor, entrega a tu pareja el mejor sexo oral de la vida y logra una noche inolvidable, se puede retirar fácilmente con agua tibia y jabón neutro. No dejes pasar más tiempo y prueba nuestra línea de Geles comestibles en diferentes sabores.",
          detalles:
            "Características:\nGel comestible con efecto calor\nEfecto: efecto calor y comestible\nContenido: 30 ml\nIngredientes: Glicerina, propilengycol, Agua, Saborizante\nObservaciones:\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. También se puede aplicar en distintas zonas erógenas para un rico sexo oral. Después de aplicarlo debes soplar y tu pareja sentirá una agradable sensación de calor\n",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1324,
              nombre: "",
              url: "https://i.ibb.co/qj7yT9W/Pics-Art-08-14-01-31-08.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 346,
          codigo: "716222204231",
          nombre: "Lubricante Comestible Sabor Frutilla - 89ml",
          descripcion:
            "El Lubricante Flavors Seductive Strawberry de Wet es elaborado de glicerina y saborizantes libres de azúcares, en formato de 89 ml, de envase discreto y cómodo dosificador. Flavors es un lubricante íntimo multiuso, ya que cumple con cuatro funciones específicas para obtener un encuentro sexual placentero.",
          detalles:
            "La elaboración del Lubricante íntimo de Flavors lo hace un producto estimulante, por sus principales características que es lubricar para facilitar la penetración, proporciona efecto calor lo cual brinda una rápida excitación, donde sus componentes permiten su uso como un gel para masajes eróticos y además es comestible para un delicioso sexo oral\n\nIngredientes: Glicerina y saborizantes artificiales.\n\nCaracterísticas:\nContenido: 89 ml\nSabor: Frutilla\nEfecto: Calor\n\nObservaciones:\nNo contiene azúcar\nNo contiene colorantes\nLibre de manchas\n\nModo de aplicación:\nAplicar la cantidad deseada para lubricar las zonas íntimas o realizar masajes.\nEnjuagar con agua fría y jabón suave.",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1053,
              nombre: "",
              url: "https://i.ibb.co/MVyGbW9/Pics-Art-07-07-01-15-18.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 548,
          codigo: "601658093506",
          nombre: "Disfraz De Mucama - Talla 4XL",
          descripcion:
            "Increíble disfraz de mucama Pluz Size con copas triangulares de malla semitransparente y borde de encaje blanco que hace juego con atrevido delantal que deja ver la parte trasera. Este sexy disfraz viene con tirantes halter y cierre con lazo en la espalda. ",
          detalles:
            "Incluye colales y cintillo\n\nTirante halter\n\nDetalles de Encaje y transparencias.\n\nColor: Blanco y Negro\n\nTalla: 4XL\n\n\nMedidas:\n\nTiras ajustables contorno bajo busto: 146 cm\n\nAncho cintura: 50 cm (elasticado)\n\nLargo disfraz: 66 cm \n\nAncho colales: 50 cm (elasticado)\n\nMaterial 95% Poliéster -5% Spandex",
          instagram: "",
          precio: 22990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1401,
              nombre: "",
              url: "https://sexshopmayorista.cl/15412-large_default/disfraz-de-mucama-una-pieza.jpg",
              descripcion: "",
            },
            {
              id: 1402,
              nombre: "",
              url: "https://sexshopmayorista.cl/15413-large_default/disfraz-de-mucama-una-pieza.jpg",
              descripcion: "",
            },
            {
              id: 1403,
              nombre: "",
              url: "https://sexshopmayorista.cl/15414-large_default/disfraz-de-mucama-una-pieza.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 549,
          codigo: "603912268447",
          nombre: "Estimulador Prostatico",
          descripcion:
            "El estimulador prostático es un juguete ideal para experimentar la variedad de placeres en el juego anal. Tiene un diseño curvado especial para masajear el Punto P, mientras que en su base posee una cola delgada y curva para estimular el perineo con firmeza. Fabricado en material de plástico, suave, liso y de textura agradable para la piel.",
          detalles:
            "Características\n\nMaterial: Plástico\nLargo insertable: 11 cm.\nColor: Negro\n\nObservaciones\n\nA prueba de agua\nSumergible\n\nRecomendaciones\n\nLavar antes y después de cada uso.\nLavar con agua y jabón neutro.\nApto para Lubricantes base a agua y silicona.\nGuardar en un sitio limpio y seco, sin mezclar con otros juguetes.\nNo compartir juguete con otras personas.",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1404,
              nombre: "",
              url: "https://sexshopmayorista.cl/15620-large_default/estimulador-prostatico.jpg",
              descripcion: "",
            },
            {
              id: 1405,
              nombre: "",
              url: "https://sexshopmayorista.cl/15621-large_default/estimulador-prostatico.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 30,
              nombre: "Prostáticos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 459,
          codigo: "855559005101",
          nombre: "Excitante Femenino On Ice (5ml)",
          descripcion:
            "El excitante femenino On efecto frío posee una mezcla de aceites esenciales puros y extractos que te entregarán una sensación plena de excitación natural además de una lubricación perfecta para que comiences a gozar. El aceite On efecto frío te producirá excitación directa a tu clítoris!.",
          detalles:
            "Ingredientes: Sweet Almond Oil,Peppermint Oil(perpene free)Cinnamomun Zeylanicum,Natural Tocopherols(Vitamina E); silica, Natural Flavors, Rosemary Oil Extract.\n\nCaracterísticas:\nContenido 5 ML\nEfecto Frío\nPulsa y Vibra\nAumenta el flujo sanguíneo y las sensaciones\nLibre de Glicerina\nLibre de Parabeno\nLibre de Gluten\n\nModo de uso: Aplicar de 1 a 2 gotas en el clítoris, luego de 2 minutos aproximados comienza su efecto, con una duración aproximada de 45 minutos.",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1288,
              nombre: "",
              url: "https://i.ibb.co/MpnqSbx/Pics-Art-08-17-02-16-08.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 372,
          codigo: "7648795032207",
          nombre: "Lubricante Sabor Frutilla - 125ml",
          descripcion:
            "Totalmente renovado en su fórmula a base de agua con sabor frutillas y  nuevo conveniente envase con dispensador.\n\nSe siente como la lubricación natural... pero muy suave y de largo aliento en su rendimiento.\n\nDeliciosamente saborizado y aromatizado con el clásico y sensual sabor de las frutillas\n\nEs sumamente hidratante y refrescante.\n\nMuy adecuado para acompañar el uso de juguetes sexuales.\n\n100% Besable",
          detalles:
            "La línea de lubricantes íntimos de Secretos de Amor está pensada para consumidores que buscan alternativas más saludables en lo que a bienestar sexual se refiere.\n\nTodas las fórmulas incluyen una combinación de ingredientes naturales, orgánicos, eco-certificados. Son libres de glicerina, balanceados en PH y funcionan a la perfección con el cuerpo femenino.Son una gran opción para personas con sensibilidad o alergias\n\n\nContenido 125ml",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1043,
              nombre: "",
              url: "https://i.ibb.co/HVLvZZc/Pics-Art-07-07-12-58-01.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 370,
          codigo: "7648795032108",
          nombre: "Lubricante Neutro",
          descripcion:
            "Totalmente renovado en su fórmula a base de agua y  nuevo conveniente envase con dispensador.\n\nSe siente como la lubricación natural... pero muy suave y de largo aliento en su rendimiento.\n\nEs sumamente hidratante y refrescante.\n\nMuy adecuado para acompañar el uso de juguetes sexuales\n\nLa línea de lubricantes íntimos de Secretos de Amor está pensada para consumidores que buscan alternativas más saludables en lo que a bienestar sexual se refiere.",
          detalles:
            "Todas las fórmulas incluyen una combinación de ingredientes naturales, orgánicos, eco-certificados. Son libres de glicerina, balanceados en PH y funcionan a la perfección con el cuerpo femenino. Son una gran opción para personas con sensibilidad o alergias\n\n\nContenido 125ml",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1046,
              nombre: "",
              url: "https://i.ibb.co/Wc45CZ1/Pics-Art-07-07-12-59-31.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 44,
          codigo: "772933000241",
          nombre: "Aceite para Masajes Sabor Pie de Limón (30ml)",
          descripcion:
            "Descubre los Geles Comestibles Erotic, con efecto calor y 100% libres de azúcar, de exquisito aroma y sabor, entrega a tu pareja el mejor sexo oral de la vida y logra una noche inolvidable, se puede retirar fácilmente con agua tibia y jabón neutro. No dejes pasar más tiempo y prueba nuestra línea de Geles comestibles en diferentes sabores. \nStarsex te trae nuevos sabores para que disfrutes junto a quien más quieras de exquisitos juegos eróticos, masajes y un delicioso sexo oral lo que aumentará la excitación en ambos. ",
          detalles:
            "Características:\nSabor Pie de Limón\nGel comestible con efecto calor\nEfecto: efecto calor y comestible\nContenido: 30 ml\nIngredientes: Glicerina, propilengycol, Agua, Saborizante\n\nObservaciones:\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. También se puede aplicar en distintas zonas erógenas para un rico sexo oral. Después de aplicarlo debes soplar y tu pareja sentirá una agradable sensación de calor.\nEliminar el exceso con agua tibia y jabón neutro.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 333,
              nombre: "",
              url: "https://i.ibb.co/RQfj8GM/1609808561451.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 19,
          codigo: "555001135003",
          nombre: "Anillo Vibrador Thor",
          descripcion:
            "Anillo Vibrador de fuerte intensidad, Desechable.\nMantenga erecciones Firmes y más prolongadas, la bala vibradora está cubierta por suaves filamentos que sumados a las fuertes vibraciones estimularán hasta la euforia el clítoris o el escroto de tu pareja.\nPoner con el pene erecto.",
          detalles:
            "Características Anillo:\nAnillo vibrador desechable\nNo sumergir\nErecciones mas firmes\nIntensa vibración\nTiempo de vida de las pilas 30 minutos aproximadamente\n\nRecomendaciones:\nLimpiar antes y después de utilizar, con agua tibia y jabón neutro\nGuardar en un sitio limpio y seco, sin mezclar con otros juguetes\nUsar con lubricante a base de agua y/o con preservativo\nLibre de Ftalatos\nNo mojar ni sumergir las partes eléctricas",
          instagram: "",
          precio: 5990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 589,
              nombre: "",
              url: "https://i.ibb.co/pLQmP9v/Pics-Art-02-27-01-45-57.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 554,
          codigo: "448001019049",
          nombre: " Estimulador Recargable Noa",
          descripcion:
            "El Estimulador Vibrador Noa Recargable USB Starsex nos presenta un diseño discreto de formato transportable. El que proporcionan una confortable estimulación de las zonas erógenas por su suave material y terminaciones redondeadas.",
          detalles:
            "Características:\nMaterial: Silicona\nColor: Purpura\nRecargables: Cable con puerto USB (incluido)\n3 Intensidades de vibración\n5 Intensidades de pulsación\n\nMedidas:\nLargo Total: 9cm\nDiámetro Mayor: 3,5cm\n\nObservaciones:\nEstimador vibrador recargable\nSilicona de textura extra suave\nTiempo de carga 60 minutos\nDuración de carga 60 minutos continuos\n\nModo de uso:\nPresionar por 2 segundos el botón que se encuentra en la parte inferior del estimulador para encenderlo y por 5 segundos apagarlo.\nPresionar el botón zigzag para cambiar las intensidades de vibración.",
          instagram: "",
          precio: 36990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1418,
              nombre: "",
              url: "https://sexshopmayorista.cl/14066-large_default/estimulador-vibrador-recargable.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 553,
          codigo: "714449810723",
          nombre: "Vibrador Nora - Conexión Wifi / bluetooth",
          descripcion:
            "Nora es un espectacular estimulador doble que gracias a su sofisticado y ergonómico diseño, estimulará tus zonas más erógenas certeramente. Su curvado diseño en la primera porción y eje principal masajearán tu zona G como ningún otro estimulador ya que realiza movimientos giratorios con tres diferentes intensidades, mientras que su eje secundario posee 7 patrones de vibración que estimularán clítoris.\nDisfruta el control local con tu Smartphone mediante la conexión bluetooth o a través de Wi-fi  permite que tu compañerx pueda comandarlo a larga distancia. Sincroniza Nora con el masturbador MAX2 u otro Nora y experimenta el sexo a distancia. \nNora posee una increíble autonomía de uso gracias a que puedes recargarlo, es resistente al agua y fabricado con materiales de gran calidad.\n",
          detalles:
            "Características\nVibrador dual, comandable a corta o larga distancia mediante Bluetooth y Wi-fi\nMaterial: Silicona y TPE\nColor: Fucsia y Blanco\nRecargable mediante puerto USB\n3 intensidades de vibración y 7 patrones de vibración ilimitados\nCabezal giratorio\nResistente al agua, NO SUMERGIBLE\n\nMedidas\nLargo insertable: 120 mm\n\nObservaciones\nLavar con agua tibia y jabón neutro antes y después de cada uso, dejar secar y guardar en un lugar fresco y seco.\nIncluye Cable USB \nCargar directo a la corriente con adaptadores certificados, no utilizar adaptadores de carga rápida.\n\nCompatible con:\niPhone/iPad Air/iPad Mini/iPod Touch iOS 10.0 y superior\nAndroid 4.3 y superior (con Bluetooth 4.0 activado) Se debe activar GPS\nMac (con Bluetooth 4.0 activado)\nPC Windows (necesita el Adaptador Bluetooth USB Lovense)",
          instagram: "",
          precio: 169990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1412,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/lovense-juguetes-vibrador-nora-29876395573437_2000x.jpg?v=1628880917",
              descripcion: "",
            },
            {
              id: 1413,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/lovense-juguetes-vibrador-nora-29726575263933_2000x.jpg?v=1628880918",
              descripcion: "",
            },
            {
              id: 1414,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/lovense-juguetes-vibrador-nora-29726575493309_2000x.jpg?v=1628880918",
              descripcion: "",
            },
            {
              id: 1415,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/lovense-juguetes-vibrador-nora-29876410187965_2000x.jpg?v=1628880918",
              descripcion: "",
            },
            {
              id: 1416,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/lovense-juguetes-vibrador-nora-29726575558845_2000x.jpg?v=1628880917",
              descripcion: "",
            },
            {
              id: 1417,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/lovense-juguetes-vibrador-nora-29876394950845_2000x.jpg?v=1628880918",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 180,
          codigo: "7898601751031",
          nombre: "Gel Analdor Striper",
          descripcion:
            "El sexo anal a menudo es caso de pánico entre hombres y mujeres de todas las edades y/o formas de vida, justamente por estar asociado a la incomodidad. El Gel Analdor Striper es producto que ayuda a desensibilizar la zona anal, además está fabricado con un conservador antimicrobial y con Vitamina E que ayuda en la regeneración de la piel.\n\nDéjate sorprender y descubre cuan placentera resulta la práctica anal.",
          detalles:
            "Ingredientes: Agua; Propilenglicol; Hidroxietilcelulosa; Diazolidinil urea (y) butilcarbamato de yodopropinilo.\n\nContenido: 8g\nEfecto: Desensibilizante.\n\nObservaciones:\nDesensibilizador anal\nMantener en lugar fresco\nEvitar contacto con los ojos\nDescontinuar su uso en caso de irritación\n\nModo de aplicación:\nAplicar una porción en la punta del dedo y aplicarlo en los pliegues anales.",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 8,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 325,
              nombre: "",
              url: "https://i.ibb.co/xCS9PK6/1609808563039.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 30,
          codigo: "44",
          nombre: "Feromonas Femeninas Roll On ENAMOR",
          descripcion:
            "ENAMOR es un perfume compuesto en un 100% con feromonas femeninas, que te permitirá evocar una respuesta sexual de quien gustes. Es un hecho que mujeres con niveles altos de emisión de feromonas tienen una ventaja para atraer la atención masculina o femenina según su preferencia sexual. Con ENAMOR sacarás todo tu potencial sexual. Las feromonas de atracción, como las que está compuesto ENAMOR, son las que nos indican de quién debemos enamorarnos. ",
          detalles:
            "Feromonas Femeninas\r\nAtracción Sexual\r\nExcitantes y afrodisiacos\r\nContenido: 10 ml.\r\nConcentración: 100%.\r\n\r\nAplicación: Rollon\r\n\r\nTen en consideración donde aplicas Enamor; debido al calor del cuerpo al rociarlo en la piel se evaporan rápidamente, y por eso sirven para una acción instantánea, de atracción inmediata. Las bacterias de la transpiración y de la superficie de la piel alejan y eliminan rápidamente a las feromonas. En cambio, el rociado en la ropa produce un efecto más duradero y prolongado. Enamor es un complemento perfecto para tus noches de conquista.",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 19,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 563,
              nombre: "",
              url: "https://i.ibb.co/0fKQXSn/Pics-Art-02-27-02-14-15.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 18,
              nombre: "Feromonas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 84,
          codigo: "903011008542",
          nombre: "Body Esther - Talla L",
          descripcion:
            "Esther es un body de encaje rojo con detalles de tiras elásticas en el cuerpo. Su top es un sostén con copa que entrega máximo soporte y tiras ajustables de hombre que se adaptan de mejor. Su parte inferior contiene un broche de seguridad para fácil uso y su corte es tipo bikini. ",
          detalles:
            "Características:\nCon Forro\nCon barba metálica\nBroche de seguridad\nTirantes de hombro ajustables\nMaterial: 95% poliéster 5% spandex\nTalla: L\nColor: Rojo\n\nMedidas: \nAncho contorno: 74 cm  \nLargo total: 52 cm ",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 749,
              nombre: "",
              url: "https://i.ibb.co/9bZcmJn/Pics-Art-03-21-12-41-05.jpg",
              descripcion: "",
            },
            {
              id: 828,
              nombre: "",
              url: "https://i.ibb.co/fM86mvH/Pics-Art-03-21-12-41-35.jpg",
              descripcion: "",
            },
            {
              id: 829,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14512-large_default/babydoll-esther.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 298,
          codigo: "7798297919657",
          nombre: "Vibrador Cyber Vibe Banana Mulato N° 4",
          descripcion:
            "El Vibrador Cyber Vibe Banana by Caiman, entregará máximo confort gracias a su afelpado y suave diseño, fabricado de Cyber y libre de Ftalatos.\n\nPresentamos la nueva línea de Vibradores Cyber Multi- velocidad Mulatos de Cyber by Caiman. Fabricados en Argentina, de PVC con Cyberskin, material muy suave y flexible. El Vibrador Cyber Vibe Banana Mulato es afelpado e hipoalergénico para brindar máximo confort además de una experiencia muy realística gracias a sus diseños y textura.\n\nPosee una perilla para regular la intensidad de vibración. Utiliza 2 baterías AA (no incluidas)",
          detalles:
            "Características:\n\nMaterial: PVC Cyber suave y flexible\nLargo total: 23 cm\nDiámetro: 4 cm\nMulti velocidades\nLibre de Ftalatos\nSúper suave, flexible y afelpado\nHipoalergénico\n\nObservaciones:\n\nBaterías: 2-AA (no incluidas)\n\nInstrucciones de cuidado:\n\nLimpiar con agua tibia y jabón neutro. \nDejar secar y guardar.\nMantener lejos del calor.\nQuitar baterías después de usar.",
          instagram: "",
          precio: 23990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 720,
              nombre: "",
              url: "https://i.ibb.co/GTpsJ43/Pics-Art-03-20-09-17-42.jpg",
              descripcion: "",
            },
            {
              id: 721,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14467-large_default/vibrador-cyber-vibe-banana-mulato.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 499,
          codigo: "7765432270126",
          nombre: "Vibrador Cliterific Bala 2 En 1",
          descripcion:
            "Este juguete posee una bala vibradora que mantiene la vibración a lo largo de toda la superficie, y es regulable desde la base. La bala de plástico le entrega una alta resistencia y posee una textura lisa y suave; material perfecto para transmitir la vibración. Además posee una funda de Jelly retirable, con una gran elasticidad y diseñada con protuberancias de distintas formas y tamaños que juegan con las texturas lisas y rugosas. Su punta abultada y flexible le permite alcanzar distintas zonas en la penetración, ideal para alcanzar el punto G y estimular el clítoris.",
          detalles:
            "Características\n\nMaterial: Plástico y Jelly\nLargo total: 21 cm\nLargo insertable: 19 cm.\nDiámetro: 4 cm.\nPeso: 180 grs.\nBaterías: 2-AA\n\nObservaciones\n\nVibraciones regulables\nBaterías no incluidas",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1333,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/starsex-juguetes-vibrador-cliterific-bala-2-en-1-18729592946838_2000x.jpg?v=1628294354",
              descripcion: "",
            },
            {
              id: 1334,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/starsex-juguetes-vibrador-cliterific-bala-2-en-1-18729593176214_2000x.jpg?v=1628294354",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 244,
          codigo: "6914421000270",
          nombre: "Dilatador y Vibrador Anal a Control Remoto",
          descripcion:
            "Libera tus manos o entrega el control de tu estimulación anal con este dilatador y vibrador a control remoto que se carga vía USB y sus 10 modalidades de vibración.\nUno de los productos más recomendados a la hora de practicar sexo anal es el dilatador. Este en particular, tiene varias ventajas:\n\n- Es un plug que dilatará tu ano para facilitar la penetración.\n\n- Hará vibrar las terminaciones nerviosas de tu ano para llevarte a un orgasmo anal.\n\n- Puede controlarse de manera remota a través de su control inalámbrico, así puedes liberar tus manos para una estimulación propia o entregarlo a tu pareja para que sea él/ella sea quien te sorprenda con los estímulos sorpresivos ya sea en la intimidad o en el exterior.\n\n- Posee una batería interna de Litio que puede cargarse vía USB con dispositivos como una batería externa, tu computador o el mismo cargador de tu celular.",
          detalles:
            "Características:\n- 10 modos de vibración únicos para elegir.\n- 1 hora de tiempo de carga rápido que le dará 1,5 horas de uso inalámbrico. \n- 100% impermeable\n- Carga USB: diseño único del puerto de carga para un funcionamiento conveniente y un juego respetuoso con el medio ambiente, satisface tus deseos en cualquier momento y en cualquier lugar.\n- Cuando esté completamente cargado tiene 90min de autonomía.\n- Hecho de silicona médica de alto grado es 100% resistente al agua y segura para tu cuerpo.\n\nObservaciones:\nSe recomienda usar siempre con algún lubricante diseñado para sex anal y compatible con juguetes.",
          instagram: "",
          precio: 34990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 469,
              nombre: "",
              url: "https://i.ibb.co/Tb98SqF/Pics-Art-02-08-03-54-09.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 302,
          codigo: "7898626040585",
          nombre: "Excitante Unisex Inflate",
          descripcion:
            'Excitante unisex Inflate de la prestigiosa marca brasilera Intt, el cual es un intenso aceite que brinda la sensación de aumento en el tamaño tanto del pene como del clítoris y dejando la zona genital mucho más sensible y aumentando la excitación. Este efecto "hinchazón" ayuda en el hombre a tener mayor sensibilidad y un mejor desempeño durante el acto; en la mujer, logra mayor receptividad a los estímulos, logrando alcanzar orgasmos más fácilmente. Además posee un rico aroma a canela.\n\nProducto con potente acción es recomendado para personas que hayan utilizado anteriormente excitantes.',
          detalles:
            'Características:\nAroma a canela\nAcción vasodilatadora\nUnisex\nEfecto "hinchazón"\n\nModo de uso: aplique 2 o 3 pulverizaciones en la zona genital y esparza. Reaplicar a gusto\n\nPrecauciones: No usar en regiones con lesiones, laceraciones o inflamaciones. En caso de contacto con los ojos, enjuague con abundante agua. Mantenga el producto en un lugar fresco, alejado del calor y la luz. Mantener fuera del alcance de los niños.\n\nComposición: glicerina, hialuronato de sodio, parafina líquida, aceite de flor Eugenia Caryophyllus, propilparabeno, metilparabeno, CI 19140.\n\nContiene: 15 ml',
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 762,
              nombre: "",
              url: "https://i.ibb.co/r0JFg0g/Pics-Art-03-21-12-30-59.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 9,
              nombre: "Potenciadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 482,
          codigo: "7730963251098",
          nombre: "Preservativo Prudence Mix 3",
          descripcion:
            "Preservativos Lubricados de Látex lisos, coloridos, con sabor, depósito, lados paralelos y ancho nominal.",
          detalles:
            "Prudence Mix contiene 3 preservativos de sabor; menta, tutti frutti y chocolate. Ideales para probar sabores nuevos y hacer un encuentro más delicioso y divertido.\n\nCaracterísticas:\n\nComposición: Látex natural, silicona, almidón de maiz.\nProveedor: Prudence\nContiene 3 unidades\nColor: Rojo, verde y cafe\nAroma: Tutti-frutti, menta y chocolate\nSabor:  Tutti-frutti, menta y chocolate\nLargo: 160 mm\nAncho Nominal: 52 mm \nEspesor: 0.063 mm aprox.",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1246,
              nombre: "",
              url: "https://i.ibb.co/nrt8bQM/Pics-Art-08-14-01-30-36.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 293,
          codigo: "789185144050",
          nombre: "Bralette Necklace - Talla M",
          descripcion:
            "Bralette de encaje elasticado con detalle strappy al cuello.\nEl Bralette Necklace es de un diseño sutil gracias a delicadeza de su tela negra transparente,  unido al detalle strappy de su cuello. Sus tirantes son ajustables.",
          detalles:
            "Características\nMaterial: Poliéster - Elastano\nColor: Negro\nPrendas: Bralette\n\nTalla: M\n\nObservaciones\nLavar a mano\nDetalles de transparencia y Strappys",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 722,
              nombre: "",
              url: "https://i.ibb.co/6mLgW9P/Pics-Art-03-21-12-37-57.jpg",
              descripcion: "",
            },
            {
              id: 723,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/13417-large_default/bralette-necklace.jpg",
              descripcion: "",
            },
            {
              id: 724,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/13413-large_default/bralette-necklace.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 297,
          codigo: "0898260240",
          nombre: "Body Abby - Talla 2XL",
          descripcion:
            "Body Encaje completo Pluz Size.\nSexy body plus size,  completo de encaje transparente rojo que te hará sentir realmente sensual. Ideal para uso íntimo, o también se podría usar con jeans para salir a una noche de baile.",
          detalles:
            "Características:\nTalla: 2XL\nEncaje\nPoliéster – Spandex\nColor Rojo\nBody sin espalda\n\nMedidas:\nAncho:45 cm (Semi elasticado)\nLargo Total:67 cm (De busto a entrepiernas)\n\nRecomendaciones:\nLavar a Mano\nNo estrujar, no planchar, no lavar en seco",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 676,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14912-large_default/body-abby.jpg",
              descripcion: "",
            },
            {
              id: 677,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14911-large_default/body-abby.jpg",
              descripcion: "",
            },
            {
              id: 826,
              nombre: "",
              url: "https://i.ibb.co/GJzRcw6/Pics-Art-03-21-01-00-50.jpg",
              descripcion: "",
            },
            {
              id: 827,
              nombre: "",
              url: "https://i.ibb.co/99yzSbd/Pics-Art-03-21-01-00-10.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 299,
          codigo: "7798297919626",
          nombre: "Vibrador Cyber Vibe Bananin Mulato N° 1",
          descripcion:
            "Presentamos la nueva línea de Vibradores Cyber Multi- velocidad Mulatos de Cyber by Caiman. Fabricados en Argentina de PVC Cyber, material muy suave y flexible. Afelpados e hipoalergénicos entregarán máximo confort además de una experiencia muy realística gracias a sus diseños y textura.\n\nPosee una perilla para regular la intensidad de vibración. Utiliza 2 baterías AA (no incluidas)",
          detalles:
            "Características:\nMaterial: PVC Cyber suave y flexible\nLargo total: 21 cm\nDiámetro: 3,5 cm\nMulti velocidades\nLibre de Ftalatos\nSuper suave, flexible y afelpado.\nHipoalergénico.\n\nObservaciones:\nBaterías: 2-AA (no incluidas)\n\nInstrucciones de cuidado:\nLimpiar con agua tibia y jabón neutro. \nDejar secar y guardar.\nMantener lejos del calor.\nQuitar baterías después de usar.",
          instagram: "",
          precio: 23990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 716,
              nombre: "",
              url: "https://i.ibb.co/SXV4gKY/Pics-Art-03-20-09-18-43.jpg",
              descripcion: "",
            },
            {
              id: 717,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14465-large_default/vibrador-cyber-vibe-bananin-mulato.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 235,
          codigo: "6959532317541",
          nombre: "Anillo Vibrador Conejo Eudora",
          descripcion:
            "Anillo con Conejo Vibrador Eudora, con vibración y estimulador de clítoris tipo conejito que presiona la base del pene y ayuda a mantener la erección por más tiempo, intensificando así los orgasmos masculinos. Es resistente al agua, reutilizable y compatible con lubricantes en base a agua e híbridos.",
          detalles:
            "Características\nMaterial: Silicona\nColor: Negro\nCarga: 3 Baterías LR41 (incluidas).\nMarca: Pretty Love \n\nMedidas\nDiámetro: 2.5cm\n\nObservaciones\nResistente al agua / No sumergible\nApto para Lubricantes híbridos",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 843,
              nombre: "",
              url: "https://i.ibb.co/HzG8bJ5/Pics-Art-02-09-02-40-36.jpg",
              descripcion: "",
            },
            {
              id: 844,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/anillo-eudora_2_2000x.jpg?v=1610874421",
              descripcion: "",
            },
            {
              id: 845,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/anillo-eudora_3_2000x.jpg?v=1610874421",
              descripcion: "",
            },
            {
              id: 846,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/anillo-eudora_4_2000x.jpg?v=1610874421",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 512,
          codigo: "6959532325911",
          nombre: "Huevo Masturbador Romantic",
          descripcion:
            "Suave y Flexible Huevo Masturbador con textura interna y externa (intercambiable) ",
          detalles:
            "Huevo masturbador completamente texturizado para mayor estimulación, posee doble cara texturizada. Su suave y flexible material entrega máximo confort a la hora de la masturbación, es un producto ideal para personas con pene ya que al introducir el pene en la abertura, se podrá deslizar el huevo hasta su base para una estimulación completa y en vulvas gracias a sus increíbles protuberancias podrás estimular toda la zona imitando una estimulación manual o frotación.\nPara mejorar la experiencia, incluye siempre lubricante a base de agua o híbrido para su uso.\n\nCaracterísticas\nMaterial: TPR\nColor: \nmorado\n\nMedidas\nLargo total: 8.6 cm (Flexible, aumenta su largo al estirar)\nAncho total: 4.6 cm\n\nObservaciones\nSuave y Flexible\nUtilizar con lubricantes a base de agua o híbrido.  \nLavar con agua tibia y jabón neutro antes y después de cada uso, dejar secar y guardar en un lugar fresco y seco.",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1361,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/15836771/thumb/540/540?1632421726",
              descripcion: "",
            },
            {
              id: 1362,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/15836772/thumb/540/540?1632421726",
              descripcion: "",
            },
            {
              id: 1363,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/15836773/thumb/540/540?1632421726",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 16,
              nombre: "Masturbadores Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 277,
          codigo: "4560220550526",
          nombre: "Masturbador Masculino Tenga Eggs Spider",
          descripcion:
            "La ciencia ha confirmado que la masturbación es saludable para la salud. De hecho, está comprobado que fortalece el sistema inmunológico, mejora el humor, mejora la calidad de la piel, potencia el autoestima, previene ciertas enfermedades, fortalece la musculatura pélvica, entre muchos otros beneficios. Entendiendo esto es que se hace necesario hacer de la masturbación una experiencia entretenida. Y para esto está el huevo masturbador Tenga que tiene la ventaja de venir con un lubricante íntimo que tiene como misión suavizar y mejorar la experiencia con el producto hecho exclusivamente para hombres. Se trata de un producto que ha sido testeado dermatológicamente y que es hipoalergénico, por lo que se puede usar con total confianza ya que no representa ningún riesgo para la salud íntima. Es apto para todos los tamaños y su material es súper elástico y muy suave al tacto, por lo que es cómodo y práctico de usar al momento de la masturbación. Viene en colores y el lubricante que incluye está en una presentación tipo sachet que se encuentra en su interior. El uso de este producto es fundamental para todos los juguetes sexuales, ya que de esta forma se evita cualquier tipo de roce molesto. Por otro lado, es aconsejable no usar más de dos veces el producto para resguardar tu higiene. Este huevo masturbador puede ser usado por el hombre en solitario, pero también queda la invitación abierta a que lo pueda ocupar con su pareja, como una forma de innovar en el sexo, para agregarle sorpresa o entretención al sexo. Otra de las ventajas de este masturbador es su pequeño tamaño, ya que lo hace muy fácil de manipular y también de transportar a donde quiera que vayas.",
          detalles:
            "Características:\nHuevo Masturbador\nMasturbador masculino\nSúper Elasticados\nReutilizable (lavar con agua y jabón)\nHipoalergénico\nIncluye Lubricante Tenga\nSe acomoda a todos los tamaños\nMaterial Súper suave y flexible\nRelieves en el interior con forma de red\n\nObservaciones:\nLa higiene del producto es primordial, por lo que se aconseja lavarlo antes de usarlo y después de utilizarlo, ya que de este modo, se estará evitando cualquier riesgo de infección. Para esto, se puede usar agua tibia y jabón neutro o también se puede emplear limpiadores especiales para los juguetes sexuales que están disponibles en el mercado.",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 617,
              nombre: "",
              url: "https://i.ibb.co/51fJD7h/Pics-Art-02-27-01-40-08.jpg",
              descripcion: "",
            },
            {
              id: 618,
              nombre: "",
              url: "https://i.ibb.co/09ckzpq/Pics-Art-02-27-01-39-16.jpg",
              descripcion: "",
            },
            {
              id: 619,
              nombre: "",
              url: "https://jvsportswear.net/wp-content/uploads/2020/08/Tenga-eggs-6.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 16,
              nombre: "Masturbadores Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 410,
          codigo: "716770033703",
          nombre: "Pinzas para Pezones",
          descripcion:
            "Estas pinzas son ideales tanto para iniciados como para expertos, ya que su tensión es regulable. Tus pezones pueden sentir un pequeño apretón o una fuerte compresión, depende de ti.",
          detalles:
            "Características:\n- Totalmente ajustable.\n- Sensación cómoda.\n- Pinzas recubiertas de goma.",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1256,
              nombre: "",
              url: "https://i.ibb.co/SvQXzVz/Pics-Art-08-14-01-29-12.jpg",
              descripcion: "",
            },
            {
              id: 1257,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/483909/thumb/540/540?1614881736",
              descripcion: "",
            },
            {
              id: 1258,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/483910/thumb/540/540?1614881736",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 32,
              nombre: "BDSM",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 159,
          codigo: "772933000081",
          nombre: "Aceite para Masajes Sabor Chocolate-Menta (30ml)",
          descripcion:
            "Descubre los Geles Comestibles Erotic, con efecto calor y 100% libres de azúcar, de exquisito aroma y sabor, entrega a tu pareja el mejor sexo oral de la vida y logra una noche inolvidable, se puede retirar fácilmente con agua tibia y jabón neutro. No dejes pasar más tiempo y prueba nuestra línea de Geles comestibles en diferentes sabores. \nStarsex te trae nuevos sabores para que disfrutes junto a quien más quieras de exquisitos juegos eróticos, masajes y un delicioso sexo oral lo que aumentará la excitación en ambos. ",
          detalles:
            "Características:\nSabor Chocolate con Menta\nGel comestible con efecto calor\nEfecto: efecto calor y comestible\nContenido: 30 ml\nIngredientes: Glicerina, propilengycol, Agua, Saborizante\n\nObservaciones:\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. También se puede aplicar en distintas zonas erógenas para un rico sexo oral. Después de aplicarlo debes soplar y tu pareja sentirá una agradable sensación de calor.\nEliminar el exceso con agua tibia y jabón neutro.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 337,
              nombre: "",
              url: "https://i.ibb.co/SVHC7q4/1609808561566.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 292,
          codigo: "0957496098",
          nombre: "Babydoll Emma - Talla 2XL",
          descripcion:
            "Emma es un badydoll semitransparente Rojo y encaje.\n\nEmma es un badydoll semitransparente rojo de cuello halter y encaje con escote largo, con detalles de tiras elásticas que abrazan el cuerpo bajo el busto que puedes ajustar con su broche de espalda en 3 medidas.",
          detalles:
            "- Incluye un calzón semitransparente rojo tipo bikini con detalles de tiras elásticas al costado.\n- Sin forro\n- Tirantes ajustables de espalda \n- Material: 95% poliéster 5% spandex\n- Talla: 2XL\n\n- Medidas babydoll: \n- Ancho contorno busto: 90-98 cm \n- Largo total: 80 cm ",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 824,
              nombre: "",
              url: "https://i.ibb.co/GJz451V/Pics-Art-03-21-12-45-40.jpg",
              descripcion: "",
            },
            {
              id: 825,
              nombre: "",
              url: "https://i.ibb.co/nwkB7dV/Pics-Art-03-21-12-46-48.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 327,
          codigo: "646709100711",
          nombre: "Pluma Traviesa Morada",
          descripcion: "Despierta la piel con suaves trazos eróticos.",
          detalles:
            "Estimulador de plumas. \nLargo total: 18 cm.\nColor Morado",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 906,
              nombre: "",
              url: "https://i.ibb.co/FzpVqDM/Pics-Art-05-16-02-35-33.jpg",
              descripcion: "",
            },
            {
              id: 907,
              nombre: "",
              url: "https://i.ibb.co/0Zts85C/Pics-Art-05-16-02-36-37.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 32,
              nombre: "BDSM",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 498,
          codigo: "817483011115",
          nombre: "Vibrador My Secret Mini Mascara",
          descripcion:
            "Vibrador My Secret es un estimulador de clítoris discreto y elegante con forma de Mascara de Pestañas de material de Silicona Suave. Posee tres velocidades de vibración y una pulsación. ",
          detalles:
            "Screaming O  con su línea de juguetes eróticos My Secret, con diseños elegantes y discretos que te permitirán disfrutar de tu sexualidad en todo momento. Vibrador My Secret Mascara es un mini estimulador que combina perfectamente la elegancia y la máxima discreción, con su fachada de rímel y su punta ultra suave que genera un placentero cosquilleo a través de su textura protuberante, simulando el cepillo de la máscara de pestañas. Posee 3 velocidades distintas de vibración más una pulsación para estimular el clítoris con distintas sensaciones. Este estimulador es el accesorio ideal para mantener la experiencia de placer en secreto y con elegancia dentro del cosmetiquero, para llevarlo a cualquier lugar que se desee, perfecto para viajes.  \n\nCaracterísticas\n\nEstimulador de Clítoris\nMaterial: Silicona suave\nColor: Negro – Rosado\nLargo total: 14 cm\nLargo insertable: 4cm\nDiámetro: 4cm\nBaterías: AG13\n\nObservaciones\n\nBaterías incluidas\nTiempo de duración baterías: 45 minutos aproximadamente\n3 intensidades de vibración más una pulsación\nLibre ftalatos y látex\nResistente al agua\nHipoalergénico",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1330,
              nombre: "",
              url: "https://sexshopmayorista.cl/9712-large_default/vibrador-my-secret-mascara.jpg",
              descripcion: "",
            },
            {
              id: 1331,
              nombre: "",
              url: "https://sexshopmayorista.cl/9713-large_default/vibrador-my-secret-mascara.jpg",
              descripcion: "",
            },
            {
              id: 1332,
              nombre: "",
              url: "https://sexshopmayorista.cl/9714-large_default/vibrador-my-secret-mascara.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 202,
          codigo: "6914421000058",
          nombre: "Colalles de Encaje",
          descripcion: "Colalles con hermosos detalles de encaje.",
          detalles:
            "Colores y Tallas Disponibles:\n\nTalla L:\n- Nude\n- Fucsia\n\nTalla XL:\n- Blanco\n- Calipso\n- Azul\nOBSERVACIÓN: \nSon tallas pequeñas, por lo que se recomienda solicitar una talla más a la que usan habitualmente. ",
          instagram: "",
          precio: 3490,
          descuento: 28.0,
          stock: 6,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 356,
              nombre: "",
              url: "https://i.ibb.co/PjkX6Kd/Pics-Art-01-18-11-29-52.jpg",
              descripcion: "",
            },
            {
              id: 357,
              nombre: "",
              url: "https://i.ibb.co/jHQTyb2/Pics-Art-01-18-11-24-47.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 206,
          codigo: "6914421000126",
          nombre: "Colalles Siempre Lista con Perlas",
          descripcion:
            "Se la más sexy y sugerente con estos hermosos colalles siempre lista de encaje con Perlas en el Centro que permitirán llegar a una máxima estimulación.\nNo te arrepentirás... 😉",
          detalles: "Talla Estándar\n\nColores: \n- Negro\n- Rojo ",
          instagram: "",
          precio: 4990,
          descuento: 28.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 366,
              nombre: "",
              url: "https://i.ibb.co/2NjTmnN/Pics-Art-01-18-11-26-57.jpg",
              descripcion: "",
            },
            {
              id: 367,
              nombre: "",
              url: "https://i.ibb.co/qghvRxV/Pics-Art-01-18-11-30-25.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 240,
          codigo: "7730963250329",
          nombre: "Preservativo con Sabor Caipirinha Prudence",
          descripcion:
            "¡Haz de tu encuentro sexual una verdadera fiesta!\nLiso, colorido y con un exquisito sabor. Si se te hizo agua la boca, no esperes para probarlos. \nLos productos Prudence se fabrican e importan desde modernas fábricas de última generación y poseen certificados de calidad de diferentes organismos internacionales.\n\nDesde la selección de la materia prima hasta el producto final, todos los preservativos de la marca se someten a pruebas 100% electrónicas en la fábrica y a innumerables controles de calidad, para garantizar que los consumidores puedan disfrutar de un sexo seguro, sin ninguna preocupación.",
          detalles:
            "Condón Prudence sabor caipirinha  3 unidades\nProducto 0% alcohol y 100% placer. Único con dos colores para que sientas el carnaval en tu habitación. \nancho nominal: 52 mm",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 522,
              nombre: "",
              url: "https://i.ibb.co/ch2qsn8/Pics-Art-02-09-02-28-36.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 79,
          codigo: "7809604500007",
          nombre: "Preservativo SensorPlus 3 Sabores",
          descripcion:
            "Porque como tú creemos en el sexo seguro, en esta sección te ofrecemos gran variedad de preservativos, ya sea con sabores, texturas o que alumbren en la oscuridad, tenemos todo lo que estés buscando para hacer de tu experiencia sexual además de placentera 100% segura.",
          detalles:
            "Preservativos lubricados interior y exteriormente, diseñados para una mayor estimulación y protección, sus deliciosos sabores lo transforman en los preservativos ideales para sexo oral.",
          instagram: "",
          precio: 1990,
          descuento: 14.0,
          stock: 11,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1082,
              nombre: "",
              url: "https://i.ibb.co/4YMdf8d/Pics-Art-07-07-02-26-18.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 497,
          codigo: "334000017110",
          nombre: "Plug Anal Mini Siliconado",
          descripcion:
            "Dilatador anal de silicona suave y forma redondeada de 10 cms de largo. Este juguete es ideal para quienes se están iniciando en el sexo anal, ya que su diámetro es pequeño para dilatar la zona gradualmente.",
          detalles:
            "Características:\n\nMaterial: Silicona\nLargo total: 10 cm\nLargo insertable: 6.5cm\nDiámetro mayor: 2.3 cm\nColor: Fucsia",
          instagram: "",
          precio: 11990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1329,
              nombre: "",
              url: "https://sexshopmayorista.cl/5988-large_default/plug-mood-siliconado.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 559,
          codigo: "1234560294589",
          nombre: "Disfraz de Colegiala Sexy Talla S/M",
          descripcion:
            "Disfraz de colegiala con blusa tipo peto spandex con amarre y falda con pretina extra elasticada diseño escocesa.",
          detalles:
            "Un sexy disfraz de colegiala de blusa blanca hecha de nylon spandex con diseño de amarre por adelante bajo el busto con una mini falda escocesa de colores verde, azul y rojo con pretina elasticada y de amplitud.\n\nEspecificaciones:\n\nClasificación: Disfraz.\nColor: Blanco y Escocés azul.\nTallas: S/M\nMaterialidad: Nylon, spandex y algodón.\nPeso: 198 grs.\nPeso empaque: 268 grs.\nDimension del empaque: 30 x 18 x 5,5",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1428,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2020/06/Lady-genny-O-207-F1.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 561,
          codigo: "051021144662",
          nombre: "Cuerda de seda para tobillos",
          descripcion:
            "Cuerda de seda para tobillos morada estilo BDSM japonés. Inmovilizadora, apta para principiantes y expertos en el arte del Shibaru. Suave y delicada para juegos seguros.",
          detalles:
            "Japanese Silk Love Rope Ankle Cuff es una cuerda de seda para tobillos estilo BDSM perfecta para poder atar e inmovilizar a tu compañero (a) de juegos. Al ser de seda es mas gentil con la piel provocando ningún daño a tu compañero. Son ajustables, tienen la longitud perfecta para poder encordar los tobillos con delicados grilletes.\n\nContiene un mini manual explicativo en inglés, variedad de cuerdas, materiales que se pueden usar en el bdsm, bondage asimétrico, técnicas básicas de cuerda, seguridad etc.\n\nEntre cada esposa de tobillo deja al rededor de 75 cms de cuerda extendida, suave, lavable y con grilletes de plástico ultra seguros, facile de poner y sacar.\n\nAnímate a atar con pasión y locura a tu pareja, verás que toda la sensualidad del Japón milenario llegará a tu cama y se quedará para que juegues.\n\nCaracterísticas:\n\nSuavidad y resistencia.\nAltamente versatil y fácil de usar.\nSedosa, una seductora textura.\nNo irrita ni daña pieles delicadas.\nTesteada y altamente resistente.\nPerfecto para principiantes y expertos en BDSM.\nIncluye libro explicativo. (Inglés)\nEspecificaciones:\n\nClasificación: BDSM\nColor: Morada.\n75 cm de longitud.\nDimension de empaque 27,5 cms x 24 cms x 5 cms\nPeso: 70 grs aprox.\nPeso total con empaque: 182 grs aprox.\nMaterialidad: Nylon sedoso.\nMarca: TLC!® USA\n\nPrecauciones y recomendaciones:\n\nTen en cuenta la seguridad de tu pareja.\nNo jugar si estas cansado o bajo la influencia de alcohol/drogas.\nNo jugar si no están 100% alerta y comunicativos.\nNo hacer nudos o posiciones que no sean 100% seguras.\nNunca dejar sola a la persona en Bondage.\nNunca aplicar presión en cualquier tipo de de área cercana a el cuello o garganta.\nNunca juegues con alguien que no sea 100% de confianza.\nTen en mente el libro de instrucciones para un correcto uso.\nPara mejor experiencia recomendamos la colección Japanese Silk Love Rope indicada en productos asociados.",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1430,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2015/07/TLC-Japanese-Silk-Love-Rope-Ankle-Cuffs-Purple-F2.jpg",
              descripcion: "",
            },
            {
              id: 1431,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2015/07/TLC-Japanese-Silk-Love-Rope-Ankle-Cuffs-Purple-F1.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 32,
              nombre: "BDSM",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 233,
          codigo: "6959532325324",
          nombre: "Arnés Strapless Valerie",
          descripcion:
            "Arnés sin correas Valerie para una mayor comodidad, posee 12 patrones de vibración, resistente al agua.\nDe la reconocida marca Pretty love llega a sexduceme Arnés Strapless Valerie, arnés sin correas el cual cuenta con 12 patrones de vibración que se sentirán en los dos extremos debido a sus  dos potentes motores, fabricado con silicona ABS muy suave al tacto, para todo tipo de parejas, cuenta con un solo botón del cual se enciende o apaga y cambia patrones de vibración.",
          detalles:
            "Características:\n12 Patrones de vibración\nEstimulador unisex         \nMaterial: silicona ABS\nCarga usb\nProveedor: Pretty Love\nColor purpura\n\nMedidas:\nLargo: 19 cm\nDiámetro: 3.3 cm\nPeso 350 gramos\n\nObservaciones:\nResistente al agua /no sumergible\n\nRecomendaciones\nLavar con agua tibia y jabón neutro antes y después de cada uso, dejar secar y guardar en un lugar fresco y seco.",
          instagram: "",
          precio: 89990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 583,
              nombre: "",
              url: "https://i.ibb.co/hYRhGm7/Pics-Art-02-09-02-33-49.jpg",
              descripcion: "",
            },
            {
              id: 584,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14754-large_default/arnes-strapless-valerie-.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 28,
              nombre: "Arnés",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 42,
          codigo: "772933000234",
          nombre: "Aceite para Masajes Sabor Chocolate-Naranja (30ml)",
          descripcion:
            "Descubre los Geles Comestibles Erotic, con efecto calor y 100% libres de azúcar, de exquisito aroma y sabor, entrega a tu pareja el mejor sexo oral de la vida y logra una noche inolvidable, se puede retirar fácilmente con agua tibia y jabón neutro. No dejes pasar más tiempo y prueba nuestra línea de Geles comestibles en diferentes sabores. \nStarsex te trae nuevos sabores para que disfrutes junto a quien más quieras de exquisitos juegos eróticos, masajes y un delicioso sexo oral lo que aumentará la excitación en ambos. ",
          detalles:
            "Características:\nSabor Chocolate Naranja\nGel comestible con efecto calor\nEfecto: efecto calor y comestible\nContenido: 30 ml\nIngredientes: Glicerina, propilengycol, Agua, Saborizante\n\nObservaciones:\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. También se puede aplicar en distintas zonas erógenas para un rico sexo oral. Después de aplicarlo debes soplar y tu pareja sentirá una agradable sensación de calor.\nEliminar el exceso con agua tibia y jabón neutro.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 9,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1325,
              nombre: "",
              url: "https://i.ibb.co/wQVzsYD/Pics-Art-08-14-01-17-13.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 271,
          codigo: "7798297917264",
          nombre: "Consolador Caiman Cyber Skin N°4",
          descripcion:
            "Amarás este Dildo Realístico  super suave y flexible , hipoalergénico con ventosa super adherente ideal para superficies lisas, Además posee una agradable aroma Dulce para incentivar el instinto  olfativo del Deseo, Totalmente flexible y manejable para dar rienda suelta a tu placer. Su diseño protuberante en la punta es ideal para estimular toda tu zona G y Zona P en caso de personas con Pene \n\nNo te pierdas de las mejores experiencias de este Dildo realístico diseñado para entregar el máximo placer ! disfruta tanto en pareja como en solitario.",
          detalles:
            "Características:\nVentosa ultra adherente\nCyber suave y flexible\nHipoalergénico\nLibre de ftalatos\nLargo total: 19 cm\nDiámetro: 5 cm\n\nInstrucciones de cuidado:\nLimpiar con agua tibia y jabón neutro.\nDejar secar y guardar\nMantener lejos del calor",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 637,
              nombre: "",
              url: "https://i.ibb.co/kXhYwX1/Pics-Art-02-28-08-25-27.jpg",
              descripcion: "",
            },
            {
              id: 638,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14426-large_default/consolador-realistico-suave-flexible.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 12,
              nombre: "Consoladores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 255,
          codigo: "825156108703",
          nombre: " Creative Kisses- Juego De Cartas",
          descripcion:
            "Creative Kisses llegó para hacer de los juegos previos, momentos más divertidos y cautivantes. Con 101 tarjetas podrás poner en práctica diversas y excitantes formas de besar. Sorprende a tu pareja con originales besos, solo debes escoger una tarjeta y ejecutar las instrucciones. Puedes turnarte con tu compañerx de manera que ambos puedan dar y recibir besos increíbles.\nSi quieres animar tu vida amorosa, selecciona una tarjeta y dale a tu amante un beso original",
          detalles:
            "Contenido:\n101 tarjetas en 4 idiomas; español, inglés, francés y alemán\n\n\nInstrucciones:\n\nEscoge una tarjeta del mazo y realiza la acción que en ella se indica.\nHaz turnos entre los participantes de modo que todos participen.",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 7,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 642,
              nombre: "",
              url: "https://i.ibb.co/18c9G0R/Pics-Art-02-28-08-13-43.jpg",
              descripcion: "",
            },
            {
              id: 643,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14393-large_default/creative-kisses.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 22,
              nombre: "Kits",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 245,
          codigo: "855559003480",
          nombre: "Potenciador de Erecciones Power Glide ",
          descripcion:
            "El gel excitante masculino On es un gel híbrido de alta efectividad, su fórmula creada con ingredientes totalmente efectivos y potentes, te provocarán aumento de la sensibilidad obteniendo mayor firmeza y máximo placer. Su efecto es inmediato! No lo pienses más y comienza a disfrutar de encuentros sexuales que jamás olvidarás. Formato sachet perfecto para llevar donde quieras!",
          detalles:
            "Características:\nExcitante masculino\nGel Híbrido\nGel de acción rápida\nContenido: 6 ml.\nLibre de Glicerina y Parabenos\nSolo para uso externo.\n\nIngredientes: Agua, Aleo Barbadensis(Aloe) Leaf Juice, Butea Superba, Vitamina E(Alpha Tocopherols), Niacinimide,Hydroxyethylcellulose, Menthyl Salicylate, Polyacrylate 13, Polyisobutene, Polysorbate 20, Gluconolactone, Sodium Benzoate, Menthyl Nicontinate, Natural Scents, Cinnamomum Zeylanuicum, Honeysuckle Extract.\n\nModo de uso: Aplicar Gel Power Glide On para el masajeando sobre el pene, repetir si es necesario. \n\nImportante: Antes de utilizar cualquier producto de cosmética erótica, se recomienda revisar componentes de este para evitar cualquier reacción alérgica. En caso de irritación suspender su uso y consultar a un dermatólogo. Hecho en EE.UU.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 525,
              nombre: "",
              url: "https://i.ibb.co/TcTH5LY/Pics-Art-02-27-01-48-53.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 9,
              nombre: "Potenciadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 17,
          codigo: "7898450818602",
          nombre: "Gel Estimulante Indiano",
          descripcion:
            "Sólo pon un poco en tu clítoris y frota suavemente hasta que se absorba, deja actuar por unos 5min, y luego a gozar!",
          detalles: "Formato: Tubo 8g",
          instagram: "",
          precio: 5990,
          descuento: 14.0,
          stock: 7,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 323,
              nombre: "",
              url: "https://i.ibb.co/xHKnKt6/1608164511555.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 201,
          codigo: "6914421000225",
          nombre: "Colalles Siempre Lista de Encaje",
          descripcion:
            'Anímate a utilizar este hermosa y sugerente prenda para una noche de pasión.\nEstas prendas tienen una apertura en la parte baja que te permitirá estar "Siempre Lista" ante cualquier situación pasional... 😉',
          detalles:
            "DISPONIBLE \nVERDE EN TALLA M - L - XL\n\nOBSERVACIÓN:\nSon tallas pequeñas, por lo que se recomienda solicitar dos talla más de la habitual, por ejemplo, si sueles usar Talla M, prefiere un XL, o al menos un L.",
          instagram: "",
          precio: 3990,
          descuento: 28.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 354,
              nombre: "",
              url: "https://i.ibb.co/x5QGGM3/Pics-Art-01-18-11-24-20.jpg",
              descripcion: "",
            },
            {
              id: 355,
              nombre: "",
              url: "https://i.ibb.co/rGv8C5r/Pics-Art-01-18-11-28-23.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 472,
          codigo: "7729300000048",
          nombre: "Aceite para Masajes sabor Chocolate (50ml)",
          descripcion:
            "Descubre los Geles Comestibles Erotic, con efecto calor y 100% libres de azúcar, de exquisito aroma y sabor, entrega a tu pareja el mejor sexo oral de la vida y logra una noche inolvidable, se puede retirar fácilmente con agua tibia y jabón neutro. No dejes pasar más tiempo y prueba nuestra línea de Geles comestibles en diferentes sabores. \nStarsex te trae nuevos sabores para que disfrutes junto a quien más quieras de exquisitos juegos eróticos, masajes y un delicioso sexo oral lo que aumentará la excitación en ambos. ",
          detalles:
            "Características:\nSabor Chocolate\nGel comestible con efecto calor\nEfecto: efecto calor y comestible\nContenido: 50 ml\nIngredientes: Glicerina, propilengycol, Agua, Saborizante\n\nObservaciones:\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. También se puede aplicar en distintas zonas erógenas para un rico sexo oral. Después de aplicarlo debes soplar y tu pareja sentirá una agradable sensación de calor.\nEliminar el exceso con agua tibia y jabón neutro.",
          instagram: "",
          precio: 7990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1322,
              nombre: "",
              url: "https://i.ibb.co/x5wSrNJ/Pics-Art-08-14-01-18-24.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 10,
          codigo: "7809604500076",
          nombre: "Preservativo SensorPlus Anatómico",
          descripcion:
            "Condón transparente de látex natural lubricado con aceite de silicona, tiene una forma anatómica ",
          detalles: "- Dimensiones: 180mm x 52mm\r\n- Grosor: 0.075mm",
          instagram: "",
          precio: 1990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1030,
              nombre: "",
              url: "https://i.ibb.co/4K5CHsL/Pics-Art-07-07-02-25-22.jpg",
              descripcion: "",
            },
            {
              id: 1031,
              nombre: "",
              url: "https://i.ibb.co/vs1Rqc7/Pics-Art-02-28-08-15-38.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 238,
          codigo: "6959532317619",
          nombre: "Plug Anal Special Stimulation Pettit",
          descripcion:
            'El Plug Special Anal Stimulation Pettit es un nuevo plug que tiene como atributo especial un "peso interior", que a través del movimiento entrega más placer con su tintinear interno. De diseño simple y color elegante, es fácil de limpiar ya que es resistente al agua y su material de silicona permite ser usado con lubricantes en base a agua o híbridos.',
          detalles:
            "Características\nMaterial Silicona\nColor Negro\nPlug con peso interior\nMarca: Pretty Love\n\nMedidas\nLargo Total: 8,5cm\nLargo insertable: 7,5cm\nDiámetro insertable: 3,2cm\n\nObservaciones\nDiseño Ergonómico\nPlug con peso interior\nResistente al agua / Sumergible\nApto para Lubricantes base a agua o híbridos",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 526,
              nombre: "",
              url: "https://i.ibb.co/C1XYPtd/Pics-Art-02-09-02-37-20.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 321,
          codigo: "6959532312119",
          nombre: "Vibrador Pequeño Beck",
          descripcion:
            "Pequeño y versátil, este vibrador te permitirá explorar nuevas zonas de placer. Su forma levemente curvada en la punta te ayudará a estimular de manera más precisa la zona del punto G.",
          detalles:
            "Especificaciones\nMaterial: silicona libre de ftalatos. \nLongitud total: 12 cms.\nDiámetro: 2 cms. \nModo de Carga: 1 pila AAA (no incluida).\nModos: 10 intensidades. \nResistente a salpicaduras. \n\nIMPORTANTE: No retires el pequeño cartón que viene al interior del juguete, donde van ubicadas las pilas. Es una parte del producto. Si lo sacas, el juguete no funcionará correctamente. ¡No lo olvides! ",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 888,
              nombre: "",
              url: "https://i.ibb.co/W3Gp1dX/Pics-Art-05-16-02-38-14.jpg",
              descripcion: "",
            },
            {
              id: 889,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/2015146/thumb/540/540?1617721994",
              descripcion: "",
            },
            {
              id: 890,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/2012022/thumb/540/540?1617721994",
              descripcion: "",
            },
            {
              id: 891,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/2012023/thumb/540/540?1617721994",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 404,
          codigo: "6900020200944",
          nombre: "Fusta Pequeña (30cm)",
          descripcion:
            "No puedes hablar sobre BDSM sin pensar en las fustas, es un implemento prácticamente obligatorio en cualquier calabozo sexual, o para cualquier pareja que quiera experimentar en el mundo de l@s am@s y sumis@s.\nEsta fusta es cómoda y te permitirá controlar fácilmente el nivel de fuerza que desea aplicar, o la delicadeza con la que deseas acariciar.\nSexdúcete y anímate a probar nuevas experiencias y deja volar tu imaginación cumpliendo tus fantasías.",
          detalles: "Color: Negro\nTamaño: 30cm.\nMaterial: Ecocuero.",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1105,
              nombre: "",
              url: "https://i.ibb.co/86kLwMN/Pics-Art-07-07-02-08-13.jpg",
              descripcion: "",
            },
            {
              id: 1106,
              nombre: "",
              url: "https://i.ibb.co/30b9mzh/Pics-Art-07-07-02-02-30.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 32,
              nombre: "BDSM",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 415,
          codigo: "6652223540029",
          nombre: "Lubricantes con Efecto Sexlub Variedades (10ml)",
          descripcion:
            "Variedad de lubricantes Anal, Retardante y afrodisiaco en formato 10ml (Valor por unidad)",
          detalles:
            "Línea de lubricantes con efectos a base de agua en presentación de 10 ml.\n\nVariedades:\n\nAfrodisíaco, genera un ligero calor en las zonas erógenas para generar mayor sensibilidad.\n\nRetardante, prolonga la erección sin perder toda la sensibilidad, logrando relaciones íntimas más prolongadas y placenteras.",
          instagram: "",
          precio: 2990,
          descuento: 14.0,
          stock: 15,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1301,
              nombre: "",
              url: "https://i.ibb.co/tBWXDnn/Pics-Art-08-17-02-20-29.jpg",
              descripcion: "",
            },
            {
              id: 1302,
              nombre: "",
              url: "https://i.ibb.co/6vXDRBm/Pics-Art-08-17-02-20-56.jpg",
              descripcion: "",
            },
            {
              id: 1438,
              nombre: "",
              url: "https://i.ibb.co/QkpVpg8/Pics-Art-08-14-01-09-21.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 483,
          codigo: "7730963250275",
          nombre: "Preservativo Prudence Frutilla",
          descripcion:
            "Preservativos Lubricados de Látex con aroma y sabor a Frutilla, contiene 3 unidades.",
          detalles:
            "Prudence con sabor y aroma a Frutilla, preservativo de color rojo lubricado, resistente y probado electrónicamente. Contiene 3 unidades \n\nCaracterísticas:\n\nComposición: Látex natural, silicona, almidón de maiz.\nProveedor: Prudence\nContiene 3 unidades\nColor: Rojo\nAroma: Frutilla\nSabor:  Frutilla\nLargo: 160 mm\nAncho Nominal: 52 mm \nEspesor: 0.063 mm aprox.",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1247,
              nombre: "",
              url: "https://i.ibb.co/3y1RL1H/Pics-Art-08-14-01-29-42.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 232,
          codigo: "6959532313475",
          nombre: "Bala Vibradora Dana",
          descripcion:
            "Bala Vibradora Curva Dana es un estimulador unisex el cual utiliza una pila AAA, de material siliconado y tiene 10 patrones de vibración.\nTenemos para ti un excelente producto ergonómico y discreto ideal para llevar en nuestros bolsillos, se trata de la Bala Vibradora Dana magnifico producto que ha sido diseñado especialmente para estimular clítoris, punto G y punto P. Su material de silicona muy suave que cuida tu piel  está libre de ftalatos, de fácil modo de uso y cuenta con un único botón del cual se apaga, prende y se activan los patrones de vibración.",
          detalles:
            "Características:\n10 Patrones de vibración\nMaterial: silicona Libre de ftalatos\nBatería: 1 pila AAA\nProveedor: Pretty Love\nColor purpura\n\nMedidas:\nDiámetro: 2,2 cm\nLargo: 10,6 cm\nPeso 40 gramos\n\nObservaciones:\nBala vibradora unisex\nResistente al agua (no sumergible)\nApto para lubricantes a base de agua o híbridos\n\nModo de uso:\nPresionar el botón que está ubicado en la parte inferior del juguete de ahí podrás encenderlo, apagarlo y cambiar intensidades de vibración.\n\nRecomendaciones\nLimpiar antes y después del uso, para encender mantener presionado y luego elegir la función.",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 7,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 578,
              nombre: "",
              url: "https://i.ibb.co/9v3XknM/Pics-Art-02-09-02-38-42.jpg",
              descripcion: "",
            },
            {
              id: 579,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14729-large_default/bala-vibradora-curva-dana.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 101,
          codigo: "3340010008076",
          nombre: "Vibrador Plug Anal Con Ventosa",
          descripcion:
            "Este vibrador Posee Vibracion para ayudar a dilatador y estimular el ano, pudiendo jugar con su forma conica para conseguir la experiencia que siempre deseaste . Con el control de mando puede elegir la vibracion que mas te deleite . Además, esta maravilla de juguetito trae una base de Ventosa  para adherir a superficie lisa y asi pode usarlo a solas o en compañía",
          detalles:
            "Características:\n\nMaterial: Silicona\nColor: Negro\nBaterías: 2-AA No incluidas\nLargo total: 11 cm\nPosee Ventosa\nDiámetro: 3 cm\nObservaciones:\n\nLavar con agua tibia y jabon neutro antes y despues de cada uso\nUsar con lubricante en base de agua o Hibridos",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 124,
              nombre: "",
              url: "https://i.ibb.co/vV7PT8J/IMG-20201108-151943.jpg",
              descripcion: "",
            },
            {
              id: 125,
              nombre: "",
              url: "https://i.ibb.co/SJNxDF7/IMG-20201108-152020.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 215,
          codigo: "7765432120032",
          nombre: "Vagina Realística",
          descripcion:
            "Masturbador  Realístico de CYBERSKIN.  Sus diseños internos proporcionan una sensación totalmente placentera  , sus protuberancias internas están diseñadas para estimular cada espacio de tu cuerpo. fabricado de silicona Cyberskin de color beige, un juguete erótico para la auto estimulación o para el juego sexual previo. Su diseño es de forma y textura realística,  puedes estirarlo o usarlo corto solo para estimular Glande o parte del pene",
          detalles:
            "Características:\n\nMaterial: Cyberskin\nColor: beige \nLargo Total: 12 cm \nLargo insertable: 11 cm\nDiámetro de su circunferencia : 18 cm sin el acrilico \nObservaciones:\n\nMaterial realístico Hipoalergénico\nLimpieza simple (agua tibia y jabón neutro)\nDejarlo secar y colocar talco para absorver la humedad y devolverle la suavidad\nApto para Lubricantes base a agua o híbridos",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 10,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 516,
              nombre: "",
              url: "https://i.ibb.co/dKp63cZ/Pics-Art-02-08-03-53-42.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 16,
              nombre: "Masturbadores Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 368,
          codigo: "825156102510",
          nombre: "Juego ¡SEXO!",
          descripcion:
            "Un alocado y romántico juego de cartas para dos\n\nCada carta de éste juego explícitamente ilustra una actividad de juego previo o una posición erótica. El color representa el voltaje e intensidad de la actividad",
          detalles: "",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1058,
              nombre: "",
              url: "https://i.ibb.co/jTF36V8/Pics-Art-07-07-01-06-17.jpg",
              descripcion: "",
            },
            {
              id: 1059,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/7055080/thumb/540/540?1623094863",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 40,
          codigo: "7765432100126",
          nombre: "Aceite para Masajes Sabor Frutilla (30ml)",
          descripcion:
            "Descubre los Geles Comestibles Erotic, con efecto calor y 100% libres de azúcar, de exquisito aroma y sabor, entrega a tu pareja el mejor sexo oral de la vida y logra una noche inolvidable, se puede retirar fácilmente con agua tibia y jabón neutro. No dejes pasar más tiempo y prueba nuestra línea de Geles comestibles en diferentes sabores. \nStarsex te trae nuevos sabores para que disfrutes junto a quien más quieras de exquisitos juegos eróticos, masajes y un delicioso sexo oral lo que aumentará la excitación en ambos. ",
          detalles:
            "Características:\nSabor Frutilla\nGel comestible con efecto calor\nEfecto: efecto calor y comestible\nContenido: 30 ml\nIngredientes: Glicerina, propilengycol, Agua, Saborizante\n\nObservaciones:\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. También se puede aplicar en distintas zonas erógenas para un rico sexo oral. Después de aplicarlo debes soplar y tu pareja sentirá una agradable sensación de calor.\nEliminar el exceso con agua tibia y jabón neutro.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 8,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 334,
              nombre: "",
              url: "https://i.ibb.co/3NVHcRG/1609808561509.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 275,
          codigo: "4560220550656",
          nombre: "Masturbador Masculino Tenga Eggs Stepper",
          descripcion:
            "La ciencia ha confirmado que la masturbación es saludable para la salud. De hecho, está comprobado que fortalece el sistema inmunológico, mejora el humor, mejora la calidad de la piel, potencia el autoestima, previene ciertas enfermedades, fortalece la musculatura pélvica, entre muchos otros beneficios. Entendiendo esto es que se hace necesario hacer de la masturbación una experiencia entretenida. Y para esto está el huevo masturbador Tenga que tiene la ventaja de venir con un lubricante íntimo que tiene como misión suavizar y mejorar la experiencia con el producto hecho exclusivamente para hombres. Se trata de un producto que ha sido testeado dermatológicamente y que es hipoalergénico, por lo que se puede usar con total confianza ya que no representa ningún riesgo para la salud íntima. Es apto para todos los tamaños y su material es súper elástico y muy suave al tacto, por lo que es cómodo y práctico de usar al momento de la masturbación. Viene en colores y el lubricante que incluye está en una presentación tipo sachet que se encuentra en su interior. El uso de este producto es fundamental para todos los juguetes sexuales, ya que de esta forma se evita cualquier tipo de roce molesto. Por otro lado, es aconsejable no usar más de dos veces el producto para resguardar tu higiene. Este huevo masturbador puede ser usado por el hombre en solitario, pero también queda la invitación abierta a que lo pueda ocupar con su pareja, como una forma de innovar en el sexo, para agregarle sorpresa o entretención al sexo. Otra de las ventajas de este masturbador es su pequeño tamaño, ya que lo hace muy fácil de manipular y también de transportar a donde quiera que vayas.",
          detalles:
            "Características:\nHuevo Masturbador\nMasturbador masculino\nSúper Elasticados\nReutilizable (lavar con agua y jabón)\nHipoalergénico\nIncluye Lubricante Tenga\nSe acomoda a todos los tamaños\nMaterial Súper suave y flexible\nRelieves en el interior con forma de triángulo\n\nObservaciones:\nLa higiene del producto es primordial, por lo que se aconseja lavarlo antes de usarlo y después de utilizarlo, ya que de este modo, se estará evitando cualquier riesgo de infección. Para esto, se puede usar agua tibia y jabón neutro o también se puede emplear limpiadores especiales para los juguetes sexuales que están disponibles en el mercado.",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 611,
              nombre: "",
              url: "https://i.ibb.co/93JTBy1/Pics-Art-02-27-01-41-41.jpg",
              descripcion: "",
            },
            {
              id: 612,
              nombre: "",
              url: "https://i.ibb.co/09ckzpq/Pics-Art-02-27-01-39-16.jpg",
              descripcion: "",
            },
            {
              id: 613,
              nombre: "",
              url: "https://jvsportswear.net/wp-content/uploads/2020/08/Tenga-eggs-6.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 16,
              nombre: "Masturbadores Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 257,
          codigo: "855559003381",
          nombre: "Aceite Excitante ON HEMP",
          descripcion:
            "El nuevo Aceite Excitante ON HEMP tiene como ingrediente principal el Aceite de semilla Cáñamo, el cual en conjunto con la mezcla de otros Extractos y Aceites esenciales lo hacen un producto libre de Parabenos, Gluten, Glicerina, Cruelty Free y Vegano. Al aplicarse ON HEMP directo en el clítoris hará que la excitación de la mujer incremente drásticamente, dando sensación de pulsaciones, vibración y hormigueo.",
          detalles:
            "CARACTERÍSTICAS\nContenido 5ml\nOlor suave y placentero\nEfecto Hormigueo\nPulsa y Vibra\nLibre de Glicerina - Parabeno - Gluten\nCruelty Free - Vegano\n\nINGREDIENTES\nPrioritariamente mezcla de Aceites esenciales de: Cannabis Sativa, Aceite de Semilla de Almendra dulce, Aceite de Pimienta, Canela, Vitamina E, Silica, Sabores Naturales, Extracto de Romero.\n\nMODO DE USO\nAgite bien y aplique de 1 a 2 gotas en el clítoris. Deje actuar por 2 minutos para ver los efectos. Duración aprox. de 45 minutos. aplicar más si se desea.",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 780,
              nombre: "",
              url: "https://i.ibb.co/Wnf5RQx/Pics-Art-03-21-12-29-08.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 420,
          codigo: "6959532319972",
          nombre: "Bala Vibradora Delfín Fucsia",
          descripcion:
            "Bala Vibradora estimuladora de zonas erógenas de 10 patrones de vibración, Utiliza 1 pila AAA",
          detalles:
            "La Balita Vibradora Delfín es una excelente alternativa para personas principiantes en el mundo de la juguetería ya que es un fantástico estimulador externo de zonas erógenas. Ideal para estimular pezones o clítoris gracias al diseño de la primera porción que permitirá la estimulación directa. \nFabricado de suave silicona, requiere tan solo de 1 pila AAA para entregar placenteros estímulos mediante sus 10 funciones de vibración.\n\nCaracterísticas\nBalita vibradora, estimulador externo\nMaterial: Silicona\nColor: Rosado\n10 funciones de vibración\n\nMedidas\nLargo Total: 14.5 cm\nDiámetro mayor: 2.9 cm\n\nObservaciones\nLavar con agua tibia y jabón neutro antes y después de cada uso, dejar secar y guardar en un lugar fresco y seco.\nResistente al agua, NO SUMERGIBLE\nNo incluye pila",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1132,
              nombre: "",
              url: "https://sexshopmayorista.cl/15189-large_default/bala-vibradora-edward.jpg",
              descripcion: "",
            },
            {
              id: 1133,
              nombre: "",
              url: "https://sexshopmayorista.cl/15188-large_default/bala-vibradora-edward.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 348,
          codigo: "716222215800",
          nombre: "Lubricante Comestible Sabor Chocolate Chip Cookie",
          descripcion:
            "El lubricante Wet Dessert Warming Chocolate Chip Cookie, de formato totalmente transportable, perfecto para la cartera o bolso, se destaca por su sabor a Galleta con chips de chocolate y su efecto calor, perfecto para el sexo oral en el juego previo",
          detalles:
            "Características:\nLubricante íntimo\nLeve efecto calor\nContenido: 30 ml\nSabor: Galleta con Chip de Chocolate\nSin Azúcar\nSin Sabor amargo\nCompatible con Latex\nDe fácil limpieza\nInodoro\nIncoloro",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 11,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1054,
              nombre: "",
              url: "https://i.ibb.co/s2cp9tN/Pics-Art-07-07-01-03-36.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 45,
          codigo: "772933000227",
          nombre: "Aceite para Masajes Sabor Mango (30ml)",
          descripcion:
            "Descubre los Geles Comestibles Erotic, con efecto calor y 100% libres de azúcar, de exquisito aroma y sabor, entrega a tu pareja el mejor sexo oral de la vida y logra una noche inolvidable, se puede retirar fácilmente con agua tibia y jabón neutro. No dejes pasar más tiempo y prueba nuestra línea de Geles comestibles en diferentes sabores. \nStarsex te trae nuevos sabores para que disfrutes junto a quien más quieras de exquisitos juegos eróticos, masajes y un delicioso sexo oral lo que aumentará la excitación en ambos. ",
          detalles:
            "Características:\nSabor Mango\nGel comestible con efecto calor\nEfecto: efecto calor y comestible\nContenido: 30 ml\nIngredientes: Glicerina, propilengycol, Agua, Saborizante\n\nObservaciones:\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. También se puede aplicar en distintas zonas erógenas para un rico sexo oral. Después de aplicarlo debes soplar y tu pareja sentirá una agradable sensación de calor.\nEliminar el exceso con agua tibia y jabón neutro.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 605,
              nombre: "",
              url: "https://i.ibb.co/DLGq3xm/Pics-Art-02-27-02-11-57.jpg",
              descripcion: "",
            },
            {
              id: 606,
              nombre: "",
              url: "https://i.ibb.co/Gkv0xdm/Aceites-para-Masaje.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 447,
          codigo: "6959532325928",
          nombre: "Huevo Masturbador Attractive",
          descripcion:
            "Suave y Flexible Huevo Masturbador con textura interna y externa (intercambiable) ",
          detalles:
            "Huevo masturbador completamente texturizado para mayor estimulación, posee doble cara texturizada. Su suave y flexible material entrega máximo confort a la hora de la masturbación, es un producto ideal para personas con pene ya que al introducir el pene en la abertura, se podrá deslizar el huevo hasta su base para una estimulación completa y en vulvas gracias a sus increíbles protuberancias podrás estimular toda la zona imitando una estimulación manual o frotación.\nPara mejorar la experiencia, incluye siempre lubricante a base de agua o híbrido para su uso.\n\nCaracterísticas\nMaterial: TPR\nColor: Celeste\n\nMedidas\nLargo total: 8.6 cm (Flexible, aumenta su largo al estirar)\nAncho total: 4.6 cm\n\nObservaciones\nSuave y Flexible\nUtilizar con lubricantes a base de agua o híbrido.  \nLavar con agua tibia y jabón neutro antes y después de cada uso, dejar secar y guardar en un lugar fresco y seco.",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1308,
              nombre: "",
              url: "https://i.ibb.co/yF03R71/Pics-Art-08-14-01-22-30.jpg",
              descripcion: "",
            },
            {
              id: 1309,
              nombre: "",
              url: "https://sexshopmayorista.cl/15183-large_default/huevo-masturbador-pretty-love.jpg",
              descripcion: "",
            },
            {
              id: 1310,
              nombre: "",
              url: "https://sexshopmayorista.cl/15182-large_default/huevo-masturbador-pretty-love.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 16,
              nombre: "Masturbadores Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 222,
          codigo: "6914421000232",
          nombre: "Plug Anal Metalizado Con Cola Blanca",
          descripcion:
            "Plug anal cola blanca, el plug de metal posee una cola larga en tono blanco, de diseño novedoso y elegante, perfecto para quienes se quieren iniciar en una experiencia anal gracias al tamaño del dilatador y su forma cónica. El plug anal de metal ofrece una estimulación placentera y segura. De muy fácil limpieza.",
          detalles:
            "Características:\nPlug anal dilatador\nDilatación anal\nEstimulación anal\nColor metal: Plateado\nColor Cola: Blanco\n\nMedidas:\nLargo plug: 7 cm\nDiámetro mayor: 3 cm\nDiámetro menor: 1 cm\nLargo cola: 73 cm\nMaterial cola: Polyester\n\nObservaciones: Lavar antes y después de usar. Utilizar con lubricante, perfecto para principiantes. De fácil limpieza.",
          instagram: "",
          precio: 32990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 530,
              nombre: "",
              url: "https://i.ibb.co/7J0nh3L/Pics-Art-02-08-03-17-35.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 264,
          codigo: "725272730706",
          nombre: "Consolador Realístico Macizo XL N°14 Negro",
          descripcion:
            "La línea de consoladores realísticos nos presenta el dildo Macizo XL N°14 negro. Su principal característica es que contiene testículos y es de un tamaño XL.",
          detalles:
            "Características:\nMaterial: Silicona\nLargo Total: 28 cm\nDiámetro: 6.5 cm\nColor: Negro\n\nObservaciones:\nConsolador con testículos y sin sopapo\nConsolador de Grandes Dimensiones",
          instagram: "",
          precio: 49990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 571,
              nombre: "",
              url: "https://i.ibb.co/QFyF0xs/Pics-Art-02-27-01-52-55.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 12,
              nombre: "Consoladores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 54,
          codigo: "7898626040622",
          nombre: "Estimulante Femenino Taradinha Striper",
          descripcion:
            "Comienza ya a disfrutar de orgasmos potentes e inolvidables, solo debes aplicar unas pequeñas porciones en el clítoris masajeando y comenzarás a sentir su poder! \nEl Gel Excitante Femenino Taradinha Striper, potencia la excitación ya que, favorece a la irrigación sanguínea en los genitales femeninos. Ideal para mujeres que deseen experimentar nuevas sensaciones en los encuentros sexuales aumentando la sensación de placer.",
          detalles:
            "Ingredientes: Carbomer, Edta, Trietanolamina, Aceite de Flor, Hidroxiethylcelulose.\n\nCaracterísticas:\nContenido: 8g\nEstimulante femenino\nExcitante femenino\nPotenciador de orgasmos\nMantener en lugar fresco\nEvitar contacto con los ojos\nSolo de uso externo\nNo ingerir\n\nModo de aplicación:\nAplicar en el clítoris una o dos porciones del gel excitante femenino Striper y masajear hasta que se absorba.",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 748,
              nombre: "",
              url: "https://i.ibb.co/23jfYM0/Pics-Art-03-21-12-31-32.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 393,
          codigo: "7798297912986",
          nombre: "Vibrador Tornado Gummy",
          descripcion:
            "Dildo de material flexible e hipoalergénico con vibración super speed. El formato escalonado del dildo facilita la inserción y permite disfrutar de una penetración progresiva. Es el momento de vivir un tornado de placer. Se recomienda el uso de lubricantes",
          detalles:
            "Tipo: Dildo con Vibro.\nMedida: 19 cm x 4 Ø.\nPilas: 2 Pilas AA (no incluidas).\nPhthalate Free / Libre de Ftalato.",
          instagram: "",
          precio: 23990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1003,
              nombre: "",
              url: "https://i.ibb.co/02W5rLy/Pics-Art-07-07-02-17-23.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 446,
          codigo: "605632611046",
          nombre: "Pack Anillo Vibrador - Mini Bala Fantasy",
          descripcion:
            "Anillo Vibrador para pene de material Jelly, flexible y fácil de usar, incluye Bala Vibradora .",
          detalles:
            "El set Fantasy Lovers consta de un anillo con una pequeña bala vibradora de 1 velocidad, su material Jelly entrega flexibilidad  y adaptabilidad a varios tamaños. Incluye bala vibradora .\n\nCaracterísticas:\nAnillo erector de pene con Vibración + Bala Vibradora\nMaterial Anillo : Jelly\nColor: Azul\nMedidas:\nDiámetro menor : 2 cm\nBala interna:\nLargo: 3,5 cm\nDiámetro: 2,5 cm\n(Batería incluida)\n1 Velocidad.\n\nBala adicional:\nMaterial:PVC con recubierta de silicona suave\nLargo: 5 cm\nDiámetro: 2 cm.\n1 Velocidad.\nBaterías incluidas",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 6,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1177,
              nombre: "",
              url: "https://sexshopmayorista.cl/11311-large_default/fantasy-lovers-pack-cockring-and-bullet-purple.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 276,
          codigo: "4560220550663",
          nombre: "Masturbador Masculino Tenga Eggs Silky",
          descripcion:
            "La ciencia ha confirmado que la masturbación es saludable para la salud. De hecho, está comprobado que fortalece el sistema inmunológico, mejora el humor, mejora la calidad de la piel, potencia el autoestima, previene ciertas enfermedades, fortalece la musculatura pélvica, entre muchos otros beneficios. Entendiendo esto es que se hace necesario hacer de la masturbación una experiencia entretenida. Y para esto está el huevo masturbador Tenga que tiene la ventaja de venir con un lubricante íntimo que tiene como misión suavizar y mejorar la experiencia con el producto hecho exclusivamente para hombres. Se trata de un producto que ha sido testeado dermatológicamente y que es hipoalergénico, por lo que se puede usar con total confianza ya que no representa ningún riesgo para la salud íntima. Es apto para todos los tamaños y su material es súper elástico y muy suave al tacto, por lo que es cómodo y práctico de usar al momento de la masturbación. Viene en colores y el lubricante que incluye está en una presentación tipo sachet que se encuentra en su interior. El uso de este producto es fundamental para todos los juguetes sexuales, ya que de esta forma se evita cualquier tipo de roce molesto. Por otro lado, es aconsejable no usar más de dos veces el producto para resguardar tu higiene. Este huevo masturbador puede ser usado por el hombre en solitario, pero también queda la invitación abierta a que lo pueda ocupar con su pareja, como una forma de innovar en el sexo, para agregarle sorpresa o entretención al sexo. Otra de las ventajas de este masturbador es su pequeño tamaño, ya que lo hace muy fácil de manipular y también de transportar a donde quiera que vayas.",
          detalles:
            "Características:\nHuevo Masturbador\nMasturbador masculino\nSúper Elasticados\nReutilizable (lavar con agua y jabón)\nHipoalergénico\nIncluye Lubricante Tenga\nSe acomoda a todos los tamaños\nMaterial Súper suave y flexible\nRelieves en el interior con forma de líneas horizontales\n\nObservaciones:\nLa higiene del producto es primordial, por lo que se aconseja lavarlo antes de usarlo y después de utilizarlo, ya que de este modo, se estará evitando cualquier riesgo de infección. Para esto, se puede usar agua tibia y jabón neutro o también se puede emplear limpiadores especiales para los juguetes sexuales que están disponibles en el mercado.",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 614,
              nombre: "",
              url: "https://i.ibb.co/6mLYzcD/Pics-Art-02-27-01-41-03.jpg",
              descripcion: "",
            },
            {
              id: 615,
              nombre: "",
              url: "https://i.ibb.co/09ckzpq/Pics-Art-02-27-01-39-16.jpg",
              descripcion: "",
            },
            {
              id: 616,
              nombre: "",
              url: "https://jvsportswear.net/wp-content/uploads/2020/08/Tenga-eggs-6.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 16,
              nombre: "Masturbadores Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 75,
          codigo: "7898626040035",
          nombre: "Retardante Masculino Spray On Intt",
          descripcion:
            "El Spray retardante On de la marca INTT, nos presenta un spray retardante de la eyaculación, prolonga el placer por mas tiempo ya que desensibiliza el orgasmo para durar mas, se aplica directo en el pene y en los testículos, pruébalo y mejora lo que ya es muy bueno\nEl Retardante Masculino Spray On de la reconocida marca Innt, la cual desensibiliza y prolonga la erección dando como resultado encuentros sexuales más satisfactorios y duraderos. Formulada a base de hierbas naturales.",
          detalles:
            "Contenido: 15ml\nObservaciones:\nRetarda la eyaculación\nDesensibilizador\nDosificador tipo aerosol\nMantener en lugar fresco\nEvitar contacto con los ojos\nModo de aplicación:\nAplicar dos o tres porciones sobre el pene o testículos, masajear hasta que se absorba.\n",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 11,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 307,
              nombre: "",
              url: "https://i.ibb.co/JdrJws1/1609808677904.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 9,
              nombre: "Potenciadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 487,
          codigo: "716222350013",
          nombre: "Lubricante Anal Uranus 30ml",
          descripcion:
            "Lubricante anal a base de silicona, de textura suave. Formato de 30ml.",
          detalles:
            "Wet nos presenta su línea Uranus, especialmente creada para practicar sexo anal, su textura y consistencia gruesa te darán mayor placer para explorar esa área.\nEl lubricante Wet Uranus anal está fabricado a base de silicona Premium de alta calidad, libre de látex y textura sedosa que perdurar más tiempo. Además su composición permite que sea usado bajo en agua.\n\nIngredientes: Dimeticona, Ciclopentasiloxano, Dimeticonol, Fenil Trimeticona.\n\nContenido: 30ml\n\nObservaciones:\nLubricante anal a base de silicona\nInodoro e incoloro\nNo mancha\nTextura suave\nConsistencia gruesa\nLibre de parabenos\n\nFabricado en U.S.A\nProducto aprobado por la FDA",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1274,
              nombre: "",
              url: "https://i.ibb.co/M7dx5mj/Pics-Art-08-14-01-26-52.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 152,
          codigo: "7898450818640",
          nombre: "Estimulante Chino Unisex",
          descripcion:
            "Gel Chines Unisex, para que potencies al máximo tu placer.\r\nHecho de extractos de JENJIBRE un riquisimo vasodilatador natural. Solo coloca una pequeña cantidad en la punta de tus dedos y aplicalo en Crlitoris, entrada de canal vaginal o en Penes!\r\nDisfruta de estas nuevas experiencias como nunca antes!",
          detalles:
            "Ingredientes:Agua, alcohol cetearílico, ceteareth-20, Dissodium EDTA BHT Methyidibromo Glutaronitrite (y) Phenoxyenone, triglicérido caprílico / cáprico, Zingiber officinalis extracto, sabor, CI15645, Parfum.\r\n\r\nCaracterísticas:\r\nContenido: 8g\r\nEfecto: Excitante\r\n\r\nObservaciones:\r\n\r\nPersonas alergicas al JENJIBRE no deben usar este producto \r\nModo de aplicación:\r\nColocar la cantidad deseada en la punta de los dedos y aplicar. Dejar en el refrigerador para potenciar el efecto frío del gel excitante.",
          instagram: "https://www.instagram.com/sexduceme.cl",
          precio: 5990,
          descuento: 14.0,
          stock: 9,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 246,
              nombre: "",
              url: "https://i.ibb.co/ZGXwnVH/1608164511616.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 48,
          codigo: "7898943080332",
          nombre: "Aceite para Masajes Oral Sex Sabor Chocolate",
          descripcion:
            "Ten una experiencia de masaje única con el aceite corporal Oral Sex de Intt. Disfruta de exquisitos y eróticos masajes en las zonas más erógenas de tu cuerpo acompañado de placenteras sensaciones de leve efecto calor.\nEl aceite corporal es comestible perfecto para practicar un rico sexo oral. Este aceite te permite disfrutar de los masajes en pareja y ayuda a que terminen en un placentero juego sexual con un delicioso sabor.\nSiente el tacto, calor y erotismo en tu piel",
          detalles:
            "Aceite de masajes sabor Chocolate\nContenido: 40 ml.\nEfecto: Leve efecto calor. \n\nObservaciones: Aplicar sobre las zonas erógenas y masajear con las manos o lamiendo las partes deseadas.\nSi desea intensificar el efecto se recomienda soplar sobre la zona.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 9,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 223,
              nombre: "",
              url: "https://i.ibb.co/RD9KXwv/IMG-20200925-230931.jpg",
              descripcion: "",
            },
            {
              id: 224,
              nombre: "",
              url: "https://i.ibb.co/Gkv0xdm/Aceites-para-Masaje.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 195,
          codigo: "777010044019",
          nombre: "Lubricante Virgin (30ml)",
          descripcion:
            "Virgin ¡Como la primera Vez!\nEs un lubricante íntimo que mejora la tonicidad muscular vaginal y anal. Al aplicar en la zona vaginal entrega una excelente sensación y contrae la mucosagenerando que se  eleven  las sensaciones, lo que permite disfrutar mucho mas Toda la Zona G ",
          detalles:
            "Ingredientes: Pure water, Silicone Oil, Glycerol, Hydroxyethyl cellulose, Alum & Gallnut, Others.\nContenido: 30 ml.\n\nModo de Uso:\nAplique la cantidad suficiente de lubricante Virgin frotando suavemente tanto en la zona vaginal interna y externa previamente a las relaciones sexuales.\n\nCaracterísticas:\n- Incoloro\n- Sin olor\n- No mancha\n- No es graso\n- Totalmente compatible con el uso de preservativos y juguetes eróticos.\n\nImportante: Los resultados dependerán del uso correcto del producto y del organismo de cada persona. Antes de usa un producto de cosmética erótica se recomienda revisar los componentes de este para evitar reacciones alérgicas. En caso de irritación suspender y consultar con su dermatólogo. Fabricado y envasado por laboratorio CQC. RPC. Distribuido por Importadora y Comercializadora Ruta 160 Ltda. Catedral N°1233, local 9, Santiago de Chile. Dispositivo médico según artículo 101 del código sanitario y reglamento DS825/98.",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 14,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 543,
              nombre: "",
              url: "https://i.ibb.co/3TCM6fC/Pics-Art-01-18-02-37-10.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 565,
          codigo: "222000139102",
          nombre: "Consolador Sex Pop Curvo",
          descripcion:
            "Su principal característica es que contiene sopapo y viene en un lindo color rosado.",
          detalles:
            "Se puede sostener en cualquier superficie lisa y plana, además de arneses. Ideal para iniciarse con juguetes debido a sus medidas moderadas.\n\nCaracterísticas:\nMaterial: Silicona\nColor: Rosado\nLargo total: 14cm\nInsertable: 13cm\nDiámetro: 4cm\n\nObservaciones:\nConsolador forma realística\nEl consolador Sex Pop Curvo es compatible con arneses.\nCon base de sopapo ultra firme capaz de adherirse a cualquier superficie plana.\nResistente al agua\nEvitar altas temperaturas",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1436,
              nombre: "",
              url: "https://sexshopmayorista.cl/10873-large_default/consolador-sex-pop-curvo.jpg",
              descripcion: "",
            },
            {
              id: 1437,
              nombre: "",
              url: "https://sexshopmayorista.cl/10872-large_default/consolador-sex-pop-curvo.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 12,
              nombre: "Consoladores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 501,
          codigo: "825156109007",
          nombre: "Cartas Let's F*Ck!",
          descripcion:
            "Kheper Games nos sorprende nuevamente con sus juegos seductores para parejas, en los que podrás descubrir nuevas prácticas sexuales, conocer los gustos sexuales de tu pareja, las fantasías eróticas y mucho más.",
          detalles:
            "El Juego de Cartas Let's F*ck! de Kheper Games se puede jugar de 3 maneras diferentes y te brinda 250.000 formas de ganar! Está enfocado en posiciones sexuales, el cual consiste principalmente en realizar las pruebas eróticas que las cartas les irán indicando. El ideal es que cumplas tus fantasías sexuales junto a tu pareja \n\nEl objetivo del Juego Juego Let's F*ck! es que logres explorar otras prácticas sexuales junto a tu pareja e incorporar nuevas posiciones sexuales, brindándote como beneficio reinventar tu relación de pareja y satisfacer todas tus fantasías eróticas.\n\nContenido: \n48 cartas let´s f*ck\n4 cartas follar\nWild  y reglas de los tres juegos: Fortuna f*ck, Partido y F*ck, Y preguntas personales sobre el sexo\n\n\nObservaciones:\nDirigido a parejas.\nEl juego contiene las instrucciones de uso en Español, Inglés, Francés y Alemán.",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 11,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1337,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/kheper-game-accesorios-juego-cartas-let-s-f-ck-28942654537917_2000x.jpg?v=1628362631",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 474,
          codigo: "5600298351003",
          nombre: "Lubricante Anal Comfort - Orgie",
          descripcion:
            "Lube Tube Anal Comfort es un gel íntimo híbrido a base de agua con silicona desarrollado especialmente para aumentar el placer y la comodidad del sexo anal",
          detalles:
            "ORGIE, fabricada en Portugal con los más altos estándares europeos y materia prima. Descubre su esencia Brasileña de fórmulas con principios activos exclusivos provenientes de la selva amazónica que brindan sensaciones inéditas y excepcionales.\n\nLube Tube Anal Comfort es un gel íntimo híbrido a base de agua con silicona desarrollado especialmente para aumentar el placer y la comodidad del sexo anal. Inoloro y con lubricación de grado superior de larga duración lo que hara de la experiencia algo mucho mas comodo. El tubo de lubricante no es pegajoso y es fácil de limpiar y lavar.\n\nCaracterísticas:\n\nEfecto: Lubricante Anal\n\nAroma: Sin Olor\n\nSabor: Sin Sabor\n\nContenido: 100ml\n\n \n\nModo de uso: Aplicar una cantidad generosa en el área intima previo a la penetración.\n\nDuración: 6 meses después de abierto y en condiciones ideales de conservación.\n\nIngredientes: Agua, Glicerina, Propilenglicol, Hidroxietilcelulosa, Fenoxietanol, EDTA disódico, Policuaternio-7, Etilhexilglicerina, Ácido poliacrílico, Copolímero PVM / MA, Fosfato disódico, Polisorbato 60, Metilparabeno, Benpazoato de sodio, Fosato de propilodio.",
          instagram: "",
          precio: 21990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1276,
              nombre: "",
              url: "https://i.ibb.co/tPz7ncK/Pics-Art-08-14-01-23-25.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 64,
          codigo: "7765431000076",
          nombre: "Lubricante Anal Starsex (60ml)",
          descripcion:
            "Vive una experiencia anal única llena de erotismo y comodidad gracias al Lubricante Anal de, es ideal para dilatar la zona anal logrando una fácil penetración Debido a sus aportes de silicona y agua . Este lubricante anal puedes aplicarlo interna y externamente, lograrás una experiencia anal sin complicaciones. Comienza a disfrutar ahora de encuentros sexuales majestuosos! ",
          detalles:
            "Ingredientes: Glycerol, Hydroxyethyl Cellulose, Water Suluble Silicone Oil, Propylene Glycol, Hyaluronic Acid, Pure Water, Other. \n\nCaracterísticas:\nLubricante dilatador anal\nContenido: 60ml\nEfecto: Dilatador\n\nObservaciones:\nAplicar la cantidad necesaria alrededor del ano, hasta que el lubricante cubra toda el área.\nSe puede utilizar externa e internamente.\nConservar a temperatura ambiente en un lugar fresco.\nNo utilizar en caso de alergia a alguno de sus componentes.",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 21,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 874,
              nombre: "",
              url: "https://i.ibb.co/wL8Xhxs/Pics-Art-05-08-12-01-53.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 228,
          codigo: "6959532310955",
          nombre: "Vibrador Snappy",
          descripcion:
            "El Vibrador Snappy de Pretty Love esta fabricado de silicona medica de textura muy suave para cuidarte mientras te estimula. Este juguete  es de doble estimulación ya que estimula el punto G internamente mientras recorre tus paredes vaginales, y por fuera estimula tu clítoris para que la experiencia de placer sea completa.\nEl Vibrador Snappy se destaca por sus 3 intensidades de vibración y 27 tipos de pulsaciones para que elijas el nivel que más te estimula. Para una experiencia más versátil, puedes decidir conectar o desconectar la vibración parcial de punto G o clítoris.\nSu silencioso motor, te entrega toda la discreción que necesitas para masturbarte a solas o jugar en la cama en pareja.",
          detalles:
            "Características\nMaterial Silicona\nColor Fucsia-blanco\n3 intensidades de vibración\n27 tipos de pulsaciones\nUsa dos baterías AAA\n\nMedidas\nLargo Total: 19.5cm\nLargo insertable: 9,0cm\nDiámetro: 3,5cm\n\nObservaciones\nDiseño ergonómico y dual\nEstimulador de Punto G/ Clítoris\nSonido discreto\nResistente al agua / no sumergible\nApto para Lubricantes base a agua o híbridos.\nPilas no incluidas",
          instagram: "",
          precio: 25990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 710,
              nombre: "",
              url: "https://i.ibb.co/BwpFhXG/Pics-Art-02-09-02-40-06.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 2,
          codigo: "92001000007",
          nombre: "Pan de Vida Erótico",
          descripcion:
            "Pan de Vida Erótico para todas aquellas parejas que quieren romper la rutina y pasar momentos inolvidablemente placenteros. Déjalo en tu velador, saca un pancito al azar y déjate llevar por el erotismo, la sensualidad y la diversión",
          detalles:
            "Algunos ejemplos de tarjetas:\n“El perrito”La mujer se debe apoyar en sus rodillas y manos, mientras el hombre la penetra desde atrás, ya sea vaginal o analmente. Esta pose en particular, permite una profunda penetración y una sensación de dominio. Se recomienda que la mujer se apoye sobre sus codos para alcanzar con mayor facilidad la estimulación del punto G.\n“La escuadra”La mujer acostada sobre una mesa esperará al hombre quien la tomará para penetrarla y hacerla completamente suya, sosteniéndose de los bordes de la mesa e incluso del cuerpo de ella, para impulsarse y penetrarla con mayor fuerza y pasión.\n“El barco de vela”La mujer debe recostarse sobre su espalda al borde de la cama, elevando las piernas para que sus pies queden a la altura de los hombros de su amante, de esta manera, el hombre se arrodilla frente a ella, sujetándola de los tobillos para penetrarla. Se recomienda utilizar un cojín en la espalda para mayor comodidad si existiese una pronunciada diferencia de estatura.\n",
          instagram: "https://www.instagram.com/sexduceme.cl",
          precio: 12990,
          descuento: 14.0,
          stock: 10,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 531,
              nombre: "",
              url: "https://i.ibb.co/SsjG5ff/Pics-Art-02-09-02-25-12.jpg",
              descripcion: "",
            },
            {
              id: 532,
              nombre: "",
              url: "https://i.ibb.co/y004MsH/Pics-Art-02-09-02-25-54.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 556,
          codigo: "603912341607",
          nombre: "Guante masturbador para masaje erótico",
          descripcion:
            "Suave y sensual guante erótico de jelly semi transparente y texturado para masturbarse, dar masajes sensuales y estimular las zonas intimas masculinas y femeninas.",
          detalles:
            "Características:\n\nPensado como guante de masturbación, para el y ella.\nMaravilloso como guante para masajes eróticos.\nSuaves protuberancias en los dedos para estimulación clitorial.\nTextura acanalada en la palma para un mejor masturbación.\nGenial para penetración con los dedos.\nExcelente para estimular el punto G.\nFabricado en suave TPR tipo jelly.\nAmbidiestro (se puede usar en la mano de fecha o izquierda).\nTotalmente a prueba de agua y reulitizable.\nSeguro para el cuerpo, Libre de Látex y ftalato.\nEspecificaciones:\n\nTalla: Única (Medium/XL)\nCantidad: 1 unidad\nColor: Blanco semi transparente (Cloud Clear).\nLargo total: 23 cms.\nAncho: Aprox 20 cms.\nBase muñeca: ± 9 cms.\nGrosor: ±2,5 mm sin las protuberancias, ±5,5 mm con las protuberancias.\nMaterialidad: Fabricado en material TPR  (Thermoplastic Rubber)\nHigiénico y seguro de alta calidad.\nSin olor y sin sabor.\nPeso aprox: 170 grs.\nPeso total con empaque: 215 grs.\nMarca Booty Gloves USA.\nFabricado en USA por Saporno Brothers Inc.\n\nRecomendaciones:\n\nPara un uso mas placentero se recomienda usar con lubricante o aceite para masajes.\nPara una experiencia mas intensa se pueden usar dos, uno en cada mano.\nLimpiar siempre después de usarlo con jabón Neutro y agua.\nSecar con toalla de algodón o al aire.",
          instagram: "",
          precio: 11490,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1420,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2015/07/Booty-Glove-Clear-F2TLC-Japanese-Silk-Love-Rope-Ball-Gag-Black-F1.jpg",
              descripcion: "",
            },
            {
              id: 1421,
              nombre: "",
              url: "https://www.eroticamayorista.cl/wp-content/uploads/2015/07/Booty-Glove-Clear-F1TLC-Japanese-Silk-Love-Rope-Ball-Gag-Black-F1.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 16,
              nombre: "Masturbadores Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 564,
          codigo: "712594186205",
          nombre: "Body Bourbon Talla S",
          descripcion:
            "Hermoso Body Bourbon para verse elegante y sensual. Su tono vino tinto entrega calidez a tu rostro.",
          detalles:
            "Esta sexy prenda se caracteriza por su escote en V profundo festoneado con detalles de encaje floral y transparencia. El Body Bourbon viene con calzón tipo tanga con broches. Es elasticado y se adapta a las distintas formas de tu cuerpo.\n\nCaracterísticas:\nMaterial: Poliéster - Spandex\nColor: Bourbon\nDetalles de transparencia y encaje\nPrendas: Body\nTalla: S",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1435,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/lenceria-lenceria-body-bourbon-talla-s-18705952440470_2000x.jpg?v=1628346962",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 294,
          codigo: "0664078951",
          nombre: "Babydoll Marina - Talla 2XL",
          descripcion:
            "Marina es un Babydoll rojo que mezcla el encaje con la tela semitransparente.\nMarina es un Babydoll rojo que mezcla el encaje con la tela semitransparente para jugar con texturas. Su top contiene detalle de encaje a la altura de los hombres y sostén con barba metálica para mayor soporte, tirantes de hombro y espalda ajustables para mejor calce.",
          detalles:
            "Detalles:\n\nIncluye colaless*\n\nSin Forro\n\nTirantes de hombro ajustables\n\nBroche de espalda ajustable en 3 medidas\n\nMaterial: 95% poliéster 5% spandex\n\nTalla: 2XL\n\nAncho Contorno busto: 90 a 98 cm \n\nLargo total: 70 cm ",
          instagram: "",
          precio: 21990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 759,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14516-large_default/Babydoll-Marina.jpg",
              descripcion: "",
            },
            {
              id: 760,
              nombre: "",
              url: "https://i.ibb.co/KhHHv1k/Pics-Art-03-21-01-01-54.jpg",
              descripcion: "",
            },
            {
              id: 761,
              nombre: "",
              url: "https://i.ibb.co/G52DrR4/Pics-Art-03-21-01-01-12.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 362,
          codigo: "6914421000287",
          nombre: "Sachet Lubricante Neutro",
          descripcion:
            "Totalmente renovado en su fórmula a base de agua.\n\nSe siente como la lubricación natural, pero muy suave y de largo aliento en su rendimiento.\n\nEs sumamente hidratante y refrescante.\n\nMuy adecuado para acompañar el uso de juguetes sexuales\n\nLa línea de lubricantes íntimos de Secretos de Amor está pensada para consumidores que buscan alternativas más saludables en lo que a bienestar sexual se refiere.",
          detalles:
            "Todas las fórmulas incluyen una combinación de ingredientes naturales, orgánicos, eco-certificados. Son libres de glicerina, balanceados en PH y funcionan a la perfección con el cuerpo femenino. Son una gran opción para personas con sensibilidad o alergias\n\nContenido 6ml / sachet",
          instagram: "",
          precio: 2490,
          descuento: 14.0,
          stock: 12,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1085,
              nombre: "",
              url: "https://i.ibb.co/59QDzzy/Pics-Art-07-07-02-19-22.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 289,
          codigo: "825156107621",
          nombre: "Juego de Trivia - Noche de Chicas",
          descripcion:
            "Aprende con y de tus amigas a través de 4 categorías de interesantes e íntimas preguntas.\n\nAlgunas preguntas de ejemplo:\n\n¿Cuál de mis antiguos pololos me gustaría más que me contacte?\n\n¿ Cuál es mi vicio favorito ?\n\nConviértete en la ganadora siendo la mejor en acertar las respuestas de tus amigas.\nDiversión garantizada para todas las asistentes",
          detalles:
            'Incluye: Una ruleta de Noche de Chicas, un block de hojas para el juego, 40 cartas con preguntas " Cosas de la vida", 40 cartas con preguntas sobre " Personalidad", 40 cartas con preguntas sobre "Amores y amantes" y 40 cartas con preguntas sobre " Salud y Belleza"',
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 734,
              nombre: "",
              url: "https://i.ibb.co/10kH31s/Pics-Art-03-21-12-36-23.jpg",
              descripcion: "",
            },
            {
              id: 735,
              nombre: "",
              url: "https://cdnx.jumpseller.com/secretos-de-amor-cl/image/5298177/resize/635/635?1595545184",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 154,
          codigo: "7898495410304",
          nombre: "Excitante Femenino Ninforgasmic",
          descripcion:
            "Disfruta de orgasmos únicos a otro nivel! Llego a Starsex el solicitado excitante femenino Ninforgasmic! de la prestigiosa marca brasileña Adao & Eva, especialistas en productos para el bienestar sexual.\r\n\r\nEl Excitante femenino Ninforgasmic esta formulado con extracto de hoja de centella asiática y extracto de raíz de jengibre, estos componentes además de potenciar tus orgasmos cuidarán tu piel. Solo debes colocar una pequeña cantidad en la punta de los dedos y aplicar en el área del clítoris, solo espera unos segundos y comenzarás a sentir sus efectos!",
          detalles:
            "CARACTERÍSTICAS\r\nExcitante femenino\r\nContenido: 30 g\r\nUso: Femenino\r\nEfecto: Excitante y protector\r\n\r\nIngredientes: Agua, Carbómero, Glicerina, Propileno Glicol, PEG-75 Lanolin, Polisorbato 20, Aceite de Hoja de Mentha Avensis, Menthol, Alcohol, Extracto de Hoja de Centella Asiática, Jugo de Hoja de Aloe Barbadensis, Extracto de Raíz de Zingiber Officinale (Jengibre), Fenoxietanol, Amimonetil Propanol.\r\n\r\nModo de uso: Aplicar una pequeña cantidad, esparcir y esperar unos segundos para ver los efectos\r\n\r\nImportante: Los resultados dependerán del uso correcto del producto y del organismo de cada persona. Antes de usar un producto de cosmética erótica se recomienda revisar los componentes de este para evitar reacciones alérgicas y realizar un pequeño testeo en el antebrazo. En caso de irritación suspender y consultar con su dermatólogo.",
          instagram: "https://www.instagram.com/sexduceme.cl",
          precio: 9990,
          descuento: 14.0,
          stock: 7,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 243,
              nombre: "",
              url: "https://i.ibb.co/SN6YW7k/1608164511375.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 9,
              nombre: "Potenciadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 273,
          codigo: "6970556030674",
          nombre: "Huevito Vibrador a Control Remoto - Verde Menta",
          descripcion:
            "Atrévete a una nueva experiencia con este huevo vibrador, insertable y recargable.\nSus 10 modalidades de vibración y sus texturas en silicona suave y cómoda te harán sentir nuevas e indescriptibles experiencias.\nPuedes estimular tu clítoris y toda zona erógena externa, además puedes insertarlo para estimulación interna y puedes controlarlo a distancia o entregarle el control a otra persona para que te las haga sentir de manera sorpresiva.",
          detalles:
            "Características:\n- Material: Silicona Segura\n- Dimensiones Huevo: 8cm x 3.5cm\n- Dimensiones Control: 5.5cm x 3.5cm\n- Fuente de Energía Huevo: Batería Interna recargable vía USB.\n- Fuente de Energía Control: 1 Pila CR2032 (Incluida).\n- 10 Modalidades de Vibración.\n- Huevo Sumergible.\n\nObservaciones:\n- Limpiar antes y después de cada uso con agua tibia y Jabón Neutro.\n- El huevo puede usarse con o sin el control remoto, se cambia el modo de vibración presionando nuevamente el botón de encendido.\n- No usar para inserción anal.",
          instagram: "",
          precio: 29990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 609,
              nombre: "",
              url: "https://i.ibb.co/4TrWHGp/Pics-Art-02-27-01-38-20.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 508,
          codigo: "818631020294",
          nombre: "Dickalicious Piña Colada",
          descripcion:
            "Estimulador masculino para sexo oral sabor piña colada, con sensación de hormigueo. \n\nEl nuevo Dickalicious es un gel estimulante  para sexo oral que entrega un excitante efecto hormigueo y un delicioso sabor a frutilla.",
          detalles:
            "Ingredientes: Petrolatum, Mineral oil, Sorbitan Oreate, Flavor, Menthol, Sodium Saccharin, Yellow 11(CI#47000), Red 17 (CI# 26100), Propylparaben.\n\nContenido: 56g\nSabor: Piña Colada\nEfecto: Hormigueo\n\nCaracterísticas:\nEstimulante\nGel para sexo oral\nFormula soluble al agua\nNo pegajoso\nHidratante ",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1357,
              nombre: "",
              url: "https://ripleycl.imgix.net/https%3A%2F%2Fs3-us-west-2.amazonaws.com%2Fim-prod-products-images%2F1eb76fa4-493a-4167-a5b7-3fce615c9279-dickaliciousdepinacolada.jpeg?w=750&h=555&ch=Width&auto=format&cs=strip&bg=FFFFFF&q=60&trimcolor=FFFFFF&trim=color&fit=fillmax&ixlib=js-1.1.0&s=a23672c6945bc0d5ba598dd0d19c4007",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 394,
          codigo: "7766432910128",
          nombre: "Vibrador Cliterific Rosado",
          descripcion:
            "El vibrador Cliterific Rosado fue fabricado de silicona y diseñado con seductoras texturas que acariciarán suave y placenteramente tanto punto G, como entrada y canal vaginal debido a su fascinante grosor.",
          detalles:
            "Disfruta solx o acompañadx de sus 17 centímetros insertables y sus 3.5 centímetros de diámetro con su siempre bien oportuna vibración regulable en intensidad. Haz de este juguete tu aliado perfecto a la hora de experimentar nuevas sensaciones, puedes estimular de manera externa cualquier parte de tu cuerpo.\nCaracterísticas\nMaterial: Silicona\nColor: Rosado\nPeso: 159grs \nLargo Total: 20cm\nLargo Insertable: 17cm\nDiámetro: 3.5cm\nBaterías: 2-AA \n\nObservaciones:\nBaterías no incluidas\nVelocidad regulable\n\nRecomentaciones de uso:\nLimpiar con agua y jabón neutro antes y después de usar.\nUtilizar con lubricante en base a agua.\nQuitar las baterías después de cada uso.",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1113,
              nombre: "",
              url: "https://i.ibb.co/qkQn1gk/Pics-Art-07-09-01-10-16.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 502,
          codigo: "444000650047",
          nombre: "Vibrador - G-Spot Gimme More",
          descripcion:
            "Vibrador de Silicona, liso y ultrasuave, que estimula el Punto G gracias a su forma abultada en la punta, y posee 10 potentes funciones de Vibración. Resistente a salpicaduras.",
          detalles:
            "Posee un contorno abultado en su punta para dar estimulación a toda la zona G. Fabricado en silicona médica, un material suave y liso que entregará la textura más agradable para entregar todo el placer deseado. Además, se adapta con facilidad a la temperatura corporal. Posee 10 niveles de pulsaciones y vibración que llevarán la experiencia placentera a otro nivel. Es un juguete ideal para iniciarse en el mundo de accesorios sexuales.\n\nCaracterísticas\n\nMaterial: Silicona medica\nColor: Rosado con mango blanco\nLargo total: 21cm\nLargo insertable: 12cm\nDiámetro superior: 3cm\nBaterías: 2 – AAA (no incluidas)\n\nObservaciones\n\nVibrador Multifunciones\nEstimulador de punto G\n3 intensidades de vibración\n7 patrones de pulsaciones\nErgonómico\nLibre ftalatos e  impermeable\nHipoalergénico\nResistente a salpicaduras",
          instagram: "",
          precio: 26990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1338,
              nombre: "",
              url: "https://sexshopmayorista.cl/15503-large_default/vibrador-g-spot-gimme-more.jpg",
              descripcion: "",
            },
            {
              id: 1339,
              nombre: "",
              url: "https://sexshopmayorista.cl/9879-large_default/vibrador-g-spot-gimme-more.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 258,
          codigo: "855559003305",
          nombre: "Aceite Excitante Femenino On 5ml",
          descripcion:
            "On Aceite natural excitante para ella, es el indicado para quienes quieren llegar a orgasmos único e inolvidables, su mezcla de aceites esenciales llevan a crear sensaciones de excitación natural y un aumento de lubricación perfecta para la mujer.",
          detalles:
            "Características:\n\nContenido: 5 ML\nlibre de Mentol\nExcitante de uso externo.\n\n\nIngredientes: Proprietary Essential Oil, Sweet Almond Oil, Cinnamomun Zeylanicum, Natural Tocopherols (vitamin e ) silica, natural flavors, Rosemary oil extract.\nModo de uso: Aplicar de 1 a 2 gotas en el clítoris , luego de 2 minutos aproximados comienza su efecto, con una duración aproximada de 45 minutos. Repita si es necesario.",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1296,
              nombre: "",
              url: "https://i.ibb.co/Ns3mPGg/Pics-Art-08-17-02-07-51.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 390,
          codigo: "7798297913907",
          nombre: "Consolador Jelly Bananin Rojo- Caiman",
          descripcion:
            "Línea de consoladores tipo Jelly, Gummy by Caiman. El Consolador Jelly Bananin Rojo- Caiman esta fabricado en Argentina, hecho de PVC que se adapta fácilmente a la temperatura corporal. Cristalino, flexible, muy suave y libre de Ftalatos.\n\nPosee una ventosa adherente a superficies planas y es compatible con arnés.",
          detalles:
            "Características:\n\nVentosa adherente\nPVC suave y flexible\nLibre de Ftalatos\nColor: Rojo\nLargo total: 21 cm \nDiámetro: 3,5 cm\n\nInstrucciones de cuidado:\n\nLimpiar con agua tibia y jabón neutro.\nDejar secar y guardar.\nMantener lejos del calor",
          instagram: "",
          precio: 18990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1076,
              nombre: "",
              url: "https://i.ibb.co/4gTbbzV/Pics-Art-07-07-02-16-33.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 12,
              nombre: "Consoladores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 28,
              nombre: "Arnés",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 376,
          codigo: "697309062008",
          nombre: "Lubricante Natural Toko",
          descripcion:
            "Creado exclusivamente para reproducir una lubricación natural ilimitada y facilitar la penetración. Es ultra sedoso y aumenta la sensación de los cuerpos en movimiento. También es ideal para usarlo con accesorios para adultos. ",
          detalles:
            "- Base de Agua \n- Compatible con látex \n- 100% soluble en agua\nHecho en Canadá \nContenido 125 ml",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1047,
              nombre: "",
              url: "https://i.ibb.co/23y2jkY/Pics-Art-07-07-01-00-15.jpg",
              descripcion: "",
            },
            {
              id: 1048,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/2247194/thumb/540/540?1623442976",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 329,
          codigo: "697309063005",
          nombre: "Lubricante Silicona Toko",
          descripcion:
            "Si buscas un lubricante de larga duración y sedoso, este es ideal para ti. En la cama, en la ducha o donde tú quieras. Lubricante Toko silicona se puede usar debajo del agua o para realizar interminables masajes eróticos",
          detalles:
            "Modo de uso: Aplica la cantidad deseada sobre la zona a lubricar masajeando suavemente.\n\nEspecificaciones\n- A base de silicona. \n- Contenido: 165 ml. \n- Lubricación de larga duración. \n- Hipoalergénico, no mancha. \n- Libre de: parabenos, azúcar, fragancias. \n- Apto para el uso con preservativo.\n - Vegano.",
          instagram: "",
          precio: 20990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 881,
              nombre: "",
              url: "https://i.ibb.co/JchyJbG/Pics-Art-05-16-01-50-21.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 385,
          codigo: "6914421000027",
          nombre: "Sachet Crema Excitante para Él y Ella ",
          descripcion:
            "Intensificador con fórmula patentada, diseñada para aumentar la estimulación tanto del hombre como de la mujer. Posee un efecto que concentra el flujo de sangre hacia el área genital.\n\nÉsta suave  y sedosa crema elevará la excitación a nuevas alturas",
          detalles:
            "- Fórmula soluble en agua\n- No pegajoso\n- Hidratante\nContenido: 5ml",
          instagram: "",
          precio: 2490,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1020,
              nombre: "",
              url: "https://i.ibb.co/nDbQ7Dj/Pics-Art-07-07-02-20-51.jpg",
              descripcion: "",
            },
            {
              id: 1021,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/7085569/thumb/540/540?1614101189",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 115,
          codigo: "7770007210018",
          nombre: "Estimulador Orgasmix (Sachet)",
          descripcion:
            "El gel Orgasmix te ayudará a lograr orgasmos más duraderos e intensos, disminuyendo el tiempo necesario para alcanzar múltiples orgasmos. Este potenciador se convertirá en tu nuevo aliado al momento de intensificar tus encuentros",
          detalles:
            "Observaciones:\nGel potenciador de orgasmos\nGel Multiorgásmico\nExcitante femenino\nCosmética erótica",
          instagram: "",
          precio: 2490,
          descuento: 14.0,
          stock: 27,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 157,
              nombre: "",
              url: "https://i.ibb.co/D5LZBjJ/IMG-20201108-201739.jpg",
              descripcion: "",
            },
            {
              id: 158,
              nombre: "",
              url: "https://i.ibb.co/cQT5cQ8/IMG-20201108-201715.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 203,
          codigo: "3005",
          nombre: "Pantaleta de Encaje",
          descripcion: "Pantaletas con hermoso diseño de encaje.",
          detalles:
            "Colores y tallas disponibles:\n\nNegro: Talla S - Talla M\nNude: Talla S - Talla M\nVerde: Talla M\nBlanco: Talla L - Talla XL\n\n\nOBSERVACIÓN:\nSon tallas pequeñas, por lo que se recomienda que solicite una talla más a la que utiliza regularmente.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 9,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 358,
              nombre: "",
              url: "https://i.ibb.co/pw7ymGG/Pics-Art-01-18-11-21-27.jpg",
              descripcion: "",
            },
            {
              id: 359,
              nombre: "",
              url: "https://i.ibb.co/rwHZXtr/Pics-Art-01-18-11-23-22.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 24,
          codigo: "855559003312",
          nombre: "Aceite Excitante Femenino On (0,5ml)",
          descripcion:
            "Excitante femenino en formato de pipeta posee una mezcla de aceites esenciales que otorgarán una sensación de excitación natural.",
          detalles:
            "Características:\nExcitante Femenino\nFormato Pipeta Personal\nLibre de Mentol\nExcitante de uso externo\nFormato: Pipeta 0,5ml\n\nModo de uso: Aplicar de 1 a 2 gotas en el clítoris, luego de 2 minutos aproximados comienza su efecto, con una duración aproximada de 45 minutos. Repita si es necesario.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 10,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 635,
              nombre: "",
              url: "https://i.ibb.co/Zgf5HrQ/Pics-Art-02-28-08-18-01.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 15,
          codigo: "555000024131",
          nombre: "Anillo para Pene con Perlas",
          descripcion:
            "Set de 3 anillos con perla, perfecto para primerizos.\r\nLos anillos peneanos evitan que la sangre abandone el pene, mejorando y prolongando la erección.\r\nPonlo en la base del pene erecto y disfruta sus beneficios.\r\nSe recomienda su uso con lubricante en base a agua.\r\nPuedes usarlos de forma individual o los 3 a la vez",
          detalles: "Diámetros de cada anillo:\n- 2cm\n- 2,5cm\n- 3cm",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 517,
              nombre: "",
              url: "https://i.ibb.co/MDCvWrY/Pics-Art-02-27-01-58-46.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 13,
              nombre: "Anillos Peneanos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 22,
              nombre: "Kits",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 440,
          codigo: "825156107584",
          nombre: "Juego Fetish Seductions",
          descripcion: "Juego de mesa Fetish Seductions",
          detalles:
            "Explora el curioso mundo de fetiches con el juego Fetish Seductions, ideal para salir de la rutina junto a tu pareja e innovar en tu vida sexual.\n\nContenido:\n- 36 tarjetas\n- 2 cuerdas\n- 1 cegadera\n- 2 dados\n- 1 Vela\n- 1 Pluma\n- Reglas del juego\n\nObservaciones:\nLas tarjetas contienen pruebas relacionada con fetiches, las que puedes realizar a modo de juego, o tomar tarjetas de sugerencia.\nDirigido a parejas.\nDisponible en Español, inglés, francés y alemán.",
          instagram: "",
          precio: 24990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1283,
              nombre: "",
              url: "https://i.ibb.co/Tknvyxk/Pics-Art-08-17-02-10-43.jpg",
              descripcion: "",
            },
            {
              id: 1284,
              nombre: "",
              url: "https://sexshopmayorista.cl/15233-large_default/juego-fetish-seductions.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 32,
              nombre: "BDSM",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 22,
              nombre: "Kits",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 312,
          codigo: "6970556030667",
          nombre: "Huevito Vibrador a Control Remoto - Rosado",
          descripcion:
            "Atrévete a una nueva experiencia con este huevo vibrador, insertable y recargable.\nSus 10 modalidades de vibración y sus texturas en silicona suave y cómoda te harán sentir nuevas e indescriptibles experiencias.\nPuedes estimular tu clítoris y toda zona erógena externa, además puedes insertarlo para estimulación interna y puedes controlarlo a distancia o entregarle el control a otra persona para que te las haga sentir de manera sorpresiva.",
          detalles:
            "Características:\n- Material: Silicona Segura\n- Dimensiones Huevo: 8cm x 3.5cm\n- Dimensiones Control: 5.5cm x 3.5cm\n- Fuente de Energía Huevo: Batería Interna recargable vía USB.\n- Fuente de Energía Control: 1 Pila CR2032 (Incluida).\n- 10 Modalidades de Vibración.\n- Huevo Sumergible.\n\nObservaciones:\n- Limpiar antes y después de cada uso con agua tibia y Jabón Neutro.\n- El huevo puede usarse con o sin el control remoto, se cambia el modo de vibración presionando nuevamente el botón de encendido.\n- No usar para inserción anal.",
          instagram: "",
          precio: 29990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 797,
              nombre: "",
              url: "https://i.ibb.co/JCpPVzd/Pics-Art-04-19-12-53-40.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 360,
          codigo: "679359000568",
          nombre: "Potenciador Masculino Size RX sachet",
          descripcion:
            "Size Rx es una loción de uso tópico hecha con extractos naturales, que incluyen L-arginina, ginseng, Yohimbe y aloe vera, entre otros. Está formulado para dilatar los vasos sanguíneos y expandir los capilares del pene, permitiendo un mayor flujo sanguíneo para obtener erecciones más firmes y duraderas.\n\nContribuye a mejorar el desempeño sexual masculino, potencia las erecciones e intensifica los orgasmos. Size Rx también es el complemento perfecto para el uso de bombas de vacío.",
          detalles:
            "Modo de uso: Aplica el producto en el glande y a lo largo del pene; luego masajea durante unos 30 segundos para esparcirlo de manera uniforme. Remueve el exceso de producto antes de la penetración.\n\nCantidad : 2 ml.\n\nSin Aroma / Sin sabor\n\nBase  Agua",
          instagram: "",
          precio: 3490,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1297,
              nombre: "",
              url: "https://i.ibb.co/DDfStzQ/Pics-Art-08-14-01-15-18.jpg",
              descripcion: "",
            },
            {
              id: 1298,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/13612580/thumb/540/540?1619799170",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 9,
              nombre: "Potenciadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 291,
          codigo: "502631050704",
          nombre: "Body Stella - Talla 2XL",
          descripcion:
            "Body Stella, negro semi transparente con brillos.\nStella es un bello body semi transparente de cuello alto con aplicaciones de mostacillas brillantes y marga larga. Su parte inferior es de una tela elasticada opaca negra cubriente. Trae un broche de seguridad para fácil uso. Ideal para complementar un look.",
          detalles:
            "Características:\nSin Forro\nBroche de seguridad\nColor: Negro transparente y mostacillas plateadas\nMaterial: 33% cotton 63% poliéster 4% spandex\nTalla: 2xl\n\nMedidas: \nAncho contorno: 50 cm (tela semi elasticada)\nLargo total: 75 cm (De hombro a entre pierna broche)\n\nRecomendaciones:\nLavar a mano\nEscurrir suavemente sin estrujar, para evitar estropear el tejido. No planchar, no lavar en seco",
          instagram: "",
          precio: 23990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 743,
              nombre: "",
              url: "https://i.ibb.co/9pFJbWh/Pics-Art-03-21-12-47-24.jpg",
              descripcion: "",
            },
            {
              id: 744,
              nombre: "",
              url: "https://i.ibb.co/VLsRynV/Pics-Art-03-21-12-48-03.jpg",
              descripcion: "",
            },
            {
              id: 745,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14890-large_default/body-semitransparente-brillos-talla-2xl.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 216,
          codigo: "7765432910039",
          nombre: "Vibrador Oruga",
          descripcion:
            "El Vibrador Oruga, viene a entregar placer sin igual gracias a su cuerpo con estimuladores para recorrer cada rincón interno y no dejar punto sin estimular. Además posee un juguetón estimulador de clítoris con suaves y potentes antenitas para complacerte como gustes y cuando gustes. Mientras Orugón recorre el interior de tu vagina hasta encontrar con precisión tu punto G, Oruguita se encarga de acariciar tu clítoris o estimular tu ano. Hecho en silicona hipoalergénica, sin látex, ni ftalatos, posee vibración regulable nivelada con el potenciómetro ubicado en la tapa del compartimiento de las pilas. ",
          detalles:
            "Características: \nMaterial: Silicona\nColor: Fucsia\nLargo total: 22.5cm\nLargo insertable: 13.5cm\nDiámetro mayor: 4.5cm\nLargo Estimulador de Clítoris: 8cm\nBaterías: 2 AA (no incluidas)\n\nObservaciones:\nMultivelocidades\nVibración regulable\nDos motores sincronizados\nFlexible\nA prueba de agua\nLibre de Látex y Ftalátos\nEstimulador de Clítoris\nEstimulador Punto G\n\nRecomendaciones:\nLimpiar antes y después de utilizar con agua tibia y jabón neutro.\nGuardar en un sitio limpio y seco y sin mezclar con otros juguetes.\nUsar con lubricante a base de agua y con preservativo.\nNo mojar ni sumergir las partes eléctricas.",
          instagram: "",
          precio: 24990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1350,
              nombre: "",
              url: "https://sexshopmayorista.cl/15633-large_default/vibrador-oruga-estimulador-clitorial.jpg",
              descripcion: "",
            },
            {
              id: 1351,
              nombre: "",
              url: "https://sexshopmayorista.cl/15634-large_default/vibrador-oruga-estimulador-clitorial.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 46,
          codigo: "7898943080479",
          nombre: "Aceite para Masajes Fruit Sexy Efecto Frío",
          descripcion:
            "Ten una experiencia de masaje única con el aceite corporal Oral Sex de Intt. Disfruta de exquisitos y eróticos masajes en las zonas más erógenas de tu cuerpo acompañado de placenteras sensaciones de leve efecto frío\nEl aceite corporal es comestible perfecto para practicar un rico sexo oral. Este aceite te permite disfrutar de los masajes en pareja y ayuda a que terminen en un placentero juego sexual.",
          detalles:
            "Características:\nAceite de masajes\nContenido: 40 ml.\nEfecto: Leve efecto Frío. \nObservaciones: Aplicar sobre las zonas erógenas y masajear con las manos o lamiendo las partes deseadas.\nSi desea intensificar el efecto se recomienda soplar sobre la zona.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 9,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 341,
              nombre: "",
              url: "https://i.ibb.co/D8cjb1S/1609808562422.jpg",
              descripcion: "",
            },
            {
              id: 342,
              nombre: "",
              url: "https://i.ibb.co/Gkv0xdm/Aceites-para-Masaje.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 33,
          codigo: "7765432100010",
          nombre: "Lubricante Femenino (60ml)",
          descripcion:
            "El Lubricante Vaginal Femenino Starsex intensifica tu placer sexual ya que humedece tu vagina dejándola lista para una noche placentera. Es a base de agua, no quema ni provoca irritación y su uso es compatible con preservativos y juguetes eróticos. Además posee un agradable y muy suave sabor y aroma a frutilla que brindará un rico y estimulante aroma mientras lo usas, ya sea con tu amante o en tu momento de intimidad.",
          detalles:
            "Formato: 60ml\n\nCaracterísticas:\nDe base acuosa.\nEnvase con dispensador para una fácil aplicación.\nCompatible con preservativos y juguetes sexuales.\nSuave olor y sabor a frutilla.\nComestible.\nUso externo, interno y sexo oral.\n\nIngredientes: Agua, Metilparabeno, Propilparabeno, Glicerina, Carboximetilcelulosa, Glicolpropileno, Metilsalicilato, Saborizante Frutilla.\n\nModo de uso:\nAplicar una pequeña cantidad en la zona a penetrar, esparcir hasta que el lubricante cubra toda el área. Se puede usar externa e internamente, así como para el sexo oral.",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 10,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1307,
              nombre: "",
              url: "https://i.ibb.co/FgGhyd1/Pics-Art-08-14-01-24-17.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 220,
          codigo: "445083050045",
          nombre: "Vibrador Cristal Doble Placer",
          descripcion:
            "Enriquece tu vida sexual con este vibrante estimulador con pene flexible y bolas anales. Satisface tus pícaras fantasías de doble penetración!\nDeléitate con la penetración del eje principal, un pene realista que se hundirá profundamente en tu interior, acompañado de un collar de traviesas bolas anales. El motor, colocado en el centro, envía poderosas vibraciones con varias velocidades vibrando a través de ambos ejes para golpear todos tus puntos calientes a la vez.\nPuedes disfrutarlo bajo la ducha porque es resistente al agua",
          detalles:
            "Características:\nMaterial: Jelly\nColor: Rosado\nLargo total: 26.5cm\nLargo estimulador vaginal: 13cm\nLargo estimulador anal: 12cm\nDiámetro: 2cm\nBaterías: 2-AA\n\nObservaciones:\nVibrador doble penetración\nMulti velocidades\nA prueba de agua.\nUtilizar siempre con lubricante a base de agua \n\nRecomendaciones:\nUsar con lubricante a base de agua y preservativo.\nPara uso vaginal y anal.\nLimpiar antes y después de usar, con agua tibia y jabón neutro.\nGuardar en un sitio limpio y seco, sin mezclar con otros juguetes.\nDeje secar a la sombra o de forma natural (no utilice paño o papel).\nNo mojar ni sumergir las partes eléctricas.",
          instagram: "",
          precio: 22990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1346,
              nombre: "",
              url: "https://sexshopmayorista.cl/6500-large_default/vibrador-26cm-double-pleasure.jpg",
              descripcion: "",
            },
            {
              id: 1347,
              nombre: "",
              url: "https://sexshopmayorista.cl/6499-large_default/vibrador-26cm-double-pleasure.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 409,
          codigo: "697309064101",
          nombre: "Lubricante Agua de Coco Toko",
          descripcion:
            "La línea Aroma de Shunga tiene exquisitos lubricantes con sabores frutales, especiales para excitar y guiar los sentidos. Además se caracterizan por su sensación ultra sedosa y larga duración.\n\nTodos los lubricantes en base de agua están pensados para facilitar la penetración, el sexo manual, o simplemente para ofrecer una sensación aún más placentera. La gracia de esta línea es que además brinda la posibilidad de estimular el cuerpo del otro a través del sentido del gusto, aprovechando los deliciosos sabores que trae esta excelente línea de productos.",
          detalles:
            "Características: \n- 165 ml \n- Hipoalergénico \n- Base de agua \n- Comestible \n- No mancha \n- No contiene azúcar \n- Compatible con látex y juguetes de silicona \n- Fabricado en Canadá",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1116,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/6147646/thumb/540/540?1623943566",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 247,
          codigo: "334000026112",
          nombre: "Plug Anal Liso",
          descripcion:
            "Mini Plug Anal Liso de Starsex es una muy buena opción para la práctica del sexo anal ya que, es un juguete de tamaño mediano que ayuda a dilatar la zona anal, preparándola para la penetración o bien para utilizar otro juguete. Recomendados para quienes busquen experimentar las sensaciones y el placer que brinda el sexo anal.",
          detalles:
            "Características:\nMaterial: PVC\nLargo total: 10 cm.\nDiámetro mayor: 3 cm.\nColor: Fucsia\nUnidades: 1\n\nObservaciones:\nLibre de Ftalato y látex.\n\n¡IMPORTANTE!\nTodos los empaques de nuestros productos han sido creados para mantener de forma optima todas sus características Organolépticas, (olor, color, textura, etc). Cualquier alteración al packing original puede afectar la calidad del producto y cualquier proceso de garantía.",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 6,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 538,
              nombre: "",
              url: "https://i.ibb.co/KwLfkJv/Pics-Art-02-27-01-57-05.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 242,
          codigo: "6959532317855",
          nombre: "Funda Penis Sleeve",
          descripcion:
            "Funda Penis Sleeve, de material realístico diseñado como funda o extensión peneana de textura muy real, bastante cómoda y con anillo incluido para sostener la prótesis de pene en los testículos. Además de alargar el pene, lo engrosa y entrega una sensación real.",
          detalles:
            "Características:\nMaterial: TPR \nColor: Piel\n\nMedidas:\nLargo total: 15cm\nLargo extensión: 6,5cm\nDiámetro mayor: 3,8cm\n\nObservaciones:\nPrótesis para el pene\nDiseño realístico\nExtensión de seis centímetros\n\nRecomendaciones de Uso:\nLavar antes y después de usar\nUtilizar este producto con lubricante a base de agua\nGuardar en lugar seco\nNo secar con paños para evitar residuos\nUsar con preservativo o con lubricante a base de agua o híbrido\n",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 557,
              nombre: "",
              url: "https://i.ibb.co/hdSPt56/Pics-Art-02-09-02-32-47.jpg",
              descripcion: "",
            },
            {
              id: 558,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/2534-4667_2000x.jpg?v=1610924693",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 418,
          codigo: "1833364183213",
          nombre: "Lubricante Anal con Lidocaína 60ml",
          descripcion:
            "Esta fórmula se recomienda para aquellos que están buscando placer en lo inexplorado",
          detalles:
            "Al ser más espeso que nuestras otras variedades de geles lubricantes SEXLUB ANALUB podrá entregar una lubricación de larga duración y esa “capa extra” adicional necesaria para el juego anal. Libre de parabeno y perfecto para condones. Recuerda que esta zona del cuerpo no se auto lubrica y es necesario un eficiente método para disfrutar al máximo estas nuevas sensaciones.\n\nFórmula a base de agua\nConsistencia más espesa\nNo mancha.\nAprobado por el ISP.\nCon Lidocaína Anestésico",
          instagram: "",
          precio: 11990,
          descuento: 14.0,
          stock: 9,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1273,
              nombre: "",
              url: "https://i.ibb.co/5Tp2wJt/Pics-Art-08-14-01-25-11.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 205,
          codigo: "6914421000164",
          nombre: "Body de Encaje",
          descripcion: "Body de Encaje con espalda descubierta",
          detalles:
            "Tallas y Colores Disponibles:\n\n- Rojo: L - XL\n- Blanco: M - L - XL\n- Negro: M - L",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 8,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 363,
              nombre: "",
              url: "https://i.ibb.co/T2w0K5G/Pics-Art-01-18-11-26-01.jpg",
              descripcion: "",
            },
            {
              id: 364,
              nombre: "",
              url: "https://i.ibb.co/XDsvD9h/Pics-Art-01-18-11-20-46.jpg",
              descripcion: "",
            },
            {
              id: 365,
              nombre: "",
              url: "https://i.ibb.co/mSkWW3t/Pics-Art-01-18-11-29-20.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 268,
          codigo: "7798297919640",
          nombre: "Vibrador Realístico Cyber Skin N°3",
          descripcion:
            "Descubre la suavidad y multi-velocidad de los nuevos Vibradores Cyber Vibe by Caiman libres de Ftalatos.\nDe material muy suave y flexible, afelpados e hipoalergénicos entregarán máximo confort además de una experiencia muy realística gracias a sus diseños y textura.",
          detalles:
            "Posee una perilla para regular la intensidad de vibración. Utiliza 2 baterías AA (no incluidas)\n\nCaracterísticas:\nMaterial: PVC Cyber suave y flexible\nLargo total: 20 cm\nDiámetro: 4 cm\nMulti velocidades\nLibre de Ftalatos\nSuper suave, flexible y afelpado\nHipoalergénico\n\nObservaciones:\nBaterías: 2-AA (no incluidas)\n\nInstrucciones de cuidado:\nLimpiar con agua tibia y jabón neutro. \nDejar secar y guardar.\nMantener lejos del calor.\nQuitar baterías después de usar.",
          instagram: "",
          precio: 23990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1345,
              nombre: "",
              url: "https://sexshopmayorista.cl/14468-large_default/vibrador-cyber-vibe-americano-grande.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 358,
          codigo: "891875005647",
          nombre: "Estimulante Femenino Bebestible Pink Pussycat",
          descripcion:
            "Desarrollado para aumentar las sensaciones y el deseo, Pink Pussycat  Drink trabaja para mejorar el flujo sanguíneo genital, aumentando la sensibilidad y dando a su lubricación natural un impulso increíble.",
          detalles:
            "Pink Pussycat utiliza una mezcla patentada de potenciadores de la libido natural conocidos como extracto de goji, guiguidang, panax ginseng y corteza de canela, por lo que experimentará cero dolores de cabeza.\nCon resultados que duran hasta 72 horas, su libido se incrementa y tendrás más hambre de sexo que nunca antes!\nBeba media botella para una  energía moderada, o una botella llena para efectos máximos.\n\nContenido: 60ml",
          instagram: "",
          precio: 16490,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1073,
              nombre: "",
              url: "https://i.ibb.co/Fqx4dNS/Pics-Art-07-07-01-12-03.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 254,
          codigo: "855559003527",
          nombre: "Feromonas en Labial sabor Frutilla",
          descripcion:
            "Bálsamo Labial creado con aceites esenciales, no contiene mentol, parabenos, glicerina ni aminoácidos, convirtiéndolo en un producto perfecto para el uso diario.\nLa mezcla de feromonas atrae a hombres y mujeres por lo que funciona para parejas gays y heterosexuales!",
          detalles:
            "Características:\n\nSabor: Frutilla\nContenido: 2g.\nNo contiene mentol, parabenos, glicerina ni aminoácidos.\n\n\nIngredientes: Beeswax, Proprietary Essential Oil Blend including: Prunus Amygdalus Dulcis (Sweet Almond Oil), Cinnamomum Zeylanicum (Cinnamon Oil), Natural Tocopherols (Vitamin E), Rosmarinus Officinalis (Rosemary Oil Extract), Pheromones.\n\nNo contiene Mentol, glicerina ni parabenos.",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 546,
              nombre: "",
              url: "https://i.ibb.co/wCKcNyZ/Pics-Art-02-27-02-10-29.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 18,
              nombre: "Feromonas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 181,
          codigo: "7898626040257",
          nombre: "Gel Estimulante Vibration Sabor Fresa",
          descripcion:
            "El Gel Estimulante Vibration Morango Fresa permite la aplicación en las zonas erógenas, proporcionando un efecto electrizante o de vibración liquida, la cual provoca una rápida excitación. Además es comestible lo que permite implementarlo para el sexo oral con un delicioso sabor a Fresa.",
          detalles:
            "Contenido: 17ml\nEfecto: Vibratorio\nSabor: Fresa\n\nObservaciones:\nAplicar en las partes deseadas esparcir dando masajes suaves y comenzará a sentir los efectos vibratorios.\nMantener en lugar fresco y seco.\n\nImportante\nLos resultados dependerán del uso correcto del producto y del organismo de cada persona.\nAntes de usa un producto de cosmética erótica se recomienda revisar los componentes de este para evitar reacciones alérgicas.\nEn caso de irritación suspender y consultar con su dermatólogo.",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 319,
              nombre: "",
              url: "https://i.ibb.co/ZHdx84f/1609808562793.jpg",
              descripcion: "",
            },
            {
              id: 320,
              nombre: "",
              url: "https://i.ibb.co/CQd9tQX/1609808562667.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 311,
          codigo: "712594133261",
          nombre: "Body Stella - Talla M",
          descripcion:
            "Stella es un bello body semi transparente de cuello alto con aplicaciones de mostacillas brillantes y marga larga. Su parte inferior es de una tela elasticada opaca negra cubriente. Trae un broche de seguridad para fácil uso.",
          detalles:
            "Sin Forro\nBroche de seguridad\nMaterial: 33% cotton 63% poliéster 4% spandex\nTalla: M\n\nMedidas: \nAncho contorno: 72 cm\nLargo total: 70 cm ",
          instagram: "",
          precio: 23990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 740,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14499-large_default/body-stella.jpg",
              descripcion: "",
            },
            {
              id: 830,
              nombre: "",
              url: "https://i.ibb.co/9pFJbWh/Pics-Art-03-21-12-47-24.jpg",
              descripcion: "",
            },
            {
              id: 831,
              nombre: "",
              url: "https://i.ibb.co/VLsRynV/Pics-Art-03-21-12-48-03.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 492,
          codigo: "855559007693",
          nombre: "Excitante Femenino On Ice (sachet)",
          descripcion:
            "El aceite On efecto frío produce una excitación directa una vez aplicado en el clítoris, formulado con aceites esenciales puros y extractos, provocando en la mayoría de las mujeres sensación de mayor lubricación.",
          detalles:
            "Características:\nContenido: 0.3ml\nEfecto Frío\nPulsa y Vibra\nAumenta el flujo sanguíneo y las sensaciones\nLibre de Glicerina\nLibre de Parabeno\nLibre de Gluten\n\nIngredientes:\nSweet Almond Oil,Peppermint Oil(perpene free)Cinnamomun Zeylanicum,Natural Tocopherols(Vitamina E); silica, Natural Flavors, Rosemary Oil Extract.\n\nModo de Uso:\nAplicar en 1 a 2 gotas en el clítoris, luego de 2 minutos aproximados comienzan sus efectos, duración aproximada 45 minutos.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1317,
              nombre: "",
              url: "https://i.ibb.co/bdSM2zC/Pics-Art-08-14-01-13-30.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 458,
          codigo: "825156109731",
          nombre: "Juego The Oral Sex Card",
          descripcion: "Juego de parejas enfocado al sexo oral. Idioma Ingles.",
          detalles:
            "Kheper Games nos sorprende con juegos para parejas, donde encontrarás pruebas aun más sensuales, deliciosas y atrevidas. Ideal para fortalecer las relaciones de pareja o para innovar en los juegos sexuales previos.\n\nEl Juego Juego The Oral Sex Card permite innovar en pareja proporcionando 50 ideas para la práctica del sexo oral. Solo deben escoger una carta que contiene el juego de parejas y realizar la posición sexual que ilustra la tarjeta.\n\nContenido:\n50 Tarjetas de posturas sexuales\n\nObservaciones:\nJuego dirigido a parejas.\nEl juego contiene las instrucciones de uso en inglés",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1281,
              nombre: "",
              url: "https://i.ibb.co/9Vp1ytb/Pics-Art-08-17-02-10-03.jpg",
              descripcion: "",
            },
            {
              id: 1282,
              nombre: "",
              url: "https://sexshopmayorista.cl/13475-large_default/juego-the-oral-sex-card.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 381,
          codigo: "6914421000010",
          nombre: "Pintura de Chocolate Amargo",
          descripcion:
            "Elige la parte favorita del cuerpo de tu pareja, aplica el chocolate semi-líquido y comienza el ritual de lamer y chupar\n\nAbsolutamente comestible y de sabor delicioso",
          detalles: "* No mancha\nCada pote contiene 55grs",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1039,
              nombre: "",
              url: "https://i.ibb.co/qF3PsRf/Pics-Art-07-07-12-55-45.jpg",
              descripcion: "",
            },
            {
              id: 1040,
              nombre: "",
              url: "https://i.ibb.co/c8pzQXb/Pics-Art-07-07-12-54-26.jpg",
              descripcion: "",
            },
            {
              id: 1041,
              nombre: "",
              url: "https://i.ibb.co/qgs3yqt/Pics-Art-07-07-12-57-16.jpg",
              descripcion: "",
            },
            {
              id: 1042,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/7079929/thumb/540/540?1612294964",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 86,
          codigo: "789185127596",
          nombre: "Bralette Carlotta - Talla M",
          descripcion:
            "Carlotta es un  brallete de encaje con choker incluido y delicado detalle de cinta bajo el busto. Puedes ajustar los tirantes de hombro para mejor calce.\n",
          detalles:
            "Sin Forro\n\nTirantes de hombro ajustables\n\nMaterial: 95% poliéster 5% spandex\n\nTalla: M\nColor: Rojo\n\nMedidas bralette: \nAncho contorno busto: 70cm\nLargo chocker: 25 cm\nAncho contorno chocker: 34cm ",
          instagram: "",
          precio: 7990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 727,
              nombre: "",
              url: "https://i.ibb.co/BT441cW/Pics-Art-03-21-12-38-25.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 416,
          codigo: "127035243346",
          nombre: "Lubricante Retardante 60ml Sexlub",
          descripcion:
            "Lubricante retardante masculino, combate la eyaculación precoz e hipersensibilidad. A base de agua, no mancha, 60ml.",
          detalles:
            "Con este lubricante retardante masculino lograrás tener una relación sexual más larga y satisfactoria para ti y para tu pareja.\n\nEspecialmente fabricado para hombres con problemas de eyaculación precoz. Este lubricante retarda la eyaculación y te brinda mayor placer para que disfrutes por más tiempo.\n\nSexlub hará de tus relaciones una experiencia nueva con sus exquisitas presentaciones.\n\nProducto hipoalergénico\nA base de Agua\nNo Mancha\nCompatible con Condones y juguetes sexuales.",
          instagram: "",
          precio: 11990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1270,
              nombre: "",
              url: "https://i.ibb.co/yQrjKrk/Pics-Art-08-14-01-25-55.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 363,
          codigo: "6914421000096",
          nombre: "Sachet Lubricante Frutilla",
          descripcion:
            "Totalmente renovado en su fórmula a base de agua. Se siente como la lubricación natural, pero muy suave y de largo aliento en su rendimiento.\n\nEs sumamente hidratante y refrescante. \n\nMuy adecuado para acompañar el uso de juguetes sexuales\n\nLa línea de lubricantes íntimos de Secretos de Amor está pensada para consumidores que buscan alternativas más saludables en lo que a bienestar sexual se refiere.",
          detalles:
            "Todas las fórmulas incluyen una combinación de ingredientes naturales, orgánicos, eco-certificados. Son libres de glicerina, balanceados en PH y funcionan a la perfección con el cuerpo femenino. Son una gran opción para personas con sensibilidad o alergias\n\n\nContenido 6ml / sachet\nSabor: Frutilla",
          instagram: "",
          precio: 2490,
          descuento: 14.0,
          stock: 8,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1086,
              nombre: "",
              url: "https://i.ibb.co/2F78shJ/Pics-Art-07-07-02-18-50.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 374,
          codigo: "697309064019",
          nombre: "Lubricante Frutilla/Champaña Toko",
          descripcion:
            "La línea Aroma de Shunga tiene exquisitos lubricantes con sabores frutales, especiales para excitar y guiar los sentidos. Además se caracterizan por su sensación ultra sedosa y larga duración.\n\nTodos los lubricantes en base de agua están pensados para facilitar la penetración, el sexo manual, o simplemente para ofrecer una sensación aún más placentera. La gracia de esta línea es que además brinda la posibilidad de estimular el cuerpo del otro a través del sentido del gusto, aprovechando los deliciosos sabores que trae esta excelente línea de productos.",
          detalles:
            "Características:\n- 165 ml\n- Hipoalergénico\n- Base de agua\n- Comestible\n- No mancha\n- No contiene azúcar\n- Compatible con látex y juguetes de silicona\n- Fabricado en Canadá",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 964,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/657226/thumb/540/540?1622497849",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 74,
          codigo: "7898626040189",
          nombre: "Potenciador de Erecciones Super Macho Striper",
          descripcion:
            "Gel excitante masculino, fortalecedor y potenciador de la erección.\nEl Gel Excitante Super Macho Striper, potencia la erección del pene y la mantiene por más tiempo. Ideal para hombres que les cuesta mantener la erección o para acelerarla después de la eyaculación. No renuncies a una vida sexual activa, con Súper Macho Striper vuelve a ser la máquina que fuiste.\n",
          detalles:
            "Características:\nContenido: 8g\nEstimulante de erección\nExcitante masculino\nFacilita la erección\nMantener en lugar fresco\nEvitar contacto con los ojos\nNo ingerir\nDescontinuar su uso en caso de irritación\nModo de aplicación:\nAplicar en el pene una o dos porciones y masajear, unos minutos antes de la penetración.\n",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 326,
              nombre: "",
              url: "https://i.ibb.co/qN8vC0X/1609808563100.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 9,
              nombre: "Potenciadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 364,
          codigo: "6914421000188",
          nombre: "Gel Beso Sensual RON Sachet",
          descripcion: "Práctico y conveniente pack de sachets para un uso",
          detalles:
            "Haz sonrojar a tu pareja con este gel de efecto calor comestible sobre la piel, que se entibia cuando soplas sobre él.\nMasajea una pequeña área del cuerpo, sopla suavemente y llegará la calidez deseada.\n\nEste gel tiene un exquisito sabor a ron caribeño.\n\nContenido: 6ml ",
          instagram: "",
          precio: 2490,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1067,
              nombre: "",
              url: "https://i.ibb.co/sqVsR5D/Pics-Art-07-07-02-17-50.jpg",
              descripcion: "",
            },
            {
              id: 1068,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/10132054/thumb/540/540?1618071047",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 341,
          codigo: "7809604505033",
          nombre: "Preservativos Sensor Plus - Ribbed",
          descripcion:
            "Ribbed es un condón entretenido y excitante a la vez, puesto que posee relieves en forma de espiral los cuales estimularán a tu pareja hasta conseguir un clímax supremo. Obtendrás sensaciones únicas y más intensas, no te arrepentirás.",
          detalles:
            "Características\nPreservativo de látex\nTesteados Electrónicamente\nCaja incluye 3 unidades\n\nMedidas\n18cm de largo\n5,2cm de ancho\n0,07cm de espesor",
          instagram: "",
          precio: 1990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1024,
              nombre: "",
              url: "https://i.ibb.co/DzPS7Mh/Pics-Art-07-07-02-30-44.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 396,
          codigo: "7766432810121",
          nombre: "Vibrador Cliterific Beige",
          descripcion:
            "Un excelente juguete erótico para experimentar y disfrutar, es sin duda el Vibrador Cliterific, un Vibrador  sencillo y muy fácil de utilizar.\n\nEn un juguete con vibración regulable, la cual se controla desde la parte inferior del dildo, como un rodillo que va aumentando y disminuyendo la intensidad de vibración. Utiliza 2 pilas AA, además siempre se recomienda su uso con lubricante en base a agua o híbrido.\n\nCliterific es un vibrador que posee unas porosidades (relieves) en la parte inferior del juguete, las cuales servirán para estimular el clítoris de manera intensa.",
          detalles:
            "Características:\nMaterial: Silicona\nPeso: 159 grs\nLargo Total: 20cm\nLargo Insertable: 17cm\nDiámetro: 3.5cm\nBaterías: 2-AA\n\nObservaciones:\nBaterías no incluidas\nVelocidad regulable",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1342,
              nombre: "",
              url: "https://sexshopmayorista.cl/10763-large_default/vibrador-cliterific-piel.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 480,
          codigo: "7729300001113",
          nombre: "Aceite para Masajes sabor Caramelo (50ml)",
          descripcion:
            "Descubre los Geles Comestibles Erotic, con efecto calor y 100% libres de azúcar, de exquisito aroma y sabor, entrega a tu pareja el mejor sexo oral de la vida y logra una noche inolvidable, se puede retirar fácilmente con agua tibia y jabón neutro. No dejes pasar más tiempo y prueba nuestra línea de Geles comestibles en diferentes sabores. \nStarsex te trae nuevos sabores para que disfrutes junto a quien más quieras de exquisitos juegos eróticos, masajes y un delicioso sexo oral lo que aumentará la excitación en ambos. ",
          detalles:
            "Características:\nSabor Caramelo\nGel comestible con efecto calor\nEfecto: efecto calor y comestible\nContenido: 50 ml\nIngredientes: Glicerina, propilengycol, Agua, Saborizante\n\nObservaciones:\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. También se puede aplicar en distintas zonas erógenas para un rico sexo oral. Después de aplicarlo debes soplar y tu pareja sentirá una agradable sensación de calor.\nEliminar el exceso con agua tibia y jabón neutro.",
          instagram: "",
          precio: 7990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1323,
              nombre: "",
              url: "https://i.ibb.co/60v2gM3/Pics-Art-08-14-01-19-04.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 349,
          codigo: "6970260907446",
          nombre: "Huevo Masturbador Gigante Climax Azul",
          descripcion:
            "El Huevo Masturbador Gigante - Vertical , es un estimulador masculino con un diseño novedoso y funcional, gracias a su material TPE muy elástico y flexible y sus texturas verticales acanaladas intensificaran el placer. Libre de Phthalatos , seguro para la piel. Totalmente discreto y práctico, ideal para transportar , además incluye un tubo de lubricante a base de agua para poder utilizarlo en el momento. Totalmente adaptable gracias a su material elástico y flexible.",
          detalles:
            "Características:\nMasturbador Masculino\nHuevo Masturbador\nMaterial: TPE Flexible\nTextura con ondas verticales\nColor: Transparente\n\nMedidas:\nLargo sin extender: 12 cm\nDiámetro sin extender: 7 cm\nLargo penetrable sin extender: 10 cm\nIncluye Lubricante a base de agua\n\nObservaciones:\nEl Huevo Masturbador Gigante - Vertical, es de muy fácil limpieza, se recomienda lavar con agua tibia y jabón neutro y dejar secar antes de guardar. Sexshop Mayorista recomienda siempre el uso con lubricantes a base a agua.",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1060,
              nombre: "",
              url: "https://i.ibb.co/6Ptjbs5/Pics-Art-07-07-02-24-14.jpg",
              descripcion: "",
            },
            {
              id: 1061,
              nombre: "",
              url: "https://sexshopmayorista.cl/14302-large_default/huevo-masturbador-giant-egg.jpg",
              descripcion: "",
            },
            {
              id: 1062,
              nombre: "",
              url: "https://sexshopmayorista.cl/14292-large_default/huevo-masturbador-giant-egg.jpg",
              descripcion: "",
            },
            {
              id: 1063,
              nombre: "",
              url: "https://sexshopmayorista.cl/14301-large_default/huevo-masturbador-giant-egg.jpg",
              descripcion: "",
            },
            {
              id: 1064,
              nombre: "",
              url: "https://sexshopmayorista.cl/14295-large_default/huevo-masturbador-giant-egg.jpg",
              descripcion: "",
            },
            {
              id: 1065,
              nombre: "",
              url: "https://sexshopmayorista.cl/14297-large_default/huevo-masturbador-giant-egg.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 16,
              nombre: "Masturbadores Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 66,
          codigo: "777010011004",
          nombre: "Lubricante Orgánico (100ml)",
          descripcion:
            "Lubricante Orgánico 100% vegano y sin gluten, a base de goma xantana, sin parabenos ni formaldehidos, de sedosa textura y compatible con el látex y preservativos. Perfecto para lubricar zonas erógenas y mejorar la estimulación.",
          detalles:
            "Ingredientes: Agua/goma Xanthan, DMDM hidantoína y yodopropinilo butilcarbamato\n\nCaracterísticas:\nLubricante organico\nDe base acuosa\nEnvase con dispensador para una fácil aplicación\nCompatible con preservativos y juguetes sexuales\nSuave olor\nSin parabenos\nSin gluten\nVegano\nCompatible con latex, preservativos, juguetes, sexo vaginal, anal.\nUso externo e interno\n\nModo de uso:\nAplicar la cantidad deseada sobre el cuerpo esparcir hasta que el lubricante cubra toda el área. Se puede usar externa e internamente.",
          instagram: "",
          precio: 11990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 884,
              nombre: "",
              url: "https://i.ibb.co/nkHKFWm/Pics-Art-05-08-12-01-14.jpg",
              descripcion: "",
            },
            {
              id: 885,
              nombre: "",
              url: "https://i.ibb.co/Xb8F4wr/IMG-20201031-151425.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 445,
          codigo: "667000809103",
          nombre: "Masturbador Vagina Realista Mini Lady",
          descripcion:
            "El Masturbador Vagina Realista Mini, entrega mayor placer al momento de la masturbación, por su material realístico que es el Cyber Skin y por la textura interna que presenta, asemejando el interior de una mujer.",
          detalles:
            "Características:\nMaterial: Cyber Skin\nLargo total: 12.5 cm.\nLago insertable: 12 cm Aprox.\nColor: Piel\n\nObservaciones:\nMasturbador pequeño\nNo posee orificio de limpieza.\nLavar con agua tibia y jabón neutro después de utilizar\nSecar el interior con aire frio del secador de ser necesario",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1260,
              nombre: "",
              url: "https://i.ibb.co/gg9HKyy/Pics-Art-08-17-02-17-47.jpg",
              descripcion: "",
            },
            {
              id: 1261,
              nombre: "",
              url: "https://sexshopmayorista.cl/7785-large_default/masturbador-vagina-realista-mini.jpg",
              descripcion: "",
            },
            {
              id: 1262,
              nombre: "",
              url: "https://sexshopmayorista.cl/7786-large_default/masturbador-vagina-realista-mini.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 16,
              nombre: "Masturbadores Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 392,
          codigo: "7798297913228",
          nombre: "Vibrador Funny Oruga",
          descripcion:
            "Un producto con vibrador interno cuyo formato escalonado le permite disfrutar de una penetración progresiva, que sumado a la intensidad del vibro intensificará tus noches de placer.  Este articulo tiene la particularidad de ser doble, lo que permite también la estimulación del clítoris. La línea Funny es de una material siliconado que permite tener sensibilidad ya que se adapta perfectamente a la temperatura corporal. Este articulo es resistente al agua.",
          detalles:
            "Tipo: Dildo con Vibro.\nMedida: 17 cm x 4  Ø.\nPilas: 2 Pilas AA (no incluidas).\nWaterproof\nPhthalate Free / Libre de Ftalato.",
          instagram: "",
          precio: 22990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1004,
              nombre: "",
              url: "https://i.ibb.co/9nSHv6S/Pics-Art-07-07-02-15-37.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 384,
          codigo: "4049369016501",
          nombre: "Satisfyer Balls C03 single (set de 3)",
          descripcion:
            "El entrenamiento siempre resulta más divertido cuando se pueden medir\nlos progresos, por ello este lujurioso set incluye directamente 3 bolas\nde placer: las tres tienen el mismo tamaño, con un diámetro de 3,8 cm,\npero varían en el peso. Empieza con la más ligera y ve aumentando\ngradualmente. En este set, la bola más ligera tiene un peso de 61,9 g\n(normal), la mediana tiene un peso de 82,1 g (experimentado) y la más\npesada tiene un peso de 97,9 g (avanzado)",
          detalles:
            "Silicona hipoalergénica\n\nAplicación segura gracias a la anilla de recuperación\n\nFácil de limpiar\n\nSet para el entrenamiento de orgasmos: 61,9 g (normal), 82,1 g (experimentado), 97,9 g (avanzado)\n\nDiámetro: 3,8 cm\n\nSexo más divertido para él y para ella mediante estrechamiento de la musculatura",
          instagram: "",
          precio: 23990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1015,
              nombre: "",
              url: "https://i.ibb.co/nRcszTy/Pics-Art-07-07-01-09-10.jpg",
              descripcion: "",
            },
            {
              id: 1016,
              nombre: "",
              url: "https://i.ibb.co/vjzR4N9/Pics-Art-07-07-01-10-32.jpg",
              descripcion: "",
            },
            {
              id: 1017,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/6604214/thumb/540/540?1621460101",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 69,
          codigo: "777010010601",
          nombre: "Lubricante Orgánico (60ml)",
          descripcion:
            "Lubricante Orgánico 100% vegano y sin gluten, a base de goma xantana, sin parabenos ni formaldehidos, de sedosa textura y compatible con el látex y preservativos. Perfecto para lubricar zonas erógenas y mejorar la estimulación.",
          detalles:
            "Ingredientes: Agua/goma Xanthan, DMDM hidantoína y yodopropinilo butilcarbamato\n\nCaracterísticas:\nFormato: 60ml\nLubricante organico\nDe base acuosa\nEnvase con dispensador para una fácil aplicación\nCompatible con preservativos y juguetes sexuales\nSuave olor\nSin parabenos\nSin gluten\nVegano\nCompatible con latex, preservativos, juguetes, sexo vaginal, anal.\nUso externo e interno\n\nModo de uso:\nAplicar la cantidad deseada sobre el cuerpo esparcir hasta que el lubricante cubra toda el área. Se puede usar externa e internamente.",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 630,
              nombre: "",
              url: "https://i.ibb.co/7nx5BkR/Pics-Art-02-28-08-17-04.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 279,
          codigo: "4560220550649",
          nombre: "Masturbador Masculino Tenga Eggs Twister",
          descripcion:
            "La ciencia ha confirmado que la masturbación es saludable para la salud. De hecho, está comprobado que fortalece el sistema inmunológico, mejora el humor, mejora la calidad de la piel, potencia el autoestima, previene ciertas enfermedades, fortalece la musculatura pélvica, entre muchos otros beneficios. Entendiendo esto es que se hace necesario hacer de la masturbación una experiencia entretenida. Y para esto está el huevo masturbador Tenga que tiene la ventaja de venir con un lubricante íntimo que tiene como misión suavizar y mejorar la experiencia con el producto hecho exclusivamente para hombres. Se trata de un producto que ha sido testeado dermatológicamente y que es hipoalergénico, por lo que se puede usar con total confianza ya que no representa ningún riesgo para la salud íntima. Es apto para todos los tamaños y su material es súper elástico y muy suave al tacto, por lo que es cómodo y práctico de usar al momento de la masturbación. Viene en colores y el lubricante que incluye está en una presentación tipo sachet que se encuentra en su interior. El uso de este producto es fundamental para todos los juguetes sexuales, ya que de esta forma se evita cualquier tipo de roce molesto. Por otro lado, es aconsejable no usar más de dos veces el producto para resguardar tu higiene. Este huevo masturbador puede ser usado por el hombre en solitario, pero también queda la invitación abierta a que lo pueda ocupar con su pareja, como una forma de innovar en el sexo, para agregarle sorpresa o entretención al sexo. Otra de las ventajas de este masturbador es su pequeño tamaño, ya que lo hace muy fácil de manipular y también de transportar a donde quiera que vayas.",
          detalles:
            "Características:\nHuevo Masturbador\nMasturbador masculino\nSúper Elasticados\nReutilizable (lavar con agua y jabón)\nHipoalergénico\nIncluye Lubricante Tenga\nSe acomoda a todos los tamaños\nMaterial Súper suave y flexible\nRelieves en el interior con forma de Ondas verticales\n\nObservaciones:\nLa higiene del producto es primordial, por lo que se aconseja lavarlo antes de usarlo y después de utilizarlo, ya que de este modo, se estará evitando cualquier riesgo de infección. Para esto, se puede usar agua tibia y jabón neutro o también se puede emplear limpiadores especiales para los juguetes sexuales que están disponibles en el mercado.",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 623,
              nombre: "",
              url: "https://i.ibb.co/LJgbxjd/Pics-Art-02-27-01-44-13.jpg",
              descripcion: "",
            },
            {
              id: 624,
              nombre: "",
              url: "https://i.ibb.co/09ckzpq/Pics-Art-02-27-01-39-16.jpg",
              descripcion: "",
            },
            {
              id: 625,
              nombre: "",
              url: "https://jvsportswear.net/wp-content/uploads/2020/08/Tenga-eggs-6.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 16,
              nombre: "Masturbadores Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 369,
          codigo: "7648795033440",
          nombre: "Lubricante Intensificador Amazing",
          descripcion:
            "Se siente diferente a cualquier otro lubricante que hayas probado, pues dentro de los ingredientes de su formulación contiene intensificador ON, con el cual se logra una mezcla súper excitante entre lubricante y estimulante. Produce un efecto cálido y además crea una sensación de cosquilleo en la zona genital.",
          detalles:
            "Delicioso de utilizar ya sea para facilitar la penetración, como también para otorgar sensaciones intensas al aplicarlo en forma externa en los genitales.\n\nEspecificaciones\nContenido: 125 ml\nEn base de agua\nCompatible con juguetes y preservativos",
          instagram: "",
          precio: 17990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1049,
              nombre: "",
              url: "https://i.ibb.co/hDPRL75/Pics-Art-07-07-01-01-43.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 38,
          codigo: "777000804005",
          nombre: "Lubricante Masculino Efecto Prolongado (Sachet 5ml)",
          descripcion:
            "Con el Lubricante Masculino Efecto Prolongado podrás  disfrutar de erecciones más duraderas buscando que  tu encuentro sexual sea más placentero. Este Lubricante retarda la eyaculación y prolonga la erección gracias a su componente natural  que funciona como un sedante y potenciador a su vez  llamado Eugenol. \n\nPuedes usarlo con tu pareja o en solitario, las posibilidades son infinitas \n\n¡Solo debe aplicar una pequeña cantidad sobre el pene erecto y masajear hasta que se absorba !",
          detalles:
            "Ingredientes:\nGlycerol, Hydroxyethyl cellulose, Silicone oil, PureWater, Eugenol, Otros\n\nCaracterísticas:\nLubricante masculino.\nRetardante.\nLubricante Hibrido, mezcla de silicona con agua \nApto para latex y juguetes de silicona \nContenido sachet 5ml.\nEfecto calor",
          instagram: "",
          precio: 1490,
          descuento: 14.0,
          stock: 173,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 297,
              nombre: "",
              url: "https://i.ibb.co/PTxdfkW/1608164511355.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 27,
          codigo: "7898626040356",
          nombre: "Estimulante Sexual Vulcano",
          descripcion:
            "Si quieres potenciar tu experiencia sexual, el Gel Excitante Vulcano de Intt es para ti. Gracias a su fórmula, provoca un efecto excitante que te estimula y te hace sentir como nunca antes. Además, potencia el orgasmo femenino, masculino. Ahora puedes cumplir tus fantasías con tus sentidos amplificados y disfruta de una excitante experiencia sexual que querrás repetir siempre con el Gel Excitante Vulcano de Intt.",
          detalles:
            "Excitante femenino y masculino\nEstimulante\nAumentador del libido\nFacilitador del orgasmo\nMantener en lugar fresco\nEvitar contacto con los ojos\n\nModo de aplicación:\nAplicar la cantidad deseada sobre el glande, clítoris o vagina, y masajear suavemente hasta que el gel se absorba. Válida por 36 meses desde la fecha de elaboración.\n",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 328,
              nombre: "",
              url: "https://i.ibb.co/nkNcPm1/1609808562607.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 208,
          codigo: "603912262650",
          nombre: "Peta Zeta Sabor Frutilla",
          descripcion:
            "Peta Zeta es un delicioso caramelo en forma de pequeños cristales que en contacto con la saliva “estallan”, provocando un pequeño hormigueo en la boca y en las partes intimas de tu pareja, es ideal para el sexo oral.\nPeta Zeta te ayuda a salir de la rutina y jugar con distintos movimientos para el disfrute en compañía, pasar un rato distinto y divertido.\nEl uso de Peta Zeta es muy sencillo, solo debes poner los dulces dentro de la boca antes de comenzar a dar sexo oral a la pareja y listo.\nCada sachet trae el contenido suficiente para poder realizar varias sesiones de sexo oral e incluso intercambiar en la pareja si se desea ya que es compatible tanto para hombres como para mujeres.\nEs ideal para quienes buscan nuevas sensaciones y salir de la rutina o para quienes simplemente aman el sexo oral y quieren seguir experimentándolo y lo más importante, a diferencia de otro tipo de elementos comestibles que se utilizan en el sexo, Peta Zeta no deja pegajoso.",
          detalles:
            "Características:\nCaramelo efervescente\nPara sexo oral \nUnisex\n\nIngredientes: Azúcar, jarabe de maíz, ácido cítrico, ácido láctico, lactosa\n\nObservaciones: Vierte una porción en tu boca del caramelo efervescente y practica sexo oral. Libre de grasas saturadas y trans, colesterol, sodio. No aplicar directo en genitales \n\nImportante: Este producto contiene azúcar y lactosa por lo que si eres intolerante a la lactosa, no es recomendable que lo utilices.",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 537,
              nombre: "",
              url: "https://i.ibb.co/bJHtf05/Pics-Art-02-09-02-29-11.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 300,
          codigo: "825156102350",
          nombre: "Juego Sexual Lésbico de Cartas",
          descripcion:
            "Divertido juego de cartas Lesbian de la línea Sex! De Kheper Game. Perfecto para descubrir los deseos sexuales  y construir fantasías con las 50 tarjetas ilustrativas de posiciones sexuales y actividades de juego previo.\nLos colores de las tarjetas varían según la intensidad de la acción a realizar. Incluye 3 tarjetas con instrucciones para tres juegos diferentes. Ideal para jugar en pareja con la posibilidad de incluir a un tercer o cuarto participante.",
          detalles:
            "Contenido:\n50 Cartas de posiciones sexuales\n3 Cartas de reglas de juego\n1 Carta de “Libere su Fantasía”\n\nObservaciones:\nDirigido a parejas o grupos\nDisponible en Español, inglés, francés y alemán.",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 736,
              nombre: "",
              url: "https://i.ibb.co/BjpqbwL/Pics-Art-03-21-12-32-32.jpg",
              descripcion: "",
            },
            {
              id: 737,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/14480-large_default/lesbian-sex-juego-sexual-de-cartas.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 24,
              nombre: "Juegos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 7,
          codigo: "7809604500533",
          nombre: "Preservativo SensorPlus Triple Estimulación",
          descripcion:
            "Condones con triple estimulación, texturizados por fuera para el placer de ella, ajuste anatómico y extra delgado para máxima sensibilidad",
          detalles: "Dimensiones: 180mm x 52mm",
          instagram: "",
          precio: 1990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1081,
              nombre: "",
              url: "https://i.ibb.co/M9cPHKt/Pics-Art-07-07-02-27-06.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 90,
          codigo: "6959532311181",
          nombre: "Bala Vibradora Conejito Boyce",
          descripcion:
            "La Bala Vibradora Conejito Boyce presenta un diseño con orejas de conejo en la zona superior, la cual facilita la estimulación del clítoris. Es fabricado de silicona suave, un material amigable con el cuerpo humano, su diseño es de fácil uso. Las diez intensidades de vibración que posee se manejan desde un botón alojado en la parte superior de la bala vibradora",
          detalles:
            "Características:\nMaterial: Silicona\nColor: Rosado\nBaterías: 1 AAA (no incluida)\n3 Intensidades de vibración\n7 intensidades de pulsación\nMedidas:\nLargo total: 12,5cm\nDiámetro mayor: 2,5cm\nObservaciones:\nBala vibradora\nEstimulador de clítoris\nResistente al agua\nModo de uso:\nPresionar el botón que se encuentra en la parte superior del control remoto para encenderlo, apagarlo y cambiar las intensidades de vibración.\n",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 6,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 580,
              nombre: "",
              url: "https://i.ibb.co/Gk30YMW/Pics-Art-02-09-02-38-16.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 147,
          codigo: "6914421000065",
          nombre: "Babydoll Negro con Rojo",
          descripcion:
            "Sexy babydoll negro, con hermosos detalles de encaje rojo en el busto. Hará encender tus noches de pasión. Incluye colales ",
          detalles: "Tallas Disponibles:\n- M\n- L\n- XL\n",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 194,
              nombre: "",
              url: "https://i.ibb.co/2yCLrB0/1607005989727.jpg",
              descripcion: "",
            },
            {
              id: 195,
              nombre: "",
              url: "https://i.ibb.co/BngSz0t/1607005989710.jpg",
              descripcion: "",
            },
            {
              id: 196,
              nombre: "",
              url: "https://i.ibb.co/hR5qrqK/1607005989693.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 146,
          codigo: "6914421000256",
          nombre: "Babydoll Azul Floreado",
          descripcion:
            "Hermoso y sexy babydoll de color azul, con detalles de encaje floreado en la zona del busto. Incluye Colales.",
          detalles: "Tallas Disponibles.\n- M\n- L\n- XL",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 191,
              nombre: "",
              url: "https://i.ibb.co/1mQSC5T/1606606137760.jpg",
              descripcion: "",
            },
            {
              id: 192,
              nombre: "",
              url: "https://i.ibb.co/JKKfVNM/1606606137742.jpg",
              descripcion: "",
            },
            {
              id: 193,
              nombre: "",
              url: "https://i.ibb.co/KXX3N5H/1606606137724.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 34,
              nombre: "Ofertas Relámpago",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 111,
          codigo: "4410200511118",
          nombre: "Bolas Chinas On",
          descripcion:
            "Las Bolas Chinas On o mas conocidas como Bolas Keggel  son dos esferas unidas que poseen en su interior una bola de menor tamaño que al moverse golpean las paredes de las esferas externas, lo que produce una suave vibración . En uno de sus extremos posee una cuerda para la extracción.\n\nEl uso de las bolas crea una estimulación sutil y estan totalmente diseñadas para ejecitar el Piso Pelvico y asi contribuir a nuestro bienestar sexual\n\nEl ejercicio del músculo pélvico mejora el tono de la vagina y con ello, su salud, incrementando la sensibilidad de la zona y potenciando la sensación de placer \nMejoran notablemente la lubricación vaginal. Las bolitas metálicas del interior golpean las paredes vaginales estimulando la circulación sanguínea de la zona.\nLa vagina al verse fortalecida mejora el rendimiento sexual así como la incontinencia urinaria leve.\n\nRecuerda que ademas puedes visitar a un especialista de Piso Pelvico",
          detalles:
            "Características:\n\nDiámetro: 3.5cm\nCircunferencia: 12cm\nPeso: 50grs\nMaterial: Silicona\nColor: Según stock\n\nBeneficios:\nEl ejercicio del músculo pélvico mejora el tono de la vagina y con ello, su salud, incrementando la sensibilidad de la zona y potenciando la sensación orgásmica.\nMejoran notablemente la lubricación vaginal. Las bolitas metálicas del interior golpean las paredes vaginales estimulando la circulación sanguínea de la zona.\nLa vagina al verse fortalecida mejora el rendimiento sexual así como la incontinencia urinaria leve.",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 149,
              nombre: "",
              url: "https://i.ibb.co/qyYZ7z5/IMG-20201108-153649.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 52,
          codigo: "7898495410335",
          nombre: "Gel Estimulante Electroshock Sabor Chocolate",
          descripcion:
            "El Gel Excitante Electro Shock de Intt presenta un formato de 8g, de diseño discreto con un gel estimulante, efecto de vibración. Al aplicar Electro Shock en los genitales y zonas erógenas, sentirás por primera vez lo que es la vibración líquida, dando paso a un aumento del libido. Además viene con exquisitos sabores, lo cual permite que lo apliques y lo comas de las zonas deseadas del cuerpo de tu amante.\nCon el Gel Excitante Electro Shock, podrás potenciar tus encuentros sexuales, ya que elevará tu libido proporcionando un momento inolvidable.",
          detalles:
            "Características:\nSabor: Chocolate\nGel excitante unisex\nContenido: 8g\nEfecto: Vibración\n\nIngredientes: Aqua, Glycerin, Menthol, Propylene Glycol, Iodopropynyl Butylcarbamate, Sodium, Saccarin, Triethanolamine. Contenido: 8g Efecto: Vibración.\n\nObservaciones: Gel excitante con efecto de vibración. De uso unisex. Producto comestible. Mantener en lugar fresco. Evitar contacto con los ojos.\n\nModo de aplicación: Aplicar la cantidad deseada y masajear suavemente.",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 23,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 249,
              nombre: "",
              url: "https://i.ibb.co/qNSwV3r/1608164511456.jpg",
              descripcion: "",
            },
            {
              id: 250,
              nombre: "",
              url: "https://i.ibb.co/f2vq96B/1608164511436.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 29,
          codigo: "33",
          nombre: "Feromonas Masculinas Roll On ENAMOR",
          descripcion:
            "Enamor es un perfume compuesto de feromonas con una concentración de 100%. No contiene olor ni mancha, así que podrás seguir haciendo uso de tu perfume habitual sin problemas. El modo de uso es muy fácil; Aplica este perfume Enamor en el cuello o las muñecas y comienza la conquista. Se recomienda su uso cuando vayas de fiesta o a una discoteca, ya que se trata de lugares muy concurridos y, normalmente, son espacios reducidos en los que te cruzas varias veces con las mismas personas. En estos lugares hombres y mujeres están más dispuestas al romance y a encuentros sexuales.",
          detalles:
            "Características:\nFeromonas Masculinas\nContenido: 10 ml.\nConcentración: 100%\n\nAplicación: Rollon\n\nTen en consideración donde aplicas Enamor; debido al calor del cuerpo al rociarlo en la piel se evaporan rápidamente, y por eso sirven para una acción instantánea, de atracción inmediata. Las bacterias de la transpiración y de la superficie de la piel alejan y eliminan rápidamente a las feromonas. En cambio, el rociado en la ropa produce un efecto más duradero y prolongado. Enamor es un complemento perfecto para tus noches de conquista.",
          instagram: "",
          precio: 6990,
          descuento: 14.0,
          stock: 17,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 562,
              nombre: "",
              url: "https://i.ibb.co/1Ljdv5S/Pics-Art-02-27-02-13-41.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 18,
              nombre: "Feromonas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 417,
          codigo: "9780201379723",
          nombre: "Lubricante Sexlub Soft Anal 60ml",
          descripcion:
            "Fórmula Fabricada a base de agua, permite estimular, esta vez llevando una experiencia SEXLUB SOFT ANAL con ingredientes naturales como la flor de Árnica actuando como un antiinflamatorio natural y en combinación del aloe Vera que restaura las paredes anales dañadas por la fricción ",
          detalles:
            "Formato 60ml\nIngredientes: Agua, glycerin, propyleneglycol, carboxymethy,  cellulose, dmdm hydantion and iodopropynyl butylcarbmate, extracto de aloe vera y árnica.",
          instagram: "",
          precio: 11990,
          descuento: 14.0,
          stock: 11,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1269,
              nombre: "",
              url: "https://i.ibb.co/2sNttb6/Pics-Art-08-14-01-26-22.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 157,
          codigo: "772933000210",
          nombre: "Aceite para Masajes Sabor Chocolate-Frutilla (30ml)",
          descripcion:
            "Descubre los Geles Comestibles Erotic, con efecto calor y 100% libres de azúcar, de exquisito aroma y sabor, entrega a tu pareja el mejor sexo oral de la vida y logra una noche inolvidable, se puede retirar fácilmente con agua tibia y jabón neutro. No dejes pasar más tiempo y prueba nuestra línea de Geles comestibles en diferentes sabores. \r\nStarsex te trae nuevos sabores para que disfrutes junto a quien más quieras de exquisitos juegos eróticos, masajes y un delicioso sexo oral lo que aumentará la excitación en ambos. ",
          detalles:
            "Características:\r\nSabor Chocolate Frutilla\r\nGel comestible con efecto calor\r\nEfecto: efecto calor y comestible\r\nContenido: 30 ml\r\nIngredientes: Glicerina, propilengycol, Agua, Saborizante\r\n\r\nObservaciones:\r\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. También se puede aplicar en distintas zonas erógenas para un rico sexo oral. Después de aplicarlo debes soplar y tu pareja sentirá una agradable sensación de calor.\r\nEliminar el exceso con agua tibia y jabón neutro.",
          instagram: "https://www.instagram.com/sexduceme.cl",
          precio: 4990,
          descuento: 14.0,
          stock: 13,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 244,
              nombre: "",
              url: "https://i.ibb.co/YbgvZpX/1608164511274.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 105,
          codigo: "848631011015",
          nombre: "Estrechante Vaginal - Liquid Virgin (30ml)",
          descripcion:
            "El lubricante estrechante vaginal Liquid Virgin produce una sensación rejuvenecedora única la cual, creando una sensación de presión provocando el aumento de placer sexual! Posee un delicioso aroma a Fresas.",
          detalles: "",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 6,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 137,
              nombre: "",
              url: "https://i.ibb.co/Ngvw2kn/IMG-20201108-160455.jpg",
              descripcion: "",
            },
            {
              id: 138,
              nombre: "",
              url: "https://i.ibb.co/vDh0x0L/IMG-20201108-161255.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 41,
          codigo: "7765432100195",
          nombre: "Aceite para Masajes Sabor Chocolate (30ml)",
          descripcion:
            "Descubre los Geles Comestibles Erotic, con efecto calor y 100% libres de azúcar, de exquisito aroma y sabor, entrega a tu pareja el mejor sexo oral de la vida y logra una noche inolvidable, se puede retirar fácilmente con agua tibia y jabón neutro. No dejes pasar más tiempo y prueba nuestra línea de Geles comestibles en diferentes sabores. \nStarsex te trae nuevos sabores para que disfrutes junto a quien más quieras de exquisitos juegos eróticos, masajes y un delicioso sexo oral lo que aumentará la excitación en ambos. ",
          detalles:
            "Características:\nSabor Chocolate\nGel comestible con efecto calor\nEfecto: efecto calor y comestible\nContenido: 30 ml\nIngredientes: Glicerina, propilengycol, Agua, Saborizante\n\nObservaciones:\nModo de uso: Vierta la cantidad deseada en sus manos y luego frote con suavidad sobre el cuerpo de su amante. También se puede aplicar en distintas zonas erógenas para un rico sexo oral. Después de aplicarlo debes soplar y tu pareja sentirá una agradable sensación de calor.\nEliminar el exceso con agua tibia y jabón neutro.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 14,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 414,
              nombre: "",
              url: "https://i.ibb.co/vPTTpsF/Pics-Art-01-18-02-35-45.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 168,
          codigo: "7898450818619",
          nombre: "Estrechante Vaginal Tailandés",
          descripcion:
            "Gel intimo de efecto astringente, contrae las paredes vaginales entregando mayor placer en el momento de la penetración. además de hidratar gracias a sus componentes de aloe vera .Solo debes aplicar una pequeña cantidad en la punta de los dedos y aplicar en la vagina por fuera y por dentro unos minutos antes de la penetración, luego solo comienza a gozar, ya verás cómo serán más placenteras tus relaciones!",
          detalles:
            "Características:\nGel astringente\nEstrechante vaginal\nDe uso femenino\nTubo plástico\nMantener en lugar fresco\nEvitar contacto con los ojos\n\nModo de aplicación: Colocar la cantidad deseada en la punta de los dedos y aplicar en la vagina interna y externamente unos minutos antes de la penetración.\n\nImportante: Los resultados dependerán del uso correcto del producto y del organismo de cada persona. Antes de usa un producto de cosmética erótica se recomienda revisar los componentes de este para evitar reacciones alérgicas. En caso de irritación suspender y consultar con su dermatólogo.",
          instagram: "",
          precio: 4990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 327,
              nombre: "",
              url: "https://i.ibb.co/Kzmw9hs/1609808563224.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 366,
          codigo: "855559003770",
          nombre: "Lubricante Insane Sachet",
          descripcion:
            "Lubricante estimulante que otorga una sensación de hormigueo cálido y aumenta la sensibilidad de las zonas erógenas. Fórmula mixta a base de agua/silicona y hecho con el famoso On For Her como ingrediente principal.",
          detalles:
            "Especificaciones\nCompatible con preservativos de látex.\nLibre de parabenos, colorantes y sabores artificiales.\nLibre de mentol.\nDuración: 24 meses una vez abierto.",
          instagram: "",
          precio: 2990,
          descuento: 14.0,
          stock: 10,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1050,
              nombre: "",
              url: "https://i.ibb.co/ZB7ZdX2/Pics-Art-07-07-02-19-50.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 200,
          codigo: "7809604500403",
          nombre: "Preservativo SensorPlus Long Love (Retardante)",
          descripcion:
            "Condón Transparente de látex natural, lubricado con aceite de silicona (sustancia no espermicida) con efecto retardante (Benzocaína) para una relación más prolongada.",
          detalles: "- Dimensiones: 180mm x 52mm\n- Grosor: 0.075mm",
          instagram: "",
          precio: 1990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1029,
              nombre: "",
              url: "https://i.ibb.co/P5WGrGT/Pics-Art-07-07-02-30-19.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 422,
          codigo: "6959532319989",
          nombre: "Bala Vibradora Pera Morado",
          descripcion:
            "Bala Vibradora estimuladora de zonas erógenas de 10 patrones de vibración, Utiliza 1 pila AAA",
          detalles:
            "La Balita Pera es una excelente alternativa para personas principiantes en el mundo de la juguetería ya que es un fantástico estimulador externo de zonas erógenas. Ideal para estimular pezones o clítoris gracias al diseño de la primera porción que permitirá la estimulación directa. Fabricado de suave silicona, requiere tan solo de 1 pila AAA para entregar placenteros estímulos mediante sus 10 funciones de vibración.\n\nCaracterísticas\nBalita vibradora, estimulador externo\nMaterial: Silicona\nColor: Fucsia\n10 funciones de vibración.\n\nMedidas\nLargo Total: 14.5 cm\nDiámetro mayor: 2.9 cm\n\nObservaciones\nLavar con agua tibia y jabón neutro antes y después de cada uso, dejar secar y guardar en un lugar fresco y seco.\nResistente al agua, NO SUMERGIBLE\nNo incluye pila\n",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1134,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/9475178/thumb/540/540?1625688525",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 11,
              nombre: "Balas Vibradoras",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 67,
          codigo: "777000803008",
          nombre: "Lubricante Anal Híbrido (Sachet 5ml)",
          descripcion:
            "Vive una experiencia anal única llena de erotismo y comodidad gracias al Lubricante Anal, es ideal para dilatar la zona anal logrando una fácil penetración Debido a sus aportes de silicona y agua . Este lubricante anal puedes aplicarlo interna y externamente, lograrás una experiencia anal sin complicaciones. Comienza a disfrutar ahora de encuentros sexuales majestuosos! ",
          detalles:
            "Ingredientes: Glycerol, Hydroxyethyl Cellulose, Water Suluble Silicone Oil, Propylene Glycol, Hyaluronic Acid, Pure Water, Other. \n\nCaracterísticas:\nLubricante dilatador anal\nContenido: Sachet 5ml\nEfecto: Dilatador\n\nObservaciones:\nAplicar la cantidad necesaria alrededor del ano, hasta que el lubricante cubra toda el área.\nSe puede utilizar externa e internamente.\nConservar a temperatura ambiente en un lugar fresco.\nNo utilizar en caso de alergia a alguno de sus componentes.",
          instagram: "",
          precio: 1490,
          descuento: 14.0,
          stock: 148,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 875,
              nombre: "",
              url: "https://i.ibb.co/xX2gnfp/Pics-Art-05-08-12-04-39.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 164,
          codigo: "7798297914669",
          nombre: "Vibrador Gummy Vibe Americano C",
          descripcion:
            "Nuestro Vibrador pequeño Americano esta hecho de PVC super cristalino rojo, suave y flexible de textura tipo Jelly libre de Ftalatos, ideal para disfrutar de uso vagina y/o anal. Disfruta de su multi-velocidad manejado con su control de perilla que va desde las vibraciones mas suaves hasta la mas intensa que te dejara gritando del placer. Ideal para los juegos en solitario o en pareja!\nPara máximo placer utiliza lubricantes a base de agua o lubricantes híbridos.",
          detalles:
            "Características:\nMaterial: PVC super cristalino rojo\nLargo total: 19 cm\nInsertable: 17,5cm\nDiámetro: 4 cm\nColor: Rojo\nBaterías: 2-AA (No Incluidas)\n\nObservaciones:\nMulti velocidades\nLibre de Ftalatos\nLimpiar con agua tibia y jabón neutro, dejar secar y guardar",
          instagram: "",
          precio: 23990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 784,
              nombre: "",
              url: "https://i.ibb.co/Yk2zrBJ/Pics-Art-04-19-01-03-04.jpg",
              descripcion: "",
            },
            {
              id: 785,
              nombre: "",
              url: "https://cdnx.jumpseller.com/orgasmic/image/11100969/resize/635/635?1597166096",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 145,
          codigo: "6914421000263",
          nombre: "Babydoll Morado",
          descripcion:
            "Hermoso y delicado babydoll morado, posee detalles de encaje a tono en la zona de la cintura. Incluye colales a juego.",
          detalles:
            "Talla Estándar.\n\nSe recomienda para talla XS - S, puesto que son tallas pequeñas. ",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 188,
              nombre: "",
              url: "https://i.ibb.co/J7ZXztb/1606606137707.jpg",
              descripcion: "",
            },
            {
              id: 189,
              nombre: "",
              url: "https://i.ibb.co/7kKJq9T/1606606137689.jpg",
              descripcion: "",
            },
            {
              id: 190,
              nombre: "",
              url: "https://i.ibb.co/h9XkdwZ/1606606137671.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 4,
              nombre: "Lencería Femenina",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 2,
              nombre: "Lencería",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/lenceria.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 309,
          codigo: "7765432110118",
          nombre: "Vibrador Mini Conejito ",
          descripcion:
            "El Vibrador  Mini Conejito tiene como principal característica su tamaño moderado y textura de jelly. Contiene estimulador de clítoris y es ideal para iniciarse en el mundo de juguetes sexuales.",
          detalles:
            "Características:\nMaterial: Jelly\nColor: Rosado-Morado\nLargo total: 18cm\nLargo insertable: 13cm\nDiámetro: 2,5cm\nBaterías: 1 AA (no incluidas)\n\nObservaciones:\nVibrador con estimulador de clítoris\n1 intensidad de vibración\nColor según stock\nLavar antes y después de usarlo, con agua tibia y jabón neutro.\n",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 788,
              nombre: "",
              url: "https://i.ibb.co/xmrN93g/Pics-Art-04-19-12-56-34.jpg",
              descripcion: "",
            },
            {
              id: 789,
              nombre: "",
              url: "https://presta.sexshopmayorista.cl/11264-large_default/mini-conejito-vibrador-18x25-cm.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 193,
          codigo: "777010042015",
          nombre: "Lubricante Ice (30ml)",
          descripcion:
            "Ice es un lubricante intimo con efecto frío e intensificador del placer, formulado para otorgar lubricación extra durante tus relaciones sexuales. Hecho a base de agua y silicona  de lenta absorción, te permitirá sentir mayor lubricación durante más tiempo. ICE complementa la lubricación natural del cuerpo y posee efecto frío que maximiza el placer.",
          detalles:
            "Ingredientes: Pure water, Silicone Oil, Glycerol, Hydroxyethyl cellulose, Menthol , Others.\n\nContenido: 30 ml.\n\nModo de Uso:\nAplica la cantidad suficiente de lubricante ICE sobre la zona a lubricar, sopla suavemente y sentirás una agradable sensación de frescura. Incoloro, sin olor, no mancha, no es graso, de base acuosa, totalmente compatible con el uso de preservativos y juguetes eróticos.\n\nPrecauciones:\nConservar a temperatura ambiente en un lugar fresco, seco y protegido de la luz. No utilizar en caso de alergia a alguno de sus componentes. En caso de irritación o incomodidad, descontinúe su uso y consulte a su médico. Mantener fuera del alcance de los niños. Fabricado y envasado por laboratorio CQC. RPC. Dispositivo médico según artículo 101 del código sanitario y reglamento DS825/98.",
          instagram: "",
          precio: 5990,
          descuento: 14.0,
          stock: 34,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 405,
              nombre: "",
              url: "https://i.ibb.co/sQtjQxy/Pics-Art-01-18-02-28-38.jpg",
              descripcion: "",
            },
            {
              id: 406,
              nombre: "",
              url: "https://i.ibb.co/tP3wkMq/Pics-Art-01-18-02-34-17.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 401,
          codigo: "690002020090",
          nombre: "Plug Anal Con Joya Pequeño - Morado",
          descripcion:
            "El Plug anal dilatador Joya, posee un diseño sofisticado y elegante, perfecto para los más exigentes. El plug de metal plateado es inoxidable y su característica principal es su brillante en tono rosado en la base del plug anal. Tamaño pequeño de forma cónica. El Plug Anal Metalizado Joya entrega una estimulación segura y placentera gracias a su diseño, material inoxidable y de fácil limpieza.",
          detalles:
            "Características:\nPlug metalizado de acero inoxidable\nTalla M\nPlug anal dilatador\nDilatación anal\nEstimulación anal\nColor metal: Plateado\nColor joya: Morado\n\nMedidas:\nLargo: 7 cm\nDiámetro mayor: 2.8 cm\nDiámetro menor: 1 cm\n\nObservaciones: Utilizar con lubricante. De fácil limpieza.",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1099,
              nombre: "",
              url: "https://i.ibb.co/GtnnX9T/Pics-Art-07-09-01-08-32.jpg",
              descripcion: "",
            },
            {
              id: 1100,
              nombre: "",
              url: "https://i.ibb.co/tYXsdyb/Pics-Art-07-09-01-09-25.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 387,
          codigo: "7798297917295",
          nombre: "Consolador Caiman Cyber Skin N°1",
          descripcion:
            "Consolador Realístico Suave- Nature 1, fabricado de Cyber Skin, suave, flexible y libre de ftalatos.",
          detalles:
            "Dildos realísticos fabricados de Cyber Skin (silicona médica), material muy suave, flexible y semejante a la piel que entregará máximo confort. El Consolador Realístico Suave- Nature 1 es hipoalergénico, libre de Ftalatos y posee una poderosa ventosa ultra adherente.\n\nCaracterísticas:\nVentosa ultra adherente\nCyber suave y flexible\nHipoalergénico\nLibre de ftalatos\nLargo total: 16,5 cm\nDiametro: 4,5 cm\n\nInstrucciones de cuidado:\nLimpiar con agua tibia y jabón neutro.\nDejar secar y guardar.\nMantener lejos del calor.",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1080,
              nombre: "",
              url: "https://i.ibb.co/RPgrxp4/Pics-Art-07-07-02-14-43.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 12,
              nombre: "Consoladores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 225,
          codigo: "6970260907439",
          nombre: "Huevo Masturbador Gigante - Morado",
          descripcion:
            "El Huevo Masturbador Gigante de Lovetoy es sin duda alguna una excelente opción para principiantes en el uso de juguetes sexuales y un imprescindible  para los más experimentados puesto que sus prominentes texturas distribuidas en su interior de forma horizontal, acariciarán el pene otorgando al usuario una estimulación muy agradable. Su diseño permite mantener un excelente control de la intensidad. Su adaptabilidad te otorgará una cómoda manipulación que desde luego te hará alcanzar el éxtasis.\n\nPara hacer más ameno el uso de este fantástico huevo, aplica lubricante de base acuosa.",
          detalles:
            "Características:\nMaterial: TPE Flexible\nTextura con ondas horizontales\nTransparente\n\nMedidas:\nLargo sin extender: 12 cm\nDiámetro sin extender: 7 cm\nLargo penetrable sin extender: 10 cm\nIncluye Lubricante a base de agua\n\nObservaciones:\nSe recomienda lavar con agua tibia y jabón neutro. Dejar secar antes de guardar.",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 554,
              nombre: "",
              url: "https://i.ibb.co/L9fmHLT/Pics-Art-02-08-03-50-50.jpg",
              descripcion: "",
            },
            {
              id: 555,
              nombre: "",
              url: "https://i.ibb.co/Ky3LCwG/Pics-Art-02-08-03-48-46.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 16,
              nombre: "Masturbadores Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 365,
          codigo: "6914421000195",
          nombre: "Sachet Estimulante Femenino OH",
          descripcion:
            "La marca Secretos de Amor presenta un fantástico gel para la estimulación del deseo sexual y la intensificación de las sensaciones del clítoris, acercándote a la posibilidad de orgasmos más potentes.\n\nExperimenta la calidez, la sensación vibrante con ésta fórmula en gel, fácil de aplicar y sin molestos derrames del producto. Al aplicarlo sobre el clítoris crea una sensación pulsante y vibrante que te excitará y hasta provocará un aumento de tu propia lubricación.",
          detalles:
            "Fórmula inocua a base de agua con componentes de origen herbal 100% naturales.\n\nCon una gota o dos es suficiente para gatillar el deseo y aumentar la excitación\n\nContenido 4 ml",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1018,
              nombre: "",
              url: "https://i.ibb.co/86zMPZQ/Pics-Art-07-07-02-18-18.jpg",
              descripcion: "",
            },
            {
              id: 1019,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/7483396/thumb/540/540?1620678732",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 402,
          codigo: "6900020200913",
          nombre: "Plug Anal Con Joya Pequeño - Fucsia",
          descripcion:
            "El Plug anal dilatador Joya, posee un diseño sofisticado y elegante, perfecto para los más exigentes. El plug de metal plateado es inoxidable y su característica principal es su brillante en tono rosado en la base del plug anal. Tamaño pequeño de forma cónica. El Plug Anal Metalizado Joya entrega una estimulación segura y placentera gracias a su diseño, material inoxidable y de fácil limpieza.",
          detalles:
            "Características:\nPlug metalizado de acero inoxidable\nTalla M\nPlug anal dilatador\nDilatación anal\nEstimulación anal\nColor metal: Plateado\nColor joya: Fucsia\n\nMedidas:\nLargo: 7 cm\nDiámetro mayor: 2.8 cm\nDiámetro menor: 1 cm\n\nObservaciones: Utilizar con lubricante. De fácil limpieza.",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1111,
              nombre: "",
              url: "https://i.ibb.co/BfmNgDT/Pics-Art-07-09-01-08-08.jpg",
              descripcion: "",
            },
            {
              id: 1112,
              nombre: "",
              url: "https://i.ibb.co/tYXsdyb/Pics-Art-07-09-01-09-25.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 26,
          codigo: "7765432130024",
          nombre: "Feromonas Femeninas ENAMOR (30ml)",
          descripcion:
            "ENAMOR es un perfume compuesto en un 100% con feromonas femeninas, que te permitirá evocar una respuesta sexual de quien gustes. Es un hecho que mujeres con niveles altos de emisión de feromonas tienen una ventaja para atraer la atención masculina o femenina según su preferencia sexual. Con ENAMOR sacarás todo tu potencial sexual. Las feromonas de atracción, como las que está compuesto ENAMOR, son las que nos indican de quién debemos enamorarnos. ",
          detalles:
            "Feromonas Femeninas\nAtracción Sexual\nExcitantes y afrodisiacos\nContenido: 30 ml.\nConcentración: 100%.\n\nTen en consideración donde aplicas Enamor; debido al calor del cuerpo al rociarlo en la piel se evaporan rápidamente, y por eso sirven para una acción instantánea, de atracción inmediata. Las bacterias de la transpiración y de la superficie de la piel alejan y eliminan rápidamente a las feromonas. En cambio, el rociado en la ropa produce un efecto más duradero y prolongado. Enamor es un complemento perfecto para tus noches de conquista.",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 15,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 747,
              nombre: "",
              url: "https://i.ibb.co/J3TFxSM/Pics-Art-03-18-04-21-42.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 18,
              nombre: "Feromonas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 165,
          codigo: "7798297914249",
          nombre: "Consolador Jelly Americano C Gummy",
          descripcion:
            "Dildo Consolador suave y flexibles sin perder su firmeza. Lo mejor es su aroma dulce que te teletrasportara al deseo.\nSus rugosidades estan diseñadas para estimular totalmente tu interior .\nEste modelo viene con ventosa adherente en superficies libres y compatible con arnés. Ideal para uso vaginal .\n Para mejorar la experiencia utiliza lubricantes a base de agua o lubricantes híbridos.",
          detalles:
            "Características:\nVentosa adherente\nPVC suave y flexible\nSin ftalatos y sin látex\nLibre de ftalatos\nLargo insertable : 18 cm\nDiámetro de circunferencia:: 14 cm\n\nInstrucciones de cuidado:\nLimpiar con agua tibia y jabón neutro antes y después de cada uso. Dejar secar y guardar\nMantener lejos del calor y guardar en un lugar seco",
          instagram: "",
          precio: 17990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1077,
              nombre: "",
              url: "https://i.ibb.co/YhN12QD/Pics-Art-07-07-02-16-06.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 12,
              nombre: "Consoladores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 153,
          codigo: "7898450818626",
          nombre: "Gel Adormecedor Anal Africano",
          descripcion:
            "Disfruta como nunca antes de una experiencia anal, ya llegó el Gel Africano, de la reconocida marca brasileña Adao & Eva, especialistas en cosmética erótica tales como lubricantes, excitantes, adormecedores anales, y mucho más.\r\nEl gel Africano, es conocido por su potente efecto adormecedor provocando una sensación de menor fricción haciendo más placentera la penetración.",
          detalles:
            "Características:\r\nGel adormecedor anal\r\nContenido: 8 g\r\nUso Anal\r\nTubo plástico\r\nMantener en lugar fresco\r\nEvitar contacto con los ojos\r\n\r\nModo de aplicación:Aplicar la cantidad deseada alrededor del ano unos minutos antes de la penetración.\r\n\r\nImportante: Los resultados dependerán del uso correcto del producto y del organismo de cada persona Antes de usar un producto de cosmética erótica, se recomienda revisar los componentes de este para evitar reacciones alérgicas. En caso de irritación suspender su uso y consultar con su dermatólogo.",
          instagram: "https://www.instagram.com/sexduceme.cl",
          precio: 5990,
          descuento: 14.0,
          stock: 8,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 245,
              nombre: "",
              url: "https://i.ibb.co/LtngzH0/1608164511595.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 469,
          codigo: "855559003367",
          nombre: "Excitante Femenino On Chocolate (5ml)",
          descripcion:
            "Si aun no probaste el maravilloso excitante femenino On sabor Chocolate, este es el momento para que explores sensaciones únicas de placer.\nEl excitante femenino On sabor a chocolate posee una mezcla de aceites esenciales que te entregarán una sensación plena de excitación natural además de una lubricación perfecta para que comiences a gozar!",
          detalles:
            "Ingredientes: Sweet Almond Oil,Cinnamomun Zeylanicum,Natural Tocopherols(Vitamina E); silica, Natural Flavors, Rosemary Oil Extract.\n\nCaracterísticas:\nContenido 5 ML\nEfecto inmediato\nSabor Chocolate\nPulsa y Vibra\nAumenta el flujo sanguíneo y las sensaciones\nLibre de Glicerina\nLibre de Parabeno\nLibre de Gluten\n\nModo de uso: Aplicar de 1 a 2 gotas en el clítoris, luego de 2 minutos aproximados comienza su efecto, con una duración aproximada de 45 minutos. Repita si es necesario.",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1289,
              nombre: "",
              url: "https://i.ibb.co/tHB3H1D/Pics-Art-08-17-02-15-40.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 331,
          codigo: "4049369016594",
          nombre: "Set de Plugs Anales Booty Call Satisfyer (3 piezas Color)",
          descripcion:
            "Los plugs anales presentan un diámetro ascendente increíble, por lo que el ano se estira con suavidad para que puedas acceder cuidadosamente. Es difícil superar la versatilidad de los tapones: Puedes elegir entre el diseño de espiral enrollada, una forma de cono estrecho y la clásica estructura esférica con 3 elementos.",
          detalles:
            "El Kit  contiene 3 modelos diferentes, fabricados con una silicona hipoalergénica y cuenta con una textura muy suave, lo que hace más fácil su uso, además de su limpieza.\n\nAl final del plug hay un anillo que funciona tanto como base para que el plug no se mueva del sitio y que además, sea sencillo extraerlo.\n\nPack de 3 plugs\nSilicona suave hipoalergénica\nPerfecto para principiantes\nAnillo como base de seguridad\nFácil de usar\nFácil de limpiar\n\nMedidas\nPlug 1: 13,5 cm total. Insertable: 9 cm x 2,5cm (la bola chica)/ 3cm (la bola grande).\n\nPlug 2: 13,5 cm total. Insertable: 9 cm x 1,75cm (la bola chica)/ 2,3cm (la bola mediana)/ 3cm (la bola grande)\n\nPlug 3 : 14 cm total. Insertable: 10 cm x 2,8 cm",
          instagram: "",
          precio: 17990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 897,
              nombre: "",
              url: "https://i.ibb.co/bBnGtmn/Pics-Art-05-16-02-39-39.jpg",
              descripcion: "",
            },
            {
              id: 898,
              nombre: "",
              url: "https://i.ibb.co/BrBnW5P/Pics-Art-05-16-02-41-17.jpg",
              descripcion: "",
            },
            {
              id: 899,
              nombre: "",
              url: "https://i.ibb.co/cCbmdM6/Pics-Art-05-16-02-39-06.jpg",
              descripcion: "",
            },
            {
              id: 900,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/15321221/thumb/540/540?1615407557",
              descripcion: "",
            },
            {
              id: 901,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/15321220/thumb/540/540?1615407557",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 22,
              nombre: "Kits",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
          ],
        },
        {
          id: 411,
          codigo: "6959532310856",
          nombre: "Vibrador Prostático con Base de Succión",
          descripcion:
            "Este fabuloso plug anal vibrador con 10 modos de vibración lleva una ventosa resistente en la base para que puedas usarlo donde quieras y como quieras. Está fabricado con silicona suave y segura para el cuerpo y diseñado en EEUU con unas normas de calidad extraordinarias. Recuerda que lo puede usar tanto hombres como mujeres y puedes probarlo en un encuentro para una mayor satisfacción.",
          detalles:
            "Características:\n\nLleva una fuerte ventosa\n10 modos de vibración\nPilas 3 LR34 ( vienen con el producto)\nImpermeable\nCurvado y ergonómico\n100% silicona\nMedidas: 12 cm x 3,4 cm",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1234,
              nombre: "",
              url: "https://i.ibb.co/BNjMJv6/Pics-Art-08-17-02-19-09.jpg",
              descripcion: "",
            },
            {
              id: 1235,
              nombre: "",
              url: "https://i.ibb.co/C7RPqWV/Pics-Art-08-17-02-19-35.jpg",
              descripcion: "",
            },
            {
              id: 1236,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/3946398/thumb/540/540?1622581936",
              descripcion: "",
            },
            {
              id: 1237,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/3946397/thumb/540/540?1622581936",
              descripcion: "",
            },
            {
              id: 1238,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/3946397/thumb/540/540?1622581936",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 30,
              nombre: "Prostáticos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 55,
          codigo: "7898626040219",
          nombre: "Retardante Masculino Extend Striper",
          descripcion:
            "El gel retardante Extend Striper es un potenciador masculino que retarda la eyaculación y prolonga la erección. No lo pienses más y disfruta junto a tu amante de un encuentro sexual perfecto sin preocupaciones!",
          detalles:
            "Ingredientes: Dissodium Edta, Hydroxyethylcellulose, Glicerina, Methyldibromo Glutaronitrile (and) Phenoxyethanol, Propylene Glycol, Vanillyl Butyl Ether, Aqua, Alcohol, Sabor, Extracto de Aceite de Clavo, Zingiber Officinalis Extract, CI 42090.\n\nContenido: 8g\n\nModo de aplicación:\nAplicar las porciones deseadas del Gel Extand Retardante Striper sobre el pene erecto.",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 4,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 306,
              nombre: "",
              url: "https://i.ibb.co/9NTcwCH/1609808563163.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 386,
          codigo: "4061504007366",
          nombre: "Succionador de Clítoris Satisfyer 1 Next Gen",
          descripcion:
            "El travieso Satisfyer 1 es un artilugio imprescindible para quienes están frecuentemente fuera de casa. La forma esbelta y redondeada de este vibrador de ondas de presión hace que sea el compañero de viaje ideal.\n\nEste elegante vibrador de nueva generación despierta el entusiasmo con\nsu suave color oro rosado, y es todo un campeón estimulando sin contacto\ntus zonas más íntimas. Su suave cabecita de silicona antialergénica\nrodea cariñosamente tu clítoris para que puedas relajarte tras un duro\nviaje: 11 programas diferentes te transportarán hasta las más altas\ncotas del placer gracias a la intensa estimulación del clítoris.",
          detalles:
            "Características:\n\n11 programas\nModo de susurro\nFácil de limpiar\nFunciona bajo el agua\n2 x Micro AAA Pila",
          instagram: "",
          precio: 29990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1242,
              nombre: "",
              url: "https://i.ibb.co/sw3646t/Pics-Art-08-17-02-20-03.jpg",
              descripcion: "",
            },
            {
              id: 1243,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/6604728/thumb/540/540",
              descripcion: "",
            },
            {
              id: 1244,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/6604731/thumb/540/540",
              descripcion: "",
            },
            {
              id: 1245,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/6604732/thumb/540/540",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 25,
          codigo: "7765432130017",
          nombre: "Feromonas Masculinas ENAMOR (30ml)",
          descripcion:
            "Enamor es un perfume compuesto de feromonas con una concentración de 100%. No contiene olor ni mancha, así que podrás seguir haciendo uso de tu perfume habitual sin problemas. El modo de uso es muy fácil; Aplica este perfume Enamor en el cuello o las muñecas y comienza la conquista. Se recomienda su uso cuando vayas de fiesta o a una discoteca, ya que se trata de lugares muy concurridos y, normalmente, son espacios reducidos en los que te cruzas varias veces con las mismas personas. En estos lugares hombres y mujeres están más dispuestas al romance y a encuentros sexuales.",
          detalles:
            "Características:\nFeromonas Masculinas\nContenido: 30 ml.\nConcentración: 100%\n\nTen en consideración donde aplicas Enamor; debido al calor del cuerpo al rociarlo en la piel se evaporan rápidamente, y por eso sirven para una acción instantánea, de atracción inmediata. Las bacterias de la transpiración y de la superficie de la piel alejan y eliminan rápidamente a las feromonas. En cambio, el rociado en la ropa produce un efecto más duradero y prolongado. Enamor es un complemento perfecto para tus noches de conquista.",
          instagram: "",
          precio: 12990,
          descuento: 14.0,
          stock: 15,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 742,
              nombre: "",
              url: "https://i.ibb.co/9rkFynx/Pics-Art-03-18-04-22-24.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 18,
              nombre: "Feromonas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 426,
          codigo: "7798297915789",
          nombre: "Bomba De Vacío- Power Pump",
          descripcion:
            "Potencia erecciones más firmes y duraderas con el uso de bombas de vacío. Gracias a la irrigación sanguínea que genera el vacío, lograrás engrosar y agrandar levemente y momentáneamente el pene. Complementa su uso con anillos peneanos para mejorar sus resultados.",
          detalles:
            "Modo de uso: Introduce el pene en el cilindro, presiona la bomba para crear el vacío y sentir como tu pene se agranda en longitud y en grosor a medida que vas bombeando. La transparencia del cilindro te permite ver el agrandamiento del pene. Cuándo hayas alcanzado tu tamaño deseado puedes presionar el botón de liberación, lo que aliviará al instante la presión, facilitando así la extracción.\n\nRecomendaciones:\n\nRebajar el vello alrededor del pene para una succión más cómoda.\nUtilizar lubricante al insertar el pene para evitar friccionar.\n\nCaracterísticas:\nLargo: 19 cm\nDiámetro: 5,5 cm\nLibre de Ftalatros.\n\nInstrucciones de cuidado:\nLimpiar con agua tibia y jabón neutro antes y después de usar. Dejar secar y guardar.\nGuardar en un lugar fresco y seco.",
          instagram: "",
          precio: 25990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1293,
              nombre: "",
              url: "https://i.ibb.co/tsH52Yc/Pics-Art-08-17-02-16-56.jpg",
              descripcion: "",
            },
            {
              id: 1294,
              nombre: "",
              url: "https://sexshopmayorista.cl/14489-large_default/bomba-de-vacio-power-pump.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 9,
              nombre: "Potenciadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 375,
          codigo: "697309064026",
          nombre: "Lubricante Frutas Exoticas Toko",
          descripcion:
            "La línea Aroma de Shunga tiene exquisitos lubricantes con sabores frutales, especiales para excitar y guiar los sentidos. Además se caracterizan por su sensación ultra sedosa y larga duración.\n\nTodos los lubricantes en base de agua están pensados para facilitar la penetración, el sexo manual, o simplemente para ofrecer una sensación aún más placentera. La gracia de esta línea es que además brinda la posibilidad de estimular el cuerpo del otro a través del sentido del gusto, aprovechando los deliciosos sabores que trae esta excelente línea de productos.",
          detalles:
            "Características:\n- 165 ml\n- Hipoalergénico\n- Base de agua\n- Comestible\n- No mancha\n- No contiene azúcar\n- Compatible con látex y juguetes de silicona\n- Fabricado en Canadá",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 965,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/656996/thumb/540/540?1622840846",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 489,
          codigo: "716222420075",
          nombre: "Lubricante Wet Hemptation 30 Ml",
          descripcion:
            "Lubricante íntimo formulado con extracto de cáñamo orgánico certificado.",
          detalles:
            "El lubricante Wet Hemptation, es un lubricante íntimo personal de línea Premium elaborado con extracto de cáñamo orgánico certificado por USDA, el lubricante esta formulado con una mezcla única de extractos y elementos botánicos pensados para entregar una sensación completamente sedosa y sensual al momento de aplicarlo en las zonas íntimas. Perfecto para relajarse y liberarse.\n\nDiseñado para complementar los beneficios naturales del cáñamo. Totalmente natural y seguro para todo tipo de piel , fácil de lavar solo con agua tibia o una toalla húmeda. Úsalo como complemento de su humectación natural y para mejorar la intimidad.\n\nIngredientes: Jugo de hoja de Aloe Barbadensis, Propanodiol, Extracto de Tremella Fuciformis (Champiñón), Gluconolactona, Extracto de Semilla de Cannabis Sativa (Cáñamo), Hialuronato de Sodio, Extracto de Semilla de Chenopodium Quinoa, Extracto de Semilla de Linum Usitatissimum (Semilla de Lino), Extracto de Camelia Sinensiva Avena (Avena Sinensis) Extracto de semilla, hidroxietilcelulosa, goma de xantano, benzoato de sodio, sorbato de potasio, ácido cítrico.\n\nCaracterísticas:\nLubricante íntimo\nRecomendable para pieles sensibles\nExtracto de cáñamo orgánico\nSin Azúcar\nCompatible con Látex\nDe fácil limpieza\nSeguro para usar con juguetes\nApto para veganos\nContenido: 30 ml",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1267,
              nombre: "",
              url: "https://i.ibb.co/Wg6ySWJ/Pics-Art-08-14-01-27-47.jpg",
              descripcion: "",
            },
            {
              id: 1268,
              nombre: "",
              url: "https://sexshopmayorista.cl/14187-large_default/lubricante-wet-hemptation-30-ml.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 485,
          codigo: "5600298351225",
          nombre: "Potenciador Masculino- Touro Erection",
          descripcion:
            "Crema Potenciadora de erección, formulado con potentes ingredientes naturales vasodilatadores y afrodisiacos.",
          detalles:
            "Touro es una eficaz crema potenciadora de erección, formulada con Taurina y Ginkgo Biloba, ambos ingredientes activos de extractos naturales que promueven la vasodilatación en la zona de aplicación que sensibilizará la zona dejándola expuesta a estímulos más intensos. Posee el reconocido afrodisíaco Ginseng que proporcionará rápidas y firmes erecciones promoviendo la excitación y placer sexual.\n\nCaracterísticas:\nEfecto: Sensibilizante\nAroma: Sin aroma\nSabor: Sin sabor\nContenido: 15 ml\n\nModo de uso:\nAplicar una cantidad generosa en pene especialmente en glande, masajear un par de minutos.\nDuración: 6 meses después de abierto y en condiciones ideales de conservación.\n\nIngredientes:\nIngredients: Aqua, Glycerin, Cetearyl Alcohol, Ethylhexyl Palmitate, Propylene Glycol, Glyceryl Stearate, Helianthus Annuus Seed Oil, PEG-100 Stearate, Taurine, Sodium Polyacrylate, Butylene Glycol, Niacinamide, Phenoxyethanol,Hydrogenated Polydecene, Butyrospermum Parkii Butter, Prunus Amygdalus Dulcis Oil, Panax Ginseng Root Extract, Disodium EDTA, Ginkgo Biloba Leaf Extract, Ethylhexylglycerin, Trideceth-6, BHT, Vanillyl Butyl Ether, Sodium Benzoate, Isopentyldiol, Menthyl Lactate, Sodium Metabisulfite.\n\nObservaciones:\nConservar en un lugar fresco y seco, mantener alejado de la luz y calor. El producto debe Mantener fuera del alcance de los niños.\nSolo uso externo.\nNo ingerir.",
          instagram: "",
          precio: 22990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1250,
              nombre: "",
              url: "https://i.ibb.co/XLyw6JX/Pics-Art-08-17-02-11-49.jpg",
              descripcion: "",
            },
            {
              id: 1251,
              nombre: "",
              url: "https://i.ibb.co/6DzBTJY/Pics-Art-08-17-02-12-53.jpg",
              descripcion: "",
            },
            {
              id: 1252,
              nombre: "",
              url: "https://i.ibb.co/7br8hdV/Pics-Art-08-17-02-12-18.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 9,
              nombre: "Potenciadores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
          ],
        },
        {
          id: 378,
          codigo: "879959004533",
          nombre: "Aceite de Masaje Hemp Lavanda",
          descripcion:
            "Producto 100% Vegano.\n\n Su fórmula combina aceites esenciales naturales de cáñamo, almendras, semilla de uva, damasco y vitamina E\n\nÉsta formulación rica en nutrientes se absorbe profundamente en la piel para una hidratación  y acondicionamiento sin residuo graso.\n\nNo dejes de probar alguna de sus exóticas fragantes variantes, no necesitarás ni querrás lavarlo",
          detalles:
            "Beneficios\n\nEl aceite de almendras dulces es un rico emoliente que rejuvenece la piel a través de hidratación profunda\n El aceite de cáñamo tiene altos niveles de Omega 3 y 6 , ácidos grasos esenciales para la nutrición y protección de la piel\n El aceite de damasco es naturalmente ligero y abundante en antioxidantes\nEl aceite de romero es un poderoso antioxidante y ayuda a suavizar las arrugas y marcas de expresión\nContenido 60ml",
          instagram: "",
          precio: 8990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1090,
              nombre: "",
              url: "https://i.ibb.co/w00vPZy/Pics-Art-07-07-01-14-49.jpg",
              descripcion: "",
            },
            {
              id: 1091,
              nombre: "",
              url: "https://i.ibb.co/phWCsrx/Pics-Art-07-07-01-13-15.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 438,
          codigo: "6959532319682",
          nombre: "Kit Dilatadores Anales Iniciación",
          descripcion:
            "El kit Plug Anal Begginer's consta de 3 Plugs de distintos tamaños, tanto en largo como en diámetro, pensado para principiantes que quieran experimentar. Poseen un diseño ergonómico, textura suave y son aptos para la ducha ya que son sumergibles y tienen sopapo en su base. Aptos para lubricantes base a agua o híbridos, lavables.",
          detalles:
            "Características\nMaterial Silicona\nColor Negro\nCantidad 3 Plugs\nBase en forma de sopapo\n\nMedidas\nPequeño\nLargo Total: 8,5cm\nLargo insertable: 7,0cm\nDiámetro insertable: 2,5cm\nDiámetro de Tope: 4,0cm\n\nMediano\nLargo Total: 11,5cm\nLargo insertable: 10,0cm\nDiámetro insertable: 2,8cm\nDiámetro de Tope: 5,0cm\n\nGrande\nLargo Total: 13,0cm\nLargo insertable: 11,0cm\nDiámetro insertable: 3,5cm\nDiámetro de Tope: 5,0cm\n\nObservaciones\n3 Plugs de distintos tamaños\nCobertura de Silicona\nResistente al agua / Sumergible\nApto para Lubricantes base a agua o híbridos",
          instagram: "",
          precio: 24990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1278,
              nombre: "",
              url: "https://i.ibb.co/wQmzKWw/Pics-Art-08-17-02-15-10.jpg",
              descripcion: "",
            },
            {
              id: 1279,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/prettylove-BI-014456H-05_2000x.jpg?v=1626550230",
              descripcion: "",
            },
            {
              id: 1280,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/prettylove-BI-014456H-07_2000x.jpg?v=1626550230",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 441,
          codigo: "6959532317350",
          nombre: "Vibrador Doble Estimulación Bruno",
          descripcion:
            "La masturbación es más entretenida junto a Brunito. Su diseño dual está pensado para un doble placer porque te estimula el Punto G y el clítoris.\nCon 3 intensidades de vibración y 7 tipos de pulsaciones,vas a poder elegir la que más te guste. Fuerte o suave, rápida o lenta; tú eliges.\nAl vibrador Bruno también lo puedes llevar a la cama y jugar junto a tu amante; Hagan un entretenido trío. Descubran en conjunto todos los placeres de la vida.",
          detalles:
            "Características del Vibrador: \nMaterial Silicona\nColor Fucsia-blanco\n3 Intensidades de vibración\n7 Tipos de pulsaciones\nUsa dos baterías AAA\n\nMedidas\nLargo total: 20,0cm\nLargo insertable: 10,0cm\nDiámetro insertable: 3,0cm\n\nObservaciones\nDiseño ergonómico y dual\nEstimulador de Clítoris /Punto G\nResistente al agua / No sumergible\nApto para Lubricantes base a agua o híbridos",
          instagram: "",
          precio: 25990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1239,
              nombre: "",
              url: "https://i.ibb.co/d26vf4z/Pics-Art-08-17-02-18-43.jpg",
              descripcion: "",
            },
            {
              id: 1240,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/12358_2000x.jpg",
              descripcion: "",
            },
            {
              id: 1241,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/12355_2000x.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 373,
          codigo: "697309064002",
          nombre: "Lubricante Cherry Toko",
          descripcion:
            "La línea Aroma de Shunga tiene exquisitos lubricantes con sabores frutales, especiales para excitar y guiar los sentidos. Además se caracterizan por su sensación ultra sedosa y larga duración.\n\nTodos los lubricantes en base de agua están pensados para facilitar la penetración, el sexo manual, o simplemente para ofrecer una sensación aún más placentera. La gracia de esta línea es que además brinda la posibilidad de estimular el cuerpo del otro a través del sentido del gusto, aprovechando los deliciosos sabores que trae esta excelente línea de productos.",
          detalles:
            "Características:\n- 165 ml\n- Hipoalergénico\n- Base de agua\n- Comestible\n- No mancha\n- No contiene azúcar\n- Compatible con látex y juguetes de silicona\n- Fabricado en Canadá",
          instagram: "",
          precio: 14990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1056,
              nombre: "",
              url: "https://i.ibb.co/f470Bgn/Pics-Art-07-07-01-05-37.jpg",
              descripcion: "",
            },
            {
              id: 1057,
              nombre: "",
              url: "https://cdnx.jumpseller.com/eroshopmayorista/image/656980/thumb/540/540?1622840829",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 106,
          codigo: "7804638900014",
          nombre: "Cápsulas Libi Woman",
          descripcion:
            "Estas cápsulas naturales están hechas en base a Damiana, que es una planta amazónica reconocida por ser un potente afrodisíaco natural que ayuda a aumentar el deseo sexual y la libido gracias a sus altos efectos estimulantes, lo que se traduce en una mejora de la vida sexual de la pareja en sí.\n\nEste producto se recomienda para mujeres que busquen potenciar el deseo sexual y la líbido de forma natural, lo mejor de todo es que no genera efectos colaterales ni dependencia y se pueden ver resultados permanentes a partir del 5to día de su consumo.",
          detalles:
            "Contenido: 60 cápsulas (duración 1 mes)\n\nDosis recomendada: 1 cápsula cada 12 horas.\n\nImportante:\n-Recomendado para hombres y mujeres, independiente de su edad u orientación sexual.\n-No genera dependencia ni tiene efectos colaterales.\n-Sus componentes actúan fuertemente sobre el desinterés sexual, mejorando la libido y el deseo y estimulando de manera natural la sexualidad. Esto, ya que provoca excitación en la zona genital, por lo que ayuda a sentir apetito sexual en las personas que lo ingieren.\n-Sus componentes están libres de excipientes, esto quiere decir que se encuentran en su calidad más pura, sin químicos.\n-Las cápsulas son de envoltura de origen 100% vegetal que busca responder a las necesidades y demandas de los consumidores que, ya sea por estilo de vida, necesidades dietéticas, factores culturales, tendencias vegetarianas o que están preocupadas por su salud, privilegian la ingesta de productos de origen no animal.\n-Damiana es un producto que cuenta con la certificación del Seremi de Salud y con sus registros sanitarios al día.\n\nIngredientes: Damiana, Cápsula vegetal (HPMC 100%)\n\nModo de Empleo: Tomar con un vaso de agua (200 ml)\n\nAlmacenamiento: Mantener el envase bien cerrado en un lugar fresco y seco a temperaturas entre -15-25° C \n\nPrecauciones: Su consumo no es recomendable para menores de 8 años, embarazadas y nodrizas, salvo indicación profesional competente y no reemplaza a una alimentación balanceada. Tampoco se recomienda para personas que tengan presión alta o problemas cardíacos.",
          instagram: "",
          precio: 15990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 141,
              nombre: "",
              url: "https://i.ibb.co/DpjbS9M/IMG-20201108-143453.jpg",
              descripcion: "",
            },
            {
              id: 142,
              nombre: "",
              url: "https://i.ibb.co/cTJMRPz/IMG-20201108-145236.jpg",
              descripcion: "",
            },
            {
              id: 143,
              nombre: "",
              url: "https://i.ibb.co/vzzd43n/IMG-20201108-144904.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 68,
          codigo: "777000805002",
          nombre: "Lubricante Placer Intenso (Sachet 5ml)",
          descripcion:
            "El Lubricante Excitante es intensificador del placer, formulado para otorgar lubricación extra en los encuentros sexuales. Elaborado a base de agua y de silicona de lenta absorción, te permitirá sentir mayor lubricación durante más tiempo. Sabor y aroma a frutilla, posee leve efecto calor que maximiza el placer.\n",
          detalles:
            "Ingredientes: Glycerol, Hydroxyethyl, Cellulose, Silicone Oil, Prpopylene Glycol, Hyaluronic Acid, L-Arginine, Pure wáter, Strawberry Essence, Other.\n\nContenido: 5ml\n\nObservaciones:\nLubricante íntimo\nLubricante vaginal\nLeve efecto calor\nLeve sabor a frutilla\nComestible\nIncoloro\nA base de silicona y agua",
          instagram: "",
          precio: 1490,
          descuento: 14.0,
          stock: 100,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 849,
              nombre: "",
              url: "https://i.ibb.co/TRr1t6W/Pics-Art-01-18-02-34-42.jpg",
              descripcion: "",
            },
            {
              id: 850,
              nombre: "",
              url: "https://i.ibb.co/5RbCF9Z/IMG-20201108-164403.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 479,
          codigo: "5600298351010",
          nombre: "Lubricante Anal Sensitive - Orgie",
          descripcion:
            "Es un lubricante con textura de gel, con formulación hibrida lo que lo hace un gel con un agradable textura sin residuos en la piel, está pensado para principiantes en el sexo anal, contiene ácido hialurónico que hidrata y ayuda a tonificar los pliegues anales posterior a tener relaciones.",
          detalles:
            "ORGIE como símbolo de sensualidad, complicidad y placer. La marca europea con espíritu Brasileño, que te invita a liberar e intensificar tu placer y sensualidad. \n\nLubricante Anal Sensitive, es un lubricante con textura de gel, con formulación hibrida lo que lo hace un gel con un agradable textura sin residuos en la piel, está pensado para principiantes en el sexo anal, contiene ácido hialurónico que hidrata y ayuda a tonificar los pliegues anales posterior a tener relaciones, además de tener centella asiática que trata de eliminar posibles fisuras, evitando así la incomodad posterior al sexo anal.\n\nIngredientes: Qgua, Glycerin Centella Asiática,\nContenido: 100 ml\nEfecto: Tonificador, regenerador \nSabor: Sin Sabor, no comestible.\n\nObservaciones\n- Uso diario y ocasional\n- Mantener a temperatura ambiente\nModo de Aplicación\nAplicar la cantidad de forma generosa en el área deseada. \n\nADVERTENCIAS\n- Solo uso externo\n\n- Mantener fuera del alcance de los niños\n\n- Mantener alejado de los ojos y los oídos.\n\n- No usar si su pareja está embarazada",
          instagram: "",
          precio: 21990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1275,
              nombre: "",
              url: "https://i.ibb.co/PGXtksH/Pics-Art-08-14-01-23-50.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 448,
          codigo: "5600298351157",
          nombre: "Estimulante Femenino - Orgasm Drops Clirorial",
          descripcion:
            "Estimulante en gotas con dispensador (gotario) para clítoris. Genera un efecto calor y hormigueo que sensibilizará la zona dejándola más expuesta a estímulos propiciando la excitación. Posee un sofisticado, elegante y discreto envase de cristal.",
          detalles:
            "Características:\nEfecto: Hormigueo y calor\nAroma: Menta\nSabor: Leve sabor a menta\nContenido: 30 ml\n\nModo de uso:\nAplicar 3 a 4 gotas en clítoris y masajear para su absorción.\nDuración: 6 meses después de abierto y en condiciones ideales de conservación.\n\nIngredientes:\nIngredients: Glycerin, Polysorbate 20, Propylene Glycol, Phenoxyethanol, Eugenia Caryophyllus Flower Oil, Eugenol, Mentha Arvensis Leaf Oil, Ethylhexylglycerin, Limonene\n\nObservaciones:\nConservar en un lugar fresco y seco, mantener alejado de la luz y calor. El producto debe Mantener fuera del alcance de los niños.\nSolo uso externo.\nNo ingerir.\n\nPrecauciones: No usar sobre piel irritada o lesionada. En caso de irritación, suspenda su uso y enjuague con agua. En caso de contacto con los ojos, enjuagar con abundante agua.",
          instagram: "",
          precio: 23990,
          descuento: 14.0,
          stock: 3,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1290,
              nombre: "",
              url: "https://i.ibb.co/bBqNhDS/Pics-Art-08-17-02-13-49.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 8,
              nombre: "Estimulantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 310,
          codigo: "6914421000201",
          nombre: "Ducha Anal Metálica",
          descripcion:
            "Una limpieza anal a veces puede ser un poco incómoda si se practica en la cama o en algún otro lugar de la casa. El beneficio de tener una Ducha Anal es la comodidad de hacer tu limpieza mientras te bañas. La Ducha Anal Metálica nos presenta una manguera de aluminio de 1 metro 45 centímetros con un adaptador universal para la llave de agua .Además posee tres cabezales cambiables con orificios para el lavado anal.",
          detalles:
            "Características:\nMaterial: Aluminio\nColor: Plateado\n\nMedidas:\nLargo Total: 1m 45cm\nLargo Insertable: 14,5cm\nDiámetro Mayor: 1,5cm\n\nObservaciones:\nDucha anal\nAdaptador de llave universal\nPosee tres cabezales cambiables.",
          instagram: "",
          precio: 23990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 802,
              nombre: "",
              url: "https://i.ibb.co/BshDt0D/Pics-Art-04-19-12-56-07.jpg",
              descripcion: "",
            },
            {
              id: 803,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/2640-5043_2000x.jpg?v=1617659466",
              descripcion: "",
            },
            {
              id: 804,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/33200000100_3__Starsex_2000x.jpg?v=1617659466",
              descripcion: "",
            },
            {
              id: 805,
              nombre: "",
              url: "https://cdn.shopify.com/s/files/1/0275/7820/4293/products/2640-5042_2000x.jpg?v=1617659466",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 14,
              nombre: "Accesorios",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 17,
              nombre: "Sexo Anal",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 110,
          codigo: "7765432100164",
          nombre: "Lubricante Femenino (Sachet 5ml)",
          descripcion:
            "Lubricante Vaginal Femenino  de 5 ml, con leve aroma y sabor a frutilla. Perfecto para utilizar con juguetes o para apoyar la lubricación de la zona genital durante el acto.",
          detalles:
            "Características:\nHipoalergénico\nA base de agua\nLeve sabor a frutilla\nHecho en Chile.\nPresentación de 5 Ml",
          instagram: "",
          precio: 1790,
          descuento: 14.0,
          stock: 18,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 887,
              nombre: "",
              url: "https://i.ibb.co/cXKbK62/Pics-Art-05-16-02-33-52.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 83,
          codigo: "7809604500519",
          nombre: "Preservativo SensorPlus Luminosos",
          descripcion:
            "Porque como tú creemos en el sexo seguro, en esta sección te ofrecemos gran variedad de preservativos, ya sea con sabores, texturas o que alumbren en la oscuridad, tenemos todo lo que estés buscando para hacer de tu experiencia sexual además de placentera 100% segura.",
          detalles:
            "Preservativos de látex transparente lubricados y fluorecentes para mayor diversión ",
          instagram: "",
          precio: 1990,
          descuento: 14.0,
          stock: 1,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1027,
              nombre: "",
              url: "https://i.ibb.co/NmbqzBP/Pics-Art-07-07-02-28-23.jpg",
              descripcion: "",
            },
            {
              id: 1028,
              nombre: "",
              url: "https://i.ibb.co/qNMRTMv/Pics-Art-02-28-08-14-37.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 29,
              nombre: "Preservativos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 380,
          codigo: "638258901048",
          nombre: "Loción de Masaje con Feromonas Pure Instinct",
          descripcion:
            "Tentar al Tacto. Inspirar el Deseo. Despertar los sentidos con Pure Instinct True Blue Loción de Masaje.\n\nDelicadamente perfumada con notas suculentas de mango australiano y mandarina, un toque de canela y exótica mezcla de miel y almizcle blanco, esta fórmula de feromonas infundida proporciona un deslizamiento irresistiblemente rico y sedoso.\n\nContenido: 236ml",
          detalles: "",
          instagram: "",
          precio: 16990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1277,
              nombre: "",
              url: "https://i.ibb.co/Hzcx2j8/Pics-Art-08-14-01-22-57.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 18,
              nombre: "Feromonas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 7,
              nombre: "Masajes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/masajes.jpg",
            },
          ],
        },
        {
          id: 391,
          codigo: "7798297913990",
          nombre: "Vibrador Bananin Negro",
          descripcion:
            "Vibe Americano Bananin Negro. Este vibrador perteneciente a la línea bronx posee una textura siliconada que se adapta a la temperatura corporal brindando placer a la pareja. Para mayor satisfacción el juguete viene con un vibrador multi speed que regula la intensidad.",
          detalles:
            "Características: \nDildo c/Vibracion\nMedidas: 21 x 3,5 cm\nVibración Regulable\nMaterial: Silicona\nLibre de Ftalatos.",
          instagram: "",
          precio: 21990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: null,
          imagenes: [
            {
              id: 1009,
              nombre: "",
              url: "https://i.ibb.co/qWY2XHq/Pics-Art-07-07-02-16-58.jpg",
              descripcion: "",
            },
            {
              id: 1010,
              nombre: "",
              url: "https://www.sexshopfoxy.com/wp-content/uploads/2021/05/Webp.net-resizeimage-2021-05-09T144957.630.jpg.webp",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 10,
              nombre: "Vibradores",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 1,
              nombre: "Juguetes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/juguetes.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
        {
          id: 130,
          codigo: "7765432100041",
          nombre: "Lubricante Retardante PowerSex (100ml)",
          descripcion:
            "El lubricante retardante, es un retardante eyaculador que prolonga la erección sin perder la sensibilidad, obteniendo relaciones sexuales más intensas, prolongadas y placenteras. Solo debe aplicar y masajear una pequeña cantidad sobre el pene erecto. ",
          detalles:
            "Formato: 100 ml.\nCaracterísticas:\nHipoalergénico\nA base de agua\nHecho en Chile",
          instagram: "",
          precio: 9990,
          descuento: 14.0,
          stock: 5,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1045,
              nombre: "",
              url: "https://i.ibb.co/CJhzJQB/Pics-Art-07-07-12-58-55.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 6,
              nombre: "Lubricantes",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 26,
              nombre: "Retardantes Masculinos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 210,
          codigo: "603912262667",
          nombre: "Peta Zeta Sabor Cereza",
          descripcion:
            "Peta Zeta es un delicioso caramelo en forma de pequeños cristales que en contacto con la saliva “estallan”, provocando un pequeño hormigueo en la boca y en las partes intimas de tu pareja, es ideal para el sexo oral.\nPeta Zeta te ayuda a salir de la rutina y jugar con distintos movimientos para el disfrute en compañía, pasar un rato distinto y divertido.\nEl uso de Peta Zeta es muy sencillo, solo debes poner los dulces dentro de la boca antes de comenzar a dar sexo oral a la pareja y listo.\nCada sachet trae el contenido suficiente para poder realizar varias sesiones de sexo oral e incluso intercambiar en la pareja si se desea ya que es compatible tanto para hombres como para mujeres.\nEs ideal para quienes buscan nuevas sensaciones y salir de la rutina o para quienes simplemente aman el sexo oral y quieren seguir experimentándolo y lo más importante, a diferencia de otro tipo de elementos comestibles que se utilizan en el sexo, Peta Zeta no deja pegajoso.\nDespués de probar Peta Zeta de seguro que el sexo oral será una de tus prácticas favoritas en la cama o donde quieras.",
          detalles:
            "Características:\nCaramelo efervescente\nPara sexo oral \nUnisex\n\nIngredientes: Azúcar, jarabe de maíz, ácido cítrico, ácido láctico, lactosa\n\nObservaciones: Vierte una porción en tu boca del caramelo efervescente y practica sexo oral. Libre de grasas saturadas y trans, colesterol, sodio. No aplicar directo en genitales \n\nImportante: Este producto contiene azúcar y lactosa por lo que si eres intolerante a la lactosa, no es recomendable que lo utilices.",
          instagram: "",
          precio: 3990,
          descuento: 14.0,
          stock: 28,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 536,
              nombre: "",
              url: "https://i.ibb.co/0FYRBpn/Pics-Art-02-09-02-29-43.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 23,
              nombre: "Sexo Oral",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
            {
              id: 19,
              nombre: "Para Ellos",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellos.jpg",
            },
            {
              id: 21,
              nombre: "Para Parejas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
            {
              id: 25,
              nombre: "Unisex",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ambos.jpg",
            },
          ],
        },
        {
          id: 197,
          codigo: "771956402032",
          nombre: "Feromonas Femeninas con Aroma - PureSex Sensuality ",
          descripcion:
            "Pure Sex Sensuality es un perfume de uso femenino. Su principal característica es que contiene aromas frutales y sensuales, lo cual permite potenciar la producción natural de feromonas femeninas. El Perfume Pure Sex Sensuality presenta un envase discreto y sofisticado, donde el color del perfume representa la sensualidad femenina. Con Pure Sex podrás potenciar tu confianza, autoestima y sensualidad. Disfruta de una tu vida sexual junto al Perfume Femenino Pure Sex Sensuality y potencia la producción natural de tus feromonas.",
          detalles:
            "Ingredientes: Agua, Alcohol, Esencia de Naranja, Frambuesa, Vainilla, Bergamo, Madera, Pimienta, Sándalo, Ámbar y Almizcle.Contenido: 33.5ml\nAroma: Frutal\n\nObservaciones:\nPerfume Femenino\nPotencia la producción de feromonas\nDuración moderada con aura suave\n\nImportante:\nLos resultados dependerán del uso correcto del producto y del organismo de cada persona.\nAntes de usar un producto de cosmética erótica se recomienda revisar los componentes de este para evitar reacciones alérgicas.\nEn caso de irritación suspender y consultar con su dermatólogo.",
          instagram: "",
          precio: 19990,
          descuento: 14.0,
          stock: 2,
          activo: true,
          destacado: false,
          imagenes: [
            {
              id: 1087,
              nombre: "",
              url: "https://i.ibb.co/ydHkq0P/Pics-Art-07-07-01-12-44.jpg",
              descripcion: "",
            },
          ],
          categorias: [
            {
              id: 18,
              nombre: "Feromonas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/accesorios.jpg",
            },
            {
              id: 5,
              nombre: "Cosmética",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/cosmetica.jpg",
            },
            {
              id: 20,
              nombre: "Para Ellas",
              descripcion: null,
              imagen: "https://sexduceme.cl/images/categorias/ellas.jpg",
            },
          ],
        },
      ],
      imagenesEvaluadas: [],
    };
  },
  methods: {
    getAllProducts() {
      axios
        .get("/inventarios/productos")
        .then((response) => {
          if (response.status === 200) {
            this.productos = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async evaluarImagenes() {
      let auxResult = null;
      this.productos.forEach(async (producto) => {
        auxResult = {
          productoId: producto.id,
          productoCodigo: producto.codigo,
          productoNombre: producto.nombre,
          totalImagenes: producto.imagenes.length,
          imagenesOK: [],
          imagenesFAIL: [],
        };
        await Promise.all(
          producto.imagenes.map(async (imagen) => {
            await axios
              .get(imagen.url)
              .then((response) => {
                if (response.status === 200) {
                  auxResult.imagenesOK.push(imagen);
                }
              })
              .catch((error) => {
                if (error.status === 404) {
                  auxResult.imagenesFAIL.push(imagen);
                }
              });
          })
        );
        this.imagenesEvaluadas.push(auxResult);
      });

      console.log("RESULTADOS");
      console.log(this.imagenesEvaluadas);
    },
  },
};
</script>

<style>
</style>