module.exports = {
    aplicarDescuento(precio, descuento) {
        const precioDescuento = precio - (precio * (descuento / 100));
        return precioDescuento - (precioDescuento % 10);
    },
    resumenCarro(carro) {
        const resumen = {
            total: 0,
            bultos: 0
        }
        carro.forEach(producto => {
            resumen.total += producto.precio * producto.cantidad;
            resumen.bultos += Number(producto.cantidad);
        });
        return resumen;
    }
}