<template>
  <b-container>
    <Card
      v-for="(producto, index) in productos"
      :key="producto.id"
      :producto="producto"
      :class="'my-1 '+(index%2===0?'bg-rosa':'')"
      @seleccion="seleccionar($event)"
    ></Card>
  </b-container>
</template>

<script>
import Card from "@/components/cards/HorizontalProduct";
export default {
  components: {
    Card,
  },
  props: ["productos"],
  data() {
    return {};
  },
  methods: {
    seleccionar(producto) {
      console.log("En Selector: ");
      console.log(producto);
      this.$emit("seleccion", producto);
    },
  },
};
</script>

<style>
</style>