<template>
  <div>
    <b-row @click="seleccionar(producto)" class="seleccionable hover-burdeo">
      <b-col cols="2">
        <b-img
          v-bind="mainProps"
          rounded
          left
          :src="producto.imagenes[0].url"
        ></b-img>
      </b-col>
      <b-col cols="8" class="text-left align-self-center ml-2">
        <b-col cols="12">
          <span>{{ producto.nombre }}</span>
        </b-col>
        <b-col class="small" cols="12">
          <span>{{ producto.stock }} disponibles - {{ formatPrice(aplicarDescuento(producto.precio,producto.descuento)) }}</span>
          <b-icon v-if="producto.descuento>0" class="ml-1" icon="tags-fill"></b-icon>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { formatPrice } from "@/assets/functions/formaters";
const { aplicarDescuento } = require('@/assets/functions/calculosFinancieros.js')

export default {
  props: ["producto"],
  data() {
    return {
      mainProps: {
        width: 75,
        height: 75,
      },
    };
  },
  methods: {
    formatPrice,
    aplicarDescuento,
    seleccionar(producto) {
      console.log("En Card: ");
      console.log(producto);
      this.$emit("seleccion", producto);
    },
  },
};
</script>

<style>
</style>