import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/oldLogin.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/inventario/productos',
    name: 'Inventario',
    component: () => import(/* webpackChunkName: "about" */ '../views/oldInventario.vue')
  },
  {
    path: '/punto-venta',
    name: 'punto-venta',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PosVenta.vue')
  },
  {
    path: '/ventas',
    name: 'ventas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/oldVentas.vue')
  },
  {
    path: '/informes',
    name: 'informes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Informes.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log(from.path + " -> " + to.path)
  const credenciales = JSON.parse(localStorage.getItem('credentials'));
  console.log(credenciales, !credenciales, to.path, to.path !== '/login')
  if (!credenciales && to.path !== '/login') {
    // router.push("/login");
    window.location = '/login'
    alert("Inicia Sesión");
  } else {
    next();
  }
});

export default router
